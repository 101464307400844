import moment from 'moment'
import { getCustomers } from '../../Api/customers'
import { loadCustomers } from '../State/customers'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'name',
    }

    const response = await getCustomers({ params })

    dispatch(loadCustomers(response.data.data))

  } catch (error) {
    
    throw error

  }

}
