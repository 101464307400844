import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Dropdown, Menu, Button, Tooltip, Badge } from 'antd'
import { LinkButton } from '../../../../../packages/sota'
import { get } from 'lodash'
import numeral from 'numeral'
import styles from './styles.css'

import { MediaPopover } from '../../../../Modules'

const Header = (props) => {

  const {
    customerEnquiryStatuses,
    customerEnquiry,
    activeTab,
    fileChanges,
    onBack,
    onChangeStatus,
    onQuote,
    onSalesOrderExport,
    onPurchaseOrdersExport,
    onEdit,
    onDelete,
    onChangeTab,
    onClickMoreMenuItem,
  } = props
  
  if (!customerEnquiry) return null
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.main}>
        
        
        <div className={styles.section1}>
            
          <LinkButton onClick={onBack}>
            ← All Enquiries
          </LinkButton>
          
          <div className={styles.title}>
            {customerEnquiry.reference}
          </div>
          
        </div>
        
        
        <div className={styles.section2}>
            
          <div className={styles.customer}>
            {customerEnquiry.customer.name}
          </div>
          
          <div className={styles.customerContact}>
            {get(customerEnquiry.customer_contact, 'name', '-')}
          </div>
          
          <div className={styles.tags}>
            
            <Tooltip title="Currency" placement="bottom">
              <div className={styles.tag}>
                {customerEnquiry.customer.currency.code}
              </div>
            </Tooltip>
            
            {customerEnquiry.customer.pref_service && (
              <Tooltip title="Preferred Service" placement="bottom">
                <div className={styles.tag}>
                  {customerEnquiry.customer.pref_service.name}
                </div>
              </Tooltip>
            )}
          
          </div>
          
        </div>
        
        
        <div className={styles.section3}>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Customer Ref</div>
            <div className={styles.detailContent}>{customerEnquiry.customer_ref || '-'}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Received</div>
            <div className={styles.detailContent}>{customerEnquiry.received_on.format('DD/MM/YYYY')}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Respond by</div>
            <div className={styles.detailContent}>{customerEnquiry.respond_by ? customerEnquiry.respond_by.format('DD/MM/YYYY') : '-'}</div>
          </div>
          
        </div>
        
        
        <div className={styles.section3}>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Manager</div>
            <div className={styles.detailContent}>{customerEnquiry.manager.name}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Value</div>
            <div className={styles.detailContent}>{customerEnquiry.value ? `£${numeral(customerEnquiry.value).format('0,0.00')}` : '-'}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Terms</div>
            <div className={styles.detailContent}>{customerEnquiry.payment_terms}</div>
          </div>
          
        </div>
        
        
        <div className={styles.section3}>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Status</div>
            <div className={styles.detailContent}>
              <select value={customerEnquiry.status} onChange={(event) => onChangeStatus(customerEnquiry, event)}>
                {customerEnquiryStatuses.map(customerEnquiryStatus => (
                  <option key={customerEnquiryStatus} value={customerEnquiryStatus}>{customerEnquiryStatus}</option>
                ))}
              </select>
            </div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Notes</div>
            <div className={styles.detailContent}>
              <div style={{ width: 400, maxHeight: 70, overflowY: 'auto' }}>{customerEnquiry.notes}</div>
            </div>
          </div>
          
        </div>
        
        
        <div className={styles.buttons}>
          
          <MediaPopover
            modelType="CustomerEnquiry"
            modelId={customerEnquiry.id}
            collection='enquiry'
            fileChanges={fileChanges}
            moreMenu={id => (
              <Menu onClick={({ key }) => onClickMoreMenuItem(key, id)}>
                <Menu.Item key='move-to-order-bucket'>Move to Order Bucket</Menu.Item>
              </Menu>
            )}
          >
            {({ count }) => (
              <Tooltip title="Enquiry Bucket" placement="left">
                <Badge count={count} className={styles.buttonBadge} overflowCount={99}>
                  <Button
                    shape="circle"
                    icon="rocket"
                    size="large"
                  />
                </Badge>
              </Tooltip>
            )}
          </MediaPopover>

          <div style={{ width: 16 }} />

          {customerEnquiry.sales_order_number && (

            <React.Fragment>

              <MediaPopover
                modelType="CustomerEnquiry"
                modelId={customerEnquiry.id}
                collection='order'
                fileChanges={fileChanges}
                moreMenu={id => (
                  <Menu onClick={({ key }) => onClickMoreMenuItem(key, id)}>
                    <Menu.Item key='move-to-enquiry-bucket'>Move to Enquiry Bucket</Menu.Item>
                  </Menu>
                )}
              >
                {({ count }) => (
                  <Tooltip title="Order Bucket" placement="left">
                    <Badge count={count} className={styles.buttonBadge} overflowCount={99}>
                      <Button
                        shape="circle"
                        icon="trophy"
                        size="large"
                      />
                    </Badge>
                  </Tooltip>
                )}
              </MediaPopover>

              <div style={{ width: 16 }} />

            </React.Fragment>

          )}

          <Tooltip title="Quote" placement="left">
            <Button
              shape="circle"
              icon="pound"
              size="large"
              onClick={onQuote}
            />
          </Tooltip>

          <div style={{ width: 16 }} />

          <Tooltip title="Sage Exports" placement="left">
            <Dropdown
              trigger={['click']}
              overlay={(
                <Menu
                  onClick={({ key }) => {
                    if (key === 'sales-order') onSalesOrderExport()
                    if (key === 'purchase-orders') onPurchaseOrdersExport()
                  }}
                >
                  <Menu.Item key='sales-order'>Export Sales Order</Menu.Item>
                  <Menu.Item key='purchase-orders'>Export Purchase Orders</Menu.Item>
                </Menu>
              )}
            >
              <Button
                shape="circle"
                icon="export"
                size="large"
              />
            </Dropdown>
          </Tooltip>

          <div style={{ width: 16 }} />

          <Tooltip title="Edit" placement="left">
            <Button
              shape="circle"
              icon="edit"
              size="large"
              onClick={onEdit}
            />
          </Tooltip>

          <div style={{ width: 16 }} />

          <Tooltip title="Delete" placement="left">
            <Button
              shape="circle"
              icon="delete"
              size="large"
              onClick={onDelete}
            />
          </Tooltip>

        </div>
        
        
      </div>
      
      <div className={styles.tabs}>
        
        {[
          'Request',
          'Supplier Enquiries',
          'Sourcing',
          'Customer Quote',
          'Sales Order',
        ].map((tab, tabIndex) => (
          <div
            key={tab}
            className={classNames(styles.tab, { [styles.tabActive]: activeTab === tabIndex })}
            onClick={() => onChangeTab(tabIndex)}
          >
            {tab}
          </div>
        ))}
        
      </div>
      
    </div>

  )
}

Header.propTypes = {
  customerEnquiryStatuses: PropTypes.array.isRequired,
  customerEnquiry: PropTypes.object,
  activeTab: PropTypes.number.isRequired,
  fileChanges: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onQuote: PropTypes.func.isRequired,
  onSalesOrderExport: PropTypes.func.isRequired,
  onPurchaseOrdersExport: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onClickMoreMenuItem: PropTypes.func.isRequired,
}

Header.defaultProps = {
  customerEnquiry: null,
}

export default React.memo(Header)
