import React from 'react'
import { getUsers } from '../../Api/users'

import Select from './Select'

export default class User extends React.PureComponent {
  
  fetchOptions = async () => {

    const response = await getUsers({
      params: {
        sort: 'first_name,last_name',
      },
    })
    
    return response.data.data

  }

  render () {

    return (

      <Select
        keyProp="id"
        labelProp="name"
        placeholder="Select User"
        fetchOptions={this.fetchOptions}
        {...this.props}
      />

    )
  }
}