import screenProps from '../screenProps'
import reset from '../Services/reset'
import resetRequest from '../Components/Request/Services/reset'
import resetSupplierEnquiries from '../Components/SupplierEnquiries/Services/reset'
import resetSourcedParts from '../Components/SourcedParts/Services/reset'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(reset())
  
  dispatch(resetRequest())
  dispatch(resetSupplierEnquiries())
  dispatch(resetSourcedParts())

}
