import { push } from 'connected-react-router'
import { closeSupplierEnquiryModal } from '../State/supplierEnquiryModal'

export default (supplierEnquiry) => async (dispatch) => {
  
  if (supplierEnquiry) {
    
    dispatch(push(`/portal/supplier_enquiries/${supplierEnquiry.id}`))
    
  }

  dispatch(closeSupplierEnquiryModal())
  
}
