import { push } from 'connected-react-router'
import { closeManufacturerMergeModal } from '../State/manufacturerMergeModal'

export default (manufacturer) => (dispatch) => {
  
  dispatch(closeManufacturerMergeModal())
  
  if (manufacturer) {
    dispatch(push(`/portal/manufacturers/${manufacturer.id}`))
  }
  
}
