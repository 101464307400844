import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

import Table from './Components/Table'
import SupplierContact from '../../../../Modals/SupplierContact'

export default class SupplierContacts extends React.PureComponent {
  
  static propTypes = {
    supplierContactModal: PropTypes.object.isRequired,
    supplierId: PropTypes.number.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseSupplierContactModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      supplierContactModal,
      supplierId,
      onClickNew,
      onCloseSupplierContactModal,
    } = this.props

    return (

      <React.Fragment>

        <Button key="NEW" type="primary" onClick={onClickNew} style={{ float: 'right', marginBottom: 15 }}>
          New Contact
        </Button>

        <br />

        <Table />

        {supplierContactModal.show && (
          <SupplierContact
            onClose={onCloseSupplierContactModal}
            id={supplierContactModal.supplierContactId}
            supplierId={supplierId}
          />
        )}

      </React.Fragment>

    )

  }
  
}
