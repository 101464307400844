import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openPartModal = createAction('SCREENS/PARTS/OPEN_PART_MODAL')
const closePartModal = createAction('SCREENS/PARTS/CLOSE_PART_MODAL')

const defaultState = {
  show: false,
  partId: null,
}

const reducer = handleActions(
  {
    [openPartModal]: (state, action) => Object.assign({}, state, {
      show: true,
      partId: get(action, 'payload.partId', null),
    }),
    [closePartModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openPartModal, closePartModal, reducer }
