import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon } from 'antd'
import { AppBar, Menu } from '../../packages/sota'
import logoutHandler from '../App/Services/logout'
import styles from './AppBar.css'
import logo from '../../resources/images/logo.png'

import ExchangeRates from './ExchangeRates'

class _AppBar_ extends React.PureComponent {

  static propTypes = {
    user: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
  }

  render () {

    const {
      user,
      pathname,
      logout,
    } = this.props

    const enquiriesMenu = () => (

      <Menu style={{ minWidth: 250 }}>

        <Menu.Item href="/portal/customer_enquiries">Enquiries</Menu.Item>

        <Menu.Item href="/portal/supplier_enquiries">Supplier Enquiries</Menu.Item>

      </Menu>

    )

    const manageMenu = () => (

      <Menu style={{ minWidth: 250 }}>

        {!!user.is_admin && (
          <Menu.Item href="/portal/users">Users</Menu.Item>
        )}

        <Menu.Item href="/portal/parts">Parts</Menu.Item>

        <Menu.Item href="/portal/customers">Customers</Menu.Item>

        <Menu.Item href="/portal/suppliers">Suppliers</Menu.Item>

        <Menu.Item href="/portal/manufacturers">Manufacturers</Menu.Item>

      </Menu>

    )

    const userMenu = () => (

      <Menu style={{ minWidth: 250 }}>

        <div className={styles.user}>
          <div className={styles.avatar}>
            {(`${user.first_name.substr(0,1)}${user.last_name.substr(0,1)}`).toUpperCase()}
          </div>
          <div className={styles.details}>
            <div className={styles.name}>
              {`${user.name}`}
            </div>
            <div className={styles.email}>
              {`${user.email}`}
            </div>
          </div>
        </div>

        <Menu.Divider />

        <Menu.Item onClick={logout}>Logout</Menu.Item>

      </Menu>
    )

    const inDashboard = [
      "/portal/dashboard",
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    const inEnquiries = [
      "/portal/customer_enquiries",
      "/portal/supplier_enquiries",
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    const inManage = [
      "/portal/users",
      "/portal/parts",
      "/portal/customers",
      "/portal/suppliers",
      "/portal/manufacturers",
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    return (

      <AppBar style={{ backgroundColor: '#333' }}>


        <AppBar.Left>

          <img src={logo} className={styles.logo} alt="logo" />

          <AppBar.Button
            highlight={inDashboard}
            href='/portal/dashboard'
          >
            Dashboard
          </AppBar.Button>

          <AppBar.Button
            dropdown={enquiriesMenu}
            highlight={inEnquiries}
          >
            Enquiries
          </AppBar.Button>

          <AppBar.Button
            dropdown={manageMenu}
            highlight={inManage}
          >
            Manage
          </AppBar.Button>

        </AppBar.Left>


        <AppBar.Right style={{ paddingRight: 20 }}>
          
          <ExchangeRates />

          <AppBar.Button
            dropdown={userMenu}
            dropdownPlacement="right"
          >
            <Icon type="user" style={{ fontSize: 20 }} />
          </AppBar.Button>

        </AppBar.Right>
        

      </AppBar>

    )
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  pathname: state.router.location.pathname,
})

const actionCreators = {
  logout: logoutHandler,
}

export default connect(mapStateToProps, actionCreators)(_AppBar_)
