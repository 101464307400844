import { createAction, handleActions } from 'redux-actions'

const setLastInteractionId = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/SET_LAST_INTERACTION_ID")
const resetLastInteractionId = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/RESET_LAST_INTERACTION_ID")

const defaultState = null

const reducer = handleActions(
  {
    [setLastInteractionId]: (state, action) => action.payload,
    [resetLastInteractionId]: () => defaultState,
  },
  defaultState,
)

export { setLastInteractionId, resetLastInteractionId, reducer }
