import { message } from 'antd'
import { deleteSourcedPart } from '../../../../../Api/sourcedParts'
import fetch from './fetch'
import fetchCustomerEnquiry from '../../../Services/fetch'
import { handleException } from '../../../../../../packages/sota'

export default (sourcedPart) => async (dispatch) => {

  try {

    await deleteSourcedPart({ id: sourcedPart.id })

    message.success(`Deleted Source`)

    dispatch(fetch())
    dispatch(fetchCustomerEnquiry())

  } catch (error) {

    handleException(error)

  }
}
