import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectValues = state => state.screens.CustomerEnquiry.Request.formState.values
const selectIndex = state => state.screens.CustomerEnquiry.Request.useSupplierModal.index

export default createSelector(
  selectValues,
  selectIndex,
  (values, index) => ({
    customerEnquiryItem: get(values, `customer_enquiry_items[${index}]`),
  }),
)
