import { getSalesOrderItems } from '../../../../../Api/salesOrderItems'
import { setSalesOrderItems } from '../State/salesOrderItems'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { id } = getState().screens.CustomerEnquiry.customerEnquiry

  try {

    const response = await getSalesOrderItems({
      params: {
        'filter[customer_enquiry_id]': id,
        include: [
          'customer_quote_item.sourced_part.supplier.currency',
          'customer_quote_item.sourced_part.service',
          'part.manufacturer',
          'service',
        ].join(','),
      },
    })

    dispatch(setSalesOrderItems(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
