import chance from 'chance'
import { pick } from 'lodash'
import modalProps from '../../../modalProps'
import { findSalesOrderItem } from '../../../../../Api/salesOrderItems'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = modalProps()

  try {

    if (id) {
      
      const params = {
        include: [
          'customer_quote_item.sourced_part.supplier.currency',
          'customer_quote_item.sourced_part.service',
          'part',
          'service',
        ].join(),
      }

      const response = await findSalesOrderItem({ id, params })

      const salesOrderItem = response.data.data

      const initialValues = {
        ...pick(salesOrderItem, [
          'id',
          'quantity',
          'unit_price',
          'lead_time',
          'date_code',
          'notes',
          'customer_quote_item',
        ]),
        part: {
          ...pick(salesOrderItem.part, [
            'id',
            'internal_no',
          ]),
        },
        service: {
          ...pick(salesOrderItem.service, [
            'id',
            'name',
          ]),
        },
        rohs: salesOrderItem.rohs,
        guid: salesOrderItem.guid,
      }
    
      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
        part: {},
        service: {},
        rohs: false,
        guid: chance().guid(),
      }
    
      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)
    
  }

}
