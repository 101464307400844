import React from 'react'
import PropTypes from 'prop-types'
import { Input, Table, Button, Icon } from 'antd'
import { LinkButton } from '../../../../../packages/sota'
import styles from './styles.css'

import { CustomerQuoteItemsPopover } from '../../../../Modules'
import PartModal from '../../../Part'

export default class Finder extends React.PureComponent {

  static propTypes = {
    customerEnquiryItem: PropTypes.object.isRequired,
    searchTerm: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    tableRows: PropTypes.array.isRequired,
    partModal: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClickCreatePart: PropTypes.func.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onClickSelectPart: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
  }

  render() {

    const {
      customerEnquiryItem,
      searchTerm,
      fetching,
      tableRows,
      partModal,
      onSearch,
      onClickCreatePart,
      onChangeTable,
      onClickSelectPart,
      onClosePartModal,
    } = this.props

    return (

      <React.Fragment>

        <div className={styles.header}>

          <div className={styles.left}>

            <Input.Search
              value={searchTerm}
              loading={fetching}
              autoFocus
              onSearch={value => onSearch(value)}
              onChange={e => onSearch(e.target.value)}
              placeholder='Search'
              style={{ width: 250 }}
            />

            {!!customerEnquiryItem.manufacturer.id && (

              <div className={styles.detail}>
                <div className={styles.label}>Manufacturer:</div>
                <div className={styles.value}>{customerEnquiryItem.manufacturer.name}</div>
              </div>

            )}

          </div>

          <div>

            <Button
              icon='plus'
              onClick={onClickCreatePart}
            >
              New Part
            </Button>

          </div>

        </div>

        <Table
          rowKey='id'
          dataSource={tableRows}
          size='small'
          loading={fetching}
          onChange={onChangeTable}
        >

          <Table.Column
            key='manufacturer_no'
            title='Part'
            sorter
            render={(text, record) => record.manufacturer_no}
          />

          <Table.Column
            key='manufacturer.name'
            title='Manufacturer'
            sorter
            render={(text, record) => record.manufacturer.name}
          />

          <Table.Column
            key='customer_quote_items'
            title='Quoted'
            width={100}
            sorter
            defaultSortOrder='descend'
            render={(text, record) => (
              <CustomerQuoteItemsPopover customerQuoteItems={record.customer_quote_items} placement='bottom'>
                <div style={{ display: 'flex', alignItems: 'center', color: record.customer_quote_items.length ? '#1990FF' : '#9e9e9e' }}>
                  <Icon type='file-search' style={{ marginRight: 5 }} />
                  <div>{record.customer_quote_items.length}</div>
                </div>
              </CustomerQuoteItemsPopover>
            )}
          />

          <Table.Column
            key='action'
            width={140}
            render={(text, record) => (
              <LinkButton
                type='primary'
                icon='plus'
                onClick={() => onClickSelectPart(record)}
              >
                Select Part
              </LinkButton>
            )}
          />

        </Table>

        {partModal.show && (
          <PartModal
            {...partModal}
            onClose={onClosePartModal}
          />
        )}

      </React.Fragment>

    )

  }

}
