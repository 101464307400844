import { createAction, handleActions } from 'redux-actions'

const setCustomerEnquiryItems = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/SET_CUSTOMER_ENQUIRY_ITEMS')
const resetCustomerEnquiryItems = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/RESET_CUSTOMER_ENQUIRY_ITEMS')

const defaultState = []

const reducer = handleActions(
  {
    [setCustomerEnquiryItems]: (state, action) => action.payload,
    [resetCustomerEnquiryItems]: () => [],
  },
  defaultState,
)

export { setCustomerEnquiryItems, resetCustomerEnquiryItems, reducer }
