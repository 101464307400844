import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Select } from 'antd'

export default class MoveSourcedPartModal extends React.PureComponent {

  static propTypes = {
    customerEnquiries: PropTypes.array.isRequired,
    fields: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onClickMove: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      customerEnquiries,
      fields,
      submitting,
      onChangeField,
      onClickMove,
      onClose,
    } = this.props

    return (
        
      <Modal
        title='Move Sourced Part'
        visible
        width={450}
        maskClosable={false}
        okText='Move'
        okButtonProps={{ disabled: !fields.customerEnquiryId, loading: submitting }}
        onOk={() => onClickMove()}
        onCancel={() => onClose()}
      >
        
        <Select
          value={fields.customerEnquiryId}
          onChange={value => onChangeField('customerEnquiryId', value)}
          showSearch
          optionFilterProp='children'
          allowClear
          placeholder='Select Enquiry'
          style={{ width: '100%' }}
        >
          {customerEnquiries.map(customerEnquiry => (
            <Select.Option key={customerEnquiry.id} value={customerEnquiry.id}>
              {`${customerEnquiry.reference} - ${customerEnquiry.customer.name}`}
            </Select.Option>
          ))}
        </Select>

      </Modal>
      
    )

  }

}
