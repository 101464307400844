import screenProps from '../../../screenProps'
import { getParts } from '../../../../../Api/parts'
import { setParts } from '../State/parts'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id } = screenProps().match.params

    const { searchTerm, sorting } = getState().screens.Manufacturer.Parts

    const params = {
      search: searchTerm,
      'filter[manufacturer_id]': id,
      include: 'manufacturer',
    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-internal_no'

    const response = await getParts({ id, params })

    const parts = response.data.data

    dispatch(setParts(parts))

  } catch (error) {

    handleException(error)

  }

}
