import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openCustomerQuoteItemModal = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/OPEN_CUSTOMER_QUOTE_ITEM_MODAL')
const closeCustomerQuoteItemModal = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/CLOSE_CUSTOMER_QUOTE_ITEM_MODAL')

const defaultState = {
  show: false,
  id: null,
  customerEnquiryItemId: null,
  customerEnquiry: null,
}

const reducer = handleActions(
  {
    [openCustomerQuoteItemModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id', null),
      customerEnquiryItemId: get(action, 'payload.customerEnquiryItemId', null),
      customerEnquiry: get(action, 'payload.customerEnquiry', null),
    }),
    [closeCustomerQuoteItemModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCustomerQuoteItemModal, closeCustomerQuoteItemModal, reducer }
