import modalProps from '../../../modalProps'
import { findCustomer } from '../../../../../Api/customers'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'
import { pick } from 'lodash'

export default () => async (dispatch) => {

  try {

    const { id } = modalProps()

    if (id) {

      const params = {
        include: 'currency,pref_service',
      }

      const response = await findCustomer({ id, params })

      const customer = response.data.data

      const initialValues = {
        ...pick(customer, [
          'id',
          'manager_id',
          'name',
          'telephone',
          'email',
          'currency_id',
          'pref_service_id',
          'payment_terms',
          'sage_ref',
        ]),
        currency: {
          ...pick(customer.currency, [
            'id',
            'code',
          ]),
        },
        pref_service: {
          ...pick(customer.pref_service, [
            'id',
            'name',
          ]),
        },
      }

      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
        currency: {},
        pref_service: {},
      }

      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)

  }

}
