import { message } from 'antd'
import modalProps from '../../../modalProps'
import { mergeSupplier } from '../../../../../Api/suppliers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { supplier } = modalProps()

  const data = {
    supplier_id: values.supplier.id,
  }

  try {

    const response = await mergeSupplier({ id: supplier.id, data })
    
    modalProps().onClose(response.data.data)
    
    message.success(`Merged "${supplier.name}" into "${values.supplier.name}" successfully`)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
