import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Tooltip, Icon } from 'antd'
import { LinkButton } from '../../../../../../../packages/sota'
import numeral from 'numeral'
import styles from './styles.css'

const SourcedPart = (props) => {

  const {
    sourcedPart,
    onClick,
    onCopyIntoQuote,
    onRemove,
  } = props

  const { symbol } = sourcedPart.supplier.currency
  
  return (

    <div className={styles.wrapper}>

      <div className={classNames(styles.col, styles.col0)}>

        {!!sourcedPart.customer_quote_items.length && (
          <Icon type="star" theme="filled" />
        )}

      </div>

      <div className={classNames(styles.col, styles.col1)}>

        <LinkButton type="primary" onClick={() => onClick(sourcedPart)}>
          {sourcedPart.supplier ? sourcedPart.supplier.name : '-'}
        </LinkButton>

      </div>

      <div className={classNames(styles.col, styles.col2)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.quantity ? sourcedPart.quantity : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col3)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.service ? sourcedPart.service.name : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col4)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.lead_time ? sourcedPart.lead_time : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col5)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.rohs === null && 'N/A'}
          {sourcedPart.rohs === 1 && 'Yes'}
          {sourcedPart.rohs === 0 && 'No'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col6)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.date_code ? sourcedPart.date_code : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col7)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.reference ? sourcedPart.reference : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col8)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.unit_cost ? `${symbol}${numeral(sourcedPart.unit_cost).format('0,0.[0000]')}` : 'No Bid'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col9)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.total_cost ? `${symbol}${numeral(sourcedPart.total_cost).format('0,0.[00]')}` : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col10)}>

        <div className={classNames(styles.textOutlet)}>
          {sourcedPart.landed_cost ? `£${numeral(sourcedPart.landed_cost).format('0,0.[00]')}` : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col11)}>

        {sourcedPart.notes && (

          <Tooltip title={sourcedPart.notes}>
            <Icon type="info-circle" style={{ color: '#1990FF', fontSize: 18 }} />
          </Tooltip>

        )}

      </div>
      
      <div className={styles.actions}>
        
        <Tooltip title="Copy Into Quote">
          <LinkButton icon="right-circle" onClick={() => onCopyIntoQuote(sourcedPart)} style={{ fontSize: '18px' }} />
        </Tooltip>

        <Tooltip title="Delete Source">
          <LinkButton icon="delete" type="danger" onClick={() => onRemove(sourcedPart)} style={{ fontSize: '18px' }} />
        </Tooltip>
        
      </div>
      
    </div>

  )

}

SourcedPart.propTypes = {
  sourcedPart: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onCopyIntoQuote: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default React.memo(SourcedPart)
