/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { AutoComplete as AntAutoComplete } from 'antd'
import { get } from 'lodash'
import styles from './styles.css'

import Child from './Child'

export default class AutoComplete extends React.PureComponent {

  static propTypes = {
    keyProp: PropTypes.string.isRequired,
    labelProp: PropTypes.string.isRequired,
    labelBracketsProp: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    fetchSuggestions: PropTypes.func.isRequired,
    fetchSelected: PropTypes.func.isRequired,
    value: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    labelBracketsProp: null,
  }
  
  state = {
    suggestions: [],
    fetchingSuggestions: false,
    fetchingSelected: false,
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getKey = () => get(this.props.value, this.props.keyProp, null)

  getLabel = () => get(this.props.value, this.props.labelProp, '')

  getLabelBrackets = () => get(this.props.value, this.props.labelBracketsProp)

  onSearch = (value) => {

    const [term] = value.split('|')

    if (term && term.length > 0) {
      this.fetchSuggestions(term)
    } else {
      this.setState({ suggestions: [] })
    }

  }

  fetchSuggestions = async (value) => {

    const [term] = value.split('|')

    this.setState({ fetchingSuggestions: true })

    const suggestions = await this.props.fetchSuggestions(term)

    if (!this._isMounted) return

    this.setState({
      suggestions,
      fetchingSuggestions: false,
    })

  }

  onChange = (value) => {

    const [term, id] = value.split('|')

    if (term === this.getLabel() && !!this.getKey()) return

    this.props.onChange({
      [this.props.keyProp]: null,
      [this.props.labelProp]: term,
    })

    if (term && term.length > 0 && id) {
      this.fetchSelected(term, id)
    }

  }

  fetchSelected = async (term, id) => {

    this.setState({ fetchingSelected: true })

    const selected = await this.props.fetchSelected(id)

    if (!this._isMounted) return

    if (term !== this.getLabel()) return

    this.setState({ fetchingSelected: false })

    if (selected) {
      this.props.onChange(selected)
    }

  }

  onBlur = (...args) => {
    this.setState({ suggestions: [] })
    this.props.onBlur(...args)
  }

  render () {

    const {
      onSearch,
      onChange,
      onBlur,
    } = this

    const {
      suggestions,
      fetchingSuggestions,
      fetchingSelected,
    } = this.state

    const {
      keyProp,
      labelProp,
      labelBracketsProp,
      placeholder,
      disabled,
      children,
      onFocus,
      className,
    } = this.props

    const dataSource = suggestions.map(suggestion => {
      const labelBrackets = get(suggestion, labelBracketsProp)
      return (
        <AntAutoComplete.Option key={suggestion[keyProp]} value={`${suggestion[labelProp]}|${suggestion[keyProp]}`}>
          {labelBrackets ? `${suggestion[labelProp]} (${labelBrackets})` : suggestion[labelProp]}
        </AntAutoComplete.Option>
      )
    })

    const fetching = fetchingSuggestions || fetchingSelected

    const value = this.getLabel()

    const labelBrackets = this.getLabelBrackets()

    const matched = !!this.getKey()

    const customChild = children

    return (

      <AntAutoComplete
        dataSource={dataSource}
        value={labelBrackets ? `${value} (${labelBrackets})` : value}
        disabled={disabled}
        onChange={onChange}
        onSearch={onSearch}
        onFocus={onFocus}
        onBlur={onBlur}
        dropdownMatchSelectWidth={false}
        style={{ width: '100%' }}
        className={classNames({ [styles.customChild]: !!customChild }, className)}
      >
        <Child
          fetching={fetching}
          matched={matched}
          placeholder={placeholder}
          customChild={customChild}
        />
      </AntAutoComplete>

    )
  }
}