import chance from 'chance'
import formService from '../formService'
import triggerSave from './triggerSave'

export default (index = null) => (dispatch, getState) => {

  const { values } = getState().screens.CustomerEnquiry.Request.formState

  const newRows = []

  const newRow = {
    manufacturer: {},
    suppliers: [],
    guid: chance().guid(),
  }
  
  values.customer_enquiry_items.forEach((customerEnquiryItem, $index) => {
    newRows.push(customerEnquiryItem)
    if ($index === index) {
      newRows.push(newRow)
    }
  })

  if (index === null) newRows.push(newRow)

  formService().change('customer_enquiry_items', newRows)

  dispatch(triggerSave(false))

}
