import { closeManufacturerModal } from '../State/manufacturerModal'
import { setNewField } from '../State/data'
import save from '../Services/save'

export default (manufacturer) => (dispatch, getState) => {

  const state = getState()

  const { data } = state.modals.CustomerEnquiryImport

  const { manufacturerModal } = state.modals.CustomerEnquiryImport

  dispatch(closeManufacturerModal())

  if (!manufacturer) return

  const manufacturers = []

  data.new.manufacturers.forEach((row, index) => {
    if (index === manufacturerModal.index) return
    manufacturers.push({ ...row })
  })

  dispatch(setNewField({
    field: 'manufacturers',
    data: manufacturers,
  }))

  dispatch(save())
  
}
