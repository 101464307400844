import { combineReducers } from "redux"

import { reducer as formState } from "./State/formState"
import { reducer as closedItems } from "./State/closedItems"
import { reducer as lastInteractionId } from "./State/lastInteractionId"
import { reducer as loading } from "./State/loading"
import { reducer as selectPartModal } from "./State/selectPartModal"
import { reducer as sourcedPartModal } from "./State/sourcedPartModal"
import { reducer as quoteSourcedPartModal } from "./State/quoteSourcedPartModal"

export default combineReducers({
  formState,
  closedItems,
  lastInteractionId,
  loading,
  selectPartModal,
  sourcedPartModal,
  quoteSourcedPartModal,
})
