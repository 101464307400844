import { createAction, handleActions } from 'redux-actions'

const openCustomerEnquiryModal = createAction('SCREENS/CUSTOMER_ENQUIRY/OPEN_CUSTOMER_ENQUIRY_MODAL')
const closeCustomerEnquiryModal = createAction('SCREENS/CUSTOMER_ENQUIRY/CLOSE_CUSTOMER_ENQUIRY_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openCustomerEnquiryModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeCustomerEnquiryModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCustomerEnquiryModal, closeCustomerEnquiryModal, reducer }
