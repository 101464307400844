import React from 'react'
import PropTypes from 'prop-types'
import { Table, Typography, Popover } from 'antd'
import pluralize from 'pluralize'
import { LinkButton } from '../../../../../packages/sota'
import styles from './styles.css'

const Manufacturers = props => {

  const {
    tableRows,
    onChangeName,
    onClickCreate,
  } = props
  
  return (
    
    <Table
      rowKey='key'
      dataSource={tableRows}
      size='small'
    >

      <Table.Column
        key='name'
        title='Name'
        render={(text, record) => (
          <Typography.Text
            editable={{ onChange: value => value !== record.name && onChangeName(record.guid, record.name, value) }}
          >
            {record.name}
          </Typography.Text>
        )}
      />

      <Table.Column
        key='matches'
        title=''
        render={(text, record) => !!record.matches.length && (
          <Popover
            title='Suggestions'
            content={(
              <div className={styles.matches}>
                {record.matches.map(manufacturer => <div key={manufacturer.id}>{`• ${manufacturer.name}`}</div>)}
              </div>
            )}
          >
            <a>{`${record.matches.length} ${pluralize('Suggestion', record.matches.length)}`}</a>
          </Popover>
        )}
      />

      <Table.Column
        key='create'
        title=''
        render={(text, record) => (
          <LinkButton
            type='primary'
            icon='plus'
            onClick={() => onClickCreate(record)}
          >
            Create Manufacturer
          </LinkButton>
        )}
      />

    </Table>

  )

}

Manufacturers.propTypes = {
  tableRows: PropTypes.array.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onClickCreate: PropTypes.func.isRequired,
}

export default Manufacturers
