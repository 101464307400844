import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable } from 'antd'
import { LinkButton } from '../../../../../../../packages/sota'

const Table = (props) => {

  const {
    supplierContacts,
    onChange,
    onClick,
  } = props

  return (

    <AntTable
      dataSource={supplierContacts}
      rowKey="id"
      pagination={false}
      onChange={onChange}
      size="middle"
    >

      <AntTable.Column
        key="name"
        title="Name"
        sorter
        render={(text, record) => (
          <LinkButton type="primary" onClick={() => onClick(record)}>
            {record.name}
          </LinkButton>
        )}
      />

      <AntTable.Column
        key="telephone"
        title="Telephone"
        sorter
        render={(text, record) => record.telephone}
      />

      <AntTable.Column
        key="email"
        title="Email"
        sorter
        render={(text, record) => record.email}
      />

      <AntTable.Column
        key="role"
        title="Role"
        sorter
        render={(text, record) => record.role}
      />

    </AntTable>

  )

}

Table.propTypes = {
  supplierContacts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Table
