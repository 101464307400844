import { combineReducers } from "redux"

import { reducer as formState } from "./State/formState"
import { reducer as customerEnquiryItemOptions } from "./State/customerEnquiryItemOptions"
import { reducer as partModal } from "./State/partModal"

export default combineReducers({
  formState,
  customerEnquiryItemOptions,
  partModal,
})
