import React from 'react'
import ReactDOM from 'react-dom'
import { Main, setupSentry } from '../packages/sota'
import { store, persistor, history } from './store'
import App from './App'

import 'antd/dist/antd.css'
import './global.css'

setupSentry()

ReactDOM.render((
  <Main store={store} persistor={persistor} history={history}>
    <App />
  </Main>
), document.getElementById('root'))
