import { connect } from 'react-redux'
import UserSelect from './UserSelect'

const mapStateToProps = (state) => ({
  users: state.core.users,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(UserSelect)
