import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from './Components/Table'
import Manufacturer from '../../Modals/Manufacturer'

export default class Manufacturers extends React.PureComponent {

  static propTypes = {
    manufacturerModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseManufacturerModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New Manufacturer
    </Button>,

  ])

  render() {

    const {
      manufacturerModal,
      onCloseManufacturerModal,
    } = this.props

    return (

      <Screen
        title="Manufacturers"
        headerRight={this.renderHeaderRight()}
      >

        <Table />

        {manufacturerModal.show && (
          <Manufacturer
            onClose={onCloseManufacturerModal}
          />
        )}

      </Screen>

    )

  }

}
