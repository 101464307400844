import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onClickNew from "./Handlers/onClickNew"
import onCloseCustomerEnquiryModal from "./Handlers/onCloseCustomerEnquiryModal"

const mapStateToProps = (state) => ({
  customerEnquiryModal: state.screens.CustomerEnquiries.customerEnquiryModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseCustomerEnquiryModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
