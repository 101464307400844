import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Dropdown, Menu, Select, Button, Tag, Tooltip, Icon } from 'antd'
import classNames from 'classnames'
import { ModelAutoComplete } from '../../../../../../Modules'
import styles from './styles.css'

const CustomerEnquiryItem = props => {

  const {
    customerEnquiryItem,
    index,
    services,
    onChangeField,
    onClickCreateManufacturer,
    onClickFindPart,
    onFocusSuppliers,
    onAddRow,
    onRemoveRow,
    onDuplicateRow,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.col, styles.partNos)}>

        <Field name={`customer_enquiry_items[${index}].manufacturer_part_no`}>

          {({ input }) => (

            <input
              {...input}
              onChange={event => {
                input.onChange(event)
                onChangeField(index, 'manufacturer_part_no', event.target.value)
              }}
              type='text'
              autoComplete='off'
              placeholder='Enter Manufacturer No.'
              className={styles.textInput}
              style={{ fontSize: 15, fontWeight: 500 }}
            />

          )}

        </Field>

        <Field name={`customer_enquiry_items[${index}].customer_part_no`}>

          {({ input }) => (

            <input
              {...input}
              onChange={event => {
                input.onChange(event)
                onChangeField(index, 'customer_part_no', event.target.value)
              }}
              type='text'
              autoComplete='off'
              placeholder='Enter Customer No.'
              className={styles.textInput}
              style={{ fontSize: 12 }}
            />

          )}

        </Field>

      </div>

      <div className={classNames(styles.col, styles.manufacturer)}>

        <Field name={`customer_enquiry_items[${index}].manufacturer`}>

          {({ input }) => (

            <ModelAutoComplete.Manufacturer
              {...input}
              onChange={value => {
                input.onChange(value)
                onChangeField(index, 'manufacturer', value.id)
              }}
              className={classNames(styles.antInput, styles.manufacturerAutoComplete)}
            />

          )}

        </Field>

        {!customerEnquiryItem.manufacturer.id && (

          <Tooltip title='New Manufacturer'>
            <Button
              icon='user-add'
              size='small'
              onClick={() => onClickCreateManufacturer(index)}
              className={styles.createManufacturerButton}
            />
          </Tooltip>

        )}

      </div>

      <div className={classNames(styles.col, styles.partMatch)}>

        {customerEnquiryItem.part ? (

          <Tag color='green' style={{ margin: 0 }}>Match</Tag>

        ) : (

          <Tooltip title='Find Part'>
            <Button
              icon='zoom-in'
              size='small'
              onClick={() => onClickFindPart(index)}
            />
          </Tooltip>

        )}

      </div>

      <div className={classNames(styles.col, styles.quantity)}>

        <Field name={`customer_enquiry_items[${index}].quantity`}>

          {({ input }) => (

            <input
              {...input}
              onChange={event => {
                input.onChange(event)
                onChangeField(index, 'quantity', event.target.value)
              }}
              type='text'
              autoComplete='off'
              placeholder='0'
              className={styles.textInput}
              style={{ textAlign: 'right' }}
            />

          )}

        </Field>

      </div>

      <div className={classNames(styles.col, styles.service)}>

        <Field name={`customer_enquiry_items[${index}].service_id`}>

          {({ input }) => (

            <Select
              value={input.value || undefined}
              onChange={value => {
                input.onChange(value)
                onChangeField(index, 'service_id', value)
              }}
              optionFilterProp='children'
              allowClear
              placeholder='Select Service'
              className={styles.antInput}
            >
              {services.map(service => (
                <Select.Option key={service.id} value={service.id}>
                  {service.name}
                </Select.Option>
              ))}
            </Select>

          )}

        </Field>

      </div>

      <div className={classNames(styles.col, styles.suppliers)}>

        <Field name={`customer_enquiry_items[${index}].suppliers`}>

          {({ input }) => (

            <Select
              {...input}
              value={customerEnquiryItem.suppliers.map(supplier => supplier.id)}
              onChange={value => {
                input.onChange(customerEnquiryItem.suppliers.filter(supplier => value.indexOf(supplier.id) !== -1))
                onChangeField(index, 'suppliers', value)
              }}
              onFocus={() => onFocusSuppliers(index)}
              mode='multiple'
              optionFilterProp='children'
              allowClear
              placeholder='Select Suppliers'
              className={styles.antInput}
              dropdownStyle={{ display: 'none' }}
            >
              {customerEnquiryItem.suppliers.map(supplier => (
                <Select.Option key={supplier.id} value={supplier.id}>
                  {`${supplier.name} (${supplier.currency.symbol})`}
                </Select.Option>
              ))}
            </Select>

          )}

        </Field>

      </div>

      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item onClick={() => onAddRow(index)}>
              <Icon type='plus-circle' />
              Add
            </Menu.Item>
            <Menu.Item onClick={() => onRemoveRow(index)}>
              <Icon type='minus-circle' />
              Remove
            </Menu.Item>
            <Menu.Item onClick={() => onDuplicateRow(index)}>
              <Icon type='reload' />
              Duplicate
            </Menu.Item>
          </Menu>
        )}
      >

        <div className={styles.actionsButtonWrapper}>

          <Icon type='more' />

        </div>

      </Dropdown>

    </div>

  )

}

CustomerEnquiryItem.propTypes = {
  customerEnquiryItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  services: PropTypes.array.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onClickCreateManufacturer: PropTypes.func.isRequired,
  onClickFindPart: PropTypes.func.isRequired,
  onFocusSuppliers: PropTypes.func.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onRemoveRow: PropTypes.func.isRequired,
  onDuplicateRow: PropTypes.func.isRequired,
}

export default CustomerEnquiryItem
