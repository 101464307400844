import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteSupplierEnquiry } from '../../../Api/supplierEnquiries'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { supplierEnquiry } = getState().screens.SupplierEnquiry

    await deleteSupplierEnquiry({ id: supplierEnquiry.id })

    message.success(`Deleted Enquiry`)

    dispatch(push(`/portal/customer_enquiries/${supplierEnquiry.customer_enquiry_id}`))

  } catch (error) {

    handleException(error)

  }
  
}
