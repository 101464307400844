import moment from "moment"
import screenProps from '../screenProps'
import { findCustomerEnquiry } from '../../../Api/customerEnquiries'
import { setCustomerEnquiry } from '../State/customerEnquiry'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params
    
    const include = [
      'customer.currency',
      'customer.pref_service',
      'customer_contact',
      'manager',
      'customer_enquiry_items',
      'sourced_parts.supplier',
      'imports.user',
    ].join(',')
    
    const params = {
      include,
    }

    const response = await findCustomerEnquiry({ id, params })

    const customerEnquiry = response.data.data

    dispatch(setCustomerEnquiry({
      ...customerEnquiry,
      received_on: customerEnquiry.received_on ? moment(customerEnquiry.received_on) : null,
      respond_by: customerEnquiry.respond_by ? moment(customerEnquiry.respond_by) : null,
    }))

  } catch (error) {

    handleException(error)

  }

}
