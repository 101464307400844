import React from 'react'
import { Module } from '../../../../../packages/sota'

import Header from './Components/Header'
import Table from './Components/Table'
import Footer from './Components/Footer'

const TableModule = () => {
    
  return (

    <Module>
      
      <Header />

      <Table />

      <Footer />

    </Module>

  )
}

export default TableModule
