import { createAction, handleActions } from 'redux-actions'
import { includes, without, concat } from 'lodash'

const toggleItemClosed = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/TOGGLE_ITEM_CLOSED')
const resetClosedItems = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/RESET_CLOSED_ITEMS')

const defaultState = []

const reducer = handleActions(
  {
    [toggleItemClosed]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : concat(state, action.payload),
    [resetClosedItems]: () => defaultState,
  },
  defaultState,
)

export { toggleItemClosed, resetClosedItems, reducer }
