import removeItem from '../../../Services/removeItem'
import triggerSave from '../../../Services/triggerSave'

export default (item, itemIndex) => (dispatch) => {

  dispatch(removeItem(item, itemIndex))

  dispatch(triggerSave({ wait: false }))

}
