import { message } from 'antd'
import { push } from 'connected-react-router'
import { deletePart } from '../../../Api/parts'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { part } = getState().screens.Part

    await deletePart({ id: part.id })

    message.success(`Deleted Part`)

    dispatch(push(`/portal/parts`))

  } catch (error) {

    handleException(error)

  }

}
