import { closeSelectPartModal } from '../State/selectPartModal'
import initialize from '../Services/initialize'
import fetch from '../../../Services/fetch'

export default () => async (dispatch) => {

  dispatch(closeSelectPartModal())

  dispatch(initialize())

  dispatch(fetch())
  
}
