import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from './Components/Table'
import Part from '../../Modals/Part'

export default class Parts extends React.PureComponent {

  static propTypes = {
    partModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New Part
    </Button>,

  ])

  render() {

    const {
      partModal,
      onClosePartModal,
    } = this.props

    return (

      <Screen
        title="Parts"
        headerRight={this.renderHeaderRight()}
      >

        <Table />

        {partModal.show && (
          <Part
            onClose={onClosePartModal}
          />
        )}

      </Screen>

    )

  }

}
