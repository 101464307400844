import { combineReducers } from 'redux'

import { reducer as supplierModal } from './State/supplierModal'

import Table from './Components/Table/reducer'

export default combineReducers({
  supplierModal,

  Table,
})
