import { message } from 'antd'
import { createCustomerEnquiryImport } from '../../../../../Api/customerEnquiries'
import fetch from '../../../Services/fetch'
import { openCustomerEnquiryImportModal } from '../State/customerEnquiryImportModal'
import { handleException } from '../../../../../../packages/sota'

export default (fileProps) => async (dispatch, getState) => {

  const { id } = getState().screens.CustomerEnquiry.customerEnquiry

  const data = new FormData()

  data.append('file', fileProps.file)

  try {

    const response = await createCustomerEnquiryImport({ id, data })

    message.success('Created Import')

    dispatch(openCustomerEnquiryImportModal({ id: response.data.data.id }))

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }

}
