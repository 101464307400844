import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import styles from "./Table.css"

const Table = (props) => {

  const {
    customerEnquiryStatuses,
    data,
    isFetching,
    onChange,
    onClickRow,
    onChangeStatus,
  } = props

  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      className={styles.table}
      onChange={onChange}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
    >

      <AntTable.Column
        key='reference'
        title="Ref."
        sorter
        render={(text, record) => (
          <strong>
            {record.reference}
          </strong>
        )}
      />

      <AntTable.Column
        key='customer.name'
        title="Customer"
        sorter
        render={(text, record) => `${record.customer.name} (${record.customer.currency.symbol})`}
      />

      <AntTable.Column
        key='received_on'
        title="Received"
        sorter
        render={(text, record) => moment(record.received_on).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          lastDay: '[Yesterday]',
          nextWeek: 'D MMM YYYY',
          lastWeek: 'D MMM YYYY',
          sameElse: 'D MMM YYYY',
        })}
      />

      <AntTable.Column
        key='manager.name'
        title="Manager"
        sorter
        render={(text, record) => record.manager.name}
      />

      <AntTable.Column
        key='value'
        title="Value"
        sorter
        render={(text, record) => record.value ? `£${numeral(record.value).format('0,0.00')}` : '-'}
      />

      <AntTable.Column
        key='status'
        title="Status"
        sorter
        render={(text, record) => (
          <select value={record.status} onClick={(e) => e.stopPropagation()} onChange={(event) => onChangeStatus(record, event)}>
            {customerEnquiryStatuses.map(customerEnquiryStatus => (
              <option key={customerEnquiryStatus} value={customerEnquiryStatus}>{customerEnquiryStatus}</option>
            ))}
          </select>
        )}
      />

    </AntTable>

  )
}

Table.propTypes = {
  customerEnquiryStatuses: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}

export default Table
