import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openSupplierContactModal = createAction('SCREENS/SUPPLIER/SUPPLIER_CONTACTS/OPEN_SUPPLIER_CONTACT_MODAL')
const closeSupplierContactModal = createAction('SCREENS/SUPPLIER/SUPPLIER_CONTACTS/CLOSE_SUPPLIER_CONTACT_MODAL')

const defaultState = {
  show: false,
  supplierContactId: null,
}

const reducer = handleActions(
  {
    [openSupplierContactModal]: (state, action) => Object.assign({}, state, {
      show: true,
      supplierContactId: get(action, 'payload.supplierContactId', null),
    }),
    [closeSupplierContactModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSupplierContactModal, closeSupplierContactModal, reducer }
