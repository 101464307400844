import { connect } from 'react-redux'
import { get } from 'lodash'
import Supplier from './Supplier'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseSupplierModal from './Handlers/onCloseSupplierModal'
import onCloseSupplierMergeModal from './Handlers/onCloseSupplierMergeModal'

const mapStateToProps = (state) => ({
  id: get(state.screens.Supplier.supplier, 'id'),
  initialized: state.screens.Supplier.initialized,
  activeTab: state.screens.Supplier.activeTab,
  supplierModal: state.screens.Supplier.supplierModal,
  supplierMergeModal: state.screens.Supplier.supplierMergeModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseSupplierModal,
  onCloseSupplierMergeModal,
}

export default connect(mapStateToProps, actionCreators)(Supplier)
