import { createSelector } from 'reselect'
import { includes } from 'lodash'

const selectCustomerEnquiryItems = (state) => state.screens.CustomerEnquiry.CustomerQuote.customerEnquiryItems
const selectClosedItems = (state) => state.screens.CustomerEnquiry.CustomerQuote.closedItems

const selectRows = createSelector(
  selectCustomerEnquiryItems,
  selectClosedItems,
  (customerEnquiryItems, closedItems) => {

    const rows = []

    rows.push({
      type: 'HEADER',
      key: 'header',
    })

    rows.push({
      type: 'DIVIDER',
      key: `divider-header`,
      data: {
        type: 1,
      },
    })

    if (customerEnquiryItems.length === 0) {

      rows.push({
        type: 'EMPTY',
        key: 'empty',
      })

      rows.push({
        type: 'DIVIDER',
        key: `divider-empty`,
        data: {
          type: 1,
        },
      })

      return rows

    }

    customerEnquiryItems.forEach((item, itemIndex) => {
      
      if (!item.customer_part_no && !item.quantity && !item.target_price && !item.service) {
        return
      }

      rows.push({
        type: 'CUSTOMER_ENQUIRY_ITEM',
        key: item.guid,
        data: {
          item,
          itemIndex,
        },
      })

      if (!includes(closedItems, item.guid)) {

        rows.push({
          type: 'DIVIDER',
          key: `divider-${itemIndex}-parts-top`,
          data: {
            type: 2,
          },
        })

        if (item.customer_quote_items.length > 0) {

          rows.push({
            type: 'SUBHEADER',
            key: `subheader-${itemIndex}`,
          })

        }

        item.customer_quote_items.forEach((customerQuoteItem, customerQuoteItemIndex) => {

          rows.push({
            type: 'CUSTOMER_QUOTE_ITEM',
            key: customerQuoteItem.id,
            data: {
              customerEnquiryItem: item,
              customerEnquiryItemIndex: itemIndex,
              customerQuoteItem,
              customerQuoteItemIndex,
            },
          })

        })

        rows.push({
          type: 'CUSTOMER_QUOTE_ITEM_FOOTER',
          key: `customer-quote-item-footer-${itemIndex}`,
          data: {
            customerEnquiryItem: item,
            customerEnquiryItemIndex: itemIndex,
          },
        })

        rows.push({
          type: 'DIVIDER',
          key: `divider-${itemIndex}-parts-bottom`,
          data: {
            type: 3,
          },
        })
        
      } else {

        rows.push({
          type: 'DIVIDER',
          key: `divider-${itemIndex}`,
          data: {
            type: 1,
          },
        })

      }

    })

    return rows

  },
)

export default selectRows
