import React from 'react'
import { Modal } from 'antd'
import modalProps from '../../../modalProps'
import merge from '../Services/merge'

const getCreatedBeforeWarning = (sourceSupplier, targetSupplier) => {
  if (sourceSupplier.id < targetSupplier.id) {
    return `Warning: "${sourceSupplier.name}" was created before "${targetSupplier.name}". You may want the merge to occur the other way round.`
  }
  return false
}

const getSageCodeWarning = (sourceSupplier, targetSupplier) => {
  if (sourceSupplier.sage_ref && !targetSupplier.sage_ref) {
    return `Warning: "${sourceSupplier.name}" has a Sage ref (${sourceSupplier.sage_ref}), yet "${targetSupplier.name}" doesn't. You may want the merge to occur the other way round.`
  }
  if (sourceSupplier.sage_ref && targetSupplier.sage_ref) {
    return `Warning: "${sourceSupplier.name}" and ${targetSupplier.name} both have a Sage ref (${sourceSupplier.sage_ref} & ${targetSupplier.sage_ref} respectively).`
  }
  return false
}

export default (values) => async (dispatch) => {

  const { supplier: sourceSupplier } = modalProps()

  const { supplier: targetSupplier } = values

  const createdBeforeWarning = getCreatedBeforeWarning(sourceSupplier, targetSupplier)

  const sageCodeWarning = getSageCodeWarning(sourceSupplier, targetSupplier)

  Modal.confirm({
    title: 'Merge Supplier?',
    content: (
      <div>
        <p>{`This would remove "${sourceSupplier.name}" and transfer all of its data into "${targetSupplier.name}".`}</p>
        {!!createdBeforeWarning && <p style={{ fontStyle: 'italic' }}>{createdBeforeWarning}</p>}
        {!!sageCodeWarning && <p style={{ fontStyle: 'italic' }}>{sageCodeWarning}</p>}
        <p>Are you sure you want to continue?</p>
      </div>
    ),
    okText: 'Yes',
    onOk: async () => {
      await dispatch(merge(values))
    },
  })

}
