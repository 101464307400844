import { createSelector } from 'reselect'
import { orderBy, get } from 'lodash'

const selectParts = state => state.modals.FindPart.Finder.parts
const selectSorting = state => state.modals.FindPart.Finder.sorting

export default createSelector(
  selectParts,
  selectSorting,
  (parts, { key, direction }) => {

    if (!key) return parts

    switch (key) {
      case 'manufacturer.name':
        return orderBy(parts, part => part.manufacturer.name.toLowerCase(), direction)
      case 'customer_quote_items':
        return orderBy(
          parts,
          [
            part => get(part, 'customer_quote_items', []).length,
            part => part.manufacturer_no.toLowerCase(),
          ],
          [
            direction,
            'asc',
          ],
        )
      default:
        return orderBy(parts, part => part[key].toLowerCase(), direction)
    }

  },
)
