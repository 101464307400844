import { combineReducers } from 'redux'

import { reducer as sorting } from './State/sorting'
import { reducer as supplierContacts } from './State/supplierContacts'
import { reducer as supplierContactModal } from './State/supplierContactModal'

export default combineReducers({
  sorting,
  supplierContacts,
  supplierContactModal,
})
