import { connect } from 'react-redux'
import Parts from './Parts'

import selectTableRows from './Selectors/selectTableRows'

import onClickCreate from './Handlers/onClickCreate'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
})

const actionCreators = {
  onClickCreate,
}

export default connect(mapStateToProps, actionCreators)(Parts)
