import { combineReducers } from 'redux'

import { reducer as customer } from './State/customer'
import { reducer as initialized } from './State/initialized'
import { reducer as activeTab } from './State/activeTab'
import { reducer as customerModal } from './State/customerModal'
import { reducer as customerMergeModal } from './State/customerMergeModal'

import CustomerContacts from './Components/CustomerContacts/reducer'
import CustomerEnquiries from './Components/CustomerEnquiries/reducer'

export default combineReducers({
  customer,
  initialized,
  activeTab,
  customerModal,
  customerMergeModal,

  CustomerContacts,
  CustomerEnquiries,
})
