import { getParts } from '../../../../../Api/parts'
import { setFetching } from '../State/fetching'
import { setParts } from '../State/parts'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { searchTerm } = getState().modals.SelectPart.Finder

    const params = {
      search: searchTerm || undefined,
    }

    dispatch(setFetching(true))

    const response = await getParts({ params })

    dispatch(setParts(response.data.data))

    dispatch(setFetching(false))

  } catch (error) {

    dispatch(setFetching(false))

    handleException(error)

  }
  
}
