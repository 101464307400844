import { connect } from "react-redux"
import Header from "./Header"

const mapStateToProps = (state) => ({
  supplierEnquiry: state.screens.SupplierEnquiry.supplierEnquiry,
  loading: state.screens.SupplierEnquiry.Requirements.loading,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Header)
