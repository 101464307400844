import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

const Table = (props) => {

  const {
    supplierEnquiries,
    onChange,
  } = props

  return (

    <AntTable
      dataSource={supplierEnquiries}
      rowKey="id"
      pagination={false}
      onChange={onChange}
      size="middle"
    >

      <AntTable.Column
        key="reference"
        title="Ref."
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={`/portal/supplier_enquiries/${record.id}`}>
            {record.reference}
          </NavLink>
        )}
      />

      <AntTable.Column
        key="supplier.name"
        title="Supplier"
        sorter
        render={(text, record) => record.supplier.name}
      />

      <AntTable.Column
        key="service.name"
        title="Service"
        sorter
        render={(text, record) => record.service.name}
      />

    </AntTable>

  )

}

Table.propTypes = {
  supplierEnquiries: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table
