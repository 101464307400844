import React from 'react'
import { Modal, Icon } from 'antd'
import deleteSupplierEnquiry from '../../../Services/delete'

export default () => async (dispatch) => {

  Modal.confirm({
    title: 'Delete Enquiry?',
    content: 'This action cannot be undone.',
    icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteSupplierEnquiry()),
  })

}