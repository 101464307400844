import { createAction, handleActions } from 'redux-actions'

const openUserModal = createAction('SCREENS/USERS/OPEN_USER_MODAL')
const closeUserModal = createAction('SCREENS/USERS/CLOSE_USER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openUserModal]: (state, { payload }) => ({ ...payload, show: true }),
    [closeUserModal]: () => defaultState,
  },
  defaultState,
)

export { openUserModal, closeUserModal, reducer }
