import { createAction, handleActions } from 'redux-actions'

const setPart = createAction('SCREENS/PART/SET_PART')
const resetPart = createAction('SCREENS/PART/RESET_PART')

const defaultState = null

const reducer = handleActions(
  {
    [setPart]: (state, action) => action.payload,
    [resetPart]: () => defaultState,
  },
  defaultState,
)

export { setPart, resetPart, reducer }
