import { openManufacturerModal } from '../../../State/manufacturerModal'

export default (values) => (dispatch) => {

  dispatch(openManufacturerModal({
    index: values.key,
    name: values.name,
  }))

}
