import { getUsers } from '../../../../../Api/users'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { searchTerm, filters, sorting, pagination } = getState().screens.Users.Table

  const params = {}

  if (searchTerm) params.search = searchTerm

  if (filters.departmentKey) params['filter[department_key]'] = filters.departmentKey
  
  if (filters.status) params['filter[status]'] = filters.status

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getUsers({ params })

    dispatch(setData(response.data.data))

    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
