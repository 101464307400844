import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { get } from 'lodash'
import styles from './styles.css'

const SubTable = props => {

  const {
    tableRows,
  } = props

  return (

    <Table
      dataSource={tableRows}
      rowKey='id'
      size='small'
      pagination={false}
      className={styles.table}
    >

      <Table.Column
        key='part.manufacturer_no'
        title='Manufacturer PN'
        render={(text, record) => get(record, 'part.manufacturer_no', '-')}
      />

      <Table.Column
        key='part.manufacturer.name'
        title='Manufacturer'
        render={(text, record) => get(record, 'part.manufacturer.name', '-')}
      />

      <Table.Column
        key='part.internal_no'
        title='Internal PN'
        render={(text, record) => get(record, 'part.internal_no', '-')}
      />

      <Table.Column
        key='quantity'
        title='Qty'
        render={(text, record) => record.quantity || '-'}
      />

    </Table>

  )

}

SubTable.propTypes = {
  tableRows: PropTypes.array.isRequired,
}

export default SubTable
