import React from 'react'
import { Modal, Icon } from 'antd'
import deleteCustomerQuoteItem from '../../../Services/delete'

export default (customerQuoteItem) => (dispatch) => {

  Modal.confirm({
    title: 'Delete Quote Item?',
    content: 'This action cannot be undone.',
    icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteCustomerQuoteItem(customerQuoteItem)),
  })
  
}
