import { closeSupplierContactModal } from '../State/supplierContactModal'
import fetchSupplierContactsTable from '../Services/fetch'

export default (supplierContact) => (dispatch) => {

  dispatch(closeSupplierContactModal())

  if (supplierContact) {
    dispatch(fetchSupplierContactsTable())
  }

}
