import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Switch, Icon, Spin, Tooltip, Button } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelAutoComplete } from '../../../../Modules'
import formService from './formService'
import styles from './Component.css'

import ManufacturerModal from '../../../Manufacturer'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object,
    manufacturerModal: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickCreateManufacturer: PropTypes.func.isRequired,
    onCloseManufacturerModal: PropTypes.func.isRequired,
  }

  render () {

    const {
      initialValues,
      manufacturerModal,
      onChangeFormState,
      onSubmit,
      validate,
      onClickCreateManufacturer,
      onCloseManufacturerModal,
    } = this.props

    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    return (
      
      <React.Fragment>
        
        <FormProvider
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, submitting }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}
              autoComplete="off"
              colon={false}
            >

              <FormInterceptor
                ref={formService}
                subscription={{ submitting: true }}
                onChange={onChangeFormState}
              />

              <Field name="manufacturer_no">
                {({ input, meta }) => (
                  <Form.Item
                    label="Manufacturer No."
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="manufacturer">
                {({ input, meta }) => (
                  <Form.Item
                    label="Manufacturer"
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelAutoComplete.Manufacturer {...input} />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title="Create Manufacturer">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreateManufacturer}
                />
              </Tooltip>

              <Field name="internal_no">
                {({ input, meta }) => (
                  <Form.Item
                    label="Stock Code"
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="description">
                {({ input, meta }) => (
                  <Form.Item
                    label="Description"
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Input.TextArea
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="eccn">
                {({ input, meta }) => (
                  <Form.Item
                    label="ECCN"
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="build_to_print">
                {({ input }) => (
                  <Form.Item
                    label="BTP"
                    className={styles.formItem}
                  >
                    <Switch
                      {...input}
                      checked={input.value}
                      disabled={submitting}
                      checkedChildren={<Icon type="check" />}
                      style={{ marginTop: 4 }}
                    />
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>

        {manufacturerModal.show && (
          <ManufacturerModal
            onClose={onCloseManufacturerModal}
          />
        )}

      </React.Fragment>
      
    )
  }
}