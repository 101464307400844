import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openCustomerModal = createAction('SCREENS/CUSTOMERS/OPEN_CUSTOMER_MODAL')
const closeCustomerModal = createAction('SCREENS/CUSTOMERS/CLOSE_CUSTOMER_MODAL')

const defaultState = {
  show: false,
  customerId: null,
}

const reducer = handleActions(
  {
    [openCustomerModal]: (state, action) => Object.assign({}, state, {
      show: true,
      customerId: get(action, 'payload.customerId', null),
    }),
    [closeCustomerModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCustomerModal, closeCustomerModal, reducer }
