import { createAction, handleActions } from 'redux-actions'

const setUserFilter = createAction('SCREENS/CUSTOMER_ENQUIRIES/CUSTOMER_ENQUIRIES_TABLE/SET_USER_FILTER')
const resetUserFilter = createAction('SCREENS/CUSTOMER_ENQUIRIES/CUSTOMER_ENQUIRIES_TABLE/RESET_USER_FILTER')

const defaultState = null

const reducer = handleActions(
  {
    [setUserFilter]: (state, action) => action.payload,
    [resetUserFilter]: () => defaultState,
  },
  defaultState,
)

export { setUserFilter, resetUserFilter, reducer }
