import { resetInitialized } from '../State/initialized'
import { resetCustomer } from '../State/customer'
import { resetActiveTab } from '../State/activeTab'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetCustomer())
  dispatch(resetActiveTab())
  
}
