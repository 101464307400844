import { connect } from 'react-redux'
import CustomerQuoteItem from './CustomerQuoteItem'

import onClick from './Handlers/onClick'
import onCopyIntoOrder from './Handlers/onCopyIntoOrder'
import onRemove from './Handlers/onRemove'

const mapStateToProps = (state) => ({
  customerEnquiry: state.screens.CustomerEnquiry.customerEnquiry,
})

const actionCreators = {
  onClick,
  onCopyIntoOrder,
  onRemove,
}

export default connect(mapStateToProps, actionCreators)(CustomerQuoteItem)
