import { combineReducers } from 'redux'

import { reducer as partModal } from './State/partModal'

import Table from './Components/Table/reducer'

export default combineReducers({
  partModal,

  Table,
})
