import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { App } from '../../packages/sota'
import Screens from '../Screens'
import ResetPasswordModal from '../Modals/ResetPassword'

const Component = props => {

  const {
    user,
    ready,
    showSplashscreen,
    componentDidMount,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  const [resetPassword, setResetPassword] = useState(user ? !!user.reset_password : false)

  return (

    <App
      Screens={Screens}
      showApp={ready}
      showSplashscreen={showSplashscreen}
    >

      <Screens />

      {resetPassword && (
        <ResetPasswordModal
          onClose={() => setResetPassword(false)}
        />
      )}

    </App>

  )

}

Component.propTypes = {
  user: PropTypes.object,
  ready: PropTypes.bool.isRequired,
  showSplashscreen: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
}

Component.defaultProps = {
  user: null,
}

export default Component
