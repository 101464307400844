import screenProps from '../screenProps'
import fetchServices from '../../../Core/Services/fetchServices'
import fetchCustomerEnquiryStatuses from '../../../Core/Services/fetchCustomerEnquiryStatuses'
import initialize from '../Services/initialize'

export default (props) => async (dispatch) => {

  screenProps(props)

  dispatch(fetchServices())
  dispatch(fetchCustomerEnquiryStatuses())

  await dispatch(initialize())

}
