import { pick } from 'lodash'

export default (supplierEnquiry) => {
  
  const initialValues = {
    supplier_enquiry_items: supplierEnquiry.supplier_enquiry_items.map((item) => ({
      ...pick(item, [
        'id',
        'quantity',
        'target_price',
        'guid',
        'sourced_parts',
      ]),
      part: item.part || {},
    })),
  }
  
  return initialValues

}
