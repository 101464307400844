import { connect } from 'react-redux'
import Empty from './Empty'

import onClickCreate from './Handlers/onClickCreate'

const mapStateToProps = () => ({
})

const actionCreators = {
  onClickCreate,
}

export default connect(mapStateToProps, actionCreators)(Empty)
