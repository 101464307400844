import { closeManufacturerModal } from '../State/manufacturerModal'
import updateRow from '../Services/updateRow'

export default (manufacturer) => (dispatch, getState) => {

  const { manufacturerModal } = getState().screens.CustomerEnquiry.Request

  const { index } = manufacturerModal

  if (manufacturer) {
    dispatch(updateRow(index, { manufacturer }))
  }

  dispatch(closeManufacturerModal())
  
}
