import React from 'react'
import PropTypes from 'prop-types'
import { Input, Select } from 'antd'
import { Module } from '../../../../../../../packages/sota'

const Header = (props) => {

  const {
    searchTerm,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder="Search"
        style={{ width: 250, marginRight: 10 }}
      />

    </React.Fragment>

  )

  return (

    <Module.Header
      renderLeft={renderLeft}
    />

  )
}

Header.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header
