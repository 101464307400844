import modalProps from '../modalProps'
import { resetActiveTab } from '../State/activeTab'
import { resetSearch } from '../State/search'
import { resetData } from '../State/data'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetActiveTab())
  dispatch(resetSearch())
  dispatch(resetData())

}
