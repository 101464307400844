import modalProps from '../../../modalProps'
import moment from 'moment'
import chance from 'chance'
import { pick } from 'lodash'
import { findSourcedPart } from '../../../../../Api/sourcedParts'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id, part, supplier, service, exchangeRate } = modalProps()

    if (id) {
      
      const params = {
        include: 'supplier.currency,part,service',
      }

      const response = await findSourcedPart({ id, params })

      const sourcedPart = response.data.data

      const initialValues = {
        ...pick(sourcedPart, [
          'id',
          'supplier_enquiry_item_id',
          'reference',
          'unit_cost',
          'exchange_rate',
          'landing_costs',
          'quantity',
          'lead_time',
          'date_code',
          'notes',
        ]),
        part: {
          ...pick(sourcedPart.part, [
            'id',
            'internal_no',
          ]),
        },
        supplier: {
          ...pick(sourcedPart.supplier, [
            'id',
            'name',
            'currency',
          ]),
        },
        service: {
          ...pick(sourcedPart.service, [
            'id',
            'name',
          ]),
        },
        date: moment(sourcedPart.date),
        rohs: sourcedPart.rohs,
        guid: sourcedPart.guid,
      }
    
      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
        part: part || {},
        supplier: supplier || {},
        service: service || {},
        exchange_rate: exchangeRate || 1,
        date: moment(),
        rohs: false,
        guid: chance().guid(),
      }
    
      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)
    
  }

}
