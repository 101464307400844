import { createAction, handleActions } from 'redux-actions'

const setCustomerEnquiry = createAction('SCREENS/CUSTOMER_ENQUIRY/SET_CUSTOMER_ENQUIRY')
const resetCustomerEnquiry = createAction('SCREENS/CUSTOMER_ENQUIRY/RESET_CUSTOMER_ENQUIRY')

const defaultState = null

const reducer = handleActions(
  {
    [setCustomerEnquiry]: (state, action) => action.payload,
    [resetCustomerEnquiry]: () => defaultState,
  },
  defaultState,
)

export { setCustomerEnquiry, resetCustomerEnquiry, reducer }
