export default {
  
  /** Prime Template */
 
  'Prime': [
    {
      type: 'paragraph',
      formatting: [],
      content: 'Hello ___',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please complete all relevant fields in the table below to provide us with your best price and delivery for the items listed.',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please ensure that any MOQs are identified, if no stock is available please quote item lead time.',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please only quote lines for which you hold a current manufacturer authorisation / franchise.',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please confirm whether or not manufacturers full warranty will be passed on.',
    },
    {
      type: 'table',
      cols: [
        { title: 'Part', data: (item) => item.part.manufacturer_no },
        { title: 'Manufacturer', data: (item) => item.part.manufacturer.name },
        { title: 'Qty', data: (item) => item.quantity, width: 60 },
        { title: 'Target', data: (item, enquiry) => item.target_price ? `${item.target_price} ${enquiry.supplier.currency.code}` : '', width: 80 },
        { title: 'Best Price *', width: 80 },
        { title: 'Do you hold the<br />MFR Franchise for<br />these items? (Y/N)*', width: 120 },
        { title: 'Lead Time*' },
        { title: 'DC/RoHS*' },
        { title: 'Packaging*<br />(tubes/reels/bulk/etc)' },
        { title: 'ECCN<br />(if applicable)' },
        { title: 'Notes/Comments' },
      ],
    },
    {
      type: 'paragraph',
      formatting: ['B'],
      content: 'PLEASE ENSURE THAT ALL FIELDS MARKED WITH AN ASTERISK(*) ARE COMPLETED',
    },
    {
      type: 'list',
      items: [
        'Please notify us if the total shipping weight for the items in this RFQ is likely to be over 20Kg.',
        'For shelf life items, please ensure that 90% of any shelf life will be remaining upon receipt of items.',
        'If no mention of the International Traffic in Arms Regulations(ITAR) or Export Administration Regulations (EAR) appears on the quote/order confirmation, Princeps assume the products ordered are not affected by ITAR/EAR.',
        'It is the responsibility of the supplier to advise is the product being quoted is subject to ITAR/EAR.',
        'Princeps will only accept devices that are New and Unused (As defined by AS5553/AS6081).',
        'The seller shall ensure that only new and authentic parts are quoted in response to this RFQ.',
        'The seller shall maintain a method of item traceability that ensures tracking of the supply chain back to the manufacturer of all parts being quoted per this RFQ.',
        'The seller shall only purchase directly from the Original Component Manufacturer (OCM), OCM Authorised Distributors or OCM Authorised Aftermarket Suppliers.',
      ],
    },
  ],
  
  
  


  /** Prime+ Template */

  'Prime+': [
    {
      type: 'paragraph',
      formatting: [],
      content: 'Hello ___',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please complete all relevant fields in the table below to provide us with your best price and delivery for the items listed.',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please ensure that any MOQs are identified, if no stock is available please quote item lead time.',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please only quote lines for which you hold a current manufacturer authorisation / franchise and for which you can provide full traceability back to the OCM. – Please see the Traceability Requirements at the bottom of this email.',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please confirm whether or not manufacturers full warranty will be passed on.',
    },
    {
      type: 'table',
      cols: [
        { title: 'Part', data: (item) => item.part.manufacturer_no },
        { title: 'Manufacturer', data: (item) => item.part.manufacturer.name },
        { title: 'Qty', data: (item) => item.quantity, width: 60 },
        { title: 'Target', data: (item, enquiry) => item.target_price ? `${item.target_price} ${enquiry.supplier.currency.code}` : '', width: 80 },
        { title: 'Best Price *', width: 80 },
        { title: 'Do you hold the<br />MFR Franchise for<br />these items? (Y/N)*', width: 120 },
        { title: 'Lead Time*' },
        { title: 'DC/RoHS*' },
        { title: 'Packaging*<br />(tubes/reels/bulk/etc)' },
        { title: 'ECCN<br />(if applicable)' },
        { title: 'Notes/Comments' },
      ],
    },
    {
      type: 'paragraph',
      formatting: ['B'],
      content: 'PLEASE ENSURE THAT ALL FIELDS MARKED WITH AN ASTERISK(*) ARE COMPLETED',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please confirm whether or not manufacturers full warranty will be passed on.',
    },
    {
      type: 'list',
      items: [
        'The seller shall approve, retain and provide copies of supply chain traceability documentation in the form of delivery notes and/or certificates of conformity for all stages in the supply chain back to the Original Component Manufacturer (OCM).',
        'Please notify us if the total shipping weight for the items in this RFQ is likely to be over 20Kg.',
        'For shelf life items, please ensure that 90% of any shelf life will be remaining upon receipt of items.',
        'If no mention of the International Traffic in Arms Regulations(ITAR) or Export Administration Regulations (EAR) appears on the quote/order confirmation, Princeps assume the products ordered are not affected by ITAR/EAR.',
        'It is the responsibility of the supplier to advise if the product being quoted is subject to ITAR/EAR.',
        'Princeps will only accept devices that are New and Unused (As defined by AS5553/AS6081).',
        'The seller shall ensure that only new and authentic parts are quoted in response to this RFQ.',
        'The seller shall maintain a method of item traceability that ensures tracking of the supply chain back to the manufacturer of all parts being quoted per this RFQ.',
        'The seller shall only purchase directly from the OCM, OCM Authorised Distributors or OCM Authroised Aftermarket Suppliers.',
       ],
    },
    {
      type: 'paragraph',
      formatting: ['T'],
      content: 'SUPPLY CHAIN TRACEABILITY REQUIREMENTS',
    },
    {
      type: 'paragraph',
      formatting: ['S'],
      content: 'For Deliveries to Princeps Electronics Limited',
    },
    {
      type: 'table2',
      cols: [
        { title: 'AUTHORISED DISTRIBUTOR', data: [
          { type: 'section', content: '1. Authorised Distributor C of C/Advice Note Detailing:' },
          { type: 'line', content: 'Distributor Full Name and Address' },
          { type: 'line', content: 'MFR Part Number' },
          { type: 'line', content: 'Distributor Internal Part Number' },
          { type: 'line', content: 'Princeps Ordered Part Number' },
          { type: 'line', content: 'Qty being supplied' },
          { type: 'line', content: 'Batch ID such as Date Code, Lot Number, Serialisation' },
          { type: 'line', content: 'Princeps PO Number' },
          { type: 'line', content: 'Reference to OCM Delivery Paperwork' },
          { type: 'line', content: 'Signature or Stamp' },
          { type: 'break' },
          { type: 'section', content: '2. OCM Delivery Paperwork/C of C Detailing:' },
          { type: 'line', content: 'MFR Name and Address' },
          { type: 'line', content: 'MFR and/or buyers full part number and description' },
          { type: 'line', content: 'Batch ID such as Date Code, Lot Number, Serialisation' },
          { type: 'line', content: 'Qty being supplied' },
          { type: 'line', content: 'Stamp or Signature' },
          { type: 'break' },
          { type: 'section', content: '3. If the parts have not been procured direct from the OCM, supply chain traceability in the form of Item 1 above must be provided for each step back to the OCM' },
        ]},
        { title: 'ORIGINAL COMPONENT MANUFACTURER', data: [
          { type: 'section', content: '1. OCM Delivery Paperwork/C of C Detailing:' },
          { type: 'line', content: 'MFR Name and Address' },
          { type: 'line', content: 'MFR and/or buyers full part number and description' },
          { type: 'line', content: 'Batch ID such as Date Code, Lot Number, Serialisation' },
          { type: 'line', content: 'Stamp or Signature' },
          { type: 'line', content: 'Princeps Ordered Part Number' },
          { type: 'line', content: 'Qty being supplied' },
          { type: 'line', content: 'Princeps PO number' },
        ]},
      ],
    },
  ],





  /** Assure Template */

  'Assure': [
    {
      type: 'paragraph',
      formatting: [],
      content: 'Hello ___',
    },
    {
      type: 'paragraph',
      formatting: [],
      content: 'Please complete all relevant fields in the table below to provide us with your best price and delivery for the items listed.',
    },
    {
      type: 'table',
      cols: [
        { title: 'Part', data: (item) => item.part.manufacturer_no },
        { title: 'Manufacturer', data: (item) => item.part.manufacturer.name },
        { title: 'Qty', data: (item) => item.quantity, width: 60 },
        { title: 'Target', data: (item, enquiry) => item.target_price ? `${item.target_price} ${enquiry.supplier.currency.code}` : '', width: 80 },
        { title: 'Best Price *', width: 80 },
        { title: 'DC/RoHS*' },
        { title: 'Packaging*<br />(tubes/reels/bulk/etc)' },
      ],
    },
    {
      type: 'paragraph',
      formatting: ['B'],
      content: 'PLEASE ENSURE THAT ALL FIELDS MARKED WITH AN ASTERISK(*) ARE COMPLETED',
    },
    {
      type: 'list',
      items: [
        'All parts received at Princeps will be inspected, as a minimum, to the level required under IDEA - STD - 1010',
        'If no mention of the International Traffic in Arms Regulations(ITAR) or Export Administration Regulations appears on the quotation / order confirmation, Princeps assume the products are not affected by ITAR / EAR',
        'It is the responsibility of the supplier to advice if the product being quoted is subject to ITAR/ EAR',
        'Princeps will only accept devices that are new and unused(As defined by IDEA - STD - 1010)',
        'Please do not quote if your parts have previously been used or installed in circuits, are remarked, changed or altered in any way',
        'Fraudulent or Counterfeit devices will not be returned under any circumstances',
        'Fraudulent or Counterfeit devices will be reported to the ERAI High Risk Parts Database',
      ],
    },
  ],





  /** Assure+ Template */

  'Assure+': [

  ],

}