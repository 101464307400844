import updateRow from './updateRow'

export default (part) => (dispatch, getState) => {

  const { index } = getState().screens.CustomerEnquiry.Request.findPartModal

  dispatch(updateRow(index, {
    manufacturer_part_no: part.manufacturer_no,
    manufacturer: part.manufacturer,
    part,
  }))

}
