import { createAction, handleActions } from 'redux-actions'

const openCustomerModal = createAction('MODALS/CUSTOMER_ENQUIRY/OPEN_CUSTOMER_MODAL')
const closeCustomerModal = createAction('MODALS/CUSTOMER_ENQUIRY/CLOSE_CUSTOMER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openCustomerModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeCustomerModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCustomerModal, closeCustomerModal, reducer }
