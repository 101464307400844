import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class extends React.PureComponent {

  static propTypes = {
    manufacturer: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render() {

    const {
      manufacturer,
      submitting,
      onSubmit,
      onClose,
    } = this.props

    const footer = [
      <Button key='cancel' onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
        Merge
      </Button>,
    ]

    return (

      <Modal
        visible
        title='Merge Manufacturer'
        width={550}
        footer={footer}
        onCancel={() => onClose()}
      >

        <Form manufacturer={manufacturer} />

      </Modal>

    )

  }

}
