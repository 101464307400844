import { connect } from 'react-redux'
import SupplierContacts from './SupplierContacts'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onClickNew from './Handlers/onClickNew'
import onCloseSupplierContactModal from './Handlers/onCloseSupplierContactModal'

const mapStateToProps = (state) => ({
  supplierContactModal: state.screens.Supplier.SupplierContacts.supplierContactModal,
  supplierId: state.screens.Supplier.supplier.id,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onClickNew,
  onCloseSupplierContactModal,
}

export default connect(mapStateToProps, actionCreators)(SupplierContacts)
