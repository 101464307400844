/* eslint-disable no-await-in-loop */

import { startUpload, finishUpload, setProgress, queueUploads, shiftQueue } from '../State/uploads'

export default (uploads) => async (dispatch, getState) => {

  dispatch(queueUploads(uploads))

  const { uploading } = getState().core.uploads

  if (uploading) return

  dispatch(startUpload())

  while (getState().core.uploads.queue.length > 0) {

    const upload = getState().core.uploads.queue[0]

    const data = new FormData()

    data.append('file', upload.file)

    if (upload.data) {
      Object.keys(upload.data).forEach(key => {
        data.append(key, upload.data[key])
      })
    }

    try {

      const config = {
        onUploadProgress: (progress) => dispatch(setProgress(Math.floor((progress.loaded * 100) / progress.total))),
      }

      const { data: response } = await upload.request({ data, config })

      dispatch(shiftQueue())

      dispatch(setProgress(0))

      if (upload.onSuccess) dispatch(upload.onSuccess(response))

    } catch (error) {

      dispatch(shiftQueue())

      dispatch(setProgress(0))

      if (upload.onFailure) dispatch(upload.onFailure(error, upload.file))
      
    }

  }

  dispatch(finishUpload())

}