import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

const Table = (props) => {

  const {
    customerEnquiries,
    onChange,
  } = props

  return (

    <AntTable
      dataSource={customerEnquiries}
      rowKey="id"
      pagination={false}
      onChange={onChange}
      size="middle"
    >

      <AntTable.Column
        key="reference"
        title="Ref."
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={`/portal/customer_enquiries/${record.id}`}>
            {record.reference}
          </NavLink>
        )}
      />

      <AntTable.Column
        key="customer.name"
        title="Customer"
        sorter
        render={(text, record) => record.customer.name}
      />

      <AntTable.Column
        key="received_on"
        title="Date"
        sorter
        render={(text, record) => moment(record.received_on).format('D MMM YYYY')}
      />

      <AntTable.Column
        key="manager.name"
        title="Manager"
        sorter
        render={(text, record) => record.manager.name}
      />

    </AntTable>

  )

}

Table.propTypes = {
  customerEnquiries: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table
