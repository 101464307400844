import { resetSearchTerm } from '../State/searchTerm'
import { resetSorting } from '../State/sorting'
import { resetSuppliers } from '../State/suppliers'

export default () => async (dispatch) => {

  dispatch(resetSearchTerm())
  dispatch(resetSorting())
  dispatch(resetSuppliers())
  
}
