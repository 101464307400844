import screenProps from '../screenProps'
import reset from '../Services/reset'
import resetCustomerEnquiries from '../Components/CustomerEnquiries/Services/reset'
import resetSupplierEnquiries from '../Components/SupplierEnquiries/Services/reset'
import resetSourcedParts from '../Components/SourcedParts/Services/reset'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(reset())

  dispatch(resetCustomerEnquiries())
  dispatch(resetSupplierEnquiries())
  dispatch(resetSourcedParts())

}
