import React from 'react'
import { findCustomer, getCustomers } from '../../Api/customers'

import AutoComplete from './AutoComplete'

export default class Customer extends React.PureComponent {

  fetchSuggestions = async (term) => {

    const response = await getCustomers({
      params: {
        search: term,
        sort: 'name',
        include: 'currency',
        'page[size]': 30,
      },
    })
    
    return response.data.data

  }

  fetchSelected = async (id) => {
    
    const response = await findCustomer({
      id,
      params: {
        include: 'currency',
      },
    })
    
    const customer = response.data.data

    if (!customer) return null
    
    return {
      id: customer.id,
      name: customer.name,
      payment_terms: customer.payment_terms,
      currency: customer.currency,
    }
    
  }

  render () {

    return (

      <AutoComplete
        keyProp="id"
        labelProp="name"
        labelBracketsProp="currency.symbol"
        placeholder="Select Customer"
        fetchSuggestions={this.fetchSuggestions}
        fetchSelected={this.fetchSelected}
        {...this.props}
      />

    )
  }
}