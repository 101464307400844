import { message } from 'antd'
import modalProps from '../modalProps'
import { updateImport } from '../../../Api/imports'
import { runCustomerEnquiryImport } from '../../../Api/customerEnquiries'
import fetch from './fetch'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { id, onClose } = modalProps()

  const { payload } = getState().modals.CustomerEnquiryImport.data

  const data = {
    payload,
  }

  try {

    await updateImport({ id, data })

    const { data: { success } } = await runCustomerEnquiryImport({ id })

    if (success) {
      message.success('Import successful')
      onClose(true)
    } else {
      dispatch(fetch())
    }

  } catch (error) {

    handleException(error)

  }

}
