import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, InputNumber, Button, Icon, Badge } from 'antd'
import { get, isEmpty, upperFirst } from 'lodash'
import moment from 'moment'
import { AppBar, Menu } from '../../../packages/sota'
import styles from './styles.css'

const updateDue = createdAt => {
  if (!createdAt) return true
  return moment(createdAt).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
}

const ExchangeRates = props => {

  const {
    data,
    componentDidMount,
    onSubmit,
    validate,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  const exchangeRateMenu = () => (

    <Menu className={styles.menu}>

      <div className={styles.header}>Exchange Rates</div>

      <div className={styles.form}>

        <FormProvider
          initialValues={data}
          validate={validate}
          onSubmit={onSubmit}
        >

          {({ handleSubmit, submitting, pristine }) => (

            <Form
              onSubmit={handleSubmit}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
            >

              <Field name='eur'>
                {({ input, meta }) => (
                  <Form.Item
                    label='£1 / EUR'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <InputNumber
                      {...input}
                      step={0.01}
                      min={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name='usd'>
                {({ input, meta }) => (
                  <Form.Item
                    label='£1 / USD'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <InputNumber
                      {...input}
                      step={0.01}
                      min={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              {!isEmpty(data) && pristine && (
                <div className={styles.previous}>
                  <span>{upperFirst(`${moment(data.created_at).fromNow()} by ${data.user.name}`)}</span>
                </div>
              )}

              {!pristine && (
                <div className={styles.submit}>
                  <Button
                    type='primary'
                    loading={submitting}
                    onClick={() => handleSubmit()}
                  >
                    Update
                  </Button>
                </div>
              )}

            </Form>

          )}

        </FormProvider>

      </div>

    </Menu>

  )
  
  return (

    <AppBar.Button
      dropdown={exchangeRateMenu}
      dropdownPlacement='right'
    >
      <Badge dot={updateDue(get(data, 'created_at'))} size='large'>
        <Icon type='pound' style={{ fontSize: 20 }} />
      </Badge>
    </AppBar.Button>

  )

}

ExchangeRates.propTypes = {
  data: PropTypes.object,
  componentDidMount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

ExchangeRates.defaultProps = {
  data: null,
}

export default ExchangeRates
