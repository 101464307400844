export default () => async (dispatch, getState) => {

  const { token } = getState().app

  const { searchTerm, sorting } = getState().screens.Customers.Table

  const params = {}

  if (searchTerm) params.search = searchTerm

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'

  let url = `/api/customers/export?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.open(url)

}
