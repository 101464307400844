import { message } from 'antd'
import { deleteSalesOrderItem } from '../../../../../Api/salesOrderItems'
import fetch from './fetch'
import { handleException } from '../../../../../../packages/sota'

export default (salesOrderItem) => async (dispatch) => {

  try {

    await deleteSalesOrderItem({ id: salesOrderItem.id })

    message.success('Deleted order item')

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }
  
}
