import { pick } from 'lodash'
import chance from 'chance'
import { openDuplicateSupplierEnquiryModal } from '../../../State/duplicateSupplierEnquiryModal'

export default () => (dispatch, getState) => {

  const { supplierEnquiry } = getState().screens.SupplierEnquiry

  dispatch(openDuplicateSupplierEnquiryModal({
    customerEnquiryId: supplierEnquiry.customer_enquiry_id,
    serviceId: supplierEnquiry.service_id,
    items: supplierEnquiry.supplier_enquiry_items.map(row => ({
      ...pick(row, ['part_id', 'quantity']),
      guid: chance().guid(),
    })),
  }))
  
}
