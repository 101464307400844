import { get } from 'lodash'
import { store } from '../store'

export default (code) => {

  const exchangeRate = store.getState().components.ExchangeRates.data

  if (code === 'GBP') {
    return 1
  }

  if (code === 'EUR') {
    return get(exchangeRate, 'eur')
  }

  if (code === 'USD') {
    return get(exchangeRate, 'usd')
  }

  return null

}
