import { defer } from 'lodash'
import fetch from './fetch'
import prepareInitialValues from '../Utils/prepareInitialValues'
import { setFormInitialValues } from '../State/formState'
import shouldAddRow from '../Utils/shouldAddRow'
import addRow from './addRow'

export default () => async (dispatch) => {

  const customerEnquiryItems = await dispatch(fetch())

  const initialValues = prepareInitialValues(customerEnquiryItems)

  dispatch(setFormInitialValues(initialValues))

  if (shouldAddRow(initialValues)) {
    defer(() => dispatch(addRow()))
  }

}
