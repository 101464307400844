import { combineReducers } from 'redux'

import { reducer as customerModal } from './State/customerModal'

import Table from './Components/Table/reducer'

export default combineReducers({
  customerModal,

  Table,
})
