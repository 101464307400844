import { closePartModal } from '../State/partModal'
import { setNewField } from '../State/data'
import save from '../Services/save'

export default (part) => (dispatch, getState) => {

  const state = getState()

  const { data } = state.modals.CustomerEnquiryImport

  const { partModal } = state.modals.CustomerEnquiryImport

  dispatch(closePartModal())

  if (!part) return

  const parts = []

  data.new.parts.forEach((row, index) => {
    if (index === partModal.index) return
    parts.push({ ...row })
  })

  dispatch(setNewField({
    field: 'parts',
    data: parts,
  }))

  dispatch(save())
  
}
