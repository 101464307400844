import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Tooltip } from 'antd'
import numeral from 'numeral'
import styles from './styles.css'

// Monetary
const mon = (sym, num, def = '-') => num ? `${sym}${numeral(num).format('0,0.00')}` : def

const SourcedPartPopover = props => {

  const {
    sourcedPart,
    children,
    ...rest
  } = props

  if (!sourcedPart) return children

  const { symbol: sym } = sourcedPart.supplier.currency

  const content = (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Supplier</th>
          <th className={styles.num}>Qty</th>
          <th>Service</th>
          <th>Lead Time</th>
          <th>RoHS</th>
          <th>Date Code</th>
          <th>Reference</th>
          <th className={styles.num}>Unit Cost</th>
          <th className={styles.num}>Total Cost</th>
          <th className={styles.num}>Exchange Rate</th>
          <th className={styles.num}>Total Cost</th>
          {sourcedPart.landing_costs && (
            <th className={styles.num}>Carriage Costs</th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{sourcedPart.supplier.name}</td>
          <td className={styles.num}>{numeral(sourcedPart.quantity).format('0,0')}</td>
          <td>{sourcedPart.service.name}</td>
          <td>{sourcedPart.lead_time}</td>
          <td>
            {sourcedPart.rohs === null && 'N/A'}
            {sourcedPart.rohs === 1 && 'Yes'}
            {sourcedPart.rohs === 0 && 'No'}
          </td>
          <td>{sourcedPart.date_code}</td>
          <td>{sourcedPart.reference}</td>
          <td className={styles.num}>{mon(sym, sourcedPart.unit_cost, 'No Bid')}</td>
          <td className={styles.num}>{mon(sym, sourcedPart.total_cost)}</td>
          <Tooltip title={`£1 gets you ${sym}${numeral(sourcedPart.exchange_rate).format('0,0.[00]')}`}>
            <td className={styles.num}>{numeral(sourcedPart.exchange_rate).format('0,0.00')}</td>
          </Tooltip>
          <td className={styles.num}>{mon('£', sourcedPart.total_cost_gbp)}</td>
          {sourcedPart.landing_costs && (
            <td className={styles.num}>{mon('£', sourcedPart.landing_costs)}</td>
          )}
        </tr>
      </tbody>
    </table>
  )

  return (

    <Popover
      title='Sourced Part'
      content={content}
      {...rest}
    >

      {children}

    </Popover>

  )

}

SourcedPartPopover.propTypes = {
  sourcedPart: PropTypes.object,
  children: PropTypes.node.isRequired,
}

SourcedPartPopover.defaultProps = {
  sourcedPart: null,
}

export default SourcedPartPopover
