import React from 'react'
import { Module } from '../../../../../packages/sota'

import Header from './Components/Header'
import Content from './Components/Content'
import Footer from './Components/Footer'

const Table = () => (

  <Module>

    <Header />

    <Content />

    <Footer />

  </Module>

)

export default Table
