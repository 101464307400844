import { createAction, handleActions } from 'redux-actions'
import { random } from 'lodash'

const setControls = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/SET_CONTROLS')
const setControl = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/SET_CONTROL')
const resetControls = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/RESET_CONTROLS')

const defaultState = {
  source: ['user', 'department', 'service', 'customer'][random(0, 3)],
  chart: ['column', 'pie'][random(0, 1)],
  yAxis: ['quantity', 'value'][random(0, 1)],
  sort: 'desc',
}

const reducer = handleActions(
  {
    [setControls]: (state, { payload }) => payload,
    [setControl]: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    [resetControls]: () => defaultState,
  },
  defaultState,
)

export {
  setControls,
  setControl,
  resetControls,
  reducer,
}
