import { createAction, handleActions } from 'redux-actions'

const openSupplierEnquiryModal = createAction('SCREENS/SUPPLIER_ENQUIRY/OPEN_SUPPLIER_ENQUIRY_MODAL')
const closeSupplierEnquiryModal = createAction('SCREENS/SUPPLIER_ENQUIRY/CLOSE_SUPPLIER_ENQUIRY_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSupplierEnquiryModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeSupplierEnquiryModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSupplierEnquiryModal, closeSupplierEnquiryModal, reducer }
