import { connect } from "react-redux"
import { includes } from 'lodash'
import Part from "./Part"

import selectCustomerPartNumbers from './Selectors/selectCustomerPartNumbers'

import onToggleClosed from './Handlers/onToggleClosed'

const mapStateToProps = (state, props) => ({
  isClosed: includes(state.screens.CustomerEnquiry.SourcedParts.closedParts, props.part.guid),
  customerPartNumbers: selectCustomerPartNumbers(state, props),
})

const actionCreators = {
  onToggleClosed,
}

export default connect(mapStateToProps, actionCreators)(Part)
