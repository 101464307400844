import { connect } from "react-redux"
import { get } from "lodash"
import CustomerEnquiry from "./CustomerEnquiry"

import selectActiveTab from "./Selectors/selectActiveTab"

import componentDidMount from "./Handlers/componentDidMount"
import componentDidUpdate from "./Handlers/componentDidUpdate"
import componentWillUnmount from "./Handlers/componentWillUnmount"
import onCloseCustomerEnquiryModal from "./Handlers/onCloseCustomerEnquiryModal"

const mapStateToProps = (state) => ({
  id: get(state.screens.CustomerEnquiry.customerEnquiry, 'id'),
  initialized: state.screens.CustomerEnquiry.initialized,
  activeTab: selectActiveTab(state),
  customerEnquiryModal: state.screens.CustomerEnquiry.customerEnquiryModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseCustomerEnquiryModal,
}

export default connect(mapStateToProps, actionCreators)(CustomerEnquiry)
