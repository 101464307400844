import moment from 'moment'
import { getServices } from '../../Api/services'
import { loadServices } from '../State/services'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'id',
    }

    const response = await getServices({ params })

    dispatch(loadServices(response.data.data))

  } catch (error) {
    
    throw error

  }

}
