import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'

import Header from './Components/Header'
import Divider from './Components/Divider'
import CustomerEnquiryItem from './Components/CustomerEnquiryItem'
import Actions from './Components/Actions'
import ManufacturerModal from '../../../../Modals/Manufacturer'
import FindPartModal from '../../../../Modals/FindPart'
import UseSupplierModal from '../../../../Modals/UseSupplier'
import CustomerEnquiryImportModal from '../../../../Modals/CustomerEnquiryImport'

const Components = {
  'HEADER': Header,
  'DIVIDER': Divider,
  'CUSTOMER_ENQUIRY_ITEM': CustomerEnquiryItem,
}

const Request = props => {

  const {
    initialValues,
    rows,
    manufacturerModal,
    findPartModal,
    useSupplierModal,
    useSupplierModalProps,
    customerEnquiryImportModal,
    componentDidMount,
    onFormChange,
    onSelectPart,
    onUseSupplier,
    onCloseManufacturerModal,
    onCloseFindPartModal,
    onCloseUseSupplierModal,
    onCloseCustomerEnquiryImportModal,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    
    <React.Fragment>

      <Form
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        onSubmit={() => { }}
      >

        {() => (

          <React.Fragment>

            <FormInterceptor
              ref={formService}
              subscription={{ values: true }}
              onChange={onFormChange}
            />

            {rows.map(row => {

              const Component = Components[row.type]

              return <Component key={row.key} {...row.data} />

            })}

          </React.Fragment>
          
        )}

      </Form>

      <Actions />

      {manufacturerModal.show && (
        <ManufacturerModal
          onClose={onCloseManufacturerModal}
        />
      )}

      {findPartModal.show && (
        <FindPartModal
          {...findPartModal}
          onSelect={onSelectPart}
          onClose={onCloseFindPartModal}
        />
      )}

      {useSupplierModal.show && (
        <UseSupplierModal
          {...useSupplierModalProps}
          onUse={onUseSupplier}
          onClose={onCloseUseSupplierModal}
        />
      )}

      {customerEnquiryImportModal.show && (
        <CustomerEnquiryImportModal
          {...customerEnquiryImportModal}
          onClose={onCloseCustomerEnquiryImportModal}
        />
      )}

    </React.Fragment>

  )

}

Request.propTypes = {
  initialValues: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  manufacturerModal: PropTypes.object.isRequired,
  findPartModal: PropTypes.object.isRequired,
  useSupplierModal: PropTypes.object.isRequired,
  useSupplierModalProps: PropTypes.object.isRequired,
  customerEnquiryImportModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSelectPart: PropTypes.func.isRequired,
  onUseSupplier: PropTypes.func.isRequired,
  onCloseManufacturerModal: PropTypes.func.isRequired,
  onCloseFindPartModal: PropTypes.func.isRequired,
  onCloseUseSupplierModal: PropTypes.func.isRequired,
  onCloseCustomerEnquiryImportModal: PropTypes.func.isRequired,
}

export default Request
