import { combineReducers } from 'redux'

import { reducer as searchTerm } from './State/searchTerm'
import { reducer as sorting } from './State/sorting'
import { reducer as sourcedParts } from './State/sourcedParts'

export default combineReducers({
  searchTerm,
  sorting,
  sourcedParts,
})
