import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import moment from 'moment'

const DateRangeSelect = props => {

  const {
    width,
    value,
    onChange,
  } = props

  const getDates = $value => {
    switch ($value) {
      // Month
      case 'this-month':
        return [moment().startOf('month'), moment().endOf('month')]
      case 'last-month':
        return [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      case 'month-to-date':
        return [moment().subtract(1, 'month').add(1, 'day'), moment()]
      // Quarter
      case 'this-quarter':
        return [moment().startOf('quarter'), moment().endOf('quarter')]
      case 'last-quarter':
        return [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')]
      case 'quarter-to-date':
        return [moment().subtract(1, 'quarter').add(1, 'day'), moment()]
      // Year
      case 'this-year':
        return [moment().startOf('year'), moment().endOf('year')]
      case 'last-year':
        return [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
      case 'year-to-date':
        return [moment().subtract(1, 'year').add(1, 'day'), moment()]
      default:
        return []
    }
  }
  
  return (

    <Select
      size='small'
      allowClear
      placeholder='All Time'
      value={value || undefined}
      onChange={$value => onChange([$value, getDates($value)])}
      style={{ width }}
    >
      <Select.OptGroup label='Month'>
        <Select.Option key='this-month' value='this-month'>This Month</Select.Option>
        <Select.Option key='last-month' value='last-month'>Last Month</Select.Option>
        <Select.Option key='month-to-date' value='month-to-date'>Month to Date</Select.Option>
      </Select.OptGroup>
      <Select.OptGroup label='Quarter'>
        <Select.Option key='this-quarter' value='this-quarter'>This Quarter</Select.Option>
        <Select.Option key='last-quarter' value='last-quarter'>Last Quarter</Select.Option>
        <Select.Option key='quarter-to-date' value='quarter-to-date'>Quarter to Date</Select.Option>
      </Select.OptGroup>
      <Select.OptGroup label='Year'>
        <Select.Option key='this-year' value='this-year'>This Year</Select.Option>
        <Select.Option key='last-year' value='last-year'>Last Year</Select.Option>
        <Select.Option key='year-to-date' value='year-to-date'>Year to Date</Select.Option>
      </Select.OptGroup>
    </Select>

  )

}

DateRangeSelect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

DateRangeSelect.defaultProps = {
  value: null,
}

export default DateRangeSelect
