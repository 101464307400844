import { closeSupplierModal } from '../State/supplierModal'
import fetch from '../Services/fetch'

export default (supplier) => (dispatch) => {
  
  dispatch(closeSupplierModal())
  
  if (supplier) {
    
    dispatch(fetch())
    
  }
  
}
