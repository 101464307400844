import { message } from 'antd'
import modalProps from '../../../modalProps'
import { mergeCustomer } from '../../../../../Api/customers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { customer } = modalProps()

  const data = {
    customer_id: values.customer.id,
  }

  try {

    const response = await mergeCustomer({ id: customer.id, data })
    
    modalProps().onClose(response.data.data)
    
    message.success(`Merged "${customer.name}" into "${values.customer.name}" successfully`)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
