import { closeSupplierModal } from '../State/supplierModal'
import fetch from '../Services/fetch'
import useSupplier from '../Services/useSupplier'

export default (supplier) => (dispatch) => {

  dispatch(closeSupplierModal())

  if (supplier) {
    dispatch(fetch())
    dispatch(useSupplier(supplier))
  }

}
