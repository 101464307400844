import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Spin } from 'antd'
import styles from './styles.css'

const Header = (props) => (

  <div className={styles.wrapper}>

    <div className={styles.spinWrapper}>
      {props.loading && <Spin size="small" />}
    </div>

    <div className={classNames(styles.col, styles.col1)}>
      Manufacturer PN
    </div>

    <div className={classNames(styles.col, styles.col2)}>
      Manufacturer
    </div>

    <div className={classNames(styles.col, styles.col3)}>
      Internal PN
    </div>

    <div className={classNames(styles.col, styles.col4, styles.colRight)}>
      Qty
    </div>

    <div className={classNames(styles.col, styles.col5, styles.colRight)}>
      {`Target (${props.supplierEnquiry.supplier.currency.symbol})`}
    </div>

  </div>

)

Header.propTypes = {
  supplierEnquiry: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default Header
