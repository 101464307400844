import { closePartModal } from '../State/partModal'
import fetch from '../Services/fetch'
import selectPart from '../Services/selectPart'

export default (part) => (dispatch) => {

  dispatch(closePartModal())

  if (part) {
    dispatch(fetch())
    dispatch(selectPart(part))
  }

}
