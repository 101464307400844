import { combineReducers } from "redux"

import { reducer as searchTerm } from "./State/searchTerm"
import { reducer as fetching } from "./State/fetching"
import { reducer as parts } from "./State/parts"
import { reducer as partModal } from "./State/partModal"

export default combineReducers({
  searchTerm,
  fetching,
  parts,
  partModal,
})
