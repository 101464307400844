import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'

import CustomerEnquiriesInPeriod from './Components/CustomerEnquiriesInPeriod/reducer'
import SalesOrdersInPeriod from './Components/SalesOrdersInPeriod/reducer'
import ConversionInPeriod from './Components/ConversionInPeriod/reducer'

export default combineReducers({
  controls,
  
  CustomerEnquiriesInPeriod,
  SalesOrdersInPeriod,
  ConversionInPeriod,
})
