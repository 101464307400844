import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'

const setControls = createAction('SCREENS/DASHBOARD/SET_CONTROLS')
const setControl = createAction('SCREENS/DASHBOARD/SET_CONTROL')
const resetControls = createAction('SCREENS/DASHBOARD/RESET_CONTROLS')

const defaultState = {
  dateRangeSelect: 'this-quarter',
  dateRange: [moment().startOf('quarter'), moment().endOf('quarter')],
  userIds: [],
  departmentKeys: [],
  serviceIds: [],
  customerIds: [],
}

const reducer = handleActions(
  {
    [setControls]: (state, { payload }) => payload,
    [setControl]: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    [resetControls]: () => defaultState,
  },
  defaultState,
)

export {
  setControls,
  setControl,
  resetControls,
  reducer,
}
