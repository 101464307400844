import { closeSupplierModal } from '../State/supplierModal'
import fetchSuppliersTable from '../Components/Table/Services/fetch'
import { push } from 'connected-react-router'

export default (supplier) => (dispatch) => {

  dispatch(closeSupplierModal())

  if (supplier) {
    dispatch(push(`/portal/suppliers/${supplier.id}`))
    dispatch(fetchSuppliersTable())
  }

}
