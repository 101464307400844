import { message } from 'antd'
import { deleteSupplierContact } from '../../../Api/supplierContacts'
import { handleException } from '../../../../packages/sota'
import modalProps from '../modalProps'

export default () => async () => {

  try {

    const { id } = modalProps()

    await deleteSupplierContact({ id })

    modalProps().onClose(true)

    message.success(`Deleted Contact`)

  } catch (error) {

    handleException(error)

  }
  
}
