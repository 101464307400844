import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import styles from './styles.css'

import Header from './Components/Header'
import Empty from './Components/Empty'
import Subheader from './Components/Subheader'
import Divider from './Components/Divider'
import Part from './Components/Part'
import SourcedPart from './Components/SourcedPart'
import SourcedPartFooter from './Components/SourcedPartFooter'
import SourcedPartModal from '../../../../Modals/SourcedPart'
import QuoteSourcedPart from '../../../../Modals/QuoteSourcedPart'

const Components = {
  'HEADER': Header,
  'SUBHEADER': Subheader,
  'DIVIDER': Divider,
  'PART': Part,
  'SOURCED_PART': SourcedPart,
  'SOURCED_PART_FOOTER': SourcedPartFooter,
  'EMPTY': Empty,
}

export default class SourcedParts extends React.PureComponent {
  
  static propTypes = {
    rows: PropTypes.array.isRequired,
    customerEnquiryId: PropTypes.number.isRequired,
    sourcedPartModal: PropTypes.object.isRequired,
    quoteSourcedPartModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onCloseSourcedPartModal: PropTypes.func.isRequired,
    onCloseQuoteSourcedPartModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render () {

    const {
      rows,
      customerEnquiryId,
      sourcedPartModal,
      quoteSourcedPartModal,
      onClickAdd,
      onCloseSourcedPartModal,
      onCloseQuoteSourcedPartModal,
    } = this.props

    return (
      
      <div>

        {rows.map((row) => {

          const Component = Components[row.type]

          return <Component key={row.key} {...row.data} />

        })}

        <div className={styles.buttons}>

          <div className={styles.left}>

            <Button
              type="primary"
              icon="plus"
              onClick={onClickAdd}
            >
              Add Source
            </Button>

          </div>

        </div>

        {sourcedPartModal.show && (
          <SourcedPartModal
            onClose={onCloseSourcedPartModal}
            id={sourcedPartModal.id}
            part={sourcedPartModal.part}
            exchangeRate={sourcedPartModal.exchangeRate}
            customerEnquiryId={customerEnquiryId}
          />
        )}

        {quoteSourcedPartModal.show && (
          <QuoteSourcedPart
            id={quoteSourcedPartModal.id}
            landedCost={quoteSourcedPartModal.landedCost}
            onClose={onCloseQuoteSourcedPartModal}
          />
        )}
          
      </div>
      
    )
  }
}
