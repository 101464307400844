import { connect } from 'react-redux'
import Component from './Component'

import onSearch from './Handlers/onSearch'
import onClickCreatePart from './Handlers/onClickCreatePart'
import onClickSelectPart from './Handlers/onClickSelectPart'
import onClosePartModal from './Handlers/onClosePartModal'

const mapStateToProps = (state) => ({
  searchTerm: state.modals.SelectPart.Finder.searchTerm,
  fetching: state.modals.SelectPart.Finder.fetching,
  parts: state.modals.SelectPart.Finder.parts,
  partModal: state.modals.SelectPart.Finder.partModal,
})

const actionCreators = {
  onSearch,
  onClickCreatePart,
  onClickSelectPart,
  onClosePartModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
