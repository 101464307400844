import { combineReducers } from 'redux'

import { reducer as userModal } from './State/userModal'

import Table from './Components/Table/reducer'

export default combineReducers({
  userModal,

  Table,
})
