import { createAction, handleActions } from 'redux-actions'

const setSorting = createAction('MODALS/USE_SUPPLIER/SET_SORTING')
const resetSorting = createAction('MODALS/USE_SUPPLIER/RESET_SORTING')

const defaultState = {
  key: 'sourced_parts',
  direction: 'desc',
}

const reducer = handleActions(
  {
    [setSorting]: (state, action) => ({ ...state, ...action.payload }),
    [resetSorting]: () => defaultState,
  },
  defaultState,
)

export { setSorting, resetSorting, reducer }
