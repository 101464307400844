import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchCustomerEnquiryStatuses from '../../../Core/Services/fetchCustomerEnquiryStatuses'
import { setUserFilter } from '../Components/Table/State/userFilter'
import fetchTableData from '../Components/Table/Services/fetch'

export default () => (dispatch, getState) => {

  dispatch(fetchUsers())

  dispatch(fetchCustomerEnquiryStatuses())

  dispatch(setUserFilter(getState().app.user.id))

  dispatch(fetchTableData())
  
}
