import { updateCustomerEnquiryStatus } from '../../../../../Api/customerEnquiries'
import { handleException } from '../../../../../../packages/sota'
import initialize from '../../../Services/initialize'

export default (customerEnquiry, event) => async (dispatch) => {

  try {

    await updateCustomerEnquiryStatus({
      id: customerEnquiry.id,
      data: {
        status: event.target.value,
      },
    })

    dispatch(initialize())

  } catch (error) {

    handleException(error)

  }

}
