import { createSelector } from 'reselect'

const selectCustomerEnquiry = (state) => state.screens.CustomerEnquiry.customerEnquiry
const selectSalesOrderItems = (state) => state.screens.CustomerEnquiry.SalesOrder.salesOrderItems

const selectRows = createSelector(
  selectCustomerEnquiry,
  selectSalesOrderItems,
  (customerEnquiry, salesOrderItems) => {

    const rows = []

    if (!customerEnquiry.sales_order_number && !salesOrderItems.length) {

      rows.push({
        type: 'EMPTY',
        key: 'empty',
      })

      return rows

    }

    rows.push({
      type: 'SALES_ORDER',
      key: 'sales-order',
    })

    rows.push({
      type: 'HEADER',
      key: 'header',
    })

    rows.push({
      type: 'DIVIDER',
      key: 'divider-header',
      data: { type: 1 },
    })

    salesOrderItems.forEach((salesOrderItem, salesOrderItemIndex) => {

      rows.push({
        type: 'SALES_ORDER_ITEM',
        key: salesOrderItem.id,
        data: { salesOrderItem, salesOrderItemIndex },
      })

      rows.push({
        type: 'DIVIDER',
        key: `divider-${salesOrderItemIndex}`,
        data: { type: 1 },
      })

    })

    rows.push({
      type: 'SALES_ORDER_ITEM_FOOTER',
      key: 'sales-order-item-footer',
    })

    return rows

  },
)

export default selectRows
