import React from 'react'
import PropTypes from 'prop-types'
import { getCustomerContacts } from '../../Api/customerContacts'

import Select from './Select'

export default class CustomerContact extends React.PureComponent {
  
  static propTypes = {
    customerId: PropTypes.number,
  }

  fetchOptions = async () => {
    
    if (!this.props.customerId) return []

    const response = await getCustomerContacts({
      params: {
        sort: 'name',
        'filter[customer_id]': this.props.customerId,
      },
    })
    
    return response.data.data

  }

  render () {

    return (

      <Select
        keyProp="id"
        labelProp="name"
        watchProps={['customerId']}
        placeholder="Select Contact"
        fetchOptions={this.fetchOptions}
        {...this.props}
      />

    )
  }
}