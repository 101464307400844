import { createAction, handleActions } from 'redux-actions'

const openCustomerContactModal = createAction('MODALS/CUSTOMER_ENQUIRY/OPEN_CUSTOMER_CONTACT_MODAL')
const closeCustomerContactModal = createAction('MODALS/CUSTOMER_ENQUIRY/CLOSE_CUSTOMER_CONTACT_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openCustomerContactModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeCustomerContactModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCustomerContactModal, closeCustomerContactModal, reducer }
