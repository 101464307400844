import { openSalesOrderItemModal } from '../../../State/salesOrderItemModal'

export default () => (dispatch, getState) => {

  const { customerEnquiry } = getState().screens.CustomerEnquiry

  dispatch(openSalesOrderItemModal({
    customerEnquiry,
  }))
  
}
