import modalProps from '../modalProps'
import resetFinder from '../Components/Finder/Services/reset'

export default () => (dispatch) => {

  modalProps.reset()
  
  dispatch(resetFinder())
  
}
