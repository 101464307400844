import { connect } from "react-redux"
import SourcedPart from "./SourcedPart"

import onClick from './Handlers/onClick'
import onCopyIntoQuote from './Handlers/onCopyIntoQuote'
import onRemove from './Handlers/onRemove'

const mapStateToProps = () => ({})

const actionCreators = {
  onClick,
  onCopyIntoQuote,
  onRemove,
}

export default connect(mapStateToProps, actionCreators)(SourcedPart)
