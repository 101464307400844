import { delay, has, pick } from 'lodash'
import { replace } from 'connected-react-router'
import * as Sentry from '@sentry/browser'
import { getUser } from '../../Api/auth'
import { showSplashscreen, hideSplashscreen } from '../State/showSplashscreen'
import { unsetToken } from '../State/token'
import { setUser } from '../State/user'
import { appIsReady } from '../State/ready'

export default () => async (dispatch, getState) => {

  dispatch(showSplashscreen())

  const { token } = getState().app
  const { pathname } = getState().router.location
  
  const isOnLoginPage = pathname.startsWith('/portal/login')
  const isOnRoot = pathname === '/'
  
  const redirectToLogin = () => dispatch(replace('/portal/login'))
  const redirectToPortal = () => dispatch(replace('/portal/'))

  const launch = () => {
    dispatch(appIsReady())
    delay(() => dispatch(hideSplashscreen()), 1000)
  }
  
  try {
    
    // Return to login page if token not present.

    if (!token) {

      if (!isOnLoginPage) {
        redirectToLogin()
      }

      launch()

      return
    }
    

    // Get current user.

    const { data: { data: user } } = await getUser()

    dispatch(setUser(user))

    Sentry.configureScope((scope) => {
      scope.setUser(pick(user, [
        'id',
        'first_name',
        'last_name',
        'email',
      ]))
    })
    

    // Redirect to portal if pointing to login.

    if (isOnLoginPage || isOnRoot) {

      redirectToPortal()
    }

    launch()
    
  } catch (error) {
    
    // redirect to login page if there was an authentication error.

    if (has(error,'response.status') && error.response.status === 401) {

      dispatch(unsetToken())

      if (!isOnLoginPage) {
        redirectToLogin()
      }

      launch()

    } else {
      
      throw error

    }
  }
}
