export default (values, unitPrice = undefined) => {

  const data = { margin: null, marginPerc: null }

  const $unitPrice = unitPrice || values.unit_price

  if (!values.sourced_part.total_cost_gbp || !$unitPrice || !values.quantity) return data

  const relTotalCostGbp = (values.sourced_part.total_cost_gbp / values.sourced_part.quantity) * values.quantity
  const totalPrice = $unitPrice * values.quantity
  const totalPriceGbp = totalPrice / values.customer_enquiry.exchange_rate

  data.margin = totalPriceGbp - (relTotalCostGbp + values.sourced_part.landing_costs)
  data.marginPerc = (data.margin / (totalPriceGbp - values.sourced_part.landing_costs)) * 100

  return data
  
}
