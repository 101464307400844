import { combineReducers } from 'redux'

import Dashboard from './Dashboard/reducer'

import Users from './Users/reducer'

import Customers from './Customers/reducer'
import Customer from './Customer/reducer'

import Suppliers from './Suppliers/reducer'
import Supplier from './Supplier/reducer'

import Manufacturers from './Manufacturers/reducer'
import Manufacturer from './Manufacturer/reducer'

import Parts from './Parts/reducer'
import Part from './Part/reducer'

import CustomerEnquiries from './CustomerEnquiries/reducer'
import CustomerEnquiry from './CustomerEnquiry/reducer'

import SupplierEnquiries from './SupplierEnquiries/reducer'
import SupplierEnquiry from './SupplierEnquiry/reducer'

export default combineReducers({
  Dashboard,

  Users,

  Customers,
  Customer,

  Suppliers,
  Supplier,

  Manufacturers,
  Manufacturer,

  Parts,
  Part,
  
  CustomerEnquiries,
  CustomerEnquiry,

  SupplierEnquiries,
  SupplierEnquiry,
})
