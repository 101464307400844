import React from 'react'
import PropTypes from 'prop-types'
import { Empty, Button } from 'antd'
import styles from './styles.css'

const Component = props => (

  <div className={styles.wrapper}>
    
    <Empty />

    <br />

    <Button type='primary' onClick={props.onClickCreate}>Create Sales Order</Button>
    
  </div>

)

Component.propTypes = {
  onClickCreate: PropTypes.func.isRequired,
}

export default Component
