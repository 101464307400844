import { push } from 'connected-react-router'
import { closeSupplierMergeModal } from '../State/supplierMergeModal'

export default (supplier) => (dispatch) => {

  dispatch(closeSupplierMergeModal())

  if (supplier) {
    dispatch(push(`/portal/suppliers/${supplier.id}`))
  }

}
