import modalProps from '../modalProps'
import { setSearchTerm } from '../Components/Finder/State/searchTerm'
import fetch from '../Components/Finder/Services/fetch'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(setSearchTerm(props.internalNo || ''))
  
  dispatch(fetch())
  
}
