import React from 'react'
import PropTypes from 'prop-types'
import { LinkButton } from '../../../../../../../packages/sota'
import styles from './styles.css'

const SourcedPartFooter = (props) => {

  const {
    part,
    onClickAdd,
  } = props
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.actions}>
        
        <LinkButton icon="plus" type="primary" onClick={() => onClickAdd(part)}>
          Add Source
        </LinkButton>
        
      </div>
      
    </div>

  )

}

SourcedPartFooter.propTypes = {
  part: PropTypes.object.isRequired,
  onClickAdd: PropTypes.func.isRequired,
}

export default React.memo(SourcedPartFooter)
