import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Typography } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelAutoComplete } from '../../../../Modules'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    manufacturer: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      manufacturer,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        initialValues={{
          manufacturer: {},
        }}
        validate={validate}
        onSubmit={onSubmit}
      >

        {({ handleSubmit }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            colon={false}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ submitting: true }}
              onChange={onChangeFormState}
            />

            <Form.Item
              label='Remove'
              className={styles.formItem}
            >
              <Typography.Text type='secondary'>{manufacturer.name}</Typography.Text>
            </Form.Item>

            <Field name='manufacturer'>
              {({ input, meta }) => (
                <Form.Item
                  label='Merge Into'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  className={styles.formItem}
                >
                  <ModelAutoComplete.Manufacturer {...input} />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
