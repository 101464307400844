import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createCustomerQuoteItem } from '../../../../../Api/customerQuoteItems'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {
    
    const data = {
      customer_enquiry_item_id: values.customer_enquiry_item_id,
      sourced_part_id: values.sourced_part_id,
      part_id: values.part.id,
      unit_price: values.unit_price,
      quantity: values.quantity,
      service_id: values.service.id,
      lead_time: values.lead_time,
      rohs: values.rohs !== undefined ? values.rohs : null,
      date_code: values.date_code,
      notes: values.notes,
      guid: values.guid,
    }

    const response = await createCustomerQuoteItem({ data })

    message.success('Customer quote line added')

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
