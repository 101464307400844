import React from 'react'
import { findManufacturer, getManufacturers } from '../../Api/manufacturers'

import AutoComplete from './AutoComplete'

export default class Manufacturer extends React.PureComponent {

  fetchSuggestions = async (term) => {

    const response = await getManufacturers({
      params: {
        search: term,
        sort: 'name',
        'page[size]': 30,
      },
    })
    
    return response.data.data

  }

  fetchSelected = async (id) => {
    
    const response = await findManufacturer({
      id,
      params: {
      },
    })
    
    const manufacturer = response.data.data

    if (!manufacturer) return null
    
    return {
      id: manufacturer.id,
      name: manufacturer.name,
      sage_ref: manufacturer.sage_ref,
    }
    
  }

  render () {

    return (

      <AutoComplete
        keyProp="id"
        labelProp="name"
        placeholder="Select Manufacturer"
        fetchSuggestions={this.fetchSuggestions}
        fetchSelected={this.fetchSelected}
        {...this.props}
      />

    )
  }
}