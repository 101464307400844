import screenProps from '../screenProps'
import reset from '../Services/reset'
import resetSupplierContacts from '../Components/SupplierContacts/Services/reset'
import resetSupplierEnquiries from '../Components/SupplierEnquiries/Services/reset'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(reset())

  dispatch(resetSupplierContacts())
  dispatch(resetSupplierEnquiries())

}
