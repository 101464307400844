import { combineReducers } from 'redux'

import { reducer as supplier } from './State/supplier'
import { reducer as initialized } from './State/initialized'
import { reducer as activeTab } from './State/activeTab'
import { reducer as supplierModal } from './State/supplierModal'
import { reducer as supplierMergeModal } from './State/supplierMergeModal'

import SupplierContacts from './Components/SupplierContacts/reducer'
import SupplierEnquiries from './Components/SupplierEnquiries/reducer'
import SourcedParts from './Components/SourcedParts/reducer'

export default combineReducers({
  supplier,
  initialized,
  activeTab,
  supplierModal,
  supplierMergeModal,

  SupplierContacts,
  SupplierEnquiries,
  SourcedParts,
})
