import { debounce } from 'lodash'
import chance from 'chance'
import { setLastInteractionId } from '../State/lastInteractionId'
import save from './save'

const saveWithDebounce = debounce((dispatch, interactionId) => dispatch(save(interactionId)), 1000)

const saveImmediately = (dispatch, interactionId) => dispatch(save(interactionId))

export default ({ wait = true } = {}) => (dispatch) => {
  
  const interactionId = chance().guid()
  
  dispatch(setLastInteractionId(interactionId))
  
  if (wait) {
    
    saveWithDebounce(dispatch, interactionId)
    
  } else {
    
    saveImmediately(dispatch, interactionId)
    
  }
  
}