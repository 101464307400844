import { connect } from 'react-redux'
import CustomerEnquiryItem from './CustomerEnquiryItem'

import onChangeField from './Handlers/onChangeField'
import onClickCreateManufacturer from './Handlers/onClickCreateManufacturer'
import onClickFindPart from './Handlers/onClickFindPart'
import onFocusSuppliers from './Handlers/onFocusSuppliers'
import onAddRow from './Handlers/onAddRow'
import onRemoveRow from './Handlers/onRemoveRow'
import onDuplicateRow from './Handlers/onDuplicateRow'

const mapStateToProps = (state) => ({
  services: state.core.services,
})

const actionCreators = {
  onChangeField,
  onClickCreateManufacturer,
  onClickFindPart,
  onFocusSuppliers,
  onAddRow,
  onRemoveRow,
  onDuplicateRow,
}

export default connect(mapStateToProps, actionCreators)(CustomerEnquiryItem)
