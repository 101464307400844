import { combineReducers } from "redux"

import { reducer as customerEnquiryModal } from './State/customerEnquiryModal'

import Table from './Components/Table/reducer'

export default combineReducers({
  customerEnquiryModal,

  Table,
})
