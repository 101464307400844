import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio, Select } from 'antd'
import { Module } from '../../../../../../../packages/sota'
import styles from './Header.css'

const Header = (props) => {

  const {
    users,
    customerEnquiryStatuses,
    activeTab,
    userFilter,
    searchTerm,
    onChangeTab,
    onChangeUserFilter,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <Radio.Group onChange={onChangeTab} value={activeTab}>
        <Radio.Button value="All" className={styles.tab}>All</Radio.Button>
        {customerEnquiryStatuses.map(customerEnquiryStatus => (
          <Radio.Button
            key={customerEnquiryStatus}
            value={customerEnquiryStatus}
            className={styles.tab}
          >
            {customerEnquiryStatus}
          </Radio.Button>
        ))}
      </Radio.Group>

      <div style={{ width: '16px' }} />

      <Select
        value={userFilter}
        onChange={value => onChangeUserFilter(value)}
        optionFilterProp="children"
        allowClear
        style={{ width: 200 }}
      >
        <Select.OptGroup label='Active'>
          {users.filter(user => !user.deactivated_at).map(({ id, name }) => (
            <Select.Option key={id} value={id}>{name}</Select.Option>
          ))}
        </Select.OptGroup>
        <Select.OptGroup label='Deactivated'>
          {users.filter(user => !!user.deactivated_at).map(({ id, name }) => (
            <Select.Option key={id} value={id} style={{ color: '#9e9e9e' }}>{name}</Select.Option>
          ))}
        </Select.OptGroup>
      </Select>

    </React.Fragment>

  )

  const renderRight = () => (

    <React.Fragment>

      <div style={{ width: 16 }} />

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder="Search"
        style={{ width: 200 }}
      />
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Header.propTypes = {
  users: PropTypes.array.isRequired,
  customerEnquiryStatuses: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  userFilter: PropTypes.number,
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onChangeUserFilter: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header
