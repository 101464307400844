import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import ReactHighcharts from 'react-highcharts'
import drilldown from 'highcharts/modules/drilldown'
import { Module } from '../../../../../packages/sota'

import Controls from './Components/Controls'

ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})

drilldown(Highcharts)

Highcharts.Tick.prototype.drillable = function () {}

const CustomerEnquiriesInPeriod = props => {

  const {
    controls,
    config,
    componentDidMount,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [controls])
  
  return (

    <Module>
      
      <Module.Header
        title='Quotes'
        renderRight={() => <Controls />}
      />

      <Module.Section>

        <ReactHighcharts isPureConfig config={config} />

      </Module.Section>

    </Module>

  )

}

CustomerEnquiriesInPeriod.propTypes = {
  controls: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
}

export default CustomerEnquiriesInPeriod
