import { omit } from 'lodash'
import modalProps from '../../../modalProps'
import { createPart, updatePart } from '../../../../../Api/parts'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id } = modalProps()

    const data = {
      ...omit(values, ['manufacturer']),
      manufacturer_id: values.manufacturer.id,
    }
    
    let response

    if (id) {

      response = await updatePart({ id, data })

    } else {

      response = await createPart({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)

    throw error

  }

}
