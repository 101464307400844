import React from 'react'
import { Modal } from 'antd'
import modalProps from '../../../modalProps'
import merge from '../Services/merge'

const getCreatedBeforeWarning = (sourceCustomer, targetCustomer) => {
  if (sourceCustomer.id < targetCustomer.id) {
    return `Warning: "${sourceCustomer.name}" was created before "${targetCustomer.name}". You may want the merge to occur the other way round.`
  }
  return false
}

const getSageCodeWarning = (sourceCustomer, targetCustomer) => {
  if (sourceCustomer.sage_ref && !targetCustomer.sage_ref) {
    return `Warning: "${sourceCustomer.name}" has a Sage ref (${sourceCustomer.sage_ref}), yet "${targetCustomer.name}" doesn't. You may want the merge to occur the other way round.`
  }
  if (sourceCustomer.sage_ref && targetCustomer.sage_ref) {
    return `Warning: "${sourceCustomer.name}" and ${targetCustomer.name} both have a Sage ref (${sourceCustomer.sage_ref} & ${targetCustomer.sage_ref} respectively).`
  }
  return false
}

export default (values) => async (dispatch) => {

  const { customer: sourceCustomer } = modalProps()

  const { customer: targetCustomer } = values

  const createdBeforeWarning = getCreatedBeforeWarning(sourceCustomer, targetCustomer)

  const sageCodeWarning = getSageCodeWarning(sourceCustomer, targetCustomer)

  Modal.confirm({
    title: 'Merge Customer?',
    content: (
      <div>
        <p>{`This would remove "${sourceCustomer.name}" and transfer all of its data into "${targetCustomer.name}".`}</p>
        {!!createdBeforeWarning && <p style={{ fontStyle: 'italic' }}>{createdBeforeWarning}</p>}
        {!!sageCodeWarning && <p style={{ fontStyle: 'italic' }}>{sageCodeWarning}</p>}
        <p>Are you sure you want to continue?</p>
      </div>
    ),
    okText: 'Yes',
    onOk: async () => {
      await dispatch(merge(values))
    },
  })

}
