import modalProps from '../modalProps'
import { setSearchTerm } from '../Components/Finder/State/searchTerm'
import fetch from '../Components/Finder/Services/fetch'

export default (props) => (dispatch) => {

  modalProps(props)

  const {
    manufacturer_part_no: manufacturerPartNo,
    customer_part_no: customerPartNo,
  } = props.customerEnquiryItem

  dispatch(setSearchTerm(manufacturerPartNo || customerPartNo || ''))

  dispatch(fetch())

}
