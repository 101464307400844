import { connect } from 'react-redux'
import Controls from './Controls'

import onChangeControl from './Handlers/onChangeControl'

const mapStateToProps = (state) => ({
  controls: state.screens.Dashboard.SalesOrdersInPeriod.controls,
})

const actionCreators = {
  onChangeControl,
}

export default connect(mapStateToProps, actionCreators)(Controls)
