import screenProps from '../screenProps'
import { findManufacturer } from '../../../Api/manufacturers'
import { setManufacturer } from '../State/manufacturer'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const response = await findManufacturer({ id })

    const manufacturer = response.data.data

    dispatch(setManufacturer(manufacturer))

  } catch (error) {

    handleException(error)

  }

}
