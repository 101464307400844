import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, DatePicker, Spin } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'
import styles from './Component.css'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      initialValues,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    return (

      <FormProvider
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >

            <FormInterceptor
              ref={formService}
              subscription={{ submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name="sales_order_number">
              {({ input, meta }) => (
                <Form.Item
                  label="Number"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="sales_order_date" allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label="Date"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <DatePicker
                    {...input}
                    format='DD/MM/YYYY'
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
