import { setSorting } from '../State/sorting'

export default (pagination, filters, sorting) => (dispatch) => {
  
  dispatch(setSorting({
    key: sorting.columnKey,
    direction: { ascend: 'asc', descend: 'desc' }[sorting.order],
  }))

}
