import React from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import SupplierContacts from './Components/SupplierContacts'
import SupplierEnquiries from './Components/SupplierEnquiries'
import SourcedParts from './Components/SourcedParts'
import SupplierModal from '../../Modals/Supplier'
import SupplierMergeModal from '../../Modals/SupplierMerge'

export default class Supplier extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    supplierModal: PropTypes.object.isRequired,
    supplierMergeModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
    onCloseSupplierMergeModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      supplierModal,
      supplierMergeModal,
      onCloseSupplierModal,
      onCloseSupplierMergeModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 200 }}
        contentStyle={{ backgroundColor: 'white', paddingBottom: 60 }}
      >

        {(initialized && activeTab === 0) && <SupplierContacts />}

        {(initialized && activeTab === 1) && <SupplierEnquiries />}

        {(initialized && activeTab === 2) && <SourcedParts />}

        {supplierModal.show && (
          <SupplierModal
            id={id}
            onClose={onCloseSupplierModal}
          />
        )}

        {supplierMergeModal.show && (
          <SupplierMergeModal
            {...supplierMergeModal}
            onClose={onCloseSupplierMergeModal}
          />
        )}
        
      </Screen>

    )

  }
  
}
