import React from 'react'
import PropTypes from 'prop-types'
import { FormInterceptor } from '../../../../../packages/sota'
import { Button } from 'antd'
import { Form as FormProvider } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import formService from './formService'
import styles from './styles.css'

import Header from './Components/Header'
import Subheader from './Components/Subheader'
import Divider from './Components/Divider'
import SupplierEnquiryItem from './Components/SupplierEnquiryItem'
import SourcedPart from './Components/SourcedPart'
import SourcedPartFooter from './Components/SourcedPartFooter'
import Empty from './Components/Empty'
import SelectPart from '../../../../Modals/SelectPart'
import SourcedPartModal from '../../../../Modals/SourcedPart'
import QuoteSourcedPart from '../../../../Modals/QuoteSourcedPart'

const Components = {
  'HEADER': Header,
  'SUBHEADER': Subheader,
  'DIVIDER': Divider,
  'SUPPLIER_ENQUIRY_ITEM': SupplierEnquiryItem,
  'SOURCED_PART': SourcedPart,
  'SOURCED_PART_FOOTER': SourcedPartFooter,
  'EMPTY': Empty,
}

class Requirements extends React.PureComponent {
  
  static propTypes = {
    rows: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    customerEnquiryId: PropTypes.number.isRequired,
    selectPartModal: PropTypes.object.isRequired,
    sourcedPartModal: PropTypes.object.isRequired,
    quoteSourcedPartModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickAddRequirement: PropTypes.func.isRequired,
    onCloseSelectPartModal: PropTypes.func.isRequired,
    onCloseSourcedPartModal: PropTypes.func.isRequired,
    onCloseQuoteSourcedPartModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)
  
  render () {

    const {
      rows,
      initialValues,
      customerEnquiryId,
      selectPartModal,
      sourcedPartModal,
      quoteSourcedPartModal,
      onFormChange,
      onSubmit,
      validate,
      onClickAddRequirement,
      onCloseSelectPartModal,
      onCloseSourcedPartModal,
      onCloseQuoteSourcedPartModal,
    } = this.props
    
    if (!initialValues) return null

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        mutators={{ ...arrayMutators }}
      >

        {() => (

          <div>
            
            <FormInterceptor
              ref={formService}
              subscription={{ values: true, pristine: true, submitting: true }}
              onChange={onFormChange}
            />

            {rows.map((row) => {
              
              const Component = Components[row.type]

              return <Component key={row.key} {...row.data} />

            })}

            <div className={styles.buttons}>

              <div className={styles.left}>

                <Button
                  type="primary"
                  icon="plus"
                  onClick={onClickAddRequirement}
                >
                  Add Requirement
                </Button>

              </div>

            </div>

            {selectPartModal.show && (
              <SelectPart
                internalNo={selectPartModal.internalNo}
                supplierEnquiryItemId={selectPartModal.supplierEnquiryItemId}
                onClose={onCloseSelectPartModal}
              />
            )}

            {sourcedPartModal.show && (
              <SourcedPartModal
                onClose={onCloseSourcedPartModal}
                id={sourcedPartModal.id}
                customerEnquiryId={customerEnquiryId}
                supplierEnquiryItemId={sourcedPartModal.supplierEnquiryItemId}
                part={sourcedPartModal.part}
                supplier={sourcedPartModal.supplier}
                service={sourcedPartModal.service}
                exchangeRate={sourcedPartModal.exchangeRate}
              />
            )}

            {quoteSourcedPartModal.show && (
              <QuoteSourcedPart
                id={quoteSourcedPartModal.id}
                landedCost={quoteSourcedPartModal.landedCost}
                onClose={onCloseQuoteSourcedPartModal}
              />
            )}

          </div>

        )}

      </FormProvider>

    )
  }
}

export default Requirements
