import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteCustomer } from '../../../Api/customers'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { customer } = getState().screens.Customer

    await deleteCustomer({ id: customer.id })

    message.success(`Deleted Customer`)

    dispatch(push(`/portal/customers`))

  } catch (error) {

    handleException(error)

  }

}
