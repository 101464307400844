import { resetClosedItems } from '../State/closedItems'
import { resetFormState } from '../State/formState'
import { resetLastInteractionId } from '../State/lastInteractionId'
import { resetLoading } from '../State/loading'

export default () => async (dispatch) => {
  
  dispatch(resetClosedItems())
  dispatch(resetFormState())
  dispatch(resetLastInteractionId())
  dispatch(resetLoading())

}
