import { message } from 'antd'
import screenProps from '../../../screenProps'
import { generateSupplierEnquiries } from '../../../../../Api/customerEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default () => async () => {

  const { id } = screenProps().match.params

  try {
    
    await generateSupplierEnquiries({ id })

    message.success('Generated Supplier Enquiries')

  } catch (error) {

    handleException(error)

  }

}
