import { closePartModal } from '../State/partModal'
import fetchPartsTable from '../Components/Table/Services/fetch'
import { push } from 'connected-react-router'

export default (part) => (dispatch) => {

  dispatch(closePartModal())

  if (part) {
    dispatch(push(`/portal/parts/${part.id}`))
    dispatch(fetchPartsTable())
  }

}
