import React from 'react'
import PropTypes from 'prop-types'
import { Input, Select } from 'antd'
import { Module } from '../../../../../../../packages/sota'

const Header = (props) => {

  const {
    manufacturers,
    selectedManufacturerId,
    searchTerm,
    onChangeManufacturerId,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder="Search"
        style={{ width: 250, marginRight: 10 }}
      />

      <Select
        value={selectedManufacturerId || undefined}
        onChange={value => onChangeManufacturerId(value || null)}
        style={{ width: 250 }}
        showSearch
        allowClear
        placeholder="All Manufacturers"
        optionFilterProp="children"
      >
        {manufacturers.map(manufacturer => (
          <Select.Option key={manufacturer.id} value={manufacturer.id}>
            {manufacturer.name}
          </Select.Option>
        ))}
      </Select>

    </React.Fragment>

  )

  return (

    <Module.Header
      renderLeft={renderLeft}
    />

  )
}

Header.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  selectedManufacturerId: PropTypes.any,
  searchTerm: PropTypes.string.isRequired,
  onChangeManufacturerId: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

Header.defaultProps = {
  selectedManufacturerId: null,
}

export default Header
