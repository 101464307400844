import screenProps from '../../../screenProps'
import { findSupplierEnquiry } from '../../../../../Api/supplierEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default () => async () => {

  try {

    const { id } = screenProps().match.params
    
    const response = await findSupplierEnquiry({
      id,
      params: {
        include: [
          'supplier_enquiry_items.part.manufacturer',
          'supplier_enquiry_items.sourced_parts.part.manufacturer',
          'supplier_enquiry_items.sourced_parts.supplier.currency',
          'supplier_enquiry_items.sourced_parts.service',
        ].join(','),
      },
    })

    return response.data.data

  } catch (error) {

    handleException(error)
    
    return null

  }
  
}