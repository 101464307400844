import modalProps from '../modalProps'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchCustomerPaymentTerms from '../../../Core/Services/fetchCustomerPaymentTerms'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchUsers())
  dispatch(fetchCustomerPaymentTerms())

  dispatch(initializeForm())
  
}
