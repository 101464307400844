import React from 'react'
import { Modal, Icon } from 'antd'
import deleteImport from '../Services/delete'

export default () => async (dispatch) => {

  Modal.confirm({
    title: 'Delete Import?',
    content: 'This action cannot be undone.',
    icon: <Icon type='delete' style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteImport()),
  })

}
