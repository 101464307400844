import { getManufacturers } from '../../../../../Api/manufacturers'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { searchTerm, sorting, pagination } = getState().screens.Manufacturers.Table

    const params = {}

    if (searchTerm) params.search = searchTerm

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'

    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getManufacturers({ params })

    dispatch(setData(response.data.data))

    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
