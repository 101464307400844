import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Dropdown, Menu, Button, Icon } from 'antd'
import ResetPasswordModal from '../ResetPassword'

import Form from './Components/Form'

const User = props => {

  const {
    user,
    submitting,
    resetPasswordModal,
    componentDidMount,
    componentWillUnmount,
    onSubmit,
    onDelete,
    onAction,
    onCloseResetPasswordModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  const footer = []

  if (user) footer.push(
    <Button key='delete' type='danger' onClick={onDelete} style={{ float: 'left' }}>
      Delete
    </Button>,
    <Dropdown
      key='actions'
      trigger={['click']}
      overlay={(
        <Menu onClick={({ key }) => onAction(key)}>
          {!user.deactivated_at && (
            <Menu.Item key='deactivate'>
              <Icon type='frown' />
              Deactivate
            </Menu.Item>
          )}
          {!!user.deactivated_at && (
            <Menu.Item key='reactivate'>
              <Icon type='smile' />
              Reactivate
            </Menu.Item>
          )}
          <Menu.Item key='reset-password'>
            <Icon type='edit' />
            Reset Password
          </Menu.Item>
          <Menu.Item key='require-password-reset' disabled={!!user.reset_password}>
            <Icon type='safety' />
            Require Password Reset
          </Menu.Item>
        </Menu>
      )}
    >
      <Button type='link' style={{ float: 'left' }}>
        Actions
      </Button>
    </Dropdown>,
  )

  footer.push(
    <Button key='cancel' onClick={() => onClose()}>
      Cancel
    </Button>,
    <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
      {user ? 'Update' : 'Create'}
    </Button>,
  )

  return (

    <Modal
      visible
      title={user ? 'User' : 'New User'}
      width={500}
      onCancel={() => onClose()}
      maskClosable={false}
      footer={footer}
    >

      <Form />

      {resetPasswordModal.show && (
        <ResetPasswordModal
          {...resetPasswordModal}
          userId={user.id}
          onClose={onCloseResetPasswordModal}
        />
      )}

    </Modal>

  )

}

User.propTypes = {
  user: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  resetPasswordModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  onCloseResetPasswordModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

User.defaultProps = {
  user: null,
}

export default User
