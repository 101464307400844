import { connect } from 'react-redux'
import CustomerQuote from './CustomerQuote'

import selectRows from './Selectors/selectRows'

import componentDidMount from './Handlers/componentDidMount'
import onCloseCustomerQuoteItemModal from './Handlers/onCloseCustomerQuoteItemModal'
import onCloseSalesOrderModal from './Handlers/onCloseSalesOrderModal'
import onCloseOrderQuoteItemModal from './Handlers/onCloseOrderQuoteItemModal'

const mapStateToProps = (state) => ({
  rows: selectRows(state),
  customerQuoteItemModal: state.screens.CustomerEnquiry.CustomerQuote.customerQuoteItemModal,
  salesOrderModal: state.screens.CustomerEnquiry.CustomerQuote.salesOrderModal,
  orderQuoteItemModal: state.screens.CustomerEnquiry.CustomerQuote.orderQuoteItemModal,
})

const actionCreators = {
  componentDidMount,
  onCloseCustomerQuoteItemModal,
  onCloseSalesOrderModal,
  onCloseOrderQuoteItemModal,
}

export default connect(mapStateToProps, actionCreators)(CustomerQuote)
