import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable } from 'antd'
import { NavLink } from 'react-router-dom'

const Table = (props) => {

  const {
    parts,
    onChange,
  } = props

  return (

    <AntTable
      dataSource={parts}
      rowKey="id"
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key="internal_no"
        title="Part No."
        width={200}
        sorter
        render={(text, row) => (
          <NavLink key={row.id} to={`/portal/parts/${row.id}`}>
            {row.internal_no}
          </NavLink>
        )}
      />

      <AntTable.Column
        key="manufacturer.name"
        title="Manufacturer"
        width={200}
        sorter
        render={(text, record) => record.manufacturer.name}
      />

      <AntTable.Column
        key="manufacturer_no"
        title="Manufacturer No."
        width={200}
        sorter
        render={(text, record) => record.manufacturer_no}
      />

    </AntTable>

  )

}

Table.propTypes = {
  parts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table
