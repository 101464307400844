import { combineReducers } from 'redux'

import { reducer as resetPasswordModal } from './State/resetPasswordModal'

import Form from './Components/Form/reducer'

export default combineReducers({
  resetPasswordModal,
  
  Form,
})
