import { createAction, handleActions } from 'redux-actions'

const startRunning = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/START_RUNNING')
const finishRunning = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/FINISH_RUNNING')
const resetRunning = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/RESET_RUNNING')

const defaultState = false

const reducer = handleActions(
  {
    [startRunning]: () => true,
    [finishRunning]: () => false,
    [resetRunning]: () => false,
  },
  defaultState,
)

export { startRunning, finishRunning, resetRunning, reducer }
