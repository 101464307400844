import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

const ServiceSelect = props => {

  const {
    width,
    services,
    value,
    onChange,
  } = props
  
  return (

    <Select
      size='small'
      mode='multiple'
      showSearch
      optionFilterProp='children'
      placeholder='All Services'
      value={value}
      onChange={$value => onChange($value)}
      style={{ width }}
    >
      {services.map(({ id, name }) => (
        <Select.Option key={id} value={id}>{name}</Select.Option>
      ))}
    </Select>

  )

}

ServiceSelect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  services: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ServiceSelect
