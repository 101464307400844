import { createAction, handleActions } from 'redux-actions'

const openPartModal = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/OPEN_PART_MODAL')
const closePartModal = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/CLOSE_PART_MODAL')

const defaultState = {
  show: false,
  index: null,
  manufacturerNo: null,
  manufacturer: {},
  internalNo: null,
  description: null,
}

const reducer = handleActions(
  {
    [openPartModal]: (state, { payload }) => ({ ...state, show: true, ...payload }),
    [closePartModal]: () => defaultState,
  },
  defaultState,
)

export { openPartModal, closePartModal, reducer }
