import React from 'react'
import { Modal, Icon } from 'antd'
import modalProps from '../modalProps'
import deactivate from '../Services/deactivate'
import reactivate from '../Services/reactivate'
import requirePasswordReset from '../Services/requirePasswordReset'
import { openResetPasswordModal } from '../State/resetPasswordModal'

export default (key) => (dispatch) => {

  const { user } = modalProps()

  if (key === 'deactivate') {
    Modal.confirm({
      title: `Deactivate "${user.name}"`,
      content: 'Are you sure you want to deactivate this user?',
      icon: <Icon type="eye-invisible" />,
      okText: 'Deactivate',
      onOk: () => dispatch(deactivate()),
    })
  }

  if (key === 'reactivate') {
    Modal.confirm({
      title: `Reactivate "${user.name}"`,
      content: 'Are you sure you want to reactivate this user?',
      icon: <Icon type="eye" />,
      okText: 'Reactivate',
      onOk: () => dispatch(reactivate()),
    })
  }

  if (key === 'reset-password') {
    dispatch(openResetPasswordModal())
  }

  if (key === 'require-password-reset') {
    dispatch(requirePasswordReset())
  }

}
