import { setPayload } from '../../../State/data'
import save from '../../../Services/save'

export default (guid) => (dispatch, getState) => {

  const { payload } = getState().modals.CustomerEnquiryImport.data

  const customerEnquiryItems = []

  payload.customer_enquiry_items.forEach(row => {
    if (row.guid === guid) return
    customerEnquiryItems.push({ ...row })
  })

  dispatch(setPayload({
    customer_enquiry_items: customerEnquiryItems,
  }))

  dispatch(save())

}
