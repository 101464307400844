import { createAction, handleActions } from 'redux-actions'

const startFetching = createAction('SCREENS/SUPPLIER_ENQUIRIES/SUPPLIER_ENQUIRIES_TABLE/START_FETCHING')
const finishFetching = createAction('SCREENS/SUPPLIER_ENQUIRIES/SUPPLIER_ENQUIRIES_TABLE/FINISH_FETCHING')
const resetFetching = createAction('SCREENS/SUPPLIER_ENQUIRIES/SUPPLIER_ENQUIRIES_TABLE/RESET_FETCHING')

const defaultState = false

const reducer = handleActions(
  {
    [startFetching]: () => true,
    [finishFetching]: () => false,
    [resetFetching]: () => false,
  },
  defaultState,
)

export { startFetching, finishFetching, resetFetching, reducer }