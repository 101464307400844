import { createAction, handleActions } from 'redux-actions'

const setFormState = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/SET_FORM_STATE")
const setFormInitialValues = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/SET_FORM_INITIAL_VALUES")
const resetFormState = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/RESET_FORM_STATE")

const defaultState = {
  initialValues: null,
  values: null,
  pristine: true,
  submitting: false,
}

const reducer = handleActions(
  {
    [setFormState]: (state, action) => ({ ...state, ...action.payload }),
    [setFormInitialValues]: (state, action) => ({ ...state, initialValues: action.payload }),
    [resetFormState]: () => defaultState,
  },
  defaultState,
)

export { setFormState, setFormInitialValues, resetFormState, reducer }
