import screenProps from '../screenProps'
import { findSupplierEnquiry } from '../../../Api/supplierEnquiries'
import { setSupplierEnquiry } from '../State/supplierEnquiry'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params
    
    const params = {
      include: [
        'customer_enquiry',
        'supplier.currency',
        'supplier_contact',
        'service',
        'supplier_enquiry_items.part.manufacturer',
      ],
    }

    const response = await findSupplierEnquiry({ id, params })

    const supplierEnquiry = response.data.data

    dispatch(setSupplierEnquiry(supplierEnquiry))

  } catch (error) {

    handleException(error)

  }

}
