import { liveExchangeRates } from '../../../Api/exchangeRates'
import { setData } from '../State/data'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const response = await liveExchangeRates()

    const { data } = response.data

    if (data) dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
