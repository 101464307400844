import { connect } from 'react-redux'
import Component from './Component'

const mapStateToProps = (state) => ({
  supplierEnquiry: state.screens.SupplierEnquiry.supplierEnquiry,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Component)
