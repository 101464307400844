import { closeCustomerModal } from '../State/customerModal'
import fetchCustomersTable from '../Components/Table/Services/fetch'
import { push } from 'connected-react-router'

export default (customer) => (dispatch) => {

  dispatch(closeCustomerModal())

  if (customer) {
    dispatch(push(`/portal/customers/${customer.id}`))
    dispatch(fetchCustomersTable())
  }

}
