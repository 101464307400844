import { createSelector } from 'reselect'
import { find, get } from 'lodash'

const selectManufacturers = state => state.core.manufacturers
const selectData = state => state.modals.CustomerEnquiryImport.data

export default createSelector(
  selectManufacturers,
  selectData,
  (manufacturers, data) => get(data, 'new.parts', []).map((row, index) => ({
    ...row,
    key: index,
    manufacturer: find(manufacturers, $row => $row.id === row.manufacturer_id),
  })),
)
