import { closeCustomerEnquiryModal } from '../State/customerEnquiryModal'
import { push } from 'connected-react-router'

export default (customerEnquiry) => (dispatch) => {
  
  dispatch(closeCustomerEnquiryModal())
  
  if (customerEnquiry) {
    
    dispatch(push(`/portal/customer_enquiries/${customerEnquiry.id}`))
    
  }
  
}
