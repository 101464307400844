import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectValues = state => state.screens.CustomerEnquiry.Request.formState.values

export default createSelector(
  selectValues,
  (values) => {

    const rows = []

    rows.push({
      type: 'HEADER',
      key: 'header',
    })

    rows.push({
      type: 'DIVIDER',
      key: 'divider-header',
    })

    get(values, 'customer_enquiry_items', []).forEach((customerEnquiryItem, index) => {

      rows.push({
        type: 'CUSTOMER_ENQUIRY_ITEM',
        key: customerEnquiryItem.guid,
        data: { customerEnquiryItem, index },
      })

      rows.push({
        type: 'DIVIDER',
        key: `divider-${index}`,
      })

    })

    return rows

  },
)
