export default (values) => () => {

  const errors = {}

  if (!values.customer_enquiry_item_id) errors.customer_enquiry_item_id = 'Enquiry item is required'

  if (!values.part.id) errors.part = 'Part is required'

  if (!values.service || !values.service.id) errors.service = 'Service is required'
  
  if (!values.quantity) errors.quantity = 'Qty is required'
  
  return errors

}
