import { closeManufacturerModal } from '../State/manufacturerModal'
import fetchManufacturersTable from '../Components/Table/Services/fetch'
import { push } from 'connected-react-router'

export default (manufacturer) => (dispatch) => {

  dispatch(closeManufacturerModal())

  if (manufacturer) {
    dispatch(push(`/portal/manufacturers/${manufacturer.id}`))
    dispatch(fetchManufacturersTable())
  }

}
