import { connect } from 'react-redux'
import { get } from 'lodash'
import Component from './Component'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'
import onClickCreateCustomer from './Handlers/onClickCreateCustomer'
import onClickCreateCustomerContact from './Handlers/onClickCreateCustomerContact'
import onCloseCustomerModal from './Handlers/onCloseCustomerModal'
import onCloseCustomerContactModal from './Handlers/onCloseCustomerContactModal'

const mapStateToProps = (state) => ({
  users: state.core.users,
  customerPaymentTerms: state.core.customerPaymentTerms,
  customerEnquiryStatuses: state.core.customerEnquiryStatuses,
  initialValues: state.modals.CustomerEnquiry.Form.formState.initialValues,
  customerId: get(state.modals.CustomerEnquiry.Form.formState.values, 'customer.id', null),
  customerModal: state.modals.CustomerEnquiry.Form.customerModal,
  customerContactModal: state.modals.CustomerEnquiry.Form.customerContactModal,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
  onClickCreateCustomer,
  onClickCreateCustomerContact,
  onCloseCustomerModal,
  onCloseCustomerContactModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
