import chance from 'chance'
import formService from '../formService'
import triggerSave from './triggerSave'

export default (index) => (dispatch, getState) => {

  const { values } = getState().screens.CustomerEnquiry.Request.formState

  const newRows = []

  values.customer_enquiry_items.forEach((customerEnquiryItem, $index) => {
    newRows.push(customerEnquiryItem)
    if ($index === index) {
      newRows.push({
        ...customerEnquiryItem,
        id: null,
        guid: chance().guid(),
      })
    }
  })

  formService().change('customer_enquiry_items', newRows)

  dispatch(triggerSave(false))

}
