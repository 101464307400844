import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

import Table from './Components/Table'
import CustomerContact from '../../../../Modals/CustomerContact'

export default class CustomerContacts extends React.PureComponent {
  
  static propTypes = {
    customerContactModal: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseCustomerContactModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      customerContactModal,
      customerId,
      onClickNew,
      onCloseCustomerContactModal,
    } = this.props

    return (

      <React.Fragment>

        <Button key="NEW" type="primary" onClick={onClickNew} style={{ float: 'right', marginBottom: 15 }}>
          New Contact
        </Button>

        <br />

        <Table />

        {customerContactModal.show && (
          <CustomerContact
            onClose={onCloseCustomerContactModal}
            id={customerContactModal.customerContactId}
            customerId={customerId}
          />
        )}

      </React.Fragment>

    )

  }
  
}
