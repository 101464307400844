import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'antd'
import styles from './styles.css'

const Part = (props) => {

  const {
    part,
    isClosed,
    customerPartNumbers,
    onToggleClosed,
  } = props
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.closer} onClick={() => onToggleClosed(part)}>
        
        <Icon
          type={isClosed ? "caret-right" : "caret-down"}
        />
        
      </div>

      <div className={classNames(styles.col, styles.col1)}>

        <div className={classNames(styles.textOutlet)}>
          {part.manufacturer ? part.manufacturer.name : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col2)}>

        <div className={classNames(styles.textOutlet)}>
          {part.manufacturer_no ? part.manufacturer_no : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col3)}>

        <div className={classNames(styles.textOutlet)}>
          {part.internal_no ? part.internal_no : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col4)}>

        <div className={classNames(styles.textOutlet)}>
          {customerPartNumbers.join(', ')}
        </div>

      </div>
      
    </div>

  )

}

Part.propTypes = {
  part: PropTypes.object.isRequired,
  isClosed: PropTypes.bool.isRequired,
  customerPartNumbers: PropTypes.array.isRequired,
  onToggleClosed: PropTypes.func.isRequired,
}

export default React.memo(Part)
