import { connect } from 'react-redux'
import Component from './Component'

import componentDidMount from './Handlers/componentDidMount'

const mapStateToProps = (state) => ({
  user: state.app.user,
  ready: state.app.ready,
  showSplashscreen: state.app.showSplashscreen,
})

const actionCreators = {
  componentDidMount,
}

export default connect(mapStateToProps, actionCreators)(Component)