import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchServices from '../../../Core/Services/fetchServices'
import fetchCustomers from '../../../Core/Services/fetchCustomers'
import { setControl } from '../State/controls'

export default () => (dispatch, getState) => {

  dispatch(fetchUsers())
  dispatch(fetchServices())
  dispatch(fetchCustomers())

  const { user } = getState().app

  if (!user.is_admin) {
    dispatch(setControl({ key: 'userIds', value: [user.id] }))
    if (user.department_key) {
      dispatch(setControl({ key: 'departmentKeys', value: [user.department_key] }))
    }
  }

}
