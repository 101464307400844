import React from 'react'
import PropTypes from 'prop-types'
import { Radio, Icon } from 'antd'

import ControlSpacer from '../../../ControlSpacer'

const Controls = props => {

  const {
    controls,
    onChangeControl,
  } = props
  
  return (
    
    <React.Fragment>
        
      <Radio.Group
        size='small'
        value={controls.source}
        onChange={e => onChangeControl('source', e.target.value)}
      >
        <Radio.Button key='user' value='user'>Manager</Radio.Button>
        <Radio.Button key='department' value='department'>Department</Radio.Button>
        <Radio.Button key='service' value='service'>Service</Radio.Button>
        <Radio.Button key='customer' value='customer'>Customer</Radio.Button>
      </Radio.Group>

      <ControlSpacer />
        
      <Radio.Group
        size='small'
        value={controls.sort}
        onChange={e => onChangeControl('sort', e.target.value)}
      >
        <Radio.Button key='asc' value='asc'>
          <Icon type='arrow-up' size='small' />
        </Radio.Button>
        <Radio.Button key='desc' value='desc'>
          <Icon type='arrow-down' size='small' />
        </Radio.Button>
      </Radio.Group>

    </React.Fragment>

  )

}

Controls.propTypes = {
  controls: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func.isRequired,
}

export default Controls
