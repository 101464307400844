import modalProps from '../../../modalProps'
import { createCustomerEnquiry, updateCustomerEnquiry } from '../../../../../Api/customerEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id } = modalProps()

    const data = {
      customer_id: values.customer.id,
      customer_contact_id: values.customer_contact.id,
      manager_id: values.manager_id,
      customer_ref: values.customer_ref || null,
      received_on: values.received_on.format('YYYY-MM-DD'),
      respond_by: values.respond_by ? values.respond_by.format('YYYY-MM-DD') : null,
      exchange_rate: values.exchange_rate,
      value: values.value || null,
      payment_terms: values.payment_terms,
      status: values.status,
      notes: values.notes || null,
    }
    
    let response

    if (id) {

      response = await updateCustomerEnquiry({ id, data })

    } else {

      response = await createCustomerEnquiry({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)

    throw error

  }

}
