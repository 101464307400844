import { FORM_ERROR } from 'final-form'
import login from '../../../App/Services/login'

export default (values) => async (dispatch) => {
  
  try {

    const credentials = {
      email: values.username,
      password: values.password,
    }

    return await dispatch(login(credentials))

  } catch (error) {

    if (error.message === 'INVALID_CREDENTIALS') {

      return { [FORM_ERROR]: 'Oops! That email / password combination is not valid.' }

    }

    throw error

  }

}