import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      initialValues,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >

        {({ handleSubmit }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ values: true, submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name='password'>
              {({ input, meta }) => (
                <Form.Item
                  label='Password'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input.Password
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>
  
            <Field name='password_confirm'>
              {({ input, meta }) => (
                <Form.Item
                  label='Confirm'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input.Password
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
