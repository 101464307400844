import modalProps from '../../../modalProps'
import { findCustomerContact } from '../../../../../Api/customerContacts'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = modalProps()

    if (id) {

      const response = await findCustomerContact({ id })

      const customerContact = response.data.data

      const initialValues = {
        ...customerContact,
      }
    
      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
      }
    
      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)
    
  }

}
