import { createAction, handleActions } from 'redux-actions'
import { concat, drop } from 'lodash'

const startUpload = createAction('CORE/UPLOADS/START_UPLOAD')
const finishUpload = createAction('CORE/UPLOADS/FINISH_UPLOAD')
const setProgress = createAction('CORE/UPLOADS/SET_PROGRESS')
const queueUploads = createAction('CORE/UPLOADS/QUEUE_UPLOADS')
const shiftQueue = createAction('CORE/UPLOADS/SHIFT_QUEUE')

const defaultState = {
  uploading: false,
  progress: 0,
  queue: [],
}

const reducer = handleActions(
  {
    [startUpload]: (state) => Object.assign({}, state, { uploading: true }),
    [finishUpload]: (state) => Object.assign({}, state, { uploading: false }),
    [setProgress]: (state, action) => Object.assign({}, state, { progress: action.payload }),
    [queueUploads]: (state, action) => Object.assign({}, state, { queue: concat(state.queue, action.payload) }),
    [shiftQueue]: (state) => Object.assign({}, state, { queue: drop(state.queue) }),
  },
  defaultState,
)

export { startUpload, finishUpload, setProgress, queueUploads, shiftQueue, reducer }