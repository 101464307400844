import { closePartModal } from '../State/partModal'
import formService from '../formService'

export default (part) => (dispatch) => {

  dispatch(closePartModal())

  if (part) {
    formService().change('part', {
      id: part.id,
      internal_no: part.internal_no,
    })
  }

}
