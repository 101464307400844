import { combineReducers } from 'redux'

import { reducer as customerEnquiry } from "./State/customerEnquiry"
import { reducer as initialized } from "./State/initialized"
import { reducer as customerEnquiryModal } from "./State/customerEnquiryModal"
import { reducer as fileChanges } from "./State/fileChanges"

import Request from './Components/Request/reducer'
import SupplierEnquiries from './Components/SupplierEnquiries/reducer'
import SourcedParts from './Components/SourcedParts/reducer'
import CustomerQuote from './Components/CustomerQuote/reducer'
import SalesOrder from './Components/SalesOrder/reducer'

export default combineReducers({
  customerEnquiry,
  initialized,
  customerEnquiryModal,
  fileChanges,

  Request,
  SupplierEnquiries,
  SourcedParts,
  CustomerQuote,
  SalesOrder,
})
