import { connect } from 'react-redux'
import SalesOrderItem from './SalesOrderItem'

import onClick from './Handlers/onClick'
import onRemove from './Handlers/onRemove'

const mapStateToProps = (state) => ({
  customerEnquiry: state.screens.CustomerEnquiry.customerEnquiry,
})

const actionCreators = {
  onClick,
  onRemove,
}

export default connect(mapStateToProps, actionCreators)(SalesOrderItem)
