import { closeQuoteSourcedPartModal } from '../State/quoteSourcedPartModal'
import fetchCustomerEnquiry from '../../../Services/fetch'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {
  
  dispatch(closeQuoteSourcedPartModal())

  if (reload) {
    dispatch(fetchCustomerEnquiry())
    dispatch(fetch())
  }
  
}
