import { message } from 'antd'
import modalProps from '../../../modalProps'
import { resetMyPassword, resetUserPassword } from '../../../../../Api/users'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { userId } = modalProps()
    
  const data = {
    password: values.password,
  }

  try {

    if (userId) {
  
      await resetUserPassword({ id: userId, data })
  
    } else {
  
      await resetMyPassword({ data })
  
    }
  
    message.success('Reset password')
  
    modalProps().onClose()

  } catch (error) {

    handleException(error)

    throw error

  }

}
