import React from 'react'
import PropTypes from 'prop-types'
import { getSupplierContacts } from '../../Api/supplierContacts'

import Select from './Select'

export default class SupplierContact extends React.PureComponent {
  
  static propTypes = {
    supplierId: PropTypes.number,
  }

  fetchOptions = async () => {
    
    if (!this.props.supplierId) return []

    const response = await getSupplierContacts({
      params: {
        sort: 'name',
        'filter[supplier_id]': this.props.supplierId,
      },
    })
    
    return response.data.data

  }

  render () {

    return (

      <Select
        keyProp="id"
        labelProp="name"
        watchProps={['supplierId']}
        placeholder="Select Contact"
        fetchOptions={this.fetchOptions}
        {...this.props}
      />

    )
  }
}