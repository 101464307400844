import { connect } from 'react-redux'
import Header from './Header'

import onChangeTab from './Handlers/onChangeTab'
import onChangeUserFilter from './Handlers/onChangeUserFilter'
import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'

const mapStateToProps = (state) => ({
  users: state.core.users,
  customerEnquiryStatuses: state.core.customerEnquiryStatuses,
  activeTab: state.screens.CustomerEnquiries.Table.activeTab,
  userFilter: state.screens.CustomerEnquiries.Table.userFilter,
  searchTerm: state.screens.CustomerEnquiries.Table.searchTerm,
})

const actionCreators = {
  onChangeTab,
  onChangeUserFilter,
  onChangeSearch,
  onSearch,
}

export default connect(mapStateToProps, actionCreators)(Header)
