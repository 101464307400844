import { combineReducers } from 'redux'

import { reducer as moveSourcedPartModal } from './State/moveSourcedPartModal'

import Form from './Components/Form/reducer'

export default combineReducers({
  moveSourcedPartModal,
  
  Form,
})
