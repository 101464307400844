import { createAction, handleActions } from 'redux-actions'

const setLastInteractionId = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/SET_LAST_INTERACTION_ID')
const resetLastInteractionId = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/RESET_LAST_INTERACTION_ID')

const defaultState = null

const reducer = handleActions(
  {
    [setLastInteractionId]: (state, { payload }) => payload,
    [resetLastInteractionId]: () => defaultState,
  },
  defaultState,
)

export { setLastInteractionId, resetLastInteractionId, reducer }
