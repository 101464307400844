import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from './Components/Table'
import Supplier from '../../Modals/Supplier'

export default class Suppliers extends React.PureComponent {

  static propTypes = {
    supplierModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New Supplier
    </Button>,

  ])

  render() {

    const {
      supplierModal,
      onCloseSupplierModal,
    } = this.props

    return (

      <Screen
        title="Suppliers"
        headerRight={this.renderHeaderRight()}
      >

        <Table />

        {supplierModal.show && (
          <Supplier
            onClose={onCloseSupplierModal}
            id={supplierModal.supplierId}
          />
        )}

      </Screen>

    )

  }

}
