import React from 'react'
import PropTypes from 'prop-types'
import { Input, Table, Button, Icon } from 'antd'
import { find } from 'lodash'
import { LinkButton } from '../../../../../packages/sota'
import styles from './styles.css'

import { SourcedPartsPopover } from '../../../../Modules'
import SupplierModal from '../../../Supplier'

export default class Finder extends React.PureComponent {

  static propTypes = {
    customerEnquiryItem: PropTypes.object.isRequired,
    services: PropTypes.array.isRequired,
    searchTerm: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    tableRows: PropTypes.array.isRequired,
    supplierModal: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClickCreateSupplier: PropTypes.func.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onClickUseSupplier: PropTypes.func.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
  }

  render() {

    const {
      customerEnquiryItem,
      services,
      searchTerm,
      fetching,
      tableRows,
      supplierModal,
      onSearch,
      onClickCreateSupplier,
      onChangeTable,
      onClickUseSupplier,
      onCloseSupplierModal,
    } = this.props

    const showSourcedParts = !!customerEnquiryItem.part && !!customerEnquiryItem.service_id

    const supplierIds = customerEnquiryItem.suppliers.map(supplier => supplier.id)

    return (

      <React.Fragment>

        <div className={styles.header}>

          <div className={styles.left}>

            <Input.Search
              value={searchTerm}
              loading={fetching}
              autoFocus
              onSearch={value => onSearch(value)}
              onChange={e => onSearch(e.target.value)}
              placeholder='Search'
              style={{ width: 250 }}
            />

            {showSourcedParts && (

              <div className={styles.details}>

                <div className={styles.detail}>
                  <div className={styles.label}>Part:</div>
                  <div className={styles.value}>{customerEnquiryItem.manufacturer_part_no}</div>
                </div>

                <div className={styles.detail}>
                  <div className={styles.label}>Service:</div>
                  <div className={styles.value}>{find(services, service => service.id === customerEnquiryItem.service_id).name}</div>
                </div>

              </div>

            )}

          </div>

          <div>

            <Button
              icon='plus'
              onClick={onClickCreateSupplier}
            >
              New Supplier
            </Button>

          </div>

        </div>

        <Table
          rowKey='id'
          dataSource={tableRows}
          size='small'
          loading={fetching}
          onChange={onChangeTable}
        >

          <Table.Column
            key='name'
            title='Supplier'
            sorter
            defaultSortOrder={showSourcedParts ? undefined : 'ascend'}
            render={(text, record) => `${record.name} (${record.currency.symbol})`}
          />

          <Table.Column
            key='sage_ref'
            title='Sage Ref'
            sorter
            render={(text, record) => record.sage_ref}
          />

          {showSourcedParts && (

            <Table.Column
              key='sourced_parts'
              title='Sourced'
              width={100}
              sorter
              defaultSortOrder='descend'
              render={(text, record) => (
                <SourcedPartsPopover sourcedParts={record.sourced_parts} placement='bottom'>
                  <div style={{ display: 'flex', alignItems: 'center', color: record.sourced_parts.length ? '#1990FF' : '#9e9e9e' }}>
                    <Icon type='file-search' style={{ marginRight: 5 }} />
                    <div>{record.sourced_parts.length}</div>
                  </div>
                </SourcedPartsPopover>
              )}
            />

          )}

          <Table.Column
            key='action'
            width={140}
            render={(text, record) => supplierIds.indexOf(record.id) !== -1 ? (
              <React.Fragment>
                <Icon type='check-circle' style={{ color: '#1990FF', marginRight: 8 }} />
                Using
              </React.Fragment>
            ) : (
              <LinkButton
                type='primary'
                icon='plus'
                onClick={() => onClickUseSupplier(record)}
              >
                Use Supplier
              </LinkButton>
            )}
          />

        </Table>

        {supplierModal.show && (
          <SupplierModal
            onClose={onCloseSupplierModal}
          />
        )}

      </React.Fragment>

    )

  }

}
