import { createAction, handleActions } from 'redux-actions'

const openDuplicateSupplierEnquiryModal = createAction('SCREENS/SUPPLIER_ENQUIRY/OPEN_DUPLICATE_SUPPLIER_ENQUIRY_MODAL')
const closeDuplicateSupplierEnquiryModal = createAction('SCREENS/SUPPLIER_ENQUIRY/CLOSE_DUPLICATE_SUPPLIER_ENQUIRY_MODAL')

const defaultState = {
  show: false,
  items: [],
}

const reducer = handleActions(
  {
    [openDuplicateSupplierEnquiryModal]: (state, action) => Object.assign({}, state, {
      show: true,
      customerEnquiryId: action.payload.customerEnquiryId,
      serviceId: action.payload.serviceId,
      items: action.payload.items,
    }),
    [closeDuplicateSupplierEnquiryModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openDuplicateSupplierEnquiryModal, closeDuplicateSupplierEnquiryModal, reducer }
