import { openSelectPartModal } from '../../../State/selectPartModal'

export default (item) => (dispatch) => {
  
  dispatch(openSelectPartModal({
    internalNo: item.part.internal_no,
    supplierEnquiryItemId: item.id,
  }))
  
}
