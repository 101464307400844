import { closeSalesOrderItemModal } from '../State/salesOrderItemModal'
import fetchCustomerEnquiry from '../../../Services/fetch'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {
  
  dispatch(closeSalesOrderItemModal())

  if (reload) {
    dispatch(fetchCustomerEnquiry())
    dispatch(fetch())
  }
  
}
