import { connect } from 'react-redux'
import Parts from './Parts'

import componentDidMount from './Handlers/componentDidMount'
import onClickNew from './Handlers/onClickNew'
import onClosePartModal from './Handlers/onClosePartModal'

const mapStateToProps = (state) => ({
  partModal: state.screens.Parts.partModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onClosePartModal,
}

export default connect(mapStateToProps, actionCreators)(Parts)
