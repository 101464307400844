import { createAction, handleActions } from 'redux-actions'

const appIsReady = createAction('APP/APP_IS_READY')

const defaultState = false

const reducer = handleActions(
  {
    [appIsReady]: () => true,
  },
  defaultState,
)

export { appIsReady, reducer }