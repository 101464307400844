import { message } from 'antd'
import modalProps from '../modalProps'
import { runCustomerEnquiryImport } from '../../../Api/customerEnquiries'
import fetch from './fetch'
import { startRunning, finishRunning } from '../State/running'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id, onClose } = modalProps()

  dispatch(startRunning())

  try {

    const { data: { success } } = await runCustomerEnquiryImport({ id })

    if (success) {
      message.success('Import successful')
      onClose(true)
    } else {
      message.warning('Manufacturer / part setup required')
      dispatch(fetch())
    }

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishRunning())

  }

}
