import { createAction, handleActions } from 'redux-actions'

const setMatches = createAction('MODALS/MANUFACTURER/FORM/SET_MATCHES')
const resetMatches = createAction('MODALS/MANUFACTURER/FORM/RESET_MATCHES')

const defaultState = []

const reducer = handleActions(
  {
    [setMatches]: (state, { payload }) => payload,
    [resetMatches]: () => defaultState,
  },
  defaultState,
)

export { setMatches, resetMatches, reducer }
