import modalProps from '../modalProps'
import { findImport } from '../../../Api/imports'
import { setData } from '../State/data'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = modalProps()

  const params = {}

  try {

    const response = await findImport({ id, params })

    dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
