import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createUser, updateUser } from '../../../../../Api/users'
import fetchUsers from '../../../../../Core/Services/fetchUsers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { user } = modalProps()

  const data = {
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email,
    department_key: values.department_key !== 'Other' ? values.department_key : null,
    is_admin: values.is_admin ? 1 : 0,
    password: values.password,
  }

  let response

  try {

    if (user) {
      response = await updateUser({ id: user.id, data })
      message.success(`Updated "${response.data.data.name}"`)
    } else {
      response = await createUser({ data })
      message.success(`Created "${response.data.data.name}"`)
    }

    modalProps().onClose(response.data.data)

    dispatch(fetchUsers({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
