import screenProps from '../../../screenProps'
import { handleCustomerEnquiryItems } from '../../../../../Api/customerEnquiries'
import fetch from './fetch'
import prepareInitialValues from '../Utils/prepareInitialValues'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'

export default (interactionId) => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { values } = getState().screens.CustomerEnquiry.Request.formState

  const data = values.customer_enquiry_items.map(customerEnquiryItem => ({
    id: customerEnquiryItem.id,
    manufacturer_part_no: customerEnquiryItem.manufacturer_part_no || null,
    customer_part_no: customerEnquiryItem.customer_part_no || null,
    manufacturer_id: customerEnquiryItem.manufacturer.id || null,
    part_id: customerEnquiryItem.part ? customerEnquiryItem.part.id : null,
    quantity: customerEnquiryItem.quantity || null,
    service_id: customerEnquiryItem.service_id || null,
    guid: customerEnquiryItem.guid,
    supplier_ids: customerEnquiryItem.suppliers.map(supplier => supplier.id),
  }))

  try {

    await handleCustomerEnquiryItems({ id, data })

    if (interactionId !== getState().screens.CustomerEnquiry.Request.lastInteractionId) return

    const customerEnquiryItems = await dispatch(fetch())

    if (interactionId !== getState().screens.CustomerEnquiry.Request.lastInteractionId) return

    const initialValues = prepareInitialValues(customerEnquiryItems)

    dispatch(setFormInitialValues(initialValues))

  } catch (error) {

    handleException(error)

  }

}
