import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, DatePicker, InputNumber, Select, Typography, Spin, Tooltip, Button } from 'antd'
import { get } from 'lodash'
import { getExchangeRate } from 'Common'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelAutoComplete, ModelSelect } from '../../../../Modules'
import numeral from 'numeral'
import formService from './formService'
import styles from './Component.css'

import PartModal from '../../../Part'
import SupplierModal from '../../../Supplier'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object,
    partModal: PropTypes.object.isRequired,
    supplierModal: PropTypes.object.isRequired,
    exchangeRate: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickCreatePart: PropTypes.func.isRequired,
    onClickCreateSupplier: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    exchangeRate: null,
  }

  render () {

    const {
      initialValues,
      partModal,
      supplierModal,
      exchangeRate,
      onChangeFormState,
      onSubmit,
      validate,
      onClickCreatePart,
      onClickCreateSupplier,
      onClosePartModal,
      onCloseSupplierModal,
    } = this.props
    
    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    const formatCurrencyLabel = (text, values) => {
      const symbol = get(values, 'supplier.currency.symbol')
      if (!symbol) return text
      return `${text} (${symbol})`
    }

    const getTotalCostGbp = values => {
      if (!values.unit_cost || !values.quantity || !values.exchange_rate) return false
      const totalCostGbp = (values.unit_cost * values.quantity) / values.exchange_rate
      return (
        <div style={{ display: 'flex', paddingLeft: 10 }}>
          <Typography.Text style={{ fontWeight: 500 }}>
            {numeral(totalCostGbp).format('0,0.00')}
          </Typography.Text>
          {!!values.landing_costs && (
            <React.Fragment>
              <div style={{ width: 8 }} />
              <Typography.Text style={{ fontStyle: 'italic', color: 'orange' }}>
                {`£${numeral(totalCostGbp + values.landing_costs).format('0,0.00')} landed cost`}
              </Typography.Text>
            </React.Fragment>
          )}
        </div>
      )
    }

    return (

      <React.Fragment>
        
        <FormProvider
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, submitting, form }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}
              autoComplete="off"
            >

              <FormInterceptor
                ref={formService}
                subscription={{ submitting: true }}
                onChange={onChangeFormState}
              />

              <Field name="part">
                {({ input, meta }) => (
                  <Form.Item
                    label="Part"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelAutoComplete.Part {...input} />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title="Create Part">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreatePart}
                />
              </Tooltip>

              <Field name="supplier">
                {({ input, meta }) => (
                  <Form.Item
                    label="Supplier"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelAutoComplete.Supplier
                      {...input}
                      disabled={!!exchangeRate || submitting}
                      onChange={value => {
                        form.change('exchange_rate', getExchangeRate(get(value, 'currency.code')))
                        return input.onChange(value)
                      }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title="Create Supplier">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreateSupplier}
                />
              </Tooltip>

              <Field name="quantity">
                {({ input, meta }) => (
                  <Form.Item
                    label="Qty"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="unit_cost">
                {({ input, meta }) => (
                  <Form.Item
                    label={formatCurrencyLabel('Unit Cost', values)}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={4}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              {/* If the SP is in GBP, don't bother displaying the below */}
              {get(values, 'supplier.currency.id', 1) !== 1 && (
                <React.Fragment>
                  <Field name="total_cost">
                    {() => (
                      <Form.Item
                        label={formatCurrencyLabel('Total Cost', values)}
                        className={styles.formItem}
                      >
                        <Typography.Text style={{ fontWeight: 500, paddingLeft: 10 }}>
                          {(values.unit_cost && values.quantity) ? numeral(values.unit_cost * values.quantity).format('0,0.00') : null}
                        </Typography.Text>
                      </Form.Item>
                    )}
                  </Field>
                  <Field name="exchange_rate">
                    {({ input, meta }) => (
                      <Form.Item
                        label="Exchange Rate"
                        required
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                        className={styles.formItem}
                      >
                        <Tooltip title={`£1 gets you ${get(values, 'supplier.currency.symbol', '?')}...`}>
                          <InputNumber
                            {...input}
                            disabled={!!exchangeRate || submitting}
                            min={0}
                            step={1}
                            precision={2}
                            style={{ width: '100%' }}
                          />
                        </Tooltip>
                      </Form.Item>
                    )}
                  </Field>
                </React.Fragment>
              )}

              <Field name="total_cost_gbp">
                {() => (
                  <Form.Item
                    label='Total Cost (£)'
                    className={styles.formItem}
                  >
                    {getTotalCostGbp(values)}
                  </Form.Item>
                )}
              </Field>

              {values.unit_cost && (
                <Field name="landing_costs">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Carriage Costs (£)"
                      required
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      className={styles.formItem}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>
              )}

              <Field name="service">
                {({ input, meta }) => (
                  <Form.Item
                    label="Service"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelSelect.Service
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="lead_time">
                {({ input, meta }) => (
                  <Form.Item
                    label="Lead Time"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="rohs">
                {({ input }) => (
                  <Form.Item
                    label="RoHS"
                    className={styles.formItem}
                  >
                    <Select
                      {...input}
                      value={typeof input.value === 'number' ? input.value : undefined}
                      optionFilterProp='children'
                      allowClear
                      placeholder='N/A'
                      className={styles.antInput}
                      style={{ width: 100 }}
                    >
                      {[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }].map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

              <Field name="date_code">
                {({ input, meta }) => (
                  <Form.Item
                    label="Date Code"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="reference">
                {({ input }) => (
                  <Form.Item
                    label="Reference"
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="date">
                {({ input, meta }) => (
                  <Form.Item
                    label="Date"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <DatePicker
                      {...input}
                      disabled={submitting}
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="notes">
                {({ input }) => (
                  <Form.Item
                    label="Notes"
                    className={styles.formItem}
                  >
                    <Input.TextArea
                      {...input}
                      rows={3}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>

        {partModal.show && (
          <PartModal
            onClose={onClosePartModal}
          />
        )}

        {supplierModal.show && (
          <SupplierModal
            onClose={onCloseSupplierModal}
          />
        )}
        
      </React.Fragment>

    )
  }
}