import { createAction, handleActions } from 'redux-actions'

const setSelectedManufacturerId = createAction('SCREENS/MANUFACTURERS/SET_SELECTED_MANUFACTURER_ID')
const resetSelectedManufacturerId = createAction('SCREENS/MANUFACTURERS/RESET_SELECTED_MANUFACTURER_ID')

const defaultState = null

const reducer = handleActions(
  {
    [setSelectedManufacturerId]: (state, action) => action.payload,
    [resetSelectedManufacturerId]: () => defaultState,
  },
  defaultState,
)

export { setSelectedManufacturerId, resetSelectedManufacturerId, reducer }
