import { connect } from 'react-redux'
import Finder from './Finder'

import modalProps from '../../modalProps'

import selectTableRows from './Selectors/selectTableRows'

import onSearch from './Handlers/onSearch'
import onClickCreatePart from './Handlers/onClickCreatePart'
import onChangeTable from './Handlers/onChangeTable'
import onClickSelectPart from './Handlers/onClickSelectPart'
import onClosePartModal from './Handlers/onClosePartModal'

const mapStateToProps = (state) => ({
  customerEnquiryItem: modalProps().customerEnquiryItem,
  searchTerm: state.modals.FindPart.Finder.searchTerm,
  fetching: state.modals.FindPart.Finder.fetching,
  tableRows: selectTableRows(state),
  partModal: state.modals.FindPart.Finder.partModal,
})

const actionCreators = {
  onSearch,
  onClickCreatePart,
  onChangeTable,
  onClickSelectPart,
  onClosePartModal,
}

export default connect(mapStateToProps, actionCreators)(Finder)
