import { createAction, handleActions } from 'redux-actions'

const openManufacturerModal = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/OPEN_MANUFACTURER_MODAL')
const closeManufacturerModal = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/CLOSE_MANUFACTURER_MODAL')

const defaultState = {
  show: false,
  index: null,
  name: null,
}

const reducer = handleActions(
  {
    [openManufacturerModal]: (state, { payload }) => ({ ...state, show: true, ...payload }),
    [closeManufacturerModal]: () => defaultState,
  },
  defaultState,
)

export { openManufacturerModal, closeManufacturerModal, reducer }
