import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Popover, Spin } from 'antd'
import pluralize from 'pluralize'
import { FormInterceptor } from '../../../../../packages/sota'
import modalProps from '../../modalProps'
import formService from './formService'
import styles from './Component.css'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object,
    matches: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render () {

    const {
      initialValues,
      matches,
      onChangeFormState,
      onChangeName,
      onSubmit,
      validate,
    } = this.props

    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    const isNew = !modalProps().id

    return (

      <FormProvider
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >

            <FormInterceptor
              ref={formService}
              subscription={{ submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name="name">
              {({ input, meta }) => (
                <Form.Item
                  label="Name"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  help={!!matches.length && (
                    <Popover
                      title='Matches'
                      content={(
                        <div className={styles.matches}>
                          {matches.map(manufacturer => <div key={manufacturer.id}>{`• ${manufacturer.name}`}</div>)}
                        </div>
                      )}
                    >
                      <a>{`${matches.length} potential ${pluralize('duplicate', matches.length)} found`}</a>
                    </Popover>
                  )}
                >
                  <Input
                    {...input}
                    onChange={e => {
                      input.onChange(e)
                      if (isNew) onChangeName(e.target.value)
                    }}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="aliases">
              {({ input }) => (
                <Form.Item
                  label="Aliases"
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="url">
              {({ input }) => (
                <Form.Item
                  label="URL"
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="sage_ref">
              {({ input }) => (
                <Form.Item
                  label="Sage Ref"
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
