import React from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import CustomerEnquiries from './Components/CustomerEnquiries'
import SupplierEnquiries from './Components/SupplierEnquiries'
import SourcedParts from './Components/SourcedParts'
import PartModal from '../../Modals/Part'
import PartMergeModal from '../../Modals/PartMerge'

export default class Part extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    partModal: PropTypes.object.isRequired,
    partMergeModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
    onClosePartMergeModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      partModal,
      partMergeModal,
      onClosePartModal,
      onClosePartMergeModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 200 }}
        contentStyle={{ backgroundColor: 'white', paddingBottom: 60 }}
      >

        {(initialized && activeTab === 0) && <CustomerEnquiries />}

        {(initialized && activeTab === 1) && <SupplierEnquiries />}

        {(initialized && activeTab === 2) && <SourcedParts />}

        {partModal.show && (
          <PartModal
            id={id}
            onClose={onClosePartModal}
          />
        )}

        {partMergeModal.show && (
          <PartMergeModal
            {...partMergeModal}
            onClose={onClosePartMergeModal}
          />
        )}
        
      </Screen>

    )

  }
  
}
