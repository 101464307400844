import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteCustomerEnquiry } from '../../../Api/customerEnquiries'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { customerEnquiry } = getState().screens.CustomerEnquiry

    await deleteCustomerEnquiry({ id: customerEnquiry.id })

    message.success(`Deleted Enquiry`)

    dispatch(push(`/portal/customer_enquiries`))

  } catch (error) {

    handleException(error)

  }
}
