export default (values) => () => {

  const errors = {}

  if (!values.part || !values.part.id) errors.part = 'Part is required'
  
  if (!values.supplier || !values.supplier.id) errors.supplier = 'Supplier is required'
  
  if (!values.date) errors.date = 'Date is required'

  if (!values.service || !values.service.id) errors.service = 'Service is required'
  
  if (!values.quantity) errors.quantity = 'Qty is required'

  if (!values.exchange_rate) errors.exchange_rate = 'Exchange rate is required'

  if (values.unit_cost) {
    if (typeof values.landing_costs !== 'number') errors.landing_costs = 'Carriage costs is required'
  }

  if (!values.lead_time) errors.lead_time = 'Lead time is required'
  
  if (!values.date_code) errors.date_code = 'Date code is required'
  
  return errors

}
