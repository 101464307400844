import { message } from 'antd'
import { deleteCustomerQuoteItem } from '../../../../../Api/customerQuoteItems'
import fetchCustomerEnquiry from '../../../Services/fetch'
import fetchCustomerEnquiryItems from './fetch'
import { handleException } from '../../../../../../packages/sota'

export default (customerQuoteItem) => async (dispatch) => {

  try {

    await deleteCustomerQuoteItem({ id: customerQuoteItem.id })

    message.success(`Deleted Quote Item`)

    dispatch(fetchCustomerEnquiry())
    dispatch(fetchCustomerEnquiryItems())

  } catch (error) {

    handleException(error)

  }
  
}
