import { connect } from 'react-redux'
import Customers from './Customers'

import componentDidMount from './Handlers/componentDidMount'
import onClickNew from './Handlers/onClickNew'
import onCloseCustomerModal from './Handlers/onCloseCustomerModal'

const mapStateToProps = (state) => ({
  customerModal: state.screens.Customers.customerModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseCustomerModal,
}

export default connect(mapStateToProps, actionCreators)(Customers)
