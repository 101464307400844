import { omit, get } from 'lodash'
import modalProps from '../../../modalProps'
import { createCustomer, updateCustomer } from '../../../../../Api/customers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id } = modalProps()

    const data = {
      ...omit(values, ['currency', 'pref_service']),
      manager_id: values.manager_id || null,
      telephone: values.telephone || null,
      email: values.email || null,
      currency_id: get(values, 'currency.id'),
      pref_service_id: get(values, 'pref_service.id'),
      payment_terms: values.payment_terms,
      sage_ref: values.sage_ref || null,
    }
    
    let response

    if (id) {

      response = await updateCustomer({ id, data })

    } else {

      response = await createCustomer({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)

    throw error

  }

}
