import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class CustomerContactModal extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number,
    isInitialized: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      id,
      isInitialized,
      isSubmitting,
      onSubmit,
      onDelete,
      onClose,
    } = this.props

    const footer = []

    if (id) {
      footer.push(
        <Button key="delete" style={{ float: 'left' }} type="danger" loading={isSubmitting} onClick={onDelete}>
          Delete
        </Button>,
      )
    }

    footer.push(
      <Button key='cancel' disabled={!isInitialized || isSubmitting} onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' disabled={!isInitialized} loading={isSubmitting} onClick={onSubmit}>
        Save
      </Button>,
    )

    return (
        
      <Modal
        visible
        title="Contact"
        width={550}
        maskClosable={false}
        okText="Save"
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
