import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Tooltip, Badge } from 'antd'
import { LinkButton } from '../../../../../packages/sota'
import styles from './styles.css'

import { MediaPopover } from '../../../../Modules'

const Header = (props) => {

  const {
    part,
    activeTab,
    canMerge,
    onBack,
    onEdit,
    onMerge,
    onDelete,
    onChangeTab,
  } = props
  
  if (!part) return null
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.main}>
        
        <div className={styles.section1}>
            
          <LinkButton onClick={onBack}>
            ← All Parts
          </LinkButton>
          
          <div className={styles.title}>
            {`PN ${part.internal_no}`}
          </div>
          
        </div>
        
        <div className={styles.section2}>
            
          <div className={styles.manufacturer}>
            {part.manufacturer.name}
          </div>

          <div className={styles.manufacturerNo}>
            {part.manufacturer_no}
          </div>
          
        </div>

        <div className={styles.section3}>

          <div className={styles.detail}>
            <div className={styles.detailLabel}>Name</div>
            <div className={styles.detailContent}>{part.name || '-'}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.detailLabel}>Full Name</div>
            <div className={styles.detailContent}>{part.full_name || '-'}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.detailLabel}>ECCN</div>
            <div className={styles.detailContent}>{part.eccn || '-'}</div>
          </div>

        </div>

        <div className={styles.section3}>

          <div className={styles.detail}>
            <div className={styles.detailLabel}>BTP</div>
            <div className={styles.detailContent}>{part.build_to_print ? 'Yes' : 'No'}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.detailLabel}>Description</div>
            <div className={styles.detailContent}>{part.description || '-'}</div>
          </div>

        </div>

        <div className={styles.buttons}>

          <MediaPopover
            modelType="Part"
            modelId={part.id}
          >
            {({ count }) => (
              <Tooltip title="Files" placement="left">
                <Badge count={count} className={styles.buttonBadge} overflowCount={99}>
                  <Button
                    shape="circle"
                    icon="file"
                    size="large"
                    className={styles.button}
                  />
                </Badge>
              </Tooltip>
            )}
          </MediaPopover>

          <Tooltip title="Edit" placement="bottom">
            <Button
              shape="circle"
              icon="edit"
              size="large"
              className={styles.button}
              onClick={onEdit}
            />
          </Tooltip>

          {canMerge && (
            <Tooltip title="Merge" placement="bottom">
              <Button
                shape="circle"
                icon="block"
                size="large"
                className={styles.button}
                onClick={onMerge}
              />
            </Tooltip>
          )}

          <Tooltip title="Delete" placement="bottom">
            <Button
              shape="circle"
              icon="delete"
              size="large"
              className={styles.button}
              onClick={onDelete}
            />
          </Tooltip>

        </div>
        
      </div>
      
      <div className={styles.tabs}>
        
        {[
          'Enquiries',
          'Supplier Enquiries',
          'Sourced Parts',
        ].map((tab, tabIndex) => (
          <div
            key={tab}
            className={classNames(styles.tab, { [styles.tabActive]: activeTab === tabIndex })}
            onClick={() => onChangeTab(tabIndex)}
          >
            {tab}
          </div>
        ))}
        
      </div>
      
    </div>

  )
}

Header.propTypes = {
  part: PropTypes.object,
  activeTab: PropTypes.number.isRequired,
  canMerge: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
}

Header.defaultProps = {
  part: null,
}

export default React.memo(Header)
