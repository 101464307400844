import { createAction, handleActions } from 'redux-actions'

const openCustomerEnquiryImportModal = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/OPEN_CUSTOMER_ENQUIRY_IMPORT_MODAL')
const closeCustomerEnquiryImportModal = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/CLOSE_CUSTOMER_ENQUIRY_IMPORT_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openCustomerEnquiryImportModal]: (state, { payload }) => ({ ...state, show: true, ...payload }),
    [closeCustomerEnquiryImportModal]: () => defaultState,
  },
  defaultState,
)

export { openCustomerEnquiryImportModal, closeCustomerEnquiryImportModal, reducer }
