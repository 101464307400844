import modalProps from '../modalProps'
import { resetFormState } from '../Components/Form/State/formState'
import { resetMatches } from '../Components/Form/State/matches'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetFormState())
  dispatch(resetMatches())
  
}
