/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import { Select as AntSelect } from 'antd'
import { get, find } from 'lodash'

export default class Select extends React.PureComponent {

  static propTypes = {
    keyProp: PropTypes.string.isRequired,
    labelProp: PropTypes.string.isRequired,
    watchProps: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    fetchOptions: PropTypes.func.isRequired,
  }
  
  state = {
    options: [],
    fetching: false,
  }

  componentDidMount () {
    this._isMounted = true
    this.fetchOptions()
  }
  
  componentDidUpdate (prevProps) {
    
    const {
      watchProps,
    } = this.props
    
    let shouldRefreshOptions = false
    
    if (this.props.value !== prevProps.value) shouldRefreshOptions = true
    
    if (Array.isArray(watchProps)) {
      
      watchProps.forEach(key => {
        
        if (this.props[key] !== prevProps[key]) shouldRefreshOptions = true
        
      })
      
    }
    
    if (shouldRefreshOptions) {
      this.fetchOptions(true)
    }
    
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getKey = () => get(this.props.value, this.props.keyProp, null)

  getLabel = () => get(this.props.value, this.props.labelProp, '')

  fetchOptions = async (emptyFirst = false) => {
    
    if (emptyFirst) {
      this.setState({ options: [] })
    }
    
    this.setState({ fetching: true })

    const options = await this.props.fetchOptions()

    if (!this._isMounted) return

    this.setState({
      options,
      fetching: false,
    })

  }

  onChange = (value) => {
    
    const selected = find(this.state.options, option => option[this.props.keyProp] === value)
    
    if (selected) {
      this.props.onChange(selected)
    } else {
      this.props.onChange({
        [this.props.keyProp]: null,
        [this.props.labelProp]: null,
      })
    }
    
  }

  onFocus = (...args) => {
    this.fetchOptions()
    this.props.onFocus(...args)
  }

  onBlur = (...args) => {
    this.props.onBlur(...args)
  }

  render () {

    const {
      onChange,
      onFocus,
      onBlur,
    } = this

    const {
      options,
      fetching,
    } = this.state

    const {
      placeholder,
      disabled,
    } = this.props
    
    const value = this.getKey() || undefined

    return (

      <AntSelect
        showSearch
        allowClear
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        loading={fetching}
        dropdownMatchSelectWidth={false}
        style={{ width: '100%' }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {options.map(option => (
          <AntSelect.Option key={option[this.props.keyProp]} value={option[this.props.keyProp]}>
            {option[this.props.labelProp]}
          </AntSelect.Option>
        ))}
      </AntSelect>

    )
  }
}