import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { user } = modalProps()

  if (!user) return

  const initialValues = {
    ...user,
    department_key: user.department_key || 'Other',
  }

  dispatch(setFormInitialValues(initialValues))

}
