import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import moment from 'moment'
import styles from './styles.css'

const SalesOrder = props => {

  const { customerEnquiry, onClickEdit } = props
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.detail}>
        <div className={styles.label}>Number</div>
        <div className={styles.value}>{customerEnquiry.sales_order_number || 'N/A'}</div>
        <div className={styles.edit}><Icon type='edit' onClick={onClickEdit} /></div>
      </div>

      <div className={styles.detail}>
        <div className={styles.label}>Date</div>
        <div className={styles.value}>{customerEnquiry.sales_order_date ? moment(customerEnquiry.sales_order_date).format('DD/MM/YYYY') : 'N/A'}</div>
        <div className={styles.edit}><Icon type='edit' onClick={onClickEdit} /></div>
      </div>

    </div>

  )

}

SalesOrder.propTypes = {
  customerEnquiry: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
}

export default React.memo(SalesOrder)
