import { createAction, handleActions } from 'redux-actions'

const setSalesOrderItems = createAction('SCREENS/CUSTOMER_ENQUIRY/SALES_ORDER/SET_SALES_ORDER_ITEMS')
const resetSalesOrderItems = createAction('SCREENS/CUSTOMER_ENQUIRY/SALES_ORDER/RESET_SALES_ORDER_ITEMS')

const defaultState = []

const reducer = handleActions(
  {
    [setSalesOrderItems]: (state, action) => action.payload,
    [resetSalesOrderItems]: () => defaultState,
  },
  defaultState,
)

export { setSalesOrderItems, resetSalesOrderItems, reducer }
