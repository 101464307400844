import { createAction, handleActions } from 'redux-actions'

const setFormState = createAction('MODALS/CUSTOMER_ENQUIRY/FORM/SET_FORM_STATE')
const setFormInitialValues = createAction("MODALS/CUSTOMER_ENQUIRY/FORM/SET_FORM_INITIAL_VALUES")
const resetFormState = createAction('MODALS/CUSTOMER_ENQUIRY/FORM/RESET_FORM_STATE')

const defaultState = {
  initialValues: null,
  values: {},
  submitting: false,
}

const reducer = handleActions(
  {
    [setFormState]: (state, action) => ({ ...state, ...action.payload }),
    [setFormInitialValues]: (state, action) => ({ ...state, initialValues: action.payload }),
    [resetFormState]: () => defaultState,
  },
  defaultState,
)

export { setFormState, setFormInitialValues, resetFormState, reducer }
