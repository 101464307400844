import { resetInitialized } from '../State/initialized'
import { resetSupplier } from '../State/supplier'
import { resetActiveTab } from '../State/activeTab'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetSupplier())
  dispatch(resetActiveTab())
  
}
