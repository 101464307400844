import { connect } from 'react-redux'
import SourcedParts from './SourcedParts'

import selectRows from './Selectors/selectRows'

import componentDidMount from './Handlers/componentDidMount'
import onClickAdd from './Handlers/onClickAdd'
import onCloseSourcedPartModal from './Handlers/onCloseSourcedPartModal'
import onCloseQuoteSourcedPartModal from './Handlers/onCloseQuoteSourcedPartModal'

const mapStateToProps = (state) => ({
  rows: selectRows(state),
  customerEnquiryId: state.screens.CustomerEnquiry.customerEnquiry.id,
  sourcedPartModal: state.screens.CustomerEnquiry.SourcedParts.sourcedPartModal,
  quoteSourcedPartModal: state.screens.CustomerEnquiry.SourcedParts.quoteSourcedPartModal,
})

const actionCreators = {
  componentDidMount,
  onClickAdd,
  onCloseSourcedPartModal,
  onCloseQuoteSourcedPartModal,
}

export default connect(mapStateToProps, actionCreators)(SourcedParts)
