export default (values) => () => {

  const errors = {}

  if (!values.customer || !values.customer.id) errors.customer = 'Customer is required'

  if (!values.manager_id) errors.manager_id = 'Manager is required'

  if (!values.received_on) errors.received_on = 'Date is required'

  if (!values.exchange_rate) errors.exchange_rate = 'Exchange rate is required'

  if (!values.payment_terms) errors.payment_terms = 'Payment terms are required'

  if (!values.status) errors.status = 'Status is required'

  return errors

}

