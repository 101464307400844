import { connect } from 'react-redux'
import Component from './Component'

import modalProps from '../../modalProps'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'
import onClickCreatePart from './Handlers/onClickCreatePart'
import onClickCreateSupplier from './Handlers/onClickCreateSupplier'
import onClosePartModal from './Handlers/onClosePartModal'
import onCloseSupplierModal from './Handlers/onCloseSupplierModal'

const mapStateToProps = (state) => ({
  initialValues: state.modals.SourcedPart.Form.formState.initialValues,
  partModal: state.modals.SourcedPart.Form.partModal,
  supplierModal: state.modals.SourcedPart.Form.supplierModal,
  exchangeRate: modalProps().exchangeRate,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
  onClickCreatePart,
  onClickCreateSupplier,
  onClosePartModal,
  onCloseSupplierModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
