export default (values) => () => {

  const errors = {}

  if (!values.name) errors.name = 'Name is required'

  if (!values.currency || !values.currency.id) errors.currency = 'Currency is required'

  return errors

}
