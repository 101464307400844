import React from 'react'
import { Modal } from 'antd'
import modalProps from '../../../modalProps'
import merge from '../Services/merge'

const getCreatedBeforeWarning = (sourcePart, targetPart) => {
  if (sourcePart.id < targetPart.id) {
    return `Warning: "${sourcePart.manufacturer_no}" was created before "${targetPart.manufacturer_no}". You may want the merge to occur the other way round.`
  }
  return false
}

const getSageCodeWarning = (sourcePart, targetPart) => {
  if (sourcePart.sage_ref && !targetPart.sage_ref) {
    return `Warning: "${sourcePart.manufacturer_no}" has a Sage ref (${sourcePart.sage_ref}), yet "${targetPart.manufacturer_no}" doesn't. You may want the merge to occur the other way round.`
  }
  if (sourcePart.sage_ref && targetPart.sage_ref) {
    return `Warning: "${sourcePart.manufacturer_no}" and ${targetPart.manufacturer_no} both have a Sage ref (${sourcePart.sage_ref} & ${targetPart.sage_ref} respectively).`
  }
  return false
}

export default (values) => async (dispatch) => {

  const { part: sourcePart } = modalProps()

  const { part: targetPart } = values

  const createdBeforeWarning = getCreatedBeforeWarning(sourcePart, targetPart)

  const sageCodeWarning = getSageCodeWarning(sourcePart, targetPart)

  Modal.confirm({
    title: 'Merge Part?',
    content: (
      <div>
        <p>{`This would remove "${sourcePart.manufacturer_no}" and transfer all of its data into "${targetPart.manufacturer_no}".`}</p>
        {!!createdBeforeWarning && <p style={{ fontStyle: 'italic' }}>{createdBeforeWarning}</p>}
        {!!sageCodeWarning && <p style={{ fontStyle: 'italic' }}>{sageCodeWarning}</p>}
        <p>Are you sure you want to continue?</p>
      </div>
    ),
    okText: 'Yes',
    onOk: async () => {
      await dispatch(merge(values))
    },
  })

}
