import modalProps from '../../../modalProps'
import { pick } from 'lodash'
import { findPart } from '../../../../../Api/parts'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id, manufacturerNo, manufacturer, internalNo, description } = modalProps()

    if (id) {

      const params = {
        include: 'manufacturer',
      }

      const response = await findPart({ id, params })

      const part = response.data.data
      
      const initialValues = {
        ...pick(part, [
          'id',
          'manufacturer_no',
          'internal_no',
          'name',
          'full_name',
          'description',
          'eccn',
        ]),
        manufacturer: {
          ...pick(part.manufacturer, [
            'id',
            'name',
          ]),
        },
        build_to_print: !!part.build_to_print,
      }

      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
        manufacturer_no: manufacturerNo,
        manufacturer: manufacturer || {},
        internal_no: internalNo,
        description,
        build_to_print: false,
      }

      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)

  }

}