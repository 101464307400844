import React from "react"
import PropTypes from "prop-types"

import Screen from '../../Components/Screen'
import Table from "./Components/Table"

export default class SupplierEnquiriesScreen extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render () {

    return (

      <Screen
        title="Supplier Enquiries"
      >

        <Table />

      </Screen>

    )
  }
}
