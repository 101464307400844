import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Icon } from 'antd'
import { get } from 'lodash'
import numeral from 'numeral'
import classNames from 'classnames'
import { LinkButton } from '../../../../../../../packages/sota'
import { SourcedPartPopover } from '../../../../../../Modules'
import styles from './styles.css'

const SalesOrderItem = props => {

  const {
    salesOrderItem,
    customerEnquiry,
    onClick,
    onRemove,
  } = props

  const { symbol } = customerEnquiry.customer.currency

  const getMargin = () => {
    const { margin, margin_perc: marginPerc } = salesOrderItem
    if (!margin) return '-'
    return (
      <div style={{ fontWeight: 500, color: margin > 0 ? 'green' : 'red' }}>
        {`${margin > 0 ? '+' : '-'}£${numeral(Math.abs(margin)).format('0,0')}`}
        <br />
        <div style={{ fontSize: 12 }}>
          {margin >= 0 && `(${numeral(Math.abs(marginPerc)).format('0,0')}%)`}
        </div>
      </div>
    )
  }
  
  return (

    <div className={styles.wrapper}>

      <div className={classNames(styles.col, styles.col1)}>

        <LinkButton type='primary' onClick={() => onClick(salesOrderItem)}>
          {salesOrderItem.part.manufacturer_no}
        </LinkButton>

      </div>

      <div className={classNames(styles.col, styles.col2)}>

        <div className={classNames(styles.textOutlet)}>
          {salesOrderItem.part.manufacturer.name}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col3)}>

        <div className={classNames(styles.textOutlet)}>
          {salesOrderItem.quantity}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col4)}>

        <div className={classNames(styles.textOutlet)}>
          {salesOrderItem.service.name}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col5)}>

        <div className={classNames(styles.textOutlet)}>
          {salesOrderItem.lead_time || '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col6)}>

        <div className={classNames(styles.textOutlet)}>
          {salesOrderItem.rohs === null && 'N/A'}
          {salesOrderItem.rohs === 1 && 'Yes'}
          {salesOrderItem.rohs === 0 && 'No'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col7)}>

        <div className={classNames(styles.textOutlet)}>
          {salesOrderItem.date_code || '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col8)}>

        <div className={classNames(styles.textOutlet)}>
          {`${symbol}${numeral(salesOrderItem.unit_price).format('0,0.[0000]')}`}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col9)}>

        <div className={classNames(styles.textOutlet)}>
          {`${symbol}${numeral(salesOrderItem.total_price).format('0,0.[00]')}`}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col10)}>

        <div className={classNames(styles.textOutlet)}>
          <SourcedPartPopover sourcedPart={get(salesOrderItem, 'customer_quote_item.sourced_part')} placement='bottomRight'>
            {getMargin()}
          </SourcedPartPopover>
        </div>

      </div>

      <div className={classNames(styles.col, styles.col11)}>

        {salesOrderItem.notes && (

          <Tooltip title={salesOrderItem.notes}>
            <Icon type='info-circle' style={{ color: '#1990FF', fontSize: 18 }} />
          </Tooltip>

        )}

      </div>

      <div className={classNames(styles.col, styles.col12)} />
      
      <div className={styles.actions}>
        
        <Tooltip title='Delete Order Item'>
          <LinkButton icon='delete' type='danger' onClick={() => onRemove(salesOrderItem)} />
        </Tooltip>
        
      </div>
      
    </div>

  )

}

SalesOrderItem.propTypes = {
  salesOrderItem: PropTypes.object.isRequired,
  customerEnquiry: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default React.memo(SalesOrderItem)
