import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'antd'
import { find } from 'lodash'
import styles from './styles.css'

const CustomerEnquiryItem = (props) => {

  const {
    item,
    services,
    isClosed,
    onToggleClosed,
  } = props
  
  const service = find(services, s => s.id === item.service_id)
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.closer} onClick={() => onToggleClosed(item)}>
        
        <Icon
          type={isClosed ? 'caret-right' : 'caret-down'}
        />
        
      </div>

      <div className={classNames(styles.col, styles.col1)}>

        <div className={classNames(styles.textOutlet)}>
          {item.manufacturer_part_no ? item.manufacturer_part_no : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col2)}>

        <div className={classNames(styles.textOutlet)}>
          {item.quantity ? item.quantity : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col3)}>

        <div className={classNames(styles.textOutlet)}>
          {item.target_price ? item.target_price : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col4)}>

        <div className={classNames(styles.textOutlet)}>
          {service ? service.name : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col5)}>

        <div className={classNames(styles.textOutlet)}>
          {item.notes ? item.notes : '-'}
        </div>

      </div>
      
    </div>

  )

}

CustomerEnquiryItem.propTypes = {
  item: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  isClosed: PropTypes.bool.isRequired,
  onToggleClosed: PropTypes.func.isRequired,
}

export default React.memo(CustomerEnquiryItem)
