import modalProps from '../../../modalProps'
import { createManufacturer, updateManufacturer } from '../../../../../Api/manufacturers'
import fetchManufacturers from '../../../../../Core/Services/fetchManufacturers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  try {

    const { id } = modalProps()

    const data = {
      name: values.name,
      aliases: values.aliases || null,
      url: values.url || null,
      sage_ref: values.sage_ref || null,
    }
    
    let response

    if (id) {

      response = await updateManufacturer({ id, data })

    } else {

      response = await createManufacturer({ data })

    }

    modalProps().onClose(response.data.data)

    dispatch(fetchManufacturers({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
