import { last } from 'lodash'

export default (values) => {

  const customerEnquiryItem = last(values.customer_enquiry_items)

  if (!customerEnquiryItem) return true
  if (customerEnquiryItem.manufacturer_part_no) return true
  if (customerEnquiryItem.customer_part_no) return true

  return false

}
