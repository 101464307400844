import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

const UserSelect = props => {

  const {
    width,
    users,
    value,
    onChange,
  } = props
  
  return (

    <Select
      size='small'
      mode='multiple'
      showSearch
      optionFilterProp='children'
      placeholder='All Users'
      value={value}
      onChange={$value => onChange($value)}
      style={{ width }}
    >
      <Select.OptGroup label='Active'>
        {users.filter(user => !user.deactivated_at).map(({ id, name }) => (
          <Select.Option key={id} value={id}>{name}</Select.Option>
        ))}
      </Select.OptGroup>
      <Select.OptGroup label='Deactivated'>
        {users.filter(user => !!user.deactivated_at).map(({ id, name }) => (
          <Select.Option key={id} value={id} style={{ color: '#9e9e9e' }}>{name}</Select.Option>
        ))}
      </Select.OptGroup>
    </Select>

  )

}

UserSelect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  users: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default UserSelect
