const template = {
  fontSize: 12,
}

const table = {
  marginBottom: 20,
  borderTop: '1px solid black',
  borderLeft: '1px solid black',
}

const table2 = {
  marginBottom: 20,
  borderTop: '1px solid black',
  borderLeft: '1px solid black',
}

const headerCell = {
  borderBottom: '1px solid black',
  borderRight: '1px solid black',
  padding: 4,
  fontSize: 12,
  fontWeight: 'bold',
}

const headerCell2 = {
  textAlign: 'center',
  borderBottom: '1px solid black',
  borderRight: '1px solid black',
  padding: 4,
  fontSize: 13,
  fontWeight: 'bold',
}

const cell = {
  borderBottom: '1px solid black',
  borderRight: '1px solid black',
  padding: 4,
  paddingRight: 8,
  fontSize: 12,
}

const cell2 = {
  verticalAlign: 'top',
  borderBottom: '1px solid black',
  borderRight: '1px solid black',
  padding: 15,
  paddingRight: 8,
  fontSize: 13,
}

export default {
  template,
  table,
  table2,
  headerCell,
  headerCell2,
  cell,
  cell2,
}