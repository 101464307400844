export default () => async (dispatch, getState) => {

  const { token } = getState().app

  const { activeTab, searchTerm, sorting } = getState().screens.SupplierEnquiries.Table

  const params = {
    search: searchTerm,
  }

  if (activeTab !== 'All') params['filter[status]'] = activeTab

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-created_at'

  let url = `/api/supplier_enquiries/export?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.open(url)

}
