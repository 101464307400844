import { combineReducers } from 'redux'

import { reducer as part } from './State/part'
import { reducer as initialized } from './State/initialized'
import { reducer as activeTab } from './State/activeTab'
import { reducer as partModal } from './State/partModal'
import { reducer as partMergeModal } from './State/partMergeModal'

import CustomerEnquiries from './Components/CustomerEnquiries/reducer'
import SupplierEnquiries from './Components/SupplierEnquiries/reducer'
import SourcedParts from './Components/SourcedParts/reducer'

export default combineReducers({
  part,
  initialized,
  activeTab,
  partModal,
  partMergeModal,

  CustomerEnquiries,
  SupplierEnquiries,
  SourcedParts,
})
