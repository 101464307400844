export default () => async (dispatch, getState) => {

  const { token } = getState().app

  const { activeTab, searchTerm, sorting } = getState().screens.CustomerEnquiries.Table

  const params = {
    search: searchTerm,
  }

  if (activeTab !== 'All') params['filter[status]'] = activeTab

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-received_on'

  let url = `/api/customer_enquiries/export?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.open(url)

}
