import { openCustomerQuoteItemModal } from '../../../State/customerQuoteItemModal'

export default (customerEnquiryItem) => (dispatch, getState) => {

  const { customerEnquiry } = getState().screens.CustomerEnquiry

  dispatch(openCustomerQuoteItemModal({
    customerEnquiryItemId: customerEnquiryItem.id,
    customerEnquiry,
  }))
  
}
