import { connect } from 'react-redux'
import SalesOrder from './SalesOrder'

import selectRows from './Selectors/selectRows'

import componentDidMount from './Handlers/componentDidMount'
import onCloseSalesOrderModal from './Handlers/onCloseSalesOrderModal'
import onCloseSalesOrderItemModal from './Handlers/onCloseSalesOrderItemModal'

const mapStateToProps = (state) => ({
  rows: selectRows(state),
  salesOrderModal: state.screens.CustomerEnquiry.SalesOrder.salesOrderModal,
  salesOrderItemModal: state.screens.CustomerEnquiry.SalesOrder.salesOrderItemModal,
})

const actionCreators = {
  componentDidMount,
  onCloseSalesOrderModal,
  onCloseSalesOrderItemModal,
}

export default connect(mapStateToProps, actionCreators)(SalesOrder)
