import { connect } from 'react-redux'
import { get } from 'lodash'
import Part from './Part'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onClosePartModal from './Handlers/onClosePartModal'
import onClosePartMergeModal from './Handlers/onClosePartMergeModal'

const mapStateToProps = (state) => ({
  id: get(state.screens.Part.part, 'id'),
  initialized: state.screens.Part.initialized,
  activeTab: state.screens.Part.activeTab,
  partModal: state.screens.Part.partModal,
  partMergeModal: state.screens.Part.partMergeModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onClosePartModal,
  onClosePartMergeModal,
}

export default connect(mapStateToProps, actionCreators)(Part)
