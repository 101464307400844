import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import Component from './Component'

const mapStateToProps = () => ({})

const actionCreators = {
  redirect: replace,
}

export default connect(mapStateToProps, actionCreators)(Component)
