import { createAction, handleActions } from 'redux-actions'

const showSplashscreen = createAction('APP/SHOW_SPLASHSCREEN')
const hideSplashscreen = createAction('APP/HIDE_SPLASHSCREEN')

const defaultState = false

const reducer = handleActions(
  {
    [showSplashscreen]: () => true,
    [hideSplashscreen]: () => false,
  },
  defaultState,
)

export { showSplashscreen, hideSplashscreen, reducer }