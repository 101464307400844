import { getParts } from '../../../../../Api/parts'
import { handleException } from '../../../../../../packages/sota'

export default (manufacturerPartNo, customerPartNo, manufacturerId) => async (dispatch, getState) => {

  const params = {
    'filter[manufacturer_id]': manufacturerId || undefined,
    'filter[manufacturer_no]': manufacturerPartNo || undefined,
    include: 'manufacturer',
  }

  if (customerPartNo) {
    const { customer_id: customerId } = getState().screens.CustomerEnquiry.customerEnquiry
    params['filter[customer_no]'] = `${customerId},${customerPartNo}`
  }

  try {

    const response = await getParts({ params })

    return response.data.data

  } catch (error) {

    handleException(error)

    return null

  }

}
