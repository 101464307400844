import { setSelectedManufacturerId } from '../../../State/selectedManufacturerId'
import fetch from '../../../Services/fetch'

export default (manufacturerId) => (dispatch) => {

  dispatch(setSelectedManufacturerId(manufacturerId))

  dispatch(fetch())

}
