import User from './User'
import Service from './Service'
import Currency from './Currency'
import CustomerContact from './CustomerContact'
import SupplierContact from './SupplierContact'

export default {
  User,
  Service,
  Currency,
  CustomerContact,
  SupplierContact,
}
