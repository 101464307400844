import { connect } from 'react-redux'
import { get } from 'lodash'
import SupplierEnquiry from './SupplierEnquiry'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseSupplierEnquiryModal from './Handlers/onCloseSupplierEnquiryModal'
import onCloseDuplicateSupplierEnquiryModal from './Handlers/onCloseDuplicateSupplierEnquiryModal'

const mapStateToProps = (state) => ({
  id: get(state.screens.SupplierEnquiry.supplierEnquiry, 'id'),
  initialized: state.screens.SupplierEnquiry.initialized,
  activeTab: state.screens.SupplierEnquiry.activeTab,
  supplierEnquiryModal: state.screens.SupplierEnquiry.supplierEnquiryModal,
  duplicateSupplierEnquiryModal: state.screens.SupplierEnquiry.duplicateSupplierEnquiryModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseSupplierEnquiryModal,
  onCloseDuplicateSupplierEnquiryModal,
}

export default connect(mapStateToProps, actionCreators)(SupplierEnquiry)
