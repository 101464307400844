import { closeSalesOrderModal } from '../State/salesOrderModal'
import { openOrderQuoteItemModal } from '../State/orderQuoteItemModal'
import fetchCustomerEnquiry from '../../../Services/fetch'

export default (reload = false) => (dispatch, getState) => {

  const { customerQuoteItemId } = getState().screens.CustomerEnquiry.CustomerQuote.salesOrderModal
  
  dispatch(closeSalesOrderModal())

  if (reload) {
    dispatch(openOrderQuoteItemModal({ id: customerQuoteItemId }))
    dispatch(fetchCustomerEnquiry())
  }
  
}
