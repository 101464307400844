import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.css'

const Header = (props) => (

  <div className={styles.wrapper}>

    <div className={classNames(styles.col, styles.col1)}>
      Manufacturer PN
    </div>

    <div className={classNames(styles.col, styles.col2)}>
      Qty
    </div>

    <div className={classNames(styles.col, styles.col3)}>
      {`Target (${props.customerEnquiry.customer.currency.symbol})`}
    </div>

    <div className={classNames(styles.col, styles.col4)}>
      Service
    </div>

    <div className={classNames(styles.col, styles.col5)}>
      Notes
    </div>

  </div>

)

Header.propTypes = {
  customerEnquiry: PropTypes.object.isRequired,
}

export default Header
