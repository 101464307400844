import React from 'react'
import { findSupplier, getSuppliers } from '../../Api/suppliers'

import AutoComplete from './AutoComplete'

export default class Supplier extends React.PureComponent {

  fetchSuggestions = async (term) => {

    const response = await getSuppliers({
      params: {
        search: term,
        sort: 'name',
        include: 'currency',
        'page[size]': 30,
      },
    })
    
    return response.data.data

  }

  fetchSelected = async (id) => {
    
    const response = await findSupplier({
      id,
      params: {
        include: 'currency',
      },
    })
    
    const supplier = response.data.data

    if (!supplier) return null
    
    return {
      id: supplier.id,
      name: supplier.name,
      currency: supplier.currency,
    }
    
  }

  render () {

    return (

      <AutoComplete
        keyProp="id"
        labelProp="name"
        labelBracketsProp="currency.symbol"
        placeholder="Select Supplier"
        fetchSuggestions={this.fetchSuggestions}
        fetchSelected={this.fetchSelected}
        {...this.props}
      />

    )
  }
}