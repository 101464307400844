import React from 'react'
import PropTypes from 'prop-types'
import { Table, Tag, Icon } from 'antd'
import moment from 'moment'
import styles from './styles.css'

const Content = (props) => {

  const {
    data,
    isFetching,
    status,
    onChange,
    onClickRow,
  } = props

  return (

    <Table
      dataSource={data}
      loading={isFetching}
      rowKey='id'
      pagination={false}
      className={styles.table}
      onChange={onChange}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
    >

      <Table.Column
        key='name'
        title='Name'
        width={200}
        sorter
        render={(text, record) => (
          <strong style={{ color: !!record.deactivated_at && '#9e9e9e' }}>
            {record.name}
            {!!record.is_admin && (
              <Tag style={{ marginLeft: 5 }}>Admin</Tag>
            )}
          </strong>
        )}
      />

      <Table.Column
        key='email'
        title='Email'
        width={200}
        sorter
        render={(text, record) => record.email}
      />

      <Table.Column
        key='department_name'
        title='Department'
        width={200}
        render={(text, record) => record.department_name || <span style={{ color: '#9e9e9e' }}>Other</span>}
      />

      {status === 'active' && (
        <Table.Column
          key='created_at'
          title='Created'
          width={120}
          sorter
          render={(text, record) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type='check-circle' style={{ fontSize: 16, color: 'limegreen', marginRight: 5 }} />
              {moment(record.created_at).fromNow()}
            </div>
          )}
        />
      )}

      {status === 'deactivated' && (
        <Table.Column
          key='deactivated_at'
          title='Deactivated'
          width={120}
          sorter
          render={(text, record) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type='eye-invisible' style={{ fontSize: 16, color: '#9e9e9e', marginRight: 5 }} />
              {moment(record.deactivated_at).fromNow()}
            </div>
          )}
        />
      )}

    </Table>

  )
}

Content.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Content
