import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { get } from 'lodash'
import { LinkButton } from '../../../../../packages/sota'

const Parts = props => {

  const {
    tableRows,
    onClickCreate,
  } = props
  
  return (
    
    <Table
      rowKey='key'
      dataSource={tableRows}
      size='small'
    >

      <Table.Column
        key='manufacturer_no'
        title='Manufacturer No.'
        render={(text, record) => record.manufacturer_no}
      />

      <Table.Column
        key='manufacturer.name'
        title='Manufacturer'
        render={(text, record) => get(record.manufacturer, 'name')}
      />

      <Table.Column
        key='internal_no'
        title='Internal No.'
        render={(text, record) => record.internal_no}
      />

      <Table.Column
        key='description'
        title='Description'
        render={(text, record) => record.description}
      />

      <Table.Column
        key='create'
        title=''
        render={(text, record) => (
          <LinkButton
            type='primary'
            icon='plus'
            onClick={() => onClickCreate(record)}
          >
            Create Part
          </LinkButton>
        )}
      />

    </Table>

  )

}

Parts.propTypes = {
  tableRows: PropTypes.array.isRequired,
  onClickCreate: PropTypes.func.isRequired,
}

export default Parts
