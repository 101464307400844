import screenProps from '../screenProps'
import initialize from '../Services/initialize'
import initializeCustomerContacts from '../Components/CustomerContacts/Services/initialize'
import initializeCustomerEnquiries from '../Components/CustomerEnquiries/Services/initialize'

export default (props, prevProps) => async (dispatch, getState) => {

  screenProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    const { activeTab } = getState().screens.Customer

    await dispatch(initialize())

    if (activeTab === 0) await dispatch(initializeCustomerContacts())

    if (activeTab === 1) await dispatch(initializeCustomerEnquiries())
    
  }

}
