import React from 'react'
import PropTypes from 'prop-types'
import { Input, Spin, Icon } from 'antd'

const Child = React.memo(React.forwardRef((props, ref) => {
  
  const {
    fetching,
    matched,
    placeholder,
    customChild,
    ...autoCompleteProps
  } = props
  
  if (typeof customChild === 'function') {
    return customChild({ fetching, matched, autoCompleteProps, ref })
  }

  return (
    
    <Input
      {...autoCompleteProps}
      ref={ref}
      allowClear
      autoComplete="off"
      placeholder={placeholder}
      prefix={(
        <div>
          {fetching && <Spin size="small" style={{ marginRight: 12 }} />}
          {(!fetching && matched) && <Icon type="check-circle" style={{ marginRight: 12, color: '#1990FF' }} />}
          {(!fetching && !matched) && <Icon type="search" style={{ marginRight: 12, color: '#999' }} />}
        </div>
      )}
    />
    
  )
}))

Child.propTypes = {
  fetching: PropTypes.bool.isRequired,
  matched: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  customChild: PropTypes.func,
}

export default Child