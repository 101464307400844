import { connect } from 'react-redux'
import CustomerQuoteItemFooter from './CustomerQuoteItemFooter'

import onClickAdd from './Handlers/onClickAdd'

const mapStateToProps = () => ({})

const actionCreators = {
  onClickAdd,
}

export default connect(mapStateToProps, actionCreators)(CustomerQuoteItemFooter)
