import { createAction, handleActions } from 'redux-actions'

const openSupplierModal = createAction('SCREENS/SUPPLIER/OPEN_SUPPLIER_MODAL')
const closeSupplierModal = createAction('SCREENS/SUPPLIER/CLOSE_SUPPLIER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSupplierModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeSupplierModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSupplierModal, closeSupplierModal, reducer }
