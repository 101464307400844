import { pick } from 'lodash'

export default customerEnquiryItems => ({
  customer_enquiry_items: customerEnquiryItems.map(customerEnquiryItem => ({
    ...pick(customerEnquiryItem, [
      'id',
      'manufacturer_part_no',
      'customer_part_no',
      'part',
      'quantity',
      'service_id',
      'guid',
      'suppliers',
    ]),
    manufacturer: customerEnquiryItem.manufacturer || {},
  })),
})
