import axios from './axios'

export const getCustomerEnquiriesInPeriodByUser = ({ params }) => axios.get('dashboard/customer_enquiries_in_period_by_user', { params })
export const getCustomerEnquiriesInPeriodByDepartment = ({ params }) => axios.get('dashboard/customer_enquiries_in_period_by_department', { params })
export const getCustomerEnquiriesInPeriodByService = ({ params }) => axios.get('dashboard/customer_enquiries_in_period_by_service', { params })
export const getCustomerEnquiriesInPeriodByCustomer = ({ params }) => axios.get('dashboard/customer_enquiries_in_period_by_customer', { params })

export const getSalesOrdersInPeriodByUser = ({ params }) => axios.get('dashboard/sales_orders_in_period_by_user', { params })
export const getSalesOrdersInPeriodByDepartment = ({ params }) => axios.get('dashboard/sales_orders_in_period_by_department', { params })
export const getSalesOrdersInPeriodByService = ({ params }) => axios.get('dashboard/sales_orders_in_period_by_service', { params })
export const getSalesOrdersInPeriodByCustomer = ({ params }) => axios.get('dashboard/sales_orders_in_period_by_customer', { params })

export const getConversionInPeriodByUser = ({ params }) => axios.get('dashboard/conversion_in_period_by_user', { params })
export const getConversionInPeriodByDepartment = ({ params }) => axios.get('dashboard/conversion_in_period_by_department', { params })
export const getConversionInPeriodByService = ({ params }) => axios.get('dashboard/conversion_in_period_by_service', { params })
export const getConversionInPeriodByCustomer = ({ params }) => axios.get('dashboard/conversion_in_period_by_customer', { params })
