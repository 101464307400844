import screenProps from '../../../screenProps'
import { getSupplierEnquiries } from '../../../../../Api/supplierEnquiries'
import { setSupplierEnquiries } from '../State/supplierEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id } = screenProps().match.params

    const { searchTerm, sorting } = getState().screens.Part.SupplierEnquiries

    const params = {
      search: searchTerm,
      'filter[contains_part_id]': id,
      include: 'supplier,service',
    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-created_at'

    const response = await getSupplierEnquiries({ id, params })

    const supplierEnquiries = response.data.data

    dispatch(setSupplierEnquiries(supplierEnquiries))

  } catch (error) {

    handleException(error)

  }

}
