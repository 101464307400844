import React from "react"
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import Requirements from './Components/Requirements'
import EmailTemplate from './Components/EmailTemplate'
import SupplierEnquiryModal from '../../Modals/SupplierEnquiry'

export default class SupplierEnquiry extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    supplierEnquiryModal: PropTypes.object.isRequired,
    duplicateSupplierEnquiryModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseSupplierEnquiryModal: PropTypes.func.isRequired,
    onCloseDuplicateSupplierEnquiryModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      supplierEnquiryModal,
      duplicateSupplierEnquiryModal,
      onCloseSupplierEnquiryModal,
      onCloseDuplicateSupplierEnquiryModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 200 }}
        contentStyle={{ backgroundColor: 'white', paddingBottom: 60, minHeight: 500 }}
      >
        
        {(initialized && activeTab === 0) && <Requirements />}
        
        {(EmailTemplate && activeTab === 1) && <EmailTemplate />}

        {supplierEnquiryModal.show && (
          <SupplierEnquiryModal
            id={id}
            onClose={onCloseSupplierEnquiryModal}
          />
        )}

        {duplicateSupplierEnquiryModal.show && (
          <SupplierEnquiryModal
            onClose={onCloseDuplicateSupplierEnquiryModal}
            {...duplicateSupplierEnquiryModal}
          />
        )}
        
      </Screen>

    )

  }

}
