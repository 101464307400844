import { createAction, handleActions } from 'redux-actions'

const loadCustomerEnquiryStatuses = createAction('CORE/CUSTOMER_ENQUIRY_STATUSES/LOAD_CUSTOMER_ENQUIRY_STATUSES')

const defaultState = []

const reducer = handleActions(
  {
    [loadCustomerEnquiryStatuses]: (state, action) => action.payload,
  },
  defaultState,
)

export { loadCustomerEnquiryStatuses, reducer }
