import { connect } from 'react-redux'
import { get } from 'lodash'
import Customer from './Customer'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseCustomerModal from './Handlers/onCloseCustomerModal'
import onCloseCustomerMergeModal from './Handlers/onCloseCustomerMergeModal'

const mapStateToProps = (state) => ({
  id: get(state.screens.Customer.customer, 'id'),
  initialized: state.screens.Customer.initialized,
  activeTab: state.screens.Customer.activeTab,
  customerModal: state.screens.Customer.customerModal,
  customerMergeModal: state.screens.Customer.customerMergeModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseCustomerModal,
  onCloseCustomerMergeModal,
}

export default connect(mapStateToProps, actionCreators)(Customer)
