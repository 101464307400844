import { closeCustomerQuoteItemModal } from '../State/customerQuoteItemModal'
import fetchCustomerEnquiry from '../../../Services/fetch'
import fetchCustomerEnquiryItems from '../Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeCustomerQuoteItemModal())

  if (shouldReload) {
    dispatch(fetchCustomerEnquiry())
    dispatch(fetchCustomerEnquiryItems())
  }
  
}
