import modalProps from '../../../modalProps'

export default (values) => () => {

  const { user } = modalProps()

  const errors = {}

  if (!values.first_name) errors.first_name = true

  if (!values.last_name) errors.last_name = true

  if (!values.email) errors.email = true

  if (!values.department_key) errors.department_key = true

  if (!user) {

    if (!values.password) errors.password = true
  
    if (values.password_confirm !== values.password) errors.password_confirm = true

  }

  return errors

}
