import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import styles from './styles.css'

const int = num => numeral(num).format('0,0')

const mon = (sym, num, def = '-') => num ? `${sym}${numeral(num).format('0,0.00')}` : def

const CustomerQuoteItemsPopover = props => {

  const {
    customerQuoteItems,
    children,
    ...rest
  } = props

  if (!customerQuoteItems.length) return children

  const content = (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Customer No.</th>
            <th className={styles.num}>Qty</th>
            <th>Service</th>
            <th className={styles.num}>Sale Price</th>
            <th>Lead Time</th>
            <th>RoHS</th>
            <th>Date Code</th>
          </tr>
        </thead>
        <tbody>
          {customerQuoteItems.map(customerQuoteItem => (
            <tr key={customerQuoteItem.id}>
              <td>{moment(customerQuoteItem.created_at).format('Do MMM YYYY')}</td>
              <td>{customerQuoteItem.customer_enquiry_item.customer_part_no}</td>
              <td className={styles.num}>{int(customerQuoteItem.quantity)}</td>
              <td>{customerQuoteItem.service.name}</td>
              <td className={styles.num}>{mon('£', customerQuoteItem.total_price_gbp / customerQuoteItem.quantity, 'No Bid')}</td>
              <td>{customerQuoteItem.lead_time}</td>
              <td>
                {customerQuoteItem.rohs === null && 'N/A'}
                {customerQuoteItem.rohs === 1 && 'Yes'}
                {customerQuoteItem.rohs === 0 && 'No'}
              </td>
              <td>{customerQuoteItem.date_code}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (

    <Popover
      title='Quoted'
      content={content}
      {...rest}
    >

      {children}

    </Popover>

  )

}

CustomerQuoteItemsPopover.propTypes = {
  customerQuoteItems: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default CustomerQuoteItemsPopover
