import { createSelector } from 'reselect'
import { orderBy, take } from 'lodash'

const selectChart = state => state.screens.Dashboard.CustomerEnquiriesInPeriod.controls.chart
const selectYAxis = state => state.screens.Dashboard.CustomerEnquiriesInPeriod.controls.yAxis
const selectSort = state => state.screens.Dashboard.CustomerEnquiriesInPeriod.controls.sort
const selectDepartmentData = state => state.screens.Dashboard.CustomerEnquiriesInPeriod.data.departmentData

const columnConfig = (yAxis, sort, departmentData) => {
  const yAxisConfig = {
    quantity: {
      format: '{point.y:,.0f}',
    },
    value: {
      format: '£{point.y:,.0f}',
    },
  }
  return {
    chart: {
      type: 'bar',
      height: 300,
      style: {
        fontFamily: 'Arial, sans-serif',
        textDecoration: 'none',
      },
    },
    title: false,
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: yAxisConfig[yAxis].format,
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px;color:{point.color}">{point.key}</span><br>',
      pointFormat: 'Quantity: <b>{point.quantity}</b><br>Value: <b>£{point.value:,.0f}</b>',
    },
    series: [
      {
        name: 'Bookings by Department',
        colorByPoint: true,
        data: departmentData.map(row => ({
          ...row,
          name: row.name,
          y: row[yAxis],
          drilldown: row.key,
        })),
      },
    ],
    drilldown: {
      activeDataLabelStyle: {
        color: '#000000d9',
        textDecoration: 'none',
      },
      series: departmentData.map(row => ({
        name: row.name,
        id: row.key,
        data: take(orderBy(row.users, yAxis, sort), 10).map($row => ({
          ...$row,
          name: `${$row.first_name[0]}.${$row.last_name}`,
          y: $row[yAxis],
        })),
      })),
    },
  }
}

const pieConfig = (yAxis, sort, departmentData) => {
  const yAxisConfig = {
    quantity: {
      format: '{point.name}: {point.y:,.0f}',
    },
    value: {
      format: '{point.name}: £{point.y:,.0f}',
    },
  }
  return {
    chart: {
      type: 'pie',
      height: 300,
      style: {
        fontFamily: 'Arial, sans-serif',
        textDecoration: 'none',
      },
    },
    title: false,
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: yAxisConfig[yAxis].format,
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px;color:{point.color}">{point.key}</span><br>',
      pointFormat: 'Quantity: <b>{point.quantity}</b><br>Value: <b>£{point.value:,.0f}</b>',
    },
    series: [
      {
        name: 'Bookings by Department',
        colorByPoint: true,
        data: departmentData.map(row => ({
          ...row,
          name: row.name,
          y: row[yAxis],
          drilldown: row.key,
        })),
      },
    ],
    drilldown: {
      activeDataLabelStyle: {
        color: '#000000d9',
        textDecoration: 'none',
      },
      series: departmentData.map(row => ({
        name: row.name,
        id: row.key,
        data: take(orderBy(row.users, yAxis, sort), 10).map($row => ({
          ...$row,
          name: `${$row.first_name[0]}.${$row.last_name}`,
          y: $row[yAxis],
        })),
      })),
    },
  }
}

export default createSelector(
  selectChart,
  selectYAxis,
  selectSort,
  selectDepartmentData,
  (chart, yAxis, sort, departmentData) => {
    const $departmentData = take(orderBy(departmentData, yAxis, sort), 10)
    if (chart === 'column') {
      return columnConfig(yAxis, sort, $departmentData)
    }
    if (chart === 'pie') {
      return pieConfig(yAxis, sort, $departmentData)
    }
    return {}
  },
)
