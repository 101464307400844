import { createAction, handleActions } from 'redux-actions'

const startCreatingAll = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/START_CREATING_ALL')
const finishCreatingAll = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/FINISH_CREATING_ALL')
const resetCreatingAll = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/RESET_CREATING_ALL')

const defaultState = false

const reducer = handleActions(
  {
    [startCreatingAll]: () => true,
    [finishCreatingAll]: () => false,
    [resetCreatingAll]: () => false,
  },
  defaultState,
)

export { startCreatingAll, finishCreatingAll, resetCreatingAll, reducer }
