import { setControl } from '../../../State/controls'
import { resetData } from '../../../State/data'
import fetch from '../../../Services/fetch'

export default (key, value) => (dispatch) => {
  
  dispatch(setControl({ key, value }))

  if (key !== 'source') return

  dispatch(resetData())

  dispatch(fetch())
  
}
