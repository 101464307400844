import { createAction, handleActions } from 'redux-actions'

const setSupplier = createAction('SCREENS/SUPPLIER/SET_SUPPLIER')
const resetSupplier = createAction('SCREENS/SUPPLIER/RESET_SUPPLIER')

const defaultState = null

const reducer = handleActions(
  {
    [setSupplier]: (state, action) => action.payload,
    [resetSupplier]: () => defaultState,
  },
  defaultState,
)

export { setSupplier, resetSupplier, reducer }
