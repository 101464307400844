import screenProps from '../screenProps'
import { findPart } from '../../../Api/parts'
import { setPart } from '../State/part'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params
    
    const params = {
      include: 'manufacturer',
    }

    const response = await findPart({ id, params })

    const part = response.data.data

    dispatch(setPart(part))

  } catch (error) {

    handleException(error)

  }

}
