import modalProps from '../../../modalProps'
import { createSupplierContact, updateSupplierContact } from '../../../../../Api/supplierContacts'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id, supplierId } = modalProps()
    
    const data = {
      ...values,
      supplier_id: supplierId,
    }

    let response

    if (id) {

      response = await updateSupplierContact({ id, data })

    } else {

      response = await createSupplierContact({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
