import { createAction, handleActions } from 'redux-actions'

const setData = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/SET_DATA')
const setPayload = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/SET_PAYLOAD')
const setNewField = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/SET_NEW_FIELD')
const resetData = createAction('MODULES/CUSTOMER_ENQUIRY_IMPORT/RESET_DATA')

const defaultState = null

const reducer = handleActions(
  {
    [setData]: (state, { payload }) => payload,
    [setPayload]: (state, { payload }) => ({ ...state, payload }),
    [setNewField]: (state, { payload }) => ({
      ...state,
      new: {
        ...state.new,
        [payload.field]: payload.data,
      },
    }),
    [resetData]: () => defaultState,
  },
  defaultState,
)

export { setData, setPayload, setNewField, resetData, reducer }
