import { combineReducers } from "redux"

import { reducer as formState } from "./State/formState"
import { reducer as customerModal } from "./State/customerModal"
import { reducer as customerContactModal } from "./State/customerContactModal"

export default combineReducers({
  formState,
  customerModal,
  customerContactModal,
})
