import { createAction, handleActions } from 'redux-actions'

const incrementFileChanges = createAction("SCREENS/CUSTOMER_ENQUIRY/INCREMENT_FILE_CHANGES")
const resetFileChanges = createAction("SCREENS/CUSTOMER_ENQUIRY/RESET_FILE_CHANGES")

const defaultState = 0

const reducer = handleActions(
  {
    [incrementFileChanges]: (state) => state + 1,
    [resetFileChanges]: () => defaultState,
  },
  defaultState,
)

export { incrementFileChanges, resetFileChanges, reducer }
