import { createAction, handleActions } from 'redux-actions'

const openManufacturerMergeModal = createAction('SCREENS/MANUFACTURER/OPEN_MANUFACTURER_MERGE_MODAL')
const closeManufacturerMergeModal = createAction('SCREENS/MANUFACTURER/CLOSE_MANUFACTURER_MERGE_MODAL')

const defaultState = {
  show: false,
  manufacturer: null,
}

const reducer = handleActions(
  {
    [openManufacturerMergeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      manufacturer: action.payload.manufacturer,
    }),
    [closeManufacturerMergeModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openManufacturerMergeModal, closeManufacturerMergeModal, reducer }
