import modalProps from '../../../modalProps'
import { getSuppliers } from '../../../../../Api/suppliers'
import { setFetching } from '../State/fetching'
import { setSuppliers } from '../State/suppliers'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { customerEnquiryItem } = modalProps()

  const { searchTerm } = getState().modals.UseSupplier.Finder

  const params = {
    search: searchTerm || undefined,
    include: 'currency',
  }

  if (customerEnquiryItem.part && customerEnquiryItem.service_id) {
    params['filter[include_sourced_parts]'] = `${customerEnquiryItem.part.id},${customerEnquiryItem.service_id}`
  }

  dispatch(setFetching(true))

  try {

    const response = await getSuppliers({ params })

    dispatch(setSuppliers(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }
  
}
