import screenProps from '../../../screenProps'
import fetch from '../../../Services/fetch'
import fetchRequirements from './fetch'
import prepareInitialValues from '../Utils/prepareInitialValues'
import { handleSupplierEnquiryItems } from '../../../../../Api/supplierEnquiries'
import { setFormInitialValues } from '../State/formState'
import { setLoading } from '../State/loading'
import { handleException } from '../../../../../../packages/sota'

export default (interactionId) => async (dispatch, getState) => {

  try {

    const { id } = screenProps().match.params

    const { values } = getState().screens.SupplierEnquiry.Requirements.formState
    
    const data = (
      values
      .supplier_enquiry_items
      .map(item => ({
        id: item.id,
        part_id: item.part.id || null,
        quantity: item.quantity || null,
        target_price: item.target_price || null,
        guid: item.guid,
      }))
    )

    dispatch(setLoading(true))

    await handleSupplierEnquiryItems({ id, data })

    // if a new interaction has started by this point then don't bother fetching
    if (interactionId !== getState().screens.SupplierEnquiry.Requirements.lastInteractionId) return

    dispatch(fetch())

    const supplierEnquiry = await dispatch(fetchRequirements())

    // if a new interaction has started by this point then don't bother reloading form
    if (interactionId !== getState().screens.SupplierEnquiry.Requirements.lastInteractionId) return

    const initialValues = prepareInitialValues(supplierEnquiry)

    dispatch(setFormInitialValues(initialValues))

    dispatch(setLoading(false))

  } catch (error) {

    dispatch(setLoading(false))

    handleException(error)

  }
  
}
