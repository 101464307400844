import { closeCustomerEnquiryModal } from '../State/customerEnquiryModal'
import fetch from '../Services/fetch'

export default (customerEnquiry) => (dispatch) => {
  
  dispatch(closeCustomerEnquiryModal())
  
  if (customerEnquiry) {
    
    dispatch(fetch())
    
  }
  
}
