import { debounce } from 'lodash'
import { resetMatches } from '../State/matches'
import fetchMatches from '../Services/fetchMatches'

const fetchMatchesWithDebounce = debounce((dispatch, name) => dispatch(fetchMatches(name)), 1000)

export default (name) => (dispatch) => {

  dispatch(resetMatches())

  fetchMatchesWithDebounce(dispatch, name)

}
