import { connect } from 'react-redux'
import Suppliers from './Suppliers'

import componentDidMount from './Handlers/componentDidMount'
import onClickNew from './Handlers/onClickNew'
import onCloseSupplierModal from './Handlers/onCloseSupplierModal'

const mapStateToProps = (state) => ({
  supplierModal: state.screens.Suppliers.supplierModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseSupplierModal,
}

export default connect(mapStateToProps, actionCreators)(Suppliers)
