import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, InputNumber, Select, Typography, Spin, Tooltip, Button } from 'antd'
import { get } from 'lodash'
import numeral from 'numeral'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelAutoComplete, ModelSelect, SourcedPartPopover } from '../../../../Modules'
import formService from './formService'
import styles from './Component.css'

import PartModal from '../../../Part'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    customerEnquiry: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    partModal: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickCreatePart: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
  }

  render() {

    const {
      customerEnquiry,
      initialValues,
      partModal,
      onChangeFormState,
      onSubmit,
      validate,
      onClickCreatePart,
      onClosePartModal,
    } = this.props
    
    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    const getRelTotalCostGbp = values => {
      const sourcedPart = get(values, 'customer_quote_item.sourced_part')
      return (
        <SourcedPartPopover sourcedPart={sourcedPart}>
          <div style={{ display: 'flex', paddingLeft: 10 }}>
            <Typography.Text style={{ fontWeight: 500 }}>
              {(sourcedPart && sourcedPart.total_cost_gbp && values.quantity) ? numeral((sourcedPart.total_cost_gbp / sourcedPart.quantity) * values.quantity).format('0,0.00') : '-'}
            </Typography.Text>
            <div style={{ width: 8 }} />
            <Typography.Text>
              {(sourcedPart && sourcedPart.total_cost_gbp && values.quantity) ? `${numeral(sourcedPart.total_cost_gbp / sourcedPart.quantity).format('0,0.0000')} per unit` : null}
            </Typography.Text>
            {!!sourcedPart && !!sourcedPart.landing_costs && !!values.quantity && (
              <React.Fragment>
                <div style={{ width: 8 }} />
                <Typography.Text style={{ fontStyle: 'italic', color: 'orange' }}>
                  {`+${numeral(sourcedPart.landing_costs).format('0,0.00')} carriage costs`}
                </Typography.Text>
              </React.Fragment>
            )}
          </div>
        </SourcedPartPopover>
      )
    }

    const getMargin = values => {
      const sourcedPart = get(values, 'customer_quote_item.sourced_part')
      if (!sourcedPart || !sourcedPart.total_cost_gbp || !values.unit_price || !values.quantity) return null
      const relTotalCostGbp = (sourcedPart.total_cost_gbp / sourcedPart.quantity) * values.quantity
      const totalPrice = values.unit_price * values.quantity
      const totalPriceGbp = totalPrice / customerEnquiry.exchange_rate
      const margin = totalPriceGbp - (relTotalCostGbp + sourcedPart.landing_costs)
      return (
        <Typography.Text style={{ color: margin > 0 ? 'green' : 'red' }}>
          {`${margin > 0 ? '+' : ''}${numeral(margin).format('0,0.00')}`}
          {` `}
          {margin >= 0 && `(${numeral((margin / (totalPriceGbp - sourcedPart.landing_costs)) * 100).format('0,0')}%)`}
        </Typography.Text>
      )
    }

    return (

      <React.Fragment>
        
        <FormProvider
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >

          {({ handleSubmit, values, submitting }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              autoComplete='off'
            >

              <FormInterceptor
                ref={formService}
                subscription={{ submitting: true }}
                onChange={onChangeFormState}
              />

              <Field name='part'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Part'
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelAutoComplete.Part {...input} disabled={!!values.sourced_part} />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title='Create Part'>
                <Button
                  icon='user-add'
                  className={styles.addButton}
                  onClick={onClickCreatePart}
                />
              </Tooltip>

              <Field name='quantity'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Qty'
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name='rel_total_cost_gbp'>
                {() => (
                  <Form.Item
                    label='Total Cost (£)'
                    className={styles.formItem}
                  >
                    {getRelTotalCostGbp(values)}
                  </Form.Item>
                )}
              </Field>

              <Field name='unit_price'>
                {({ input, meta }) => (
                  <Form.Item
                    label={`Sale Price (${customerEnquiry.customer.currency.symbol})`}
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={4}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              {/* If the CE is in GBP, don't bother displaying the below */}
              {customerEnquiry.customer.currency.id !== 1 && (
                <Field name='total_price'>
                  {() => (
                    <Form.Item
                      label={`Total Price (${customerEnquiry.customer.currency.symbol})`}
                      className={styles.formItem}
                    >
                      <Typography.Text style={{ fontWeight: 500, paddingLeft: 10 }}>
                        {(values.unit_price && values.quantity) ? numeral(values.unit_price * values.quantity).format('0,0.00') : null}
                      </Typography.Text>
                    </Form.Item>
                  )}
                </Field>
              )}

              <Field name='total_price_gbp'>
                {() => (
                  <Form.Item
                    label='Total Price (£)'
                    className={styles.formItem}
                  >
                    <div style={{ display: 'flex', paddingLeft: 10 }}>
                      <Tooltip title={`£1 gets you ${customerEnquiry.customer.currency.symbol}${numeral(customerEnquiry.exchange_rate).format('0,0.[00]')}`}>
                        <Typography.Text style={{ fontWeight: 500 }}>
                          {(values.unit_price && values.quantity) ? numeral((values.unit_price * values.quantity) / customerEnquiry.exchange_rate).format('0,0.00') : null}
                        </Typography.Text>
                      </Tooltip>
                      <div style={{ width: 8 }} />
                      {getMargin(values)}
                    </div>
                  </Form.Item>
                )}
              </Field>

              <Field name='service'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Service'
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelSelect.Service
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name='lead_time'>
                {({ input }) => (
                  <Form.Item
                    label='Lead Time'
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="rohs">
                {({ input }) => (
                  <Form.Item
                    label="RoHS"
                    className={styles.formItem}
                  >
                    <Select
                      {...input}
                      value={typeof input.value === 'number' ? input.value : undefined}
                      optionFilterProp='children'
                      allowClear
                      placeholder='N/A'
                      className={styles.antInput}
                      style={{ width: 100 }}
                    >
                      {[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }].map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

              <Field name='date_code'>
                {({ input }) => (
                  <Form.Item
                    label='Date Code'
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name='notes'>
                {({ input }) => (
                  <Form.Item
                    label='Notes'
                    className={styles.formItem}
                  >
                    <Input.TextArea
                      {...input}
                      rows={3}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>

        {partModal.show && (
          <PartModal
            onClose={onClosePartModal}
          />
        )}
        
      </React.Fragment>

    )

  }

}
