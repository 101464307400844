import { createAction, handleActions } from 'redux-actions'

const openSupplierModal = createAction('MODALS/USE_SUPPLIER/OPEN_SUPPLIER_MODAL')
const closeSupplierModal = createAction('MODALS/USE_SUPPLIER/CLOSE_SUPPLIER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSupplierModal]: (state) => ({ ...state, show: true }),
    [closeSupplierModal]: () => defaultState,
  },
  defaultState,
)

export { openSupplierModal, closeSupplierModal, reducer }
