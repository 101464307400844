import { createSelector } from 'reselect'
import { groupBy, keys, first, includes, find } from 'lodash'

const selectSuitableParts = (state) => state.screens.CustomerEnquiry.SourcedParts.suitableParts
const selectSourcedParts = (state) => state.screens.CustomerEnquiry.SourcedParts.sourcedParts
const selectClosedParts = (state) => state.screens.CustomerEnquiry.SourcedParts.closedParts

const selectRows = createSelector(
  selectSuitableParts,
  selectSourcedParts,
  selectClosedParts,
  (suitableParts, sourcedParts, closedParts) => {

    const rows = []
    
    rows.push({
      type: 'HEADER',
      key: 'header',
    })
    
    rows.push({
      type: 'DIVIDER',
      key: `divider-header`,
      data: {
        type: 1,
      },
    })

    if (suitableParts.length === 0 && sourcedParts.length === 0) {

      rows.push({
        type: 'EMPTY',
        key: 'empty',
      })

      rows.push({
        type: 'DIVIDER',
        key: `divider-empty`,
        data: {
          type: 1,
        },
      })
      
      return rows
      
    }

    const parts = suitableParts.map(suitablePart => {

      const part = { ...suitablePart }

      part.guid = `part-${part.id}`

      part.sourced_parts = []

      return part

    })

    const sourcedPartsGrouped = groupBy(sourcedParts, 'part_id')
    
    keys(sourcedPartsGrouped).forEach(partId => {

      let part = find(parts, item => item.id === Number(partId))

      if (!part) {

        // eslint-disable-next-line prefer-destructuring
        part = first(sourcedPartsGrouped[partId]).part

        part.guid = `part-${part.id}`

        parts.push(part)

      }

      part.sourced_parts = sourcedPartsGrouped[partId].map(sourcedPart => ({
        ...sourcedPart,
        guid: `sourced-part-${sourcedPart.id}`,
      }))
      
    })

    parts.forEach((part, partIndex) => {

      rows.push({
        type: 'PART',
        key: part.guid,
        data: {
          part,
          partIndex,
        },
      })

      if (!includes(closedParts, part.guid)) {
        
        rows.push({
          type: 'DIVIDER',
          key: `divider-${partIndex}-sourced-parts-top`,
          data: {
            type: 2,
          },
        })

        if (part.sourced_parts.length > 0) {
          rows.push({
            type: 'SUBHEADER',
            key: `subheader-${partIndex}`,
          })
        }
        
        part.sourced_parts.forEach((sourcedPart, sourcedPartIndex) => {
          
          rows.push({
            type: 'SOURCED_PART',
            key: sourcedPart.guid,
            data: {
              part,
              partIndex,
              sourcedPart,
              sourcedPartIndex,
            },
          })
  
        })

        rows.push({
          type: 'SOURCED_PART_FOOTER',
          key: `sourced-part-footer-${partIndex}`,
          data: {
            part,
            partIndex,
          },
        })
        
        rows.push({
          type: 'DIVIDER',
          key: `divider-${partIndex}-sourced-parts-bottom`,
          data: {
            type: 3,
          },
        })
        
      } else {
        
        rows.push({
          type: 'DIVIDER',
          key: `divider-${partIndex}`,
          data: {
            type: 1,
          },
        })
        
      }

    })

    return rows

  },
)

export default selectRows
