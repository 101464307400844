import { connect } from 'react-redux'
import Finder from './Finder'

import modalProps from '../../modalProps'

import selectTableRows from './Selectors/selectTableRows'

import onSearch from './Handlers/onSearch'
import onClickCreateSupplier from './Handlers/onClickCreateSupplier'
import onChangeTable from './Handlers/onChangeTable'
import onClickUseSupplier from './Handlers/onClickUseSupplier'
import onCloseSupplierModal from './Handlers/onCloseSupplierModal'

const mapStateToProps = (state) => ({
  customerEnquiryItem: modalProps().customerEnquiryItem,
  services: state.core.services,
  searchTerm: state.modals.UseSupplier.Finder.searchTerm,
  fetching: state.modals.UseSupplier.Finder.fetching,
  tableRows: selectTableRows(state),
  supplierModal: state.modals.UseSupplier.Finder.supplierModal,
})

const actionCreators = {
  onSearch,
  onClickCreateSupplier,
  onChangeTable,
  onClickUseSupplier,
  onCloseSupplierModal,
}

export default connect(mapStateToProps, actionCreators)(Finder)
