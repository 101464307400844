import { getSupplierEnquiries } from '../../../../../Api/supplierEnquiries'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { activeTab, searchTerm, sorting, pagination } = getState().screens.SupplierEnquiries.Table

    const params = {
      search: searchTerm,
    }

    if (activeTab !== 'All') params['filter[status]'] = activeTab

    params.include = [
      'customer_enquiry.customer.currency',
      'customer_enquiry.manager',
      'supplier.currency',
      'supplier_contact',
      'service',
    ].join()
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-created_at'

    dispatch(startFetching())

    const response = await getSupplierEnquiries({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}