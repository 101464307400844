import { updateCustomerEnquiryStatus } from '../../../../../../../Api/customerEnquiries'
import { handleException } from '../../../../../../../../packages/sota'
import fetchTableData from '../../../Services/fetch'

export default (customerEnquiry, event) => async (dispatch) => {

  try {

    await updateCustomerEnquiryStatus({
      id: customerEnquiry.id,
      data: {
        status: event.target.value,
      },
    })

    dispatch(fetchTableData())

  } catch (error) {

    handleException(error)

  }

}