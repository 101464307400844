export default (values) => () => {

  const errors = {}

  if (!values.sales_order_number) errors.sales_order_number = 'Number is required'

  if (!values.sales_order_date) errors.sales_order_date = 'Date is required'

  return errors

}
