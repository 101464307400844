import { connect } from 'react-redux'
import { includes } from 'lodash'
import CustomerEnquiryItem from './CustomerEnquiryItem'

import onToggleClosed from './Handlers/onToggleClosed'

const mapStateToProps = (state, props) => ({
  services: state.core.services,
  isClosed: includes(state.screens.CustomerEnquiry.CustomerQuote.closedItems, props.item.guid),
})

const actionCreators = {
  onToggleClosed,
}

export default connect(mapStateToProps, actionCreators)(CustomerEnquiryItem)
