import { connect } from "react-redux"
import Header from "./Header"

const mapStateToProps = (state) => ({
  customerEnquiry: state.screens.CustomerEnquiry.customerEnquiry,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Header)
