import { connect } from 'react-redux'
import ManufacturerMerge from './ManufacturerMerge'

import componentDidMount from './Handlers/componentDidMount'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  submitting: state.modals.ManufacturerMerge.Form.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(ManufacturerMerge)
