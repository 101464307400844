import { setPagination } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (pageNumber, pageSize) => async (dispatch) => {

  dispatch(setPagination({ pageNumber, pageSize }))

  await dispatch(fetch())

}
