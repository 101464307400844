import { configureStore } from '../packages/sota'

import app from './App/reducer'
import core from './Core/reducer'
import screens from './Screens/reducer'
import modals from './Modals/reducer'
import components from './Components/reducer'

const reducers = {
  app,
  core,
  screens,
  modals,
  components,
}

const persistConfig = {
  whitelist: [],
}

export const { store, persistor, history } = configureStore(reducers, persistConfig)
