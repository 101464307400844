import React from 'react'
import { Modal } from 'antd'
import modalProps from '../../../modalProps'
import merge from '../Services/merge'

const getCreatedBeforeWarning = (sourceManufacturer, targetManufacturer) => {
  if (sourceManufacturer.id < targetManufacturer.id) {
    return `Warning: "${sourceManufacturer.name}" was created before "${targetManufacturer.name}". You may want the merge to occur the other way round.`
  }
  return false
}

const getSageCodeWarning = (sourceManufacturer, targetManufacturer) => {
  if (sourceManufacturer.sage_ref && !targetManufacturer.sage_ref) {
    return `Warning: "${sourceManufacturer.name}" has a Sage ref (${sourceManufacturer.sage_ref}), yet "${targetManufacturer.name}" doesn't. You may want the merge to occur the other way round.`
  }
  if (sourceManufacturer.sage_ref && targetManufacturer.sage_ref) {
    return `Warning: "${sourceManufacturer.name}" and ${targetManufacturer.name} both have a Sage ref (${sourceManufacturer.sage_ref} & ${targetManufacturer.sage_ref} respectively).`
  }
  return false
}

export default (values) => async (dispatch) => {

  const { manufacturer: sourceManufacturer } = modalProps()

  const { manufacturer: targetManufacturer } = values

  const createdBeforeWarning = getCreatedBeforeWarning(sourceManufacturer, targetManufacturer)

  const sageCodeWarning = getSageCodeWarning(sourceManufacturer, targetManufacturer)

  Modal.confirm({
    title: 'Merge Manufacturer?',
    content: (
      <div>
        <p>{`This would remove "${sourceManufacturer.name}" and transfer all of its data into "${targetManufacturer.name}".`}</p>
        {!!createdBeforeWarning && <p style={{ fontStyle: 'italic' }}>{createdBeforeWarning}</p>}
        {!!sageCodeWarning && <p style={{ fontStyle: 'italic' }}>{sageCodeWarning}</p>}
        <p>Are you sure you want to continue?</p>
      </div>
    ),
    okText: 'Yes',
    onOk: async () => {
      await dispatch(merge(values))
    },
  })

}
