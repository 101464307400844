import { connect } from 'react-redux'
import Table from './Table'

import onChange from './Handlers/onChange'

const mapStateToProps = (state) => ({
  parts: state.screens.Manufacturer.Parts.parts,
})

const actionCreators = {
  onChange,
}

export default connect(mapStateToProps, actionCreators)(Table)
