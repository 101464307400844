import { createAction, handleActions } from 'redux-actions'

const initialized = createAction('SCREENS/CUSTOMER/INITIALIZED')
const resetInitialized = createAction('SCREENS/CUSTOMER/RESET_INITIALIZED')

const defaultState = false

const reducer = handleActions(
  {
    [initialized]: () => true,
    [resetInitialized]: () => defaultState,
  },
  defaultState,
)

export { initialized, resetInitialized, reducer }
