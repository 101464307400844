import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, InputNumber, Typography, Spin, Tooltip, Button, Select } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelAutoComplete, ModelSelect, SourcedPartPopover } from '../../../../Modules'
import numeral from 'numeral'
import formService from './formService'
import getUnitPrice from '../../Utils/getUnitPrice'
import getMargin from '../../Utils/getMargin'
import styles from './Component.css'

import PartModal from '../../../Part'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object,
    customerEnquiryItemOptions: PropTypes.array.isRequired,
    partModal: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickCreatePart: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
  }

  state = {
    marginPerc: 25,
  }

  render () {

    const {
      initialValues,
      customerEnquiryItemOptions,
      partModal,
      onChangeFormState,
      onSubmit,
      validate,
      onClickCreatePart,
      onClosePartModal,
    } = this.props
    
    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    const formatCurrencyLabel = (text, values) => `${text} (${values.customer_enquiry.customer.currency.symbol})`

    const getMarginDetails = (values) => {
      const { margin, marginPerc } = getMargin(values)
      if (margin === null) return null
      return (
        <Typography.Text style={{ color: margin > 0 ? 'green' : 'red' }}>
          {`${margin > 0 ? '+' : ''}${numeral(margin).format('0,0.00')}`}
          {` `}
          {margin >= 0 && `(${numeral(marginPerc).format('0,0')}%)`}
        </Typography.Text>
      )
    }

    return (

      <React.Fragment>
        
        <FormProvider
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, submitting, form }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              autoComplete="off"
            >

              <FormInterceptor
                ref={formService}
                subscription={{ submitting: true }}
                onChange={onChangeFormState}
              />

              <Field name="customer_enquiry_item_id">
                {({ input, meta }) => (
                  <Form.Item
                    label="Enquiry Item"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      showSearch
                      allowClear
                    >
                      {customerEnquiryItemOptions.map(customerEnquiryItem => (
                        <Select.Option key={customerEnquiryItem.id} value={customerEnquiryItem.id}>
                          {customerEnquiryItem.customer_part_no}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

              <Field name="part">
                {({ input, meta }) => (
                  <Form.Item
                    label="Part"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelAutoComplete.Part {...input} disabled />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title="Create Part">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreatePart}
                />
              </Tooltip>

              <Field name="quantity">
                {({ input, meta }) => (
                  <Form.Item
                    label="Qty"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <InputNumber
                      {...input}
                      onChange={value => {
                        input.onChange(value)
                        if (typeof value === 'number') {
                          form.change('unit_price', getUnitPrice(values, value, this.state.marginPerc))
                        }
                      }}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="rel_total_cost_gbp">
                {() => (
                  <Form.Item
                    label='Total Cost (£)'
                    className={styles.formItem}
                  >
                    <SourcedPartPopover sourcedPart={values.sourced_part}>
                      <div style={{ display: 'flex', paddingLeft: 10 }}>
                        <Typography.Text style={{ fontWeight: 500 }}>
                          {(values.sourced_part.total_cost_gbp && values.quantity) ? numeral((values.sourced_part.total_cost_gbp / values.sourced_part.quantity) * values.quantity).format('0,0.00') : null}
                        </Typography.Text>
                        <div style={{ width: 8 }} />
                        <Typography.Text>
                          {(values.sourced_part.total_cost_gbp && values.quantity) ? `${numeral(values.sourced_part.total_cost_gbp / values.sourced_part.quantity).format('0,0.0000')} per unit` : null}
                        </Typography.Text>
                        {!!values.sourced_part.landing_costs && !!values.quantity && (
                          <React.Fragment>
                            <div style={{ width: 8 }} />
                            <Typography.Text style={{ fontStyle: 'italic', color: 'orange' }}>
                              {`+${numeral(values.sourced_part.landing_costs).format('0,0.00')} carriage costs`}
                            </Typography.Text>
                          </React.Fragment>
                        )}
                      </div>
                    </SourcedPartPopover>
                  </Form.Item>
                )}
              </Field>

              <Field name="unit_price">
                {({ input, meta }) => (
                  <Form.Item
                    label={formatCurrencyLabel('Sale Price', values)}
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <InputNumber
                      {...input}
                      onChange={value => {
                        input.onChange(value)
                        if (typeof value === 'number') {
                          this.setState({ marginPerc: getMargin(values, value).marginPerc })
                        }
                      }}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={4}
                      style={{ width: 140 }}
                    />
                    <InputNumber
                      value={this.state.marginPerc}
                      onChange={value => {
                        if (typeof value === 'number') {
                          this.setState({ marginPerc: value })
                          form.change('unit_price', getUnitPrice(values, values.quantity, value))
                        }
                      }}
                      disabled={submitting}
                      min={0}
                      max={99.99}
                      step={1}
                      precision={2}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  </Form.Item>
                )}
              </Field>

              {/* If the CE is in GBP, don't bother displaying the below */}
              {values.customer_enquiry.customer.currency.id !== 1 && (
                <Field name="total_price">
                  {() => (
                    <Form.Item
                      label={formatCurrencyLabel('Total Price', values)}
                      className={styles.formItem}
                    >
                      <Typography.Text style={{ fontWeight: 500, paddingLeft: 10 }}>
                        {(values.unit_price && values.quantity) ? numeral(values.unit_price * values.quantity).format('0,0.00') : null}
                      </Typography.Text>
                    </Form.Item>
                  )}
                </Field>
              )}

              <Field name="total_price_gbp">
                {() => (
                  <Form.Item
                    label='Total Price (£)'
                    className={styles.formItem}
                  >
                    <div style={{ display: 'flex', paddingLeft: 10 }}>
                      <Tooltip title={`£1 gets you ${values.customer_enquiry.customer.currency.symbol}${numeral(values.customer_enquiry.exchange_rate).format('0,0.[00]')}`}>
                        <Typography.Text style={{ fontWeight: 500 }}>
                          {(values.unit_price && values.quantity) ? numeral((values.unit_price * values.quantity) / values.customer_enquiry.exchange_rate).format('0,0.00') : null}
                        </Typography.Text>
                      </Tooltip>
                      <div style={{ width: 8 }} />
                      {getMarginDetails(values)}
                    </div>
                  </Form.Item>
                )}
              </Field>

              <Field name="service">
                {({ input, meta }) => (
                  <Form.Item
                    label="Service"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelSelect.Service
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="lead_time">
                {({ input }) => (
                  <Form.Item
                    label="Lead Time"
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="rohs">
                {({ input }) => (
                  <Form.Item
                    label="RoHS"
                    className={styles.formItem}
                  >
                    <Select
                      {...input}
                      value={typeof input.value === 'number' ? input.value : undefined}
                      optionFilterProp='children'
                      allowClear
                      placeholder='N/A'
                      className={styles.antInput}
                      style={{ width: 100 }}
                    >
                      {[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }].map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

              <Field name="date_code">
                {({ input }) => (
                  <Form.Item
                    label="Date Code"
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="notes">
                {({ input }) => (
                  <Form.Item
                    label="Notes"
                    className={styles.formItem}
                  >
                    <Input.TextArea
                      {...input}
                      rows={3}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>

        {partModal.show && (
          <PartModal
            onClose={onClosePartModal}
          />
        )}
        
      </React.Fragment>

    )
  }
}