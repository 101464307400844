import axios from 'axios'
import { merge } from 'lodash'
import { store } from '../store'

const unauthed = axios.create({
  baseURL: '/api/',
})

const authed = axios.create({
  baseURL: '/api/',
})

authed.interceptors.request.use(config => merge(config, {
  headers: {
    Authorization: `Bearer ${store.getState().app.token}`,
  },
}))

authed.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location = '/'
    }
    return Promise.reject(error)
  },
)

export { unauthed, authed }

export default authed
