import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openQuoteSourcedPartModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/OPEN_QUOTE_SOURCED_PART_MODAL')
const closeQuoteSourcedPartModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/CLOSE_QUOTE_SOURCED_PART_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openQuoteSourcedPartModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id', null),
    }),
    [closeQuoteSourcedPartModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openQuoteSourcedPartModal, closeQuoteSourcedPartModal, reducer }
