import { connect } from 'react-redux'
import Component from './Component'
import { get } from 'lodash'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'

const mapStateToProps = (state, props) => ({
  id: get(props, 'id'),
  isInitialized: !!state.modals.CustomerContact.Form.formState.initialValues,
  isSubmitting: state.modals.CustomerContact.Form.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onSubmit,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(Component)