import { sortBy } from 'lodash'
import updateRow from './updateRow'

export default (supplier) => (dispatch, getState) => {

  const { formState, useSupplierModal } = getState().screens.CustomerEnquiry.Request

  const { index } = useSupplierModal

  const customerEnquiryItem = formState.values.customer_enquiry_items[index]

  dispatch(updateRow(index, {
    suppliers: sortBy([
      ...customerEnquiryItem.suppliers,
      supplier,
    ], 'name'),
  }))

}
