import getCoreParams from '../../../Utils/getCoreParams'
import { getCustomerEnquiriesInPeriodByUser, getCustomerEnquiriesInPeriodByDepartment, getCustomerEnquiriesInPeriodByService, getCustomerEnquiriesInPeriodByCustomer } from '../../../../../Api/dashboard'
import { setUserData, setDepartmentData, setServiceData, setCustomerData } from '../State/data'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const state = getState()
  
  const coreParams = getCoreParams(state)
  
  const params = {
    ...coreParams,
  }

  const { source } = state.screens.Dashboard.CustomerEnquiriesInPeriod.controls

  try {

    if (source === 'user') {
      const response = await getCustomerEnquiriesInPeriodByUser({ params })
      dispatch(setUserData(response.data))
    }

    if (source === 'department') {
      const response = await getCustomerEnquiriesInPeriodByDepartment({ params })
      dispatch(setDepartmentData(response.data))
    }

    if (source === 'service') {
      const response = await getCustomerEnquiriesInPeriodByService({ params })
      dispatch(setServiceData(response.data))
    }
    
    if (source === 'customer') {
      const response = await getCustomerEnquiriesInPeriodByCustomer({ params })
      dispatch(setCustomerData(response.data))
    }

  } catch (error) {

    handleException(error)

  }

}
