import { connect } from 'react-redux'
import ConversionInPeriod from './ConversionInPeriod'

import selectConfig from './Selectors/selectConfig'

import componentDidMount from './Handlers/componentDidMount'

const mapStateToProps = (state) => ({
  controls: state.screens.Dashboard.controls,
  config: selectConfig(state),
})

const actionCreators = {
  componentDidMount,
}

export default connect(mapStateToProps, actionCreators)(ConversionInPeriod)
