import { closeSourcedPartModal } from '../State/sourcedPartModal'
import fetch from '../Services/fetch'
import fetchCustomerEnquiry from '../../../Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeSourcedPartModal())

  if (shouldReload) {
    dispatch(fetch())
    dispatch(fetchCustomerEnquiry())
  }
  
}
