export default (values) => () => {

  const errors = {}

  if (!values.name) errors.name = 'Name is required'

  if (!values.currency || !values.currency.id) errors.currency = 'Currency is required'

  if (!values.payment_terms) errors.payment_terms = 'Payment terms are required'

  return errors

}
