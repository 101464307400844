import { message } from 'antd'
import modalProps from '../modalProps'
import { updateUser } from '../../../Api/users'
import fetchUsers from '../../../Core/Services/fetchUsers'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { user } = modalProps()

  const data = {
    reset_password: 1,
  }

  try {

    await updateUser({ id: user.id, data })

    message.success(`Required password reset for "${user.name}"`)

    modalProps().onClose(true)

    dispatch(fetchUsers({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
