import { createAction, handleActions } from 'redux-actions'

const openCustomerMergeModal = createAction('SCREENS/CUSTOMER/OPEN_CUSTOMER_MERGE_MODAL')
const closeCustomerMergeModal = createAction('SCREENS/CUSTOMER/CLOSE_CUSTOMER_MERGE_MODAL')

const defaultState = {
  show: false,
  customer: null,
}

const reducer = handleActions(
  {
    [openCustomerMergeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      customer: action.payload.customer,
    }),
    [closeCustomerMergeModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCustomerMergeModal, closeCustomerMergeModal, reducer }
