import { connect } from 'react-redux'
import Table from './Table'

import onChange from './Handlers/onChange'
import onClick from './Handlers/onClick'

const mapStateToProps = (state) => ({
  supplierContacts: state.screens.Supplier.SupplierContacts.supplierContacts,
})

const actionCreators = {
  onChange,
  onClick,
}

export default connect(mapStateToProps, actionCreators)(Table)
