import { connect } from 'react-redux'
import CustomerSelect from './CustomerSelect'

const mapStateToProps = (state) => ({
  customers: state.core.customers,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(CustomerSelect)
