import { message } from 'antd'
import { deleteSourcedPart } from '../../../../../Api/sourcedParts'
import initialize from './initialize'
import { handleException } from '../../../../../../packages/sota'

export default (sourcedPart) => async (dispatch) => {

  try {

    await deleteSourcedPart({ id: sourcedPart.id })

    message.success(`Deleted Source`)

    dispatch(initialize())

  } catch (error) {

    handleException(error)

  }
}
