import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

const DepartmentSelect = props => {

  const {
    width,
    value,
    onChange,
  } = props

  const departments = [
    { key: 'A&D', name: 'Aerospace & Defence' },
    { key: 'C&K', name: 'Commercial & Kitting' },
  ]
  
  return (

    <Select
      size='small'
      mode='multiple'
      showSearch
      optionFilterProp='children'
      placeholder='All Departments'
      value={value}
      onChange={$value => onChange($value)}
      style={{ width }}
    >
      {departments.map(({ key, name }) => (
        <Select.Option key={key} value={key}>{name}</Select.Option>
      ))}
    </Select>

  )

}

DepartmentSelect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default DepartmentSelect
