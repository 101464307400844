import { pick } from 'lodash'
import chance from 'chance'
import modalProps from '../../../modalProps'
import { findCustomerQuoteItem } from '../../../../../Api/customerQuoteItems'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = modalProps()

    const params = {
      include: [
        'customer_enquiry_item.customer_enquiry.customer.currency',
        'sourced_part.supplier.currency',
        'sourced_part.service',
        'part',
        'service',
      ].join(','),
    }

    const response = await findCustomerQuoteItem({ id, params })

    const customerQuoteItem = response.data.data

    const initialValues = {
      part: {
        ...pick(customerQuoteItem.part, [
          'id',
          'internal_no',
        ]),
      },
      service: {
        ...pick(customerQuoteItem.service, [
          'id',
          'name',
        ]),
      },
      quantity: customerQuoteItem.quantity,
      unit_price: customerQuoteItem.unit_price,
      lead_time: customerQuoteItem.lead_time,
      rohs: customerQuoteItem.rohs,
      date_code: customerQuoteItem.date_code,
      customer_quote_item: customerQuoteItem,
      customer_enquiry: customerQuoteItem.customer_enquiry_item.customer_enquiry,
      sourced_part: customerQuoteItem.sourced_part,
      guid: chance().guid(),
    }

    dispatch(setFormInitialValues(initialValues))

  } catch (error) {

    handleException(error)

  }

}
