import { createAction, handleActions } from 'redux-actions'
import { includes, without, concat } from 'lodash'

const togglePartClosed = createAction("SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/TOGGLE_PART_CLOSED")
const resetClosedParts = createAction("SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/RESET_CLOSED_PARTS")

const defaultState = []

const reducer = handleActions(
  {
    [togglePartClosed]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : concat(state, action.payload),
    [resetClosedParts]: () => defaultState,
  },
  defaultState,
)

export { togglePartClosed, resetClosedParts, reducer }
