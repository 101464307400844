import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteUser } from '../../../Api/users'
import fetchUsers from '../../../Core/Services/fetchUsers'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { user } = modalProps()

  try {

    await deleteUser({ id: user.id })

    message.success(`Deleted "${user.name}"`)

    modalProps().onClose(true)

    dispatch(fetchUsers({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
