import { isNaN, parseInt } from 'lodash'

export default (value) => {
  
  if (value.trim() === '') return true
  
  if (value !== value.replace(/\D/g, '')) return false
  
  if (isNaN(parseInt(value))) return false
  
  return true
  
}