import React from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import CustomerContacts from './Components/CustomerContacts'
import CustomerEnquiries from './Components/CustomerEnquiries'
import CustomerModal from '../../Modals/Customer'
import CustomerMergeModal from '../../Modals/CustomerMerge'

export default class Customer extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    customerModal: PropTypes.object.isRequired,
    customerMergeModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseCustomerModal: PropTypes.func.isRequired,
    onCloseCustomerMergeModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      customerModal,
      customerMergeModal,
      onCloseCustomerModal,
      onCloseCustomerMergeModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 200 }}
        contentStyle={{ backgroundColor: 'white', paddingBottom: 60 }}
      >

        {(initialized && activeTab === 0) && <CustomerContacts />}

        {(initialized && activeTab === 1) && <CustomerEnquiries />}

        {customerModal.show && (
          <CustomerModal
            id={id}
            onClose={onCloseCustomerModal}
          />
        )}

        {customerMergeModal.show && (
          <CustomerMergeModal
            {...customerMergeModal}
            onClose={onCloseCustomerMergeModal}
          />
        )}
        
      </Screen>

    )

  }
  
}
