import { combineReducers } from 'redux'

import { reducer as manufacturer } from './State/manufacturer'
import { reducer as initialized } from './State/initialized'
import { reducer as activeTab } from './State/activeTab'
import { reducer as manufacturerModal } from './State/manufacturerModal'
import { reducer as manufacturerMergeModal } from './State/manufacturerMergeModal'

import Parts from './Components/Parts/reducer'

export default combineReducers({
  manufacturer,
  initialized,
  activeTab,
  manufacturerModal,
  manufacturerMergeModal,

  Parts,
})
