import React from 'react'
import classNames from 'classnames'
import styles from './styles.css'

const Subheader = () => (

  <div className={styles.wrapper}>

    <div className={classNames(styles.col, styles.col1)}>
      Supplier
    </div>

    <div className={classNames(styles.col, styles.col2)}>
      Qty
    </div>

    <div className={classNames(styles.col, styles.col3)}>
      Service
    </div>

    <div className={classNames(styles.col, styles.col4)}>
      Lead Time
    </div>

    <div className={classNames(styles.col, styles.col5)}>
      RoHS
    </div>

    <div className={classNames(styles.col, styles.col6)}>
      Date Code
    </div>

    <div className={classNames(styles.col, styles.col7)}>
      Reference
    </div>

    <div className={classNames(styles.col, styles.col8)}>
      Unit Cost
    </div>

    <div className={classNames(styles.col, styles.col9)}>
      Total Cost
    </div>

    <div className={classNames(styles.col, styles.col10)}>
      Landed Cost
    </div>

    <div className={classNames(styles.col, styles.col11)} />

    <div className={classNames(styles.col, styles.actions)} />

  </div>

)

export default Subheader
