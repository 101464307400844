import { createAction, handleActions } from 'redux-actions'

const setSupplierContacts = createAction('SCREENS/SUPPLIER_CONTACTS/SET_SUPPLIER_CONTACTS')
const resetSupplierContacts = createAction('SCREENS/SUPPLIER_CONTACTS/RESET_SUPPLIER_CONTACTS')

const defaultState = []

const reducer = handleActions(
  {
    [setSupplierContacts]: (state, action) => action.payload,
    [resetSupplierContacts]: () => defaultState,
  },
  defaultState,
)

export { setSupplierContacts, resetSupplierContacts, reducer }
