import { connect } from 'react-redux'
import CustomerQuoteItemsPopover from './CustomerQuoteItemsPopover'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(CustomerQuoteItemsPopover)
