import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import Screen from '../../Components/Screen'

import Controls from './Components/Controls'
import CustomerEnquiriesInPeriod from './Components/CustomerEnquiriesInPeriod'
import SalesOrdersInPeriod from './Components/SalesOrdersInPeriod'
import ConversionInPeriod from './Components/ConversionInPeriod'

const Dashboard = props => {

  const {
    componentDidMount,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])
  
  return (

    <Screen
      title='Dashboard'
      headerRight={<Controls />}
    >
      
      <Row gutter={18}>

        <Col span={12}>
          
          <CustomerEnquiriesInPeriod />

        </Col>

        <Col span={12}>
          
          <SalesOrdersInPeriod />

        </Col>

      </Row>

      <br />
      
      <Row>
          
        <ConversionInPeriod />

      </Row>

    </Screen>

  )

}

Dashboard.propTypes = {
  componentDidMount: PropTypes.func.isRequired,
}

export default Dashboard
