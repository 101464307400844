import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteSupplier } from '../../../Api/suppliers'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { supplier } = getState().screens.Supplier

    await deleteSupplier({ id: supplier.id })

    message.success(`Deleted Supplier`)

    dispatch(push(`/portal/suppliers`))

  } catch (error) {

    handleException(error)

  }

}
