import { closePartModal } from '../State/partModal'
import selectPart from '../Services/selectPart'

export default (part) => (dispatch) => {

  dispatch(closePartModal())

  if (part) {
    dispatch(selectPart(part))
  }

}
