import React from 'react'
import PropTypes from 'prop-types'
import { findPart, getParts } from '../../Api/parts'

import AutoComplete from './AutoComplete'

export default class Part extends React.PureComponent {

  fetchSuggestions = async (term) => {

    const {
      labelProp,
    } = this.props

    const response = await getParts({
      params: {
        [`filter[${labelProp}_match]`]: term,
        sort: 'internal_no',
        'page[size]': 30,
      },
    })
    
    return response.data.data

  }

  fetchSelected = async (id) => {
    
    const response = await findPart({
      id,
      params: {
        include: 'manufacturer',
      },
    })
    
    const part = response.data.data
    
    if (!part) return null
    
    return {
      id: part.id,
      internal_no: part.internal_no,
      manufacturer_no: part.manufacturer_no,
      manufacturer: {
        id: part.manufacturer.id,
        name: part.manufacturer.name,
      },
    }
    
  }

  render () {

    return (

      <AutoComplete
        keyProp="id"
        placeholder="Select Part"
        fetchSuggestions={this.fetchSuggestions}
        fetchSelected={this.fetchSelected}
        {...this.props}
      />

    )
  }
}

Part.propTypes = {
  labelProp: PropTypes.oneOf(['internal_no','manufacturer_no']),
}

Part.defaultProps = {
  labelProp: 'internal_no',
}