import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as isFetching } from './State/isFetching'
import { reducer as searchTerm } from './State/searchTerm'
import { reducer as sorting } from './State/sorting'
import { reducer as pagination } from './State/pagination'
import { reducer as selectedManufacturerId } from './State/selectedManufacturerId'

export default combineReducers({
  data,
  isFetching,
  searchTerm,
  sorting,
  pagination,
  selectedManufacturerId,
})
