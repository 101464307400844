import { openSourcedPartModal } from '../../../State/sourcedPartModal'

export default (sourcedPart) => (dispatch) => {

  dispatch(openSourcedPartModal({
    id: sourcedPart.id,
    exchangeRate: !!sourcedPart.supplier_enquiry_item_id,
  }))
  
}
