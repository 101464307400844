import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from './Components/Table'
import UserModal from '../../Modals/User'

export default class Users extends React.PureComponent {

  static propTypes = {
    userModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseUserModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([
    <Button key='new' type='primary' onClick={this.props.onClickNew}>
      New User
    </Button>,
  ])

  render() {

    const {
      userModal,
      onCloseUserModal,
    } = this.props

    return (

      <Screen
        title='Users'
        headerRight={this.renderHeaderRight()}
      >

        <Table />

        {userModal.show && (
          <UserModal
            {...userModal}
            onClose={onCloseUserModal}
          />
        )}

      </Screen>

    )

  }

}
