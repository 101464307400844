import { createSelector } from 'reselect'
import { includes } from 'lodash'

const selectFormValues = (state) => state.screens.SupplierEnquiry.Requirements.formState.values
const selectClosedItems = (state) => state.screens.SupplierEnquiry.Requirements.closedItems

const selectRows = createSelector(
  selectFormValues,
  selectClosedItems,
  (formValues, closedItems) => {
    
    if (!formValues) return []

    const rows = []
    
    rows.push({
      type: 'HEADER',
      key: 'header',
    })
    
    rows.push({
      type: 'DIVIDER',
      key: `divider-header`,
      data: {
        type: 1,
      },
    })

    if (formValues.supplier_enquiry_items.length === 0) {

      rows.push({
        type: 'EMPTY',
        key: 'empty',
      })

      rows.push({
        type: 'DIVIDER',
        key: `divider-empty`,
        data: {
          type: 1,
        },
      })

      return rows

    }

    formValues.supplier_enquiry_items.forEach((item, itemIndex) => {

      rows.push({
        type: 'SUPPLIER_ENQUIRY_ITEM',
        key: item.guid,
        data: {
          item,
          itemIndex,
        },
      })

      if (!includes(closedItems, item.guid)) {
        
        rows.push({
          type: 'DIVIDER',
          key: `divider-${itemIndex}-parts-top`,
          data: {
            type: 2,
          },
        })
        
        if (item.sourced_parts.length > 0) {
          rows.push({
            type: 'SUBHEADER',
            key: `subheader-${itemIndex}`,
          })
        }
        
        item.sourced_parts.forEach((sourcedPart, sourcedPartIndex) => {

          rows.push({
            type: 'SOURCED_PART',
            key: sourcedPart.guid,
            data: {
              item,
              itemIndex,
              sourcedPart,
              sourcedPartIndex,
            },
          })
  
        })

        rows.push({
          type: 'SOURCED_PART_FOOTER',
          key: `sourced-part-footer-${itemIndex}`,
          data: {
            item,
            itemIndex,
          },
        })
        
        rows.push({
          type: 'DIVIDER',
          key: `divider-${itemIndex}-parts-bottom`,
          data: {
            type: 3,
          },
        })
        
      } else {
        
        rows.push({
          type: 'DIVIDER',
          key: `divider-${itemIndex}`,
          data: {
            type: 1,
          },
        })
        
      }

    })

    return rows

  },
)

export default selectRows
