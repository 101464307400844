import modalProps from '../modalProps'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(initializeForm())
  
}
