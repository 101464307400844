import fetch from './fetch'
import prepareInitialValues from '../Utils/prepareInitialValues'
import { setFormInitialValues } from '../State/formState'

export default () => async (dispatch) => {

  const supplierEnquiry = await dispatch(fetch())

  const initialValues = prepareInitialValues(supplierEnquiry)

  dispatch(setFormInitialValues(initialValues))

}
