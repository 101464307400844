import { connect } from 'react-redux'
import Rows from './Rows'

import selectTableRows from './Selectors/selectTableRows'

import onChangeValue from './Handlers/onChangeValue'
import onClickDelete from './Handlers/onClickDelete'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
})

const actionCreators = {
  onChangeValue,
  onClickDelete,
}

export default connect(mapStateToProps, actionCreators)(Rows)
