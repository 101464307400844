import { createAction, handleActions } from 'redux-actions'

const setCustomerEnquiries = createAction('SCREENS/CUSTOMER_ENQUIRIES/SET_CUSTOMER_ENQUIRIES')
const resetCustomerEnquiries = createAction('SCREENS/CUSTOMER_ENQUIRIES/RESET_CUSTOMER_ENQUIRIES')

const defaultState = []

const reducer = handleActions(
  {
    [setCustomerEnquiries]: (state, action) => action.payload,
    [resetCustomerEnquiries]: () => defaultState,
  },
  defaultState,
)

export { setCustomerEnquiries, resetCustomerEnquiries, reducer }
