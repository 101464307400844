import { createAction, handleActions } from 'redux-actions'

const openSalesOrderModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SALES_ORDER/OPEN_SALES_ORDER_MODAL')
const closeSalesOrderModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SALES_ORDER/CLOSE_SALES_ORDER_MODAL')

const defaultState = {
  show: false,
  customerEnquiry: null,
}

const reducer = handleActions(
  {
    [openSalesOrderModal]: (state, { payload }) => ({
      show: true,
      customerEnquiry: payload.customerEnquiry,
    }),
    [closeSalesOrderModal]: () => defaultState,
  },
  defaultState,
)

export { openSalesOrderModal, closeSalesOrderModal, reducer }
