import React from 'react'
import PropTypes from 'prop-types'

import SalesOrder from './Components/SalesOrder'
import Header from './Components/Header'
import Divider from './Components/Divider'
import SalesOrderItem from './Components/SalesOrderItem'
import SalesOrderItemFooter from './Components/SalesOrderItemFooter'
import Empty from './Components/Empty'
import SalesOrderModal from '../../../../Modals/SalesOrder'
import SalesOrderItemModal from '../../../../Modals/SalesOrderItem'

const Components = {
  'SALES_ORDER': SalesOrder,
  'HEADER': Header,
  'DIVIDER': Divider,
  'SALES_ORDER_ITEM': SalesOrderItem,
  'SALES_ORDER_ITEM_FOOTER': SalesOrderItemFooter,
  'EMPTY': Empty,
}

export default class extends React.PureComponent {

  static propTypes = {
    rows: PropTypes.array.isRequired,
    salesOrderModal: PropTypes.object.isRequired,
    salesOrderItemModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onCloseSalesOrderModal: PropTypes.func.isRequired,
    onCloseSalesOrderItemModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render() {

    const {
      rows,
      salesOrderModal,
      salesOrderItemModal,
      onCloseSalesOrderModal,
      onCloseSalesOrderItemModal,
    } = this.props

    return (

      <div>

        {rows.map((row) => {

          const Component = Components[row.type]

          return <Component key={row.key} {...row.data} />

        })}

        {salesOrderModal.show && (
          <SalesOrderModal
            {...salesOrderModal}
            onClose={onCloseSalesOrderModal}
          />
        )}

        {salesOrderItemModal.show && (
          <SalesOrderItemModal
            {...salesOrderItemModal}
            onClose={onCloseSalesOrderItemModal}
          />
        )}

      </div>

    )

  }

}
