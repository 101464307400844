import modalProps from '../../../modalProps'
import { find, pick } from 'lodash'
import { findSupplierEnquiry } from '../../../../../Api/supplierEnquiries'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id, serviceId } = modalProps()

    const { services } = getState().core

    if (id) {

      const params = {
        include: 'supplier.currency,supplier_contact,service',
      }

      const response = await findSupplierEnquiry({ id, params })

      const supplierEnquiry = response.data.data
      
      const initialValues = {
        ...pick(supplierEnquiry, [
          'id',
          'supplier_ref',
          'exchange_rate',
          'status',
        ]),
        supplier: {
          ...pick(supplierEnquiry.supplier, [
            'id',
            'name',
            'currency',
          ]),
        },
        supplier_contact: {
          ...pick(supplierEnquiry.supplier_contact, [
            'id',
            'name',
          ]),
        },
        service: {
          ...pick(supplierEnquiry.service, [
            'id',
            'name',
          ]),
        },
      }

      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
        supplier: {},
        supplier_contact: {},
        service: {},
        exchange_rate: 1,
        status: 'Draft',
      }

      if (serviceId) {
        initialValues.service = {
          ...pick(find(services, row => row.id === serviceId), [
            'id',
            'name',
          ]),
        }
      }

      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)

  }

}
