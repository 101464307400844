export default (values) => () => {

  const errors = {}

  if (!values.part.id) errors.part = 'Part is required'

  if (!values.quantity) errors.quantity = 'Qty is required'

  if (!values.unit_price) errors.unit_price = 'Unit cost is required'

  if (!values.service || !values.service.id) errors.service = 'Service is required'
  
  return errors

}
