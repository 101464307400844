import modalProps from '../modalProps'
import { deleteImport } from '../../../Api/imports'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  const { id, onClose } = modalProps()

  try {

    await deleteImport({ id })

    onClose(true)

  } catch (error) {

    handleException(error)

  }

}
