import { combineReducers } from 'redux'

import { reducer as supplierEnquiries } from './State/supplierEnquiries'
import { reducer as supplierEnquiryModal } from './State/supplierEnquiryModal'

import Header from './Components/Header/reducer'

export default combineReducers({
  supplierEnquiries,
  supplierEnquiryModal,
  Header,
})
