import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction('SCREENS/USERS/TABLE/SET_FILTERS')
const setFilter = createAction('SCREENS/USERS/TABLE/SET_FILTER')
const resetFilters = createAction('SCREENS/USERS/TABLE/RESET_FILTERS')

const defaultState = {
  departmentKey: undefined,
  status: 'active',
}

const reducer = handleActions(
  {
    [setFilters]: (state, { payload }) => ({ ...state, ...payload }),
    [setFilter]: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    [resetFilters]: () => ({ ...defaultState }),
  },
  defaultState,
)

export { setFilters, setFilter, resetFilters, reducer }
