import { resetSearchTerm } from '../State/searchTerm'
import { resetSorting } from '../State/sorting'
import { resetParts } from '../State/parts'

export default () => async (dispatch) => {

  dispatch(resetSearchTerm())
  dispatch(resetSorting())
  dispatch(resetParts())
  
}
