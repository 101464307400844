import React from 'react'
import PropTypes from 'prop-types'
import { Module } from '../../../../../../../packages/sota'
import { Input } from 'antd'

const Header = (props) => {

  const {
    searchTerm,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <Input.Search
      value={searchTerm}
      onChange={onChangeSearch}
      onSearch={onSearch}
      placeholder="Search"
      style={{ width: 200 }}
    />

  )

  return (

    <Module.Header
      renderLeft={renderLeft}
      style={{ padding: 0 }}
    />

  )

}

Header.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header
