import { combineReducers } from 'redux'

import { reducer as searchTerm } from './State/searchTerm'
import { reducer as sorting } from './State/sorting'
import { reducer as fetching } from './State/fetching'
import { reducer as suppliers } from './State/suppliers'
import { reducer as supplierModal } from './State/supplierModal'

export default combineReducers({
  searchTerm,
  sorting,
  fetching,
  suppliers,
  supplierModal,
})
