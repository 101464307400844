import { createAction, handleActions } from 'redux-actions'

const openFindPartModal = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/OPEN_FIND_PART_MODAL')
const closeFindPartModal = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/CLOSE_FIND_PART_MODAL')

const defaultState = {
  show: false,
  customerId: null,
  customerEnquiryItem: null,
  index: null,
}

const reducer = handleActions(
  {
    [openFindPartModal]: (state, { payload }) => ({ ...state, show: true, ...payload }),
    [closeFindPartModal]: () => defaultState,
  },
  defaultState,
)

export { openFindPartModal, closeFindPartModal, reducer }
