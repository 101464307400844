import React from 'react'
import classNames from 'classnames'
import styles from './styles.css'

const Header = () => (

  <div className={styles.wrapper}>

    <div className={classNames(styles.col, styles.partNos)}>
      Manufacturer No. / Customer No.
    </div>

    <div className={classNames(styles.col, styles.manufacturer)}>
      Manufacturer
    </div>

    <div className={classNames(styles.col, styles.partMatch)} />

    <div className={classNames(styles.col, styles.quantity)}>
      Qty
    </div>

    <div className={classNames(styles.col, styles.service)}>
      Service
    </div>

    <div className={classNames(styles.col, styles.suppliers)}>
      Suppliers
    </div>

  </div>

)

export default Header
