import modalProps from '../modalProps'
import fetchCustomerEnquiries from '../../../Core/Services/fetchCustomerEnquiries'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchCustomerEnquiries())
  
}
