import Part from './Part'
import Customer from './Customer'
import Supplier from './Supplier'
import Manufacturer from './Manufacturer'

export default {
  Part,
  Customer,
  Supplier,
  Manufacturer,
}
