import { connect } from 'react-redux'
import Request from './Request'

import selectRows from './Selectors/selectRows'
import selectUseSupplierModalProps from './Selectors/selectUseSupplierModalProps'

import componentDidMount from './Handlers/componentDidMount'
import onFormChange from './Handlers/onFormChange'
import onSelectPart from './Handlers/onSelectPart'
import onUseSupplier from './Handlers/onUseSupplier'
import onCloseManufacturerModal from './Handlers/onCloseManufacturerModal'
import onCloseFindPartModal from './Handlers/onCloseFindPartModal'
import onCloseUseSupplierModal from './Handlers/onCloseUseSupplierModal'
import onCloseCustomerEnquiryImportModal from './Handlers/onCloseCustomerEnquiryImportModal'

const mapStateToProps = (state) => ({
  initialValues: state.screens.CustomerEnquiry.Request.formState.initialValues,
  rows: selectRows(state),
  manufacturerModal: state.screens.CustomerEnquiry.Request.manufacturerModal,
  findPartModal: state.screens.CustomerEnquiry.Request.findPartModal,
  useSupplierModal: state.screens.CustomerEnquiry.Request.useSupplierModal,
  useSupplierModalProps: selectUseSupplierModalProps(state),
  customerEnquiryImportModal: state.screens.CustomerEnquiry.Request.customerEnquiryImportModal,
})

const actionCreators = {
  componentDidMount,
  onFormChange,
  onSelectPart,
  onUseSupplier,
  onCloseManufacturerModal,
  onCloseFindPartModal,
  onCloseUseSupplierModal,
  onCloseCustomerEnquiryImportModal,
}

export default connect(mapStateToProps, actionCreators)(Request)
