import { omit } from 'lodash'
import modalProps from '../../../modalProps'
import { createSourcedPart, updateSourcedPart } from '../../../../../Api/sourcedParts'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id, customerEnquiryId, supplierEnquiryItemId } = modalProps()
    
    const data = {
      ...omit(values, ['part', 'supplier', 'service']),
      customer_enquiry_id: customerEnquiryId,
      supplier_enquiry_item_id: supplierEnquiryItemId,
      part_id: values.part.id,
      supplier_id: values.supplier.id,
      landing_costs: values.unit_cost ? values.landing_costs : null,
      service_id: values.service.id,
      lead_time: values.lead_time || null,
      rohs: values.rohs !== undefined ? values.rohs : null,
      date_code: values.date_code || null,
      reference: values.reference || null,
      date: values.date ? values.date.format('YYYY-MM-DD') : null,
      notes: values.notes || null,
      guid: values.guid,
    }

    let response

    if (id) {

      data.supplier_enquiry_item_id = values.supplier_enquiry_item_id

      response = await updateSourcedPart({ id, data })

    } else {

      response = await createSourcedPart({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
