import { getCustomerEnquiries } from '../../../../../Api/customerEnquiries'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { activeTab, userFilter, searchTerm, sorting, pagination } = getState().screens.CustomerEnquiries.Table

    const params = {
      search: searchTerm,
    }

    if (activeTab !== 'All') params['filter[status]'] = activeTab

    if (userFilter) params['filter[manager_id]'] = userFilter

    params.include = [
      'customer.currency',
      'customer_contact',
      'manager',
    ].join()
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-received_on'

    dispatch(startFetching())

    const response = await getCustomerEnquiries({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}