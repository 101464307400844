import { createSelector } from 'reselect'

const selectSalesOrderItems = state => state.screens.CustomerEnquiry.SalesOrder.salesOrderItems

export default createSelector(
  selectSalesOrderItems,
  (salesOrderItems) => {

    let totalPriceGbp = 0
    let margin = 0
    
    salesOrderItems.forEach(salesOrderItem => {
      totalPriceGbp += Number(salesOrderItem.total_price_gbp)
      margin += Number(salesOrderItem.margin)
    })

    return { totalPriceGbp, margin }

  },
)
