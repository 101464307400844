import { createAction, handleActions } from 'redux-actions'

const setCustomerEnquiryItemOptions = createAction('MODALS/QUOTE_SOURCED_PART/FORM/SET_CUSTOMER_ENQUIRY_ITEM_OPTIONS')
const resetCustomerEnquiryItemOptions = createAction('MODALS/QUOTE_SOURCED_PART/FORM/RESET_CUSTOMER_ENQUIRY_ITEM_OPTIONS')

const defaultState = []

const reducer = handleActions(
  {
    [setCustomerEnquiryItemOptions]: (state, action) => action.payload,
    [resetCustomerEnquiryItemOptions]: () => defaultState,
  },
  defaultState,
)

export { setCustomerEnquiryItemOptions, resetCustomerEnquiryItemOptions, reducer }
