import { createSelector } from 'reselect'
import { orderBy, get } from 'lodash'

const selectSuppliers = state => state.modals.UseSupplier.Finder.suppliers
const selectSorting = state => state.modals.UseSupplier.Finder.sorting

export default createSelector(
  selectSuppliers,
  selectSorting,
  (suppliers, { key, direction }) => {

    if (!key) return suppliers

    switch (key) {
      case 'sourced_parts':
        return orderBy(
          suppliers,
          [
            supplier => get(supplier, 'sourced_parts', []).length,
            supplier => supplier.name.toLowerCase(),
          ],
          [
            direction,
            'asc',
          ],
        )
      default:
        return orderBy(suppliers, supplier => supplier[key].toLowerCase(), direction)
    }

  },
)
