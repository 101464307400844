import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable } from 'antd'
import moment from 'moment'
import styles from "./Table.css"

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
    onClickRow,
    onChangeStatus,
  } = props

  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      className={styles.table}
      onChange={onChange}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
    >

      <AntTable.Column
        key="reference"
        title="Ref."
        render={(text, record) => (
          <strong>
            {record.reference}
          </strong>
        )}
      />

      <AntTable.Column
        key="supplier.name"
        title="Supplier"
        render={(text, record) => `${record.supplier.name} (${record.supplier.currency.symbol})`}
      />

      <AntTable.Column
        key="customer_enquiry.customer.name"
        title="Customer"
        render={(text, record) => `${record.customer_enquiry.customer.name} (${record.customer_enquiry.customer.currency.symbol})`}
      />

      <AntTable.Column
        key="created_at"
        title="Started"
        render={(text, record) => moment(record.created_at).calendar(null, {
          sameDay: '[Today], h:mm a',
          nextDay: '[Tomorrow], h:mm a',
          lastDay: '[Yesterday], h:mm a',
          nextWeek: 'D MMM YYYY [at] h:mm a',
          lastWeek: 'D MMM YYYY [at] h:mm a',
          sameElse: 'D MMM YYYY [at] h:mm a',
        })}
      />

      <AntTable.Column
        key="customer_enquiry.manager.name"
        title="Manager"
        render={(text, record) => record.customer_enquiry.manager.name}
      />

      <AntTable.Column
        key="service.name"
        title="Service"
        render={(text, record) => record.service.name}
      />

      <AntTable.Column
        key='status'
        title="Status"
        render={(text, record) => (
          <select value={record.status} onClick={(e) => e.stopPropagation()} onChange={(event) => onChangeStatus(record, event)}>
            <option value='Draft'>Draft</option>
            <option value='Sent'>Sent</option>
            <option value='Responded'>Responded</option>
          </select>
        )}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}

export default Table
