import { connect } from 'react-redux'
import ExchangeRates from './ExchangeRates'

import componentDidMount from './Handlers/componentDidMount'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  data: state.components.ExchangeRates.data,
})

const actionCreators = {
  componentDidMount,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(ExchangeRates)
