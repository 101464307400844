import { openFindPartModal } from '../../../State/findPartModal'

export default (index) => (dispatch, getState) => {

  const state = getState()

  const { customer_id: customerId } = state.screens.CustomerEnquiry.customerEnquiry

  const { formState } = state.screens.CustomerEnquiry.Request

  const customerEnquiryItem = formState.values.customer_enquiry_items[index]

  dispatch(openFindPartModal({ customerId, customerEnquiryItem, index }))

}
