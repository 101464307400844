import formService from '../formService'
import shouldAddRow from '../Utils/shouldAddRow'
import addRow from './addRow'
import triggerSave from './triggerSave'

export default (index) => (dispatch, getState) => {

  formService().mutators.remove('customer_enquiry_items', index)

  const { values } = getState().screens.CustomerEnquiry.Request.formState

  if (shouldAddRow(values)) {
    dispatch(addRow())
  } else {
    dispatch(triggerSave(false))
  }

}
