import { connect } from 'react-redux'
import PartMerge from './PartMerge'

import componentDidMount from './Handlers/componentDidMount'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  submitting: state.modals.PartMerge.Form.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(PartMerge)
