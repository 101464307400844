import React from 'react'
import PropTypes from 'prop-types'
import { LinkButton } from '../../../../../../../packages/sota'
import styles from './styles.css'

const SourcedPartFooter = (props) => {

  const {
    customerEnquiryItem,
    onClickAdd,
  } = props
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.actions}>
        
        <LinkButton icon="plus" type="primary" onClick={() => onClickAdd(customerEnquiryItem)}>
          Add Quote Item
        </LinkButton>
        
      </div>
      
    </div>

  )

}

SourcedPartFooter.propTypes = {
  customerEnquiryItem: PropTypes.object.isRequired,
  onClickAdd: PropTypes.func.isRequired,
}

export default React.memo(SourcedPartFooter)
