import { createAction, handleActions } from 'redux-actions'

const openSupplierMergeModal = createAction('SCREENS/SUPPLIER/OPEN_SUPPLIER_MERGE_MODAL')
const closeSupplierMergeModal = createAction('SCREENS/SUPPLIER/CLOSE_SUPPLIER_MERGE_MODAL')

const defaultState = {
  show: false,
  supplier: null,
}

const reducer = handleActions(
  {
    [openSupplierMergeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      supplier: action.payload.supplier,
    }),
    [closeSupplierMergeModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSupplierMergeModal, closeSupplierMergeModal, reducer }
