import { omit } from 'lodash'
import modalProps from '../../../modalProps'
import { createSupplierEnquiry, updateSupplierEnquiry } from '../../../../../Api/supplierEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id, customerEnquiryId, items } = modalProps()

    const data = {
      ...omit(values, ['supplier', 'supplier_contact', 'service']),
      supplier_id: values.supplier.id,
      supplier_contact_id: values.supplier_contact.id,
      service_id: values.service.id,
      supplier_ref: values.supplier_ref || null,
    }
    
    let response

    if (id) {

      response = await updateSupplierEnquiry({ id, data })

    } else {
      
      data.customer_enquiry_id = customerEnquiryId || undefined
      
      data.supplier_enquiry_items = items || undefined

      response = await createSupplierEnquiry({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)

    throw error

  }

}
