import { connect } from 'react-redux'
import User from './User'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'
import onAction from './Handlers/onAction'
import onCloseResetPasswordModal from './Handlers/onCloseResetPasswordModal'

const mapStateToProps = (state) => ({
  submitting: state.modals.User.Form.formState.submitting,
  resetPasswordModal: state.modals.User.resetPasswordModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onSubmit,
  onDelete,
  onAction,
  onCloseResetPasswordModal,
}

export default connect(mapStateToProps, actionCreators)(User)
