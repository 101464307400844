import { connect } from 'react-redux'
import Manufacturers from './Manufacturers'

import selectTableRows from './Selectors/selectTableRows'

import onChangeName from './Handlers/onChangeName'
import onClickCreate from './Handlers/onClickCreate'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
})

const actionCreators = {
  onChangeName,
  onClickCreate,
}

export default connect(mapStateToProps, actionCreators)(Manufacturers)
