import moment from 'moment'
import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => async (dispatch) => {

  const { customerEnquiry } = modalProps()

  dispatch(setFormInitialValues({
    ...customerEnquiry,
    sales_order_date: customerEnquiry.sales_order_date ? moment(customerEnquiry.sales_order_date) : null,
  }))

}
