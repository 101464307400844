import { createAction, handleActions } from 'redux-actions'

const setUserData = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/SET_USER_DATA')
const setDepartmentData = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/SET_DEPARTMENT_DATA')
const setServiceData = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/SET_SERVICE_DATA')
const setCustomerData = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/SET_CUSTOMER_DATA')
const resetData = createAction('SCREENS/DASHBOARD/SALES_ORDERS_IN_PERIOD/RESET_DATA')

const defaultState = {
  userData: [],
  departmentData: [],
  serviceData: [],
  customerData: [],
}

const reducer = handleActions(
  {
    [setUserData]: (state, { payload }) => ({ ...state, userData: payload }),
    [setDepartmentData]: (state, { payload }) => ({ ...state, departmentData: payload }),
    [setServiceData]: (state, { payload }) => ({ ...state, serviceData: payload }),
    [setCustomerData]: (state, { payload }) => ({ ...state, customerData: payload }),
    [resetData]: () => defaultState,
  },
  defaultState,
)

export {
  setUserData,
  setDepartmentData,
  setServiceData,
  setCustomerData,
  resetData,
  reducer,
}
