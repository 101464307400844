import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Popover, Button } from 'antd'

import DateRangeSelect from '../DateRangeSelect'
import UserSelect from '../UserSelect'
import DepartmentSelect from '../DepartmentSelect'
import ServiceSelect from '../ServiceSelect'
import CustomerSelect from '../CustomerSelect'
import ControlSpacer from '../ControlSpacer'
import FilterSpacer from '../FilterSpacer'

const Controls = props => {

  const {
    controls,
    onChangeControl,
  } = props

  const customDateRangeLabel = 'Custom Range'

  const filters = (

    <div style={{ width: 280 }}>

      <h4>Date Range</h4>
      <DatePicker.RangePicker
        size='small'
        format='DD/MM/YYYY'
        allowClear={false}
        value={controls.dateRange}
        onChange={values => {
          onChangeControl('dateRangeSelect', customDateRangeLabel)
          onChangeControl('dateRange', values)
        }}
        style={{ width: '100%' }}
      />

      <FilterSpacer />

      <h4>Manager</h4>
      <UserSelect width='100%' value={controls.userIds} onChange={values => onChangeControl('userIds', values)} />

      <FilterSpacer />

      <h4>Department</h4>
      <DepartmentSelect width='100%' value={controls.departmentKeys} onChange={values => onChangeControl('departmentKeys', values)} />

      <FilterSpacer />

      <h4>Service</h4>
      <ServiceSelect width='100%' value={controls.serviceIds} onChange={values => onChangeControl('serviceIds', values)} />

      <FilterSpacer />

      <h4>Customer</h4>
      <CustomerSelect width='100%' value={controls.customerIds} onChange={values => onChangeControl('customerIds', values)} />

    </div>

  )

  const getDateRange = () => {
    if (controls.dateRange.length !== 2) return ''
    return controls.dateRange.map(date => date.format('Do MMM YYYY')).join(' - ')
  }

  const isFiltered = () => {
    if (controls.dateRangeSelect === customDateRangeLabel) return true
    if (controls.userIds.length) return true
    if (controls.departmentKeys.length) return true
    if (controls.serviceIds.length) return true
    if (controls.customerIds.length) return true
    return false
  }
  
  return (
    
    <React.Fragment>

      <div>{getDateRange()}</div>

      <ControlSpacer />

      <DateRangeSelect
        width={160}
        value={controls.dateRangeSelect}
        onChange={values => {
          const [dateRangeSelect, dateRange] = values
          onChangeControl('dateRangeSelect', dateRangeSelect)
          onChangeControl('dateRange', dateRange)
        }}
      />

      <ControlSpacer />

      <Popover
        trigger={['click']}
        placement='bottom'
        content={filters}
      >
        <Button type={isFiltered() ? 'primary' : 'default'} icon='filter' size='small' />
      </Popover>

    </React.Fragment>

  )

}

Controls.propTypes = {
  controls: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func.isRequired,
}

export default Controls
