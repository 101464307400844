import { closeSupplierModal } from '../State/supplierModal'
import formService from '../formService'

export default (supplier) => (dispatch) => {

  dispatch(closeSupplierModal())

  if (supplier) {
    formService().change('supplier', {
      id: supplier.id,
      name: supplier.name,
      currency: supplier.currency,
    })
  }

}
