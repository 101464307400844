import { combineReducers } from 'redux'

import { reducer as sorting } from './State/sorting'
import { reducer as customerContacts } from './State/customerContacts'
import { reducer as customerContactModal } from './State/customerContactModal'

export default combineReducers({
  sorting,
  customerContacts,
  customerContactModal,
})
