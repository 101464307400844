import { message } from 'antd'
import { createExchangeRate } from '../../../Api/exchangeRates'
import { setData } from '../State/data'
import { handleException } from '../../../../packages/sota'

export default (values) => async (dispatch) => {

  const data = {
    eur: values.eur,
    usd: values.usd,
  }

  try {

    const response = await createExchangeRate({ data })

    dispatch(setData(response.data.data))

    message.success('Exchange Rates Updated')

  } catch (error) {

    handleException(error)

    throw error

  }

}
