import { closeCustomerContactModal } from '../State/customerContactModal'
import fetchCustomerContactsTable from '../Services/fetch'

export default (customerContact) => (dispatch) => {

  dispatch(closeCustomerContactModal())

  if (customerContact) {
    dispatch(fetchCustomerContactsTable())
  }

}
