import { connect } from 'react-redux'
import Actions from './Actions'

import onClickNew from './Handlers/onClickNew'
import onClickCustomerEnquiryImport from './Handlers/onClickCustomerEnquiryImport'
import onGenerateSupplierEnquiries from './Handlers/onGenerateSupplierEnquiries'

const mapStateToProps = (state) => ({
  customerEnquiry: state.screens.CustomerEnquiry.customerEnquiry,
})

const actionCreators = {
  onClickNew,
  onClickCustomerEnquiryImport,
  onGenerateSupplierEnquiries,
}

export default connect(mapStateToProps, actionCreators)(Actions)
