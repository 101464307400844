import { createAction, handleActions } from 'redux-actions'

const setFetching = createAction('MODALS/USE_SUPPLIER/SET_FETCHING')
const resetFetching = createAction('MODALS/USE_SUPPLIER/RESET_FETCHING')

const defaultState = false

const reducer = handleActions(
  {
    [setFetching]: (state, action) => action.payload,
    [resetFetching]: () => defaultState,
  },
  defaultState,
)

export { setFetching, resetFetching, reducer }
