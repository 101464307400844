import { createAction, handleActions } from 'redux-actions'

const openManufacturerModal = createAction('MODALS/PART/OPEN_MANUFACTURER_MODAL')
const closeManufacturerModal = createAction('MODALS/PART/CLOSE_MANUFACTURER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openManufacturerModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeManufacturerModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openManufacturerModal, closeManufacturerModal, reducer }
