import { createAction, handleActions } from 'redux-actions'

const setFields = createAction('MODALS/MOVE_SOURCED_PART/SET_FIELDS')
const setField = createAction('MODALS/MOVE_SOURCED_PART/SET_FIELD')
const resetFields = createAction('MODALS/MOVE_SOURCED_PART/RESET_FIELDS')

const defaultState = {
  customerEnquiryId: null,
}

const reducer = handleActions(
  {
    [setFields]: (_, { payload }) => payload,
    [setField]: (state, { payload }) => ({
      ...state,
      [payload.field]: payload.value,
    }),
    [resetFields]: () => defaultState,
  },
  defaultState,
)

export { setFields, setField, resetFields, reducer }
