import { closeCustomerModal } from '../State/customerModal'
import formService from '../formService'

export default (customer) => (dispatch) => {

  dispatch(closeCustomerModal())

  if (customer) {
    formService().change('customer', {
      id: customer.id,
      name: customer.name,
      currency: customer.currency,
    })
  }

}
