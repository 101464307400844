import screenProps from '../../../screenProps'
import { getCustomerEnquiryItems } from '../../../../../Api/customerEnquiryItems'
import { handleException } from '../../../../../../packages/sota'

export default () => async () => {

  const { id } = screenProps().match.params

  try {

    const response = await getCustomerEnquiryItems({
      params: {
        'filter[customer_enquiry_id]': id,
        sort: 'order_no',
        include: [
          'manufacturer',
          'part',
          'suppliers.currency',
        ].join(','),
      },
    })

    return response.data.data

  } catch (error) {

    handleException(error)

    return null

  }

}
