import { combineReducers } from 'redux'

import { reducer as activeTab } from './State/activeTab'
import { reducer as search } from './State/search'
import { reducer as data } from './State/data'
import { reducer as creatingAll } from './State/creatingAll'
import { reducer as running } from './State/running'
import { reducer as manufacturerModal } from './State/manufacturerModal'
import { reducer as partModal } from './State/partModal'

export default combineReducers({
  activeTab,
  search,
  data,
  creatingAll,
  running,
  manufacturerModal,
  partModal,
})
