import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.css'

const Divider = (props) => (

  <div
    className={classNames({
      [styles.divider1]: props.type === 1,
      [styles.divider2]: props.type === 2,
      [styles.divider3]: props.type === 3,
    })}
  />

)

Divider.propTypes = {
  type: PropTypes.number,
}

Divider.defaultProps = {
  type: 1,
}

export default Divider
