import { message } from 'antd'
import modalProps from '../modalProps'
import { moveSourcedPart } from '../../../Api/sourcedParts'
import { setSubmitting } from '../State/submitting'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { id, onClose } = modalProps()

  const { fields } = getState().modals.MoveSourcedPart

  const data = {
    customer_enquiry_id: fields.customerEnquiryId,
  }

  dispatch(setSubmitting(true))

  try {

    await moveSourcedPart({ id, data })

    message.success('Moved Sourced Part')

    onClose(true)

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setSubmitting(false))

  }

}
