import { openCustomerQuoteItemModal } from '../../../State/customerQuoteItemModal'

export default (customerQuoteItem) => (dispatch, getState) => {

  const { customerEnquiry } = getState().screens.CustomerEnquiry

  dispatch(openCustomerQuoteItemModal({
    id: customerQuoteItem.id,
    customerEnquiryItemId: customerQuoteItem.customer_enquiry_item_id,
    customerEnquiry,
  }))
  
}
