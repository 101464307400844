import fetchTableData from '../Components/Table/Services/fetch'
import fetchManufacturers from '../../../Core/Services/fetchManufacturers'

export default () => (dispatch) => {

  dispatch(fetchManufacturers())

  dispatch(fetchTableData())

}
