export default (values) => () => {

  const errors = {}

  if (!values.supplier || !values.supplier.id) errors.supplier = 'Supplier is required'

  if (!values.service || !values.service.id) errors.service = 'Service is required'

  if (!values.exchange_rate) errors.exchange_rate = 'Exchange rate is required'

  return errors

}

