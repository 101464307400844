import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openSourcedPartModal = createAction('SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/OPEN_SOURCED_PART_MODAL')
const closeSourcedPartModal = createAction('SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/CLOSE_SOURCED_PART_MODAL')

const defaultState = {
  show: false,
  id: null,
  supplierEnquiryItemId: null,
  part: null,
  supplier: null,
  service: null,
  exchangeRate: null,
}

const reducer = handleActions(
  {
    [openSourcedPartModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id', null),
      supplierEnquiryItemId: get(action, 'payload.supplierEnquiryItemId', null),
      part: get(action, 'payload.part', null),
      supplier: get(action, 'payload.supplier', null),
      service: get(action, 'payload.service', null),
      exchangeRate: get(action, 'payload.exchangeRate', null),
    }),
    [closeSourcedPartModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSourcedPartModal, closeSourcedPartModal, reducer }
