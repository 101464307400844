import axios from './axios'

export const createCustomerEnquiry = ({ data }) => axios.post(`customer_enquiries`, data)
export const deleteCustomerEnquiry = ({ id }) => axios.delete(`customer_enquiries/${id}`)
export const findCustomerEnquiry = ({ params, id } = {}) => axios.get(`customer_enquiries/${id}`, { params })
export const getCustomerEnquiries = ({ params } = {}) => axios.get(`customer_enquiries`, { params })
export const updateCustomerEnquiry = ({ id, data }) => axios.put(`customer_enquiries/${id}`, data)
export const handleCustomerEnquiryItems = ({ id, data }) => axios.put(`customer_enquiries/${id}/items`, data)
export const updateCustomerEnquiryStatus = ({ id, data }) => axios.put(`customer_enquiries/${id}/status`, data)
export const getCustomerEnquiryStatuses = ({ params } = {}) => axios.get(`customer_enquiries/statuses`, { params })
export const generateSupplierEnquiries = ({ id } = {}) => axios.post(`customer_enquiries/${id}/supplier_enquiries`)
export const deleteCustomerEnquirySalesOrder = ({ id, params } = {}) => axios.delete(`customer_enquiries/${id}/sales_order`, { params })
export const createCustomerEnquiryImport = ({ id, data }) => axios.post(`customer_enquiries/${id}/import`, data)
export const runCustomerEnquiryImport = ({ id }) => axios.put(`customer_enquiries/${id}/import`)