import { combineReducers } from 'redux'

import { reducer as manufacturerModal } from './State/manufacturerModal'

import Table from './Components/Table/reducer'

export default combineReducers({
  manufacturerModal,

  Table,
})
