import { createAction, handleActions } from 'redux-actions'

const openSalesOrderItemModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SALES_ORDER/OPEN_SALES_ORDER_ITEM_MODAL')
const closeSalesOrderItemModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SALES_ORDER/CLOSE_SALES_ORDER_ITEM_MODAL')

const defaultState = {
  show: false,
  id: null,
  customerEnquiry: null,
}

const reducer = handleActions(
  {
    [openSalesOrderItemModal]: (state, { payload }) => ({
      show: true,
      id: payload.id,
      customerEnquiry: payload.customerEnquiry,
    }),
    [closeSalesOrderItemModal]: () => defaultState,
  },
  defaultState,
)

export { openSalesOrderItemModal, closeSalesOrderItemModal, reducer }
