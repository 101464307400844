import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openSourcedPartModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/OPEN_SOURCED_PART_MODAL')
const closeSourcedPartModal = createAction('SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/CLOSE_SOURCED_PART_MODAL')

const defaultState = {
  show: false,
  id: null,
  part: null,
  exchangeRate: null,
}

const reducer = handleActions(
  {
    [openSourcedPartModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id', null),
      part: get(action, 'payload.part', null),
      exchangeRate: get(action, 'payload.exchangeRate', null),
    }),
    [closeSourcedPartModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSourcedPartModal, closeSourcedPartModal, reducer }
