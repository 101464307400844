import React from 'react'
import classNames from 'classnames'
import styles from './styles.css'

const Header = () => (

  <div className={styles.wrapper}>

    <div className={classNames(styles.col, styles.col1)}>
      Manufacturer
    </div>

    <div className={classNames(styles.col, styles.col2)}>
      Manufacturer PN
    </div>

    <div className={classNames(styles.col, styles.col3)}>
      Internal PN
    </div>

    <div className={classNames(styles.col, styles.col4)}>
      Customer PN(s)
    </div>
    
    <div className={classNames(styles.col, styles.restCol)} />

  </div>

)

export default Header
