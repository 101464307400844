import { openSalesOrderItemModal } from '../../../State/salesOrderItemModal'

export default (salesOrderItem) => (dispatch, getState) => {

  const { customerEnquiry } = getState().screens.CustomerEnquiry

  dispatch(openSalesOrderItemModal({
    id: salesOrderItem.id,
    customerEnquiry,
  }))
  
}
