import React from 'react'
import classNames from 'classnames'
import styles from './styles.css'

const Subheader = () => (

  <div className={styles.wrapper}>

    <div className={classNames(styles.col, styles.col1)}>
      Manufacturer PN
    </div>

    <div className={classNames(styles.col, styles.col2)}>
      Manufacturer
    </div>

    <div className={classNames(styles.col, styles.col3)}>
      Qty
    </div>

    <div className={classNames(styles.col, styles.col4)}>
      Service
    </div>

    <div className={classNames(styles.col, styles.col5)}>
      Lead Time
    </div>

    <div className={classNames(styles.col, styles.col6)}>
      RoHS
    </div>

    <div className={classNames(styles.col, styles.col7)}>
      Date Code
    </div>

    <div className={classNames(styles.col, styles.col8)}>
      Sale Price
    </div>

    <div className={classNames(styles.col, styles.col9)}>
      Total Price
    </div>

    <div className={classNames(styles.col, styles.col10)}>
      Margin
    </div>

    <div className={classNames(styles.col, styles.col11)} />

    <div className={classNames(styles.col, styles.col12)} />

  </div>

)

export default Subheader
