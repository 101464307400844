import { closeCustomerContactModal } from '../State/customerContactModal'
import formService from '../formService'

export default (customerContact) => (dispatch) => {

  dispatch(closeCustomerContactModal())

  if (customerContact) {
    formService().change('customer_contact', {
      id: customerContact.id,
      name: customerContact.name,
    })
  }

}
