import { createAction, handleActions } from 'redux-actions'

const openResetPasswordModal = createAction('MODALS/USER/OPEN_RESET_PASSWORD_MODAL')
const closeResetPasswordModal = createAction('MODALS/USER/CLOSE_RESET_PASSWORD_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openResetPasswordModal]: (state) => ({ ...state, show: true }),
    [closeResetPasswordModal]: () => defaultState,
  },
  defaultState,
)

export { openResetPasswordModal, closeResetPasswordModal, reducer }
