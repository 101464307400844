import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Radio, Input, Button, Badge } from 'antd'
import styles from './styles.css'

import Rows from './Components/Rows'
import Manufacturers from './Components/Manufacturers'
import Parts from './Components/Parts'
import ManufacturerModal from '../Manufacturer'
import PartModal from '../Part'

const Spacer = () => <div style={{ width: 12 }} />

export default class CustomerEnquiryImport extends React.PureComponent {

  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    manufacturers: PropTypes.array.isRequired,
    parts: PropTypes.array.isRequired,
    creatingAll: PropTypes.bool.isRequired,
    running: PropTypes.bool.isRequired,
    manufacturerModal: PropTypes.object.isRequired,
    partModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    onClickCreateAll: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickRun: PropTypes.func.isRequired,
    onCloseManufacturerModal: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      activeTab,
      search,
      manufacturers,
      parts,
      creatingAll,
      running,
      manufacturerModal,
      partModal,
      onChangeTab,
      onChangeSearch,
      onClickCreateAll,
      onClickDelete,
      onClickRun,
      onCloseManufacturerModal,
      onClosePartModal,
      onClose,
    } = this.props

    return (

      <Modal
        visible
        title='Customer Enquiry Import'
        width={1200}
        footer={false}
        maskClosable={false}
        onCancel={() => onClose()}
      >

        <div className={styles.header}>

          <div className={styles.left}>

            <Radio.Group value={activeTab} onChange={e => onChangeTab(e.target.value)}>
              <Radio.Button value='rows'>Rows</Radio.Button>
              <Radio.Button value='manufacturers'>
                <Badge count={manufacturers.length} offset={[10, -5]}>
                  Manufacturers
                </Badge>
              </Radio.Button>
              <Radio.Button value='parts'>
                <Badge count={parts.length} offset={[10, -5]}>
                  Parts
                </Badge>
              </Radio.Button>
            </Radio.Group>

            {activeTab === 'rows' && (

              <React.Fragment>

                <Spacer />

                <Input.Search
                  value={search}
                  onChange={e => onChangeSearch(e.target.value)}
                  placeholder='Search...'
                  style={{ width: 200 }}
                />

              </React.Fragment>

            )}

          </div>

          <div className={styles.right}>

            {activeTab === 'parts' && (

              <Button
                icon='plus'
                loading={creatingAll}
                onClick={onClickCreateAll}
              >
                Create All
              </Button>

            )}

            <Spacer />

            <Button
              type='danger'
              icon='delete'
              onClick={onClickDelete}
            >
              Delete
            </Button>

            <Spacer />

            <Button
              type='primary'
              icon='caret-right'
              loading={running}
              onClick={onClickRun}
            >
              Run
            </Button>

          </div>

        </div>

        {activeTab === 'rows' && <Rows />}

        {activeTab === 'manufacturers' && <Manufacturers />}

        {activeTab === 'parts' && <Parts />}

        {manufacturerModal.show && (
          <ManufacturerModal
            {...manufacturerModal}
            onClose={onCloseManufacturerModal}
          />
        )}

        {partModal.show && (
          <PartModal
            {...partModal}
            onClose={onClosePartModal}
          />
        )}

      </Modal>

    )

  }

}
