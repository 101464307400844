import { connect } from 'react-redux'
import Component from './Component'

import onChangeFormState from './Handlers/onChangeFormState'
import onChangeName from './Handlers/onChangeName'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  initialValues: state.modals.Supplier.Form.formState.initialValues,
  matches: state.modals.Supplier.Form.matches,
})

const actionCreators = {
  onChangeFormState,
  onChangeName,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Component)
