import modalProps from '../../../modalProps'
import { getParts } from '../../../../../Api/parts'
import { setFetching } from '../State/fetching'
import { setParts } from '../State/parts'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { customerId, customerEnquiryItem } = modalProps()

  const { searchTerm } = getState().modals.FindPart.Finder

  const params = {
    'filter[include_customer_quote_items]': customerId,
    include: 'manufacturer',
  }

  if (searchTerm) {
    params['filter[search_with_customer_no]'] = `${customerId},${searchTerm}`
  }

  if (customerEnquiryItem.manufacturer.id) {
    params['filter[manufacturer_id]'] = customerEnquiryItem.manufacturer.id
  }

  dispatch(setFetching(true))

  try {

    const response = await getParts({ params })

    dispatch(setParts(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }
  
}
