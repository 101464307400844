import { combineReducers } from 'redux'

import { reducer as searchTerm } from './State/searchTerm'
import { reducer as sorting } from './State/sorting'
import { reducer as supplierEnquiries } from './State/supplierEnquiries'

export default combineReducers({
  searchTerm,
  sorting,
  supplierEnquiries,
})
