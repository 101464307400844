import { getSupplierEnquiries } from '../../../../../Api/supplierEnquiries'
import { setSupplierEnquiries } from '../State/supplierEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id } = getState().screens.CustomerEnquiry.customerEnquiry

    const { searchTerm } = getState().screens.CustomerEnquiry.SupplierEnquiries.Header

    const params = {
      'filter[customer_enquiry_id]': id,
      search: searchTerm || undefined,
      include: [
        'customer_enquiry',
        'supplier.currency',
        'service',
        'supplier_enquiry_items.part.manufacturer',
      ].join(','),
    }

    const response = await getSupplierEnquiries({ params })

    dispatch(setSupplierEnquiries(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
