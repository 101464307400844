import { combineReducers } from 'redux'

import Customer from './Customer/reducer'
import CustomerMerge from './CustomerMerge/reducer'
import CustomerContact from './CustomerContact/reducer'
import Supplier from './Supplier/reducer'
import SupplierMerge from './SupplierMerge/reducer'
import SupplierContact from './SupplierContact/reducer'
import Manufacturer from './Manufacturer/reducer'
import ManufacturerMerge from './ManufacturerMerge/reducer'
import Part from './Part/reducer'
import PartMerge from './PartMerge/reducer'
import CustomerEnquiry from './CustomerEnquiry/reducer'
import SupplierEnquiry from './SupplierEnquiry/reducer'
import SourcedPart from './SourcedPart/reducer'
import MoveSourcedPart from './MoveSourcedPart/reducer'
import FindPart from './FindPart/reducer'
import UseSupplier from './UseSupplier/reducer'
import CustomerEnquiryImport from './CustomerEnquiryImport/reducer'
import CustomerQuoteItem from './CustomerQuoteItem/reducer'
import QuoteSourcedPart from './QuoteSourcedPart/reducer'
import OrderQuoteItem from './OrderQuoteItem/reducer'
import SalesOrder from './SalesOrder/reducer'
import SalesOrderItem from './SalesOrderItem/reducer'
import SelectPart from './SelectPart/reducer'
import User from './User/reducer'
import ResetPassword from './ResetPassword/reducer'

export default combineReducers({
  Customer,
  CustomerMerge,
  CustomerContact,
  Supplier,
  SupplierMerge,
  SupplierContact,
  Manufacturer,
  ManufacturerMerge,
  Part,
  PartMerge,
  CustomerEnquiry,
  SupplierEnquiry,
  SourcedPart,
  MoveSourcedPart,
  FindPart,
  UseSupplier,
  CustomerEnquiryImport,
  CustomerQuoteItem,
  QuoteSourcedPart,
  OrderQuoteItem,
  SalesOrder,
  SalesOrderItem,
  SelectPart,
  User,
  ResetPassword,
})
