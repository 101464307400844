import { closeSupplierContactModal } from '../State/supplierContactModal'
import formService from '../formService'

export default (supplierContact) => (dispatch) => {

  dispatch(closeSupplierContactModal())

  if (supplierContact) {
    formService().change('supplier_contact', {
      id: supplierContact.id,
      name: supplierContact.name,
    })
  }

}
