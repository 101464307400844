import { Modal } from 'antd'
import pluralize from 'pluralize'
import save from '../Services/save'

export default (values) => async (dispatch, getState) => {

  const { matches } = getState().modals.Manufacturer.Form

  if (values.id || !matches.length) {
    await dispatch(save(values))
    return
  }

  Modal.confirm({
    title: `Create ${values.name}?`,
    content: `${matches.length} potential ${pluralize('duplicate', matches.length)} found. Are you sure that this manufacturer doesn't already exist under a different name?`,
    okText: 'Yes',
    onOk: async () => {
      await dispatch(save(values))
    },
  })
  
}
