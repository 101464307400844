import { connect } from 'react-redux'
import Table from './Table'

import onChange from './Handlers/onChange'

const mapStateToProps = (state) => ({
  supplierEnquiries: state.screens.Supplier.SupplierEnquiries.supplierEnquiries,
})

const actionCreators = {
  onChange,
}

export default connect(mapStateToProps, actionCreators)(Table)
