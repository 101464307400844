import { connect } from 'react-redux'
import { get } from 'lodash'
import Component from './Component'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'
import onClickCreateSupplier from './Handlers/onClickCreateSupplier'
import onClickCreateSupplierContact from './Handlers/onClickCreateSupplierContact'
import onCloseSupplierModal from './Handlers/onCloseSupplierModal'
import onCloseSupplierContactModal from './Handlers/onCloseSupplierContactModal'

const mapStateToProps = (state) => ({
  isNew: !get(state.modals.SupplierEnquiry.Form.formState.initialValues, 'id'),
  initialValues: state.modals.SupplierEnquiry.Form.formState.initialValues,
  supplierId: get(state.modals.SupplierEnquiry.Form.formState.values, 'supplier.id', null),
  supplierModal: state.modals.SupplierEnquiry.Form.supplierModal,
  supplierContactModal: state.modals.SupplierEnquiry.Form.supplierContactModal,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
  onClickCreateSupplier,
  onClickCreateSupplierContact,
  onCloseSupplierModal,
  onCloseSupplierContactModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
