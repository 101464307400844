import React from 'react'
import PropTypes from 'prop-types'

import Header from './Components/Header'
import Subheader from './Components/Subheader'
import Divider from './Components/Divider'
import CustomerEnquiryItem from './Components/CustomerEnquiryItem'
import CustomerQuoteItem from './Components/CustomerQuoteItem'
import CustomerQuoteItemFooter from './Components/CustomerQuoteItemFooter'
import Empty from './Components/Empty'
import CustomerQuoteItemModal from '../../../../Modals/CustomerQuoteItem'
import SalesOrderModal from '../../../../Modals/SalesOrder'
import OrderQuoteItemModal from '../../../../Modals/OrderQuoteItem'

const Components = {
  'HEADER': Header,
  'SUBHEADER': Subheader,
  'DIVIDER': Divider,
  'CUSTOMER_ENQUIRY_ITEM': CustomerEnquiryItem,
  'CUSTOMER_QUOTE_ITEM': CustomerQuoteItem,
  'CUSTOMER_QUOTE_ITEM_FOOTER': CustomerQuoteItemFooter,
  'EMPTY': Empty,
}

export default class extends React.PureComponent {

  static propTypes = {
    rows: PropTypes.array.isRequired,
    customerQuoteItemModal: PropTypes.object.isRequired,
    salesOrderModal: PropTypes.object.isRequired,
    orderQuoteItemModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onCloseCustomerQuoteItemModal: PropTypes.func.isRequired,
    onCloseSalesOrderModal: PropTypes.func.isRequired,
    onCloseOrderQuoteItemModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render() {

    const {
      rows,
      customerQuoteItemModal,
      salesOrderModal,
      orderQuoteItemModal,
      onCloseCustomerQuoteItemModal,
      onCloseSalesOrderModal,
      onCloseOrderQuoteItemModal,
    } = this.props

    return (

      <div>

        {rows.map((row) => {

          const Component = Components[row.type]

          return <Component key={row.key} {...row.data} />

        })}

        {customerQuoteItemModal.show && (
          <CustomerQuoteItemModal
            {...customerQuoteItemModal}
            onClose={onCloseCustomerQuoteItemModal}
          />
        )}

        {salesOrderModal.show && (
          <SalesOrderModal
            {...salesOrderModal}
            onClose={onCloseSalesOrderModal}
          />
        )}

        {orderQuoteItemModal.show && (
          <OrderQuoteItemModal
            {...orderQuoteItemModal}
            onClose={onCloseOrderQuoteItemModal}
          />
        )}

      </div>

    )

  }

}
