import { Modal } from 'antd'
import pluralize from 'pluralize'
import createAllParts from '../Services/createAllParts'

export default () => async (dispatch, getState) => {

  const { data } = getState().modals.CustomerEnquiryImport

  Modal.confirm({
    title: 'Create All Parts?',
    content: `This will create ${data.new.parts.length} new ${pluralize('part', data.new.parts.length)}. If duplicates are encountered, they will be ignored.`,
    onOk: () => dispatch(createAllParts()),
  })

}
