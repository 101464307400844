import { connect } from 'react-redux'
import CustomerContacts from './CustomerContacts'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onClickNew from './Handlers/onClickNew'
import onCloseCustomerContactModal from './Handlers/onCloseCustomerContactModal'

const mapStateToProps = (state) => ({
  customerContactModal: state.screens.Customer.CustomerContacts.customerContactModal,
  customerId: state.screens.Customer.customer.id,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onClickNew,
  onCloseCustomerContactModal,
}

export default connect(mapStateToProps, actionCreators)(CustomerContacts)
