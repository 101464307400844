import { connect } from 'react-redux'
import { get } from 'lodash'
import Manufacturer from './Manufacturer'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseManufacturerModal from './Handlers/onCloseManufacturerModal'
import onCloseManufacturerMergeModal from './Handlers/onCloseManufacturerMergeModal'

const mapStateToProps = (state) => ({
  id: get(state.screens.Manufacturer.manufacturer, 'id'),
  initialized: state.screens.Manufacturer.initialized,
  activeTab: state.screens.Manufacturer.activeTab,
  manufacturerModal: state.screens.Manufacturer.manufacturerModal,
  manufacturerMergeModal: state.screens.Manufacturer.manufacturerMergeModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseManufacturerModal,
  onCloseManufacturerMergeModal,
}

export default connect(mapStateToProps, actionCreators)(Manufacturer)
