import { connect } from 'react-redux'
import Header from './Header'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'

const mapStateToProps = (state) => ({
  searchTerm: state.screens.Part.SourcedParts.searchTerm,
})

const actionCreators = {
  onChangeSearch,
  onSearch,
}

export default connect(mapStateToProps, actionCreators)(Header)
