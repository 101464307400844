import { combineReducers } from "redux"

import { reducer as formState } from "./State/formState"
import { reducer as supplierModal } from "./State/supplierModal"
import { reducer as supplierContactModal } from "./State/supplierContactModal"

export default combineReducers({
  formState,
  supplierModal,
  supplierContactModal,
})
