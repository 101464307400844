import screenProps from '../screenProps'
import { findCustomer } from '../../../Api/customers'
import { setCustomer } from '../State/customer'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params
    
    const params = {
      include: 'manager,currency,pref_service',
    }

    const response = await findCustomer({ id, params })

    const customer = response.data.data

    dispatch(setCustomer(customer))

  } catch (error) {

    handleException(error)

  }

}
