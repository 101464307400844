import modalProps from '../../../modalProps'
import { updateCustomerEnquiry } from '../../../../../Api/customerEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { customerEnquiry } = modalProps()

    const data = {
      sales_order_number: values.sales_order_number,
      sales_order_date: values.sales_order_date.format('YYYY-MM-DD'),
    }

    const response = await updateCustomerEnquiry({ id: customerEnquiry.id, data })

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)

    throw error

  }

}
