export default (value) => {
  
  if (value.trim() === '') return true
  
  if (value !== value.replace(/[^\d.-]/g, '')) return false

  if (value.trim() === '.') return false
  
  return true
  
}