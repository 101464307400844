import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Tooltip, Badge } from 'antd'
import { LinkButton } from '../../../../../packages/sota'
import { get } from 'lodash'
import styles from './styles.css'

import { MediaPopover } from '../../../../Modules'

const Header = (props) => {

  const {
    supplierEnquiry,
    activeTab,
    onBack,
    onChangeStatus,
    onEdit,
    onDuplicate,
    onDelete,
    onChangeTab,
    onClickSourcedParts,
  } = props
  
  if (!supplierEnquiry) return null
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.main}>
        
        
        <div className={styles.section1}>
            
          <LinkButton onClick={onBack}>
            {`← Enquiry ${supplierEnquiry.customer_enquiry.reference}`}
          </LinkButton>
          
          <div className={styles.title}>
            {supplierEnquiry.reference}
          </div>
          
        </div>
        
        
        <div className={styles.section2}>
            
          <div className={styles.supplier}>
            {supplierEnquiry.supplier.name}
          </div>
          
          <div className={styles.supplierContact}>
            {get(supplierEnquiry.supplier_contact, 'name', '-')}
          </div>
          
          <div className={styles.tags}>
            
            <Tooltip title="Currency" placement="bottom">
              <div className={styles.tag}>
                {supplierEnquiry.supplier.currency.code}
              </div>
            </Tooltip>
          
          </div>
          
        </div>
        
        
        <div className={styles.section3}>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Supplier Ref</div>
            <div className={styles.detailContent}>{supplierEnquiry.supplier_ref || '-'}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.detailLabel}>Service</div>
            <div className={styles.detailContent}>{supplierEnquiry.service.name}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.detailLabel}>Status</div>
            <div className={styles.detailContent}>
              <select value={supplierEnquiry.status} onChange={(event) => onChangeStatus(supplierEnquiry, event)}>
                <option value='Draft'>Draft</option>
                <option value='Sent'>Sent</option>
                <option value='Responded'>Responded</option>
              </select>
            </div>
          </div>
          
        </div>


        <div className={styles.buttons}>

          <MediaPopover
            modelType="SupplierEnquiry"
            modelId={supplierEnquiry.id}
          >
            {({ count }) => (
              <Tooltip title="Files" placement="left">
                <Badge count={count} className={styles.buttonBadge} overflowCount={99}>
                  <Button
                    shape="circle"
                    icon="file"
                    size="large"
                    className={styles.button}
                  />
                </Badge>
              </Tooltip>
            )}
          </MediaPopover>

          <Tooltip title="Edit" placement="bottom">
            <Button
              shape="circle"
              icon="edit"
              size="large"
              className={styles.button}
              onClick={onEdit}
            />
          </Tooltip>

          <Tooltip title="Duplicate" placement="bottom">
            <Button
              shape="circle"
              icon="redo"
              size="large"
              className={styles.button}
              onClick={onDuplicate}
            />
          </Tooltip>

          <Tooltip title="Delete" placement="bottom">
            <Button
              shape="circle"
              icon="delete"
              size="large"
              className={styles.button}
              onClick={onDelete}
            />
          </Tooltip>

        </div>
        
        
      </div>
      
      <div className={styles.tabs}>
        
        {[
          'Requirements',
          'Email Template',
        ].map((tab, tabIndex) => (
          <div
            key={tab}
            className={classNames(styles.tab, { [styles.tabActive]: activeTab === tabIndex })}
            onClick={() => onChangeTab(tabIndex)}
          >
            {tab}
          </div>
        ))}

        <div className={styles.navigations}>
          <Button onClick={onClickSourcedParts}>Sourced Parts</Button>
        </div>
        
      </div>
      
    </div>

  )
}

Header.propTypes = {
  supplierEnquiry: PropTypes.object,
  activeTab: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onClickSourcedParts: PropTypes.func.isRequired,
}

Header.defaultProps = {
  supplierEnquiry: null,
}

export default React.memo(Header)
