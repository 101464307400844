import formService from '../formService'
import triggerSave from './triggerSave'

export default (index, data) => (dispatch, getState) => {

  const { formState } = getState().screens.CustomerEnquiry.Request

  const customerEnquiryItem = formState.values.customer_enquiry_items[index]
  
  formService().mutators.update('customer_enquiry_items', index, {
    ...customerEnquiryItem,
    ...data,
  })

  dispatch(triggerSave())

}
