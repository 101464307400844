import { connect } from 'react-redux'
import Component from './Component'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  isInitialized: !!state.modals.SalesOrderItem.Form.formState.initialValues,
  isSubmitting: state.modals.SalesOrderItem.Form.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(Component)
