import { createAction, handleActions } from 'redux-actions'

const loadCustomerPaymentTerms = createAction('CORE/CUSTOMER_PAYMENT_TERMS/LOAD_CUSTOMER_PAYMENT_TERMS')

const defaultState = []

const reducer = handleActions(
  {
    [loadCustomerPaymentTerms]: (state, action) => action.payload,
  },
  defaultState,
)

export { loadCustomerPaymentTerms, reducer }
