import { connect } from 'react-redux'
import Table from './Table'

import onChange from './Handlers/onChange'

const mapStateToProps = (state) => ({
  customerEnquiries: state.screens.Customer.CustomerEnquiries.customerEnquiries,
})

const actionCreators = {
  onChange,
}

export default connect(mapStateToProps, actionCreators)(Table)
