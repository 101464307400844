import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, DatePicker, Spin, Select, InputNumber, Button, Tooltip } from 'antd'
import { get } from 'lodash'
import { getExchangeRate } from 'Common'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelAutoComplete, ModelSelect } from '../../../../Modules'
import formService from './formService'
import styles from './Component.css'

import CustomerModal from '../../../Customer'
import CustomerContactModal from '../../../CustomerContact'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    users: PropTypes.array.isRequired,
    customerPaymentTerms: PropTypes.array.isRequired,
    customerEnquiryStatuses: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    customerId: PropTypes.number,
    customerModal: PropTypes.object.isRequired,
    customerContactModal: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickCreateCustomer: PropTypes.func.isRequired,
    onClickCreateCustomerContact: PropTypes.func.isRequired,
    onCloseCustomerModal: PropTypes.func.isRequired,
    onCloseCustomerContactModal: PropTypes.func.isRequired,
  }

  render () {

    const {
      users,
      customerPaymentTerms,
      customerEnquiryStatuses,
      initialValues,
      customerId,
      customerModal,
      customerContactModal,
      onChangeFormState,
      onSubmit,
      validate,
      onClickCreateCustomer,
      onClickCreateCustomerContact,
      onCloseCustomerModal,
      onCloseCustomerContactModal,
    } = this.props

    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    return (
      
      <React.Fragment>

        <FormProvider
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, submitting, form }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              autoComplete="off"
            >

              <FormInterceptor
                ref={formService}
                subscription={{ values: true, submitting: true }}
                onChange={onChangeFormState}
              />

              <Field name="customer">
                {({ input, meta }) => (
                  <Form.Item
                    label="Customer"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelAutoComplete.Customer
                      {...input}
                      disabled={submitting}
                      onChange={value => {
                        form.change('customer_contact', { id: null, name: null })
                        form.change('exchange_rate', getExchangeRate(get(value, 'currency.code')))
                        form.change('payment_terms', get(value, 'payment_terms'))
                        return input.onChange(value)
                      }}
                    />
                  </Form.Item>
                )}
              </Field>
              
              <Tooltip title="Create Customer">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreateCustomer}
                />
              </Tooltip>

              <Field name="customer_contact">
                {({ input, meta }) => (
                  <Form.Item
                    label="Contact"
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelSelect.CustomerContact
                      {...input}
                      disabled={submitting || !customerId}
                      customerId={customerId}
                    />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title="Create Contact">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreateCustomerContact}
                  disabled={!customerId}
                />
              </Tooltip>

              <Field name="manager_id">
                {({ input, meta }) => (
                  <Form.Item
                    label="Manager"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Select
                      {...input}
                      value={input.value || undefined}
                      disabled={submitting}
                      placeholder='Select User'
                      allowClear
                    >
                      <Select.OptGroup label='Active'>
                        {users.filter(user => !user.deactivated_at).map(({ id, name }) => (
                          <Select.Option key={id} value={id}>{name}</Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Deactivated'>
                        {users.filter(user => !!user.deactivated_at).map(({ id, name }) => (
                          <Select.Option key={id} value={id} style={{ color: '#9e9e9e' }}>{name}</Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                )}
              </Field>

              <Field name="customer_ref">
                {({ input }) => (
                  <Form.Item
                    label="Customer Ref"
                    className={styles.formItem}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="received_on">
                {({ input, meta }) => (
                  <Form.Item
                    label="Date"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <DatePicker
                      {...input}
                      disabled={submitting}
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="respond_by">
                {({ input }) => (
                  <Form.Item
                    label="Respond By"
                    className={styles.formItem}
                  >
                    <DatePicker
                      {...input}
                      disabled={submitting}
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              {/* If the CE is in GBP, don't bother displaying the below */}
              {get(values, 'customer.currency.id', 1) !== 1 && (
                <Field name="exchange_rate">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Exchange Rate"
                      required
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      className={styles.formItem}
                    >
                      <Tooltip title={`£1 gets you ${get(values, 'customer.currency.symbol', '?')}...`}>
                        <InputNumber
                          {...input}
                          disabled={submitting}
                          min={0}
                          step={1}
                          precision={2}
                          style={{ width: '50%' }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Field>
              )}

              <Field name="value">
                {({ input }) => (
                  <Form.Item
                    label="Value"
                    className={styles.formItem}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={2}
                      style={{ width: '50%' }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name="payment_terms">
                {({ input, meta }) => (
                  <Form.Item
                    label="Terms"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      style={{ width: '50%' }}
                    >
                      {customerPaymentTerms.map(customerPaymentTerm => (
                        <Select.Option key={customerPaymentTerm} value={customerPaymentTerm}>
                          {customerPaymentTerm}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

              <Field name="status">
                {({ input, meta }) => (
                  <Form.Item
                    label="Status"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={false}
                      style={{ width: '50%' }}
                    >
                      {customerEnquiryStatuses.map((customerEnquiryStatus) => (
                        <Select.Option key={customerEnquiryStatus} value={customerEnquiryStatus}>
                          {customerEnquiryStatus}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

              <Field name="notes">
                {({ input }) => (
                  <Form.Item
                    label="Notes"
                    className={styles.formItem}
                  >
                    <Input.TextArea
                      {...input}
                      disabled={submitting}
                      rows={3}
                    />
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>
        
        {customerModal.show && (
          <CustomerModal
            onClose={onCloseCustomerModal}
          />
        )}

        {customerContactModal.show && (
          <CustomerContactModal
            customerId={customerId}
            onClose={onCloseCustomerContactModal}
          />
        )}
      
      </React.Fragment>

    )
    
  }

}
