import chance from 'chance'
import screenProps from '../../../screenProps'
import { createSupplierEnquiryItem } from '../../../../../Api/supplierEnquiryItems'
import { openSelectPartModal } from '../State/selectPartModal'
import initialize from '../Services/initialize'

export default () => async (dispatch) => {

  const { id } = screenProps()

  const response = await createSupplierEnquiryItem({
    data: {
      supplier_enquiry_id: id,
      guid: chance().guid(),
    },
  })

  dispatch(initialize())
  
  dispatch(openSelectPartModal({
    internalNo: null,
    supplierEnquiryItemId: response.data.data.id,
  }))
  
}
