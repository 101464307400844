import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Form from './Components/Form'

export default class SupplierEnquiryModal extends React.PureComponent {

  static propTypes = {
    isInitialized: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      isInitialized,
      isSubmitting,
      onSubmit,
      onClose,
    } = this.props

    return (
        
      <Modal
        visible
        title="Supplier Enquiry"
        width={600}
        maskClosable={false}
        okText="Save"
        okButtonProps={{ disabled: !isInitialized, loading: isSubmitting }}
        cancelButtonProps={{ disabled: !isInitialized || isSubmitting }}
        onOk={() => onSubmit()}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
