import { updateSupplierEnquiryItem } from '../../../../../Api/supplierEnquiryItems'
import { handleException } from '../../../../../../packages/sota'
import modalProps from '../../../modalProps'

export default (part) => async () => {

  try {
    
    const { supplierEnquiryItemId } = modalProps()

    const data = {
      part_id: part.id,
    }

    const response = await updateSupplierEnquiryItem({ id: supplierEnquiryItemId, data })
    
    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)

  }

}
