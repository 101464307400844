import React from 'react'
import PropTypes from 'prop-types'
import { Input, Select, Radio } from 'antd'
import { Module } from '../../../../../../../packages/sota'

const Spacer = () => <div style={{ width: 12 }} />

const Header = (props) => {

  const {
    searchTerm,
    filters,
    onChangeSearch,
    onSearch,
    onChangeFilter,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder='Search'
        style={{ width: 220 }}
      />

      <Spacer />

      <Select
        value={filters.departmentKey || undefined}
        onChange={value => onChangeFilter('departmentKey', value || null)}
        allowClear
        placeholder='All Departments'
        style={{ width: 220 }}
      >
        {[
          { label: 'Aerospace & Defence', value: 'A&D' },
          { label: 'Commercial & Kitting', value: 'C&K' },
          { label: 'Other', value: 'Other' },
        ].map(({ label, value }) => (
          <Select.Option key={value} value={value}>{label}</Select.Option>
        ))}
      </Select>

    </React.Fragment>

  )

  const renderRight = () => (

    <Radio.Group
      value={filters.status}
      onChange={e => onChangeFilter('status', e.target.value)}
    >
      <Radio.Button value='active'>Active</Radio.Button>
      <Radio.Button value='deactivated'>Deactivated</Radio.Button>
    </Radio.Group>

  )

  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )

}

Header.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
}

export default Header
