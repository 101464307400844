import { setUserFilter } from '../../../State/userFilter'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (userId) => (dispatch) => {

  dispatch(setUserFilter(userId))

  dispatch(firstPage())

  dispatch(fetch())

}
