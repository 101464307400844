import React from 'react'
import PropTypes from 'prop-types'
import Table from './Components/Table'
import Header from './Components/Header'
import SupplierEnquiryModal from '../../../../Modals/SupplierEnquiry'

export default class SupplierEnquiries extends React.PureComponent {
  
  static propTypes = {
    customerEnquiryId: PropTypes.number.isRequired,
    supplierEnquiryModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onCloseSupplierEnquiryModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()
  
  render () {
    
    const {
      customerEnquiryId,
      supplierEnquiryModal,
      onCloseSupplierEnquiryModal,
    } = this.props

    return (
      
      <React.Fragment>

        <Header />

        <Table />

        {supplierEnquiryModal.show && (
          <SupplierEnquiryModal
            onClose={onCloseSupplierEnquiryModal}
            customerEnquiryId={customerEnquiryId}
          />
        )}
        
      </React.Fragment>

    )
  }
}
