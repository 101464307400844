import React from 'react'
import PropTypes from 'prop-types'
import { Affix, List, Button, Upload, Popover, Tooltip, Badge } from 'antd'
import { orderBy } from 'lodash'
import moment from 'moment'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 16 }} />

const Actions = props => {

  const {
    customerEnquiry,
    onClickNew,
    onClickCustomerEnquiryImport,
    onGenerateSupplierEnquiries,
  } = props

  return (

    <Affix offsetBottom={60}>

      <div className={styles.wrapper}>

        <Popover
          title={(
            <div className={styles.title}>
              <div>Imports</div>
              <Upload
                name='file'
                customRequest={onClickNew}
                showUploadList={false}
              >
                <a>New</a>
              </Upload>
            </div>
          )}
          trigger={['hover']}
          placement='left'
          overlayClassName={styles.popover}
          content={(
            <List
              dataSource={orderBy(customerEnquiry.imports, 'created_at', 'desc')}
              className={styles.list}
              renderItem={item => (
                <List.Item className={styles.listItem}>
                  <List.Item.Meta
                    title={moment(item.created_at).format('Do MMMM YYYY HH:mm')}
                    description={`By: ${item.user.name}`}
                    onClick={() => onClickCustomerEnquiryImport(item.id)}
                  />
                </List.Item>
              )}
            />
          )}
        >
          <Badge count={customerEnquiry.imports.length} size='small'>
            <Button
              size='large'
              shape='circle'
              icon='upload'
            />
          </Badge>
        </Popover>
        
        <Spacer />

        <Tooltip title='Generate Supplier Enquiries'>
          <Button
            type='primary'
            size='large'
            shape='circle'
            icon='arrow-right'
            onClick={() => onGenerateSupplierEnquiries()}
          />
        </Tooltip>

      </div>

    </Affix>

  )

}

Actions.propTypes = {
  customerEnquiry: PropTypes.object.isRequired,
  onClickNew: PropTypes.func.isRequired,
  onClickCustomerEnquiryImport: PropTypes.func.isRequired,
  onGenerateSupplierEnquiries: PropTypes.func.isRequired,
}

export default Actions
