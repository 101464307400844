import { closeManufacturerModal } from '../State/manufacturerModal'
import formService from '../formService'

export default (manufacturer) => (dispatch) => {

  dispatch(closeManufacturerModal())

  if (manufacturer) {
    formService().change('manufacturer', {
      id: manufacturer.id,
      name: manufacturer.name,
    })
  }

}
