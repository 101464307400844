import { getSuppliers } from '../../../../../Api/suppliers'
import { setMatches } from '../State/matches'
import { handleException } from '../../../../../../packages/sota'

export default (name) => async (dispatch) => {

  if (!name) return

  const params = {
    'filter[match]': name,
    sort: 'name',
  }

  try {

    const response = await getSuppliers({ params })

    dispatch(setMatches(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
