import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectData = state => state.modals.CustomerEnquiryImport.data

export default createSelector(
  selectData,
  (data) => get(data, 'new.manufacturers', []).map((row, index) => ({
    ...row,
    key: index,
  })),
)
