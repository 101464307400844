import { connect } from 'react-redux'
import Header from './Header'

import onBack from './Handlers/onBack'
import onChangeStatus from './Handlers/onChangeStatus'
import onEdit from './Handlers/onEdit'
import onDuplicate from './Handlers/onDuplicate'
import onDelete from './Handlers/onDelete'
import onChangeTab from './Handlers/onChangeTab'
import onClickSourcedParts from './Handlers/onClickSourcedParts'

const mapStateToProps = (state) => ({
  supplierEnquiry: state.screens.SupplierEnquiry.supplierEnquiry,
  activeTab: state.screens.SupplierEnquiry.activeTab,
})

const actionCreators = {
  onBack,
  onChangeStatus,
  onEdit,
  onDuplicate,
  onDelete,
  onChangeTab,
  onClickSourcedParts,
}

export default connect(mapStateToProps, actionCreators)(Header)
