/* eslint-disable react/no-array-index-key */

import React from 'react'
import PropTypes from 'prop-types'
import { Card, Button, message } from 'antd'
import templates from './templates'
import style from './style'

class EmailTemplate extends React.PureComponent {
  
  copyToClip = () => {
    
    const template = document.getElementById('email-template').innerHTML
    
    function listener(e) {
      e.clipboardData.setData("text/html", template)
      e.clipboardData.setData("text/plain", template)
      e.preventDefault()
    }
    
    document.addEventListener("copy", listener)
    document.execCommand("copy")
    document.removeEventListener("copy", listener)
    
    message.success('Copied to Clipboard!')
  }
  
  render () {
    
    const {
      supplierEnquiry,
    } = this.props
    
    if (!supplierEnquiry) return null
    
    const template = templates[supplierEnquiry.service.name]
    
    return (

      <Card
        title={`${supplierEnquiry.service.name} Template`}
        extra={(
          <Button onClick={() => this.copyToClip()} type="primary" icon="copy">
            Copy
          </Button>
        )}
      >
        
        <div id="email-template" style={style.template}>
          
          {template.map((element, elementIndex) => {
            
            if (element.type === 'paragraph') {
              
              const formatting = {}
              
              if (Array.isArray(element.formatting) && element.formatting.includes('B')) formatting.fontWeight = 'bold'
              if (Array.isArray(element.formatting) && element.formatting.includes('I')) formatting.fontStyle = 'italic'
              if (Array.isArray(element.formatting) && element.formatting.includes('U')) formatting.textDecoration = 'underline'
              if (Array.isArray(element.formatting) && element.formatting.includes('T')) {
                formatting.fontSize = 24
                formatting.marginBottom = 5
                formatting.fontWeight = 'bold'
                formatting.textAlign = 'center'
              }
              if (Array.isArray(element.formatting) && element.formatting.includes('S')) {
                formatting.fontSize = 18
                formatting.marginBottom = 10
                formatting.fontWeight = 'bold'
                formatting.textAlign = 'center'
              }
              
              return (
                <p key={elementIndex} style={formatting}>
                  {element.content}
                </p>
              )
            }
            
            if (element.type === 'list') {
              
              return (
                <ul key={elementIndex}>
                  {element.items.map((item, itemIndex) => <li key={itemIndex}>{item}</li>)}
                </ul>
              )
            }

            if (element.type === 'table') {

              return (
                <table key={elementIndex} style={style.table} cellPadding={0} cellSpacing={0}>
                  <thead>
                    <tr>
                      {element.cols.map((col, colIndex) => (
                        <td key={colIndex} style={{ ...style.headerCell, minWidth: col.width }}>
                          {col.title.split('<br />').map((text, textIndex) => <div key={textIndex}>{text}</div>)}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {supplierEnquiry.supplier_enquiry_items.filter(item => item.part && item.part.id).map((item, itemIndex) => (
                      <tr key={itemIndex}>
                        {element.cols.map((col, colIndex) => (
                          <td key={colIndex} style={style.cell}>
                            {col.data ? col.data(item, supplierEnquiry) : ''}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            }

            if (element.type === 'table2') {

              return (
                <table key={elementIndex} style={style.table2} cellPadding={0} cellSpacing={0}>
                  <thead>
                    <tr>
                      {element.cols.map((col, colIndex) => (
                        <td key={colIndex} style={{ ...style.headerCell2, minWidth: col.width }}>
                          {col.title.split('<br />').map((text, textIndex) => <div key={textIndex}>{text}</div>)}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {element.cols.map((col, colIndex) => (
                        <td key={colIndex} style={style.cell2}>
                          {col.data.map((row, x) => {
                            if (row.type === 'section') return (
                              <div key={x} style={{ width: 600 }}>{row.content}</div>
                            )
                            if (row.type === 'line') return (
                              <div key={x} style={{ width: 600, paddingLeft: 20 }}>{row.content}</div>
                            )
                            if (row.type === 'break') return (
                              <br key={x} />
                            )
                            return false
                          })}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              )
            }
            
            return null
            
          })}
          
        </div>
        
      </Card>

    )
  }
}

EmailTemplate.propTypes = {
  supplierEnquiry: PropTypes.object,
}

EmailTemplate.defaultProps = {
  supplierEnquiry: null,
}

export default EmailTemplate
