import { push } from 'connected-react-router'
import { closeCustomerMergeModal } from '../State/customerMergeModal'

export default (customer) => (dispatch) => {

  dispatch(closeCustomerMergeModal())

  if (customer) {
    dispatch(push(`/portal/customers/${customer.id}`))
  }

}
