import { createAction, handleActions } from 'redux-actions'

const setSourcedParts = createAction('SCREENS/SOURCED_PARTS/SET_SUPPLIER_ENQUIRIES')
const resetSourcedParts = createAction('SCREENS/SOURCED_PARTS/RESET_SUPPLIER_ENQUIRIES')

const defaultState = []

const reducer = handleActions(
  {
    [setSourcedParts]: (state, action) => action.payload,
    [resetSourcedParts]: () => defaultState,
  },
  defaultState,
)

export { setSourcedParts, resetSourcedParts, reducer }
