import { createAction, handleActions } from 'redux-actions'

const setSupplierEnquiry = createAction('SCREENS/SUPPLIER_ENQUIRY/SET_SUPPLIER_ENQUIRY')
const resetSupplierEnquiry = createAction('SCREENS/SUPPLIER_ENQUIRY/RESET_SUPPLIER_ENQUIRY')

const defaultState = null

const reducer = handleActions(
  {
    [setSupplierEnquiry]: (state, action) => action.payload,
    [resetSupplierEnquiry]: () => defaultState,
  },
  defaultState,
)

export { setSupplierEnquiry, resetSupplierEnquiry, reducer }
