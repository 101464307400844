import { closeManufacturerModal } from '../State/manufacturerModal'
import fetch from '../Services/fetch'

export default (manufacturer) => (dispatch) => {
  
  dispatch(closeManufacturerModal())
  
  if (manufacturer) {
    
    dispatch(fetch())
    
  }
  
}
