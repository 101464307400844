import { createAction, handleActions } from 'redux-actions'
import { includes, without, concat } from 'lodash'

const toggleItemClosed = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/TOGGLE_ITEM_CLOSED")
const resetClosedItems = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/RESET_CLOSED_ITEMS")

const defaultState = []

const reducer = handleActions(
  {
    [toggleItemClosed]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : concat(state, action.payload),
    [resetClosedItems]: () => defaultState,
  },
  defaultState,
)

export { toggleItemClosed, resetClosedItems, reducer }
