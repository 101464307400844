export default () => async (dispatch, getState) => {

  const { token } = getState().app

  const { selectedManufacturerId, searchTerm, sorting } = getState().screens.Parts.Table

  const params = {}

  if (searchTerm) params.search = searchTerm

  if (selectedManufacturerId) params['filter[manufacturer_id]'] = selectedManufacturerId

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'internal_no'

  params.include = 'manufacturer'

  let url = `/api/parts/export?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.open(url)

}
