import screenProps from '../screenProps'
import initialize from '../Services/initialize'
import initializeSupplierContacts from '../Components/SupplierContacts/Services/initialize'
import initializeSupplierEnquiries from '../Components/SupplierEnquiries/Services/initialize'
import initializeSourcedParts from '../Components/SourcedParts/Services/initialize'

export default (props, prevProps) => async (dispatch, getState) => {

  screenProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    const { activeTab } = getState().screens.Supplier

    await dispatch(initialize())

    if (activeTab === 0) await dispatch(initializeSupplierContacts())

    if (activeTab === 1) await dispatch(initializeSupplierEnquiries())

    if (activeTab === 2) await dispatch(initializeSourcedParts())

  }

}
