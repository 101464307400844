import { connect } from 'react-redux'
import SourcedPartsPopover from './SourcedPartsPopover'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(SourcedPartsPopover)
