import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, InputNumber, Spin, Select, Button, Tooltip } from 'antd'
import { get } from 'lodash'
import { getExchangeRate } from 'Common'
import { FormInterceptor } from '../../../../../packages/sota'
import { ModelAutoComplete, ModelSelect } from '../../../../Modules'
import formService from './formService'
import styles from './Component.css'

import SupplierModal from '../../../Supplier'
import SupplierContactModal from '../../../SupplierContact'

export default class FormFields extends React.PureComponent {

  static propTypes = {
    isNew: PropTypes.bool.isRequired,
    initialValues: PropTypes.object,
    supplierId: PropTypes.number,
    supplierModal: PropTypes.object.isRequired,
    supplierContactModal: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickCreateSupplier: PropTypes.func.isRequired,
    onClickCreateSupplierContact: PropTypes.func.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
    onCloseSupplierContactModal: PropTypes.func.isRequired,
  }

  render () {

    const {
      isNew,
      initialValues,
      supplierId,
      supplierModal,
      supplierContactModal,
      onChangeFormState,
      onSubmit,
      validate,
      onClickCreateSupplier,
      onClickCreateSupplierContact,
      onCloseSupplierModal,
      onCloseSupplierContactModal,
    } = this.props

    if (!initialValues) return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <Spin />
      </div>
    )

    return (

      <React.Fragment>

        <FormProvider
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, submitting, form }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              autoComplete="off"
            >

              <FormInterceptor
                ref={formService}
                subscription={{ values: true, submitting: true }}
                onChange={onChangeFormState}
              />

              <Field name="supplier">
                {({ input, meta }) => (
                  <Form.Item
                    label="Supplier"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelAutoComplete.Supplier
                      {...input}
                      disabled={submitting}
                      onChange={value => {
                        form.change('supplier_contact', { id: null, name: null })
                        form.change('exchange_rate', getExchangeRate(get(value, 'currency.code')))
                        return input.onChange(value)
                      }}
                    />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title="Create Supplier">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreateSupplier}
                />
              </Tooltip>

              <Field name="supplier_contact">
                {({ input, meta }) => (
                  <Form.Item
                    label="Contact"
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelSelect.SupplierContact
                      {...input}
                      disabled={submitting}
                      supplierId={supplierId}
                    />
                  </Form.Item>
                )}
              </Field>

              <Tooltip title="Create Contact">
                <Button
                  icon="user-add"
                  className={styles.addButton}
                  onClick={onClickCreateSupplierContact}
                  disabled={!supplierId}
                />
              </Tooltip>

              <Field name="service">
                {({ input, meta }) => (
                  <Form.Item
                    label="Service"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    className={styles.formItem}
                  >
                    <ModelSelect.Service
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>
              
              {!isNew && (
                <Field name="supplier_ref">
                  {({ input }) => (
                    <Form.Item
                      label="Supplier Ref"
                      className={styles.formItem}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>
              )}

              {/* If the SE is in GBP, don't bother displaying the below */}
              {get(values, 'supplier.currency.id', 1) !== 1 && (
                <Field name="exchange_rate">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Exchange Rate"
                      required
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      className={styles.formItem}
                    >
                      <Tooltip title={`£1 gets you ${get(values, 'supplier.currency.symbol', '?')}...`}>
                        <InputNumber
                          {...input}
                          disabled={submitting}
                          min={0}
                          step={1}
                          precision={2}
                          style={{ width: '50%' }}
                        />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Field>
              )}

              {!isNew && (
                <Field name="status">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Status"
                      required
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      className={styles.formItem}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        dropdownMatchSelectWidth={false}
                        style={{ width: '50%' }}
                      >
                        {['Draft', 'Sent', 'Responded'].map((status) => (
                          <Select.Option key={status} value={status}>
                            {status}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>
              )}

            </Form>

          )}

        </FormProvider>

        {supplierModal.show && (
          <SupplierModal
            onClose={onCloseSupplierModal}
          />
        )}

        {supplierContactModal.show && (
          <SupplierContactModal
            supplierId={supplierId}
            onClose={onCloseSupplierContactModal}
          />
        )}

      </React.Fragment>

    )
  }
}