import { createSelector } from 'reselect'
import { orderBy, take } from 'lodash'

const selectSort = state => state.screens.Dashboard.ConversionInPeriod.controls.sort
const selectServiceData = state => state.screens.Dashboard.ConversionInPeriod.data.serviceData

export default createSelector(
  selectSort,
  selectServiceData,
  (sort, serviceData) => {
    const $serviceData = take(orderBy(serviceData, ['ratio', 'quoted'], [sort, 'desc']), 40)
    return {
      chart: {
        type: 'column',
        height: 300,
        style: {
          fontFamily: 'Arial, sans-serif',
          textDecoration: 'none',
        },
      },
      title: false,
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f}%',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px;color:{point.color}">{point.key}</span><br>',
        pointFormat: 'Quoted: <b>{point.quoted}</b><br>Ordered: <b>{point.ordered}</b></b><br>Conversion: <b>{point.y:,.0f}%</b>',
      },
      series: [
        {
          name: 'Conversion by Service',
          colorByPoint: true,
          data: $serviceData.map(row => ({
            ...row,
            name: row.name,
            y: row.ratio,
            drilldown: row.id,
          })),
        },
      ],
      drilldown: {
        activeDataLabelStyle: {
          color: '#000000d9',
          textDecoration: 'none',
        },
        series: $serviceData.map(row => ({
          name: row.name,
          id: row.id,
          data: take(orderBy(row.customers, ['ratio', 'quoted'], [sort, 'desc']), 40).map($row => ({
            ...$row,
            name: $row.name,
            y: $row.ratio,
          })),
        })),
      },
    }
  },
)
