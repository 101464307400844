import selectPart from '../Services/selectPart'
import { closeFindPartModal } from '../State/findPartModal'

export default (part) => (dispatch) => {

  dispatch(selectPart(part))

  dispatch(closeFindPartModal())
  
}
