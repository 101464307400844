import { setSearchTerm } from '../State/searchTerm'
import fetch from '../Services/fetch'

export default (value) => (dispatch) => {
  
  dispatch(setSearchTerm(value))
  
  dispatch(fetch())

}
