import screenProps from '../screenProps'
import { findSupplier } from '../../../Api/suppliers'
import { setSupplier } from '../State/supplier'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const params = {
      include: 'currency',
    }

    const response = await findSupplier({ id, params })

    const supplier = response.data.data

    dispatch(setSupplier(supplier))

  } catch (error) {

    handleException(error)

  }

}
