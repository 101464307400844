import { connect } from "react-redux"
import SourcedPartFooter from "./SourcedPartFooter"

import onClickAdd from './Handlers/onClickAdd'

const mapStateToProps = () => ({})

const actionCreators = {
  onClickAdd,
}

export default connect(mapStateToProps, actionCreators)(SourcedPartFooter)
