import { push } from 'connected-react-router'
import { closePartMergeModal } from '../State/partMergeModal'

export default (part) => (dispatch) => {

  dispatch(closePartMergeModal())

  if (part) {
    dispatch(push(`/portal/parts/${part.id}`))
  }

}
