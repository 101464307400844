import { resetInitialized } from "../State/initialized"
import { resetSupplierEnquiry } from "../State/supplierEnquiry"
import { resetActiveTab } from "../State/activeTab"

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetSupplierEnquiry())
  dispatch(resetActiveTab())
  
}
