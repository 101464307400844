import modalProps from '../../../modalProps'
import { findSupplier } from '../../../../../Api/suppliers'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'
import { pick } from 'lodash'

export default () => async (dispatch) => {

  try {

    const { id } = modalProps()

    if (id) {

      const params = {
        include: 'currency',
      }

      const response = await findSupplier({ id, params })

      const supplier = response.data.data

      const initialValues = {
        ...pick(supplier, [
          'id',
          'name',
          'telephone',
          'email',
          'currency_id',
          'sage_ref',
        ]),
        currency: {
          ...pick(supplier.currency, [
            'id',
            'code',
          ]),
        },
      }

      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
        currency: {},
      }

      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)

  }

}
