import React from 'react'
import { Module } from '../../../../../../../packages/sota'
import { Input, Button } from 'antd'

const Header = (props) => {

  const {
    customerEnquiryId,
    searchTerm,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <Input.Search
      value={searchTerm}
      onChange={onChangeSearch}
      onSearch={() => onSearch(customerEnquiryId)}
      placeholder="Search"
      style={{ width: 200 }}
    />

  )

  return (

    <Module.Header
      renderLeft={renderLeft}
      style={{ padding: 0 }}
    />

  )

}

export default Header
