import { connect } from 'react-redux'
import Header from './Header'

import onBack from './Handlers/onBack'
import onEdit from './Handlers/onEdit'
import onMerge from './Handlers/onMerge'
import onDelete from './Handlers/onDelete'
import onChangeTab from './Handlers/onChangeTab'

const mapStateToProps = (state) => ({
  manufacturer: state.screens.Manufacturer.manufacturer,
  activeTab: state.screens.Manufacturer.activeTab,
  canMerge: !!state.app.user.is_admin,
})

const actionCreators = {
  onBack,
  onEdit,
  onMerge,
  onDelete,
  onChangeTab,
}

export default connect(mapStateToProps, actionCreators)(Header)
