import { closeUserModal } from '../State/userModal'
import fetchTableData from '../Components/Table/Services/fetch'

export default (user) => (dispatch) => {

  dispatch(closeUserModal())
  
  if (user) {
    dispatch(fetchTableData())
  }

}
