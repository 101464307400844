import { createAction, handleActions } from 'redux-actions'

const openSupplierContactModal = createAction('MODALS/SUPPLIER_ENQUIRY/OPEN_SUPPLIER_CONTACT_MODAL')
const closeSupplierContactModal = createAction('MODALS/SUPPLIER_ENQUIRY/CLOSE_SUPPLIER_CONTACT_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSupplierContactModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeSupplierContactModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSupplierContactModal, closeSupplierContactModal, reducer }
