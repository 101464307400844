import { createAction, handleActions } from 'redux-actions'

const setParts = createAction('MODALS/SELECT_PART/SET_PARTS')
const resetParts = createAction('MODALS/SELECT_PART/RESET_PARTS')

const defaultState = []

const reducer = handleActions(
  {
    [setParts]: (state, action) => action.payload,
    [resetParts]: () => defaultState,
  },
  defaultState,
)

export { setParts, resetParts, reducer }
