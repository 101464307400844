import { combineReducers } from 'redux'

import { reducer as formState } from './State/formState'
import { reducer as lastInteractionId } from './State/lastInteractionId'
import { reducer as manufacturerModal } from './State/manufacturerModal'
import { reducer as findPartModal } from './State/findPartModal'
import { reducer as useSupplierModal } from './State/useSupplierModal'
import { reducer as customerEnquiryImportModal } from './State/customerEnquiryImportModal'

export default combineReducers({
  formState,
  lastInteractionId,
  manufacturerModal,
  findPartModal,
  useSupplierModal,
  customerEnquiryImportModal,
})
