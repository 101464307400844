import { pick, has } from 'lodash'
import { push } from 'connected-react-router'
import * as Sentry from '@sentry/browser'
import { setToken } from '../State/token'
import { setUser } from '../State/user'
import { login, getUser } from '../../Api/auth'

export default (credentials) => async (dispatch) => {
  
  try {

    const response = await login({ data: credentials })
    
    dispatch(setToken(response.data.token))

    const { data: { data: user } } = await getUser()

    dispatch(setUser(user))

    Sentry.configureScope((scope) => {
      scope.setUser(pick(user, [
        'id',
        'name',
        'email',
        'username',
      ]))
    })

    dispatch(push('/portal/'))

    return undefined

  } catch (error) {

    if (has(error,'response.status') && error.response.status === 401) {

      throw new Error('INVALID_CREDENTIALS')

    }

    throw error

  }

}