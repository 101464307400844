import { debounce } from 'lodash'
import { setSearchTerm } from '../State/searchTerm'
import fetch from '../Services/fetch'

const fetchWithDebounce = debounce((dispatch) => dispatch(fetch()), 1000)

export default (value) => (dispatch) => {
  
  dispatch(setSearchTerm(value))
  
  fetchWithDebounce(dispatch)

}
