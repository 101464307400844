import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openSelectPartModal = createAction('SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/OPEN_SELECT_PART_MODAL')
const closeSelectPartModal = createAction('SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/CLOSE_SELECT_PART_MODAL')

const defaultState = {
  show: false,
  internalNo: null,
  supplierEnquiryItemId: null,
}

const reducer = handleActions(
  {
    [openSelectPartModal]: (state, action) => Object.assign({}, state, {
      show: true,
      internalNo: get(action, 'payload.internalNo', null),
      supplierEnquiryItemId: get(action, 'payload.supplierEnquiryItemId', null),
    }),
    [closeSelectPartModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openSelectPartModal, closeSelectPartModal, reducer }
