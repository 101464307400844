import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from './Components/Table'
import Customer from '../../Modals/Customer'

export default class Customers extends React.PureComponent {

  static propTypes = {
    customerModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseCustomerModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New Customer
    </Button>,

  ])

  render() {

    const {
      customerModal,
      onCloseCustomerModal,
    } = this.props

    return (

      <Screen
        title="Customers"
        headerRight={this.renderHeaderRight()}
      >

        <Table />

        {customerModal.show && (
          <Customer
            onClose={onCloseCustomerModal}
            id={customerModal.customerId}
          />
        )}

      </Screen>

    )

  }

}
