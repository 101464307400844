import { createAction, handleActions } from 'redux-actions'

const openPartModal = createAction('MODALS/FIND_PART/OPEN_PART_MODAL')
const closePartModal = createAction('MODALS/FIND_PART/CLOSE_PART_MODAL')

const defaultState = {
  show: false,
  manufacturerNo: null,
  manufacturer: {},
}

const reducer = handleActions(
  {
    [openPartModal]: (state, { payload }) => ({ ...state, show: true, ...payload }),
    [closePartModal]: () => defaultState,
  },
  defaultState,
)

export { openPartModal, closePartModal, reducer }
