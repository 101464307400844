export default (values) => () => {

  const errors = {}

  if (!values.eur) errors.eur = true

  if (!values.usd) errors.usd = true

  return errors

}

