import { closeCustomerModal } from '../State/customerModal'
import fetch from '../Services/fetch'

export default (customer) => (dispatch) => {
  
  dispatch(closeCustomerModal())
  
  if (customer) {
    
    dispatch(fetch())
    
  }
  
}
