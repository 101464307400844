import { setPayload, setNewField } from '../../../State/data'
import save from '../../../Services/save'

export default (guid, prevName, newName) => (dispatch, getState) => {

  const { data } = getState().modals.CustomerEnquiryImport

  const manufacturers = []
  const customerEnquiryItems = []

  data.new.manufacturers.forEach(row => {
    if (row.guid !== guid) {
      manufacturers.push({ ...row })
      return
    }
    manufacturers.push({
      ...row,
      name: newName,
    })
  })

  data.payload.customer_enquiry_items.forEach(row => {
    if (row['manufacturer.name'] !== prevName) {
      customerEnquiryItems.push({ ...row })
      return
    }
    customerEnquiryItems.push({
      ...row,
      'manufacturer.name': newName,
    })
  })

  dispatch(setNewField({
    field: 'manufacturers',
    data: manufacturers,
  }))

  dispatch(setPayload({
    customer_enquiry_items: customerEnquiryItems,
  }))

  dispatch(save())

}
