import { combineReducers } from 'redux'

import { reducer as customerEnquiryItems } from './State/customerEnquiryItems'
import { reducer as closedItems } from './State/closedItems'
import { reducer as customerQuoteItemModal } from './State/customerQuoteItemModal'
import { reducer as salesOrderModal } from './State/salesOrderModal'
import { reducer as orderQuoteItemModal } from './State/orderQuoteItemModal'

export default combineReducers({
  customerEnquiryItems,
  closedItems,
  customerQuoteItemModal,
  salesOrderModal,
  orderQuoteItemModal,
})
