import screenProps from '../../../screenProps'
import { getCustomerEnquiries } from '../../../../../Api/customerEnquiries'
import { setCustomerEnquiries } from '../State/customerEnquiries'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id } = screenProps().match.params

    const { searchTerm, sorting } = getState().screens.Customer.CustomerEnquiries

    const params = {
      search: searchTerm,
      'filter[customer_id]': id,
      include: 'customer,manager',
    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-received_on'

    const response = await getCustomerEnquiries({ id, params })

    const customerEnquiries = response.data.data

    dispatch(setCustomerEnquiries(customerEnquiries))

  } catch (error) {

    handleException(error)

  }

}
