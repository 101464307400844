import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import styles from './styles.css'

const int = num => numeral(num).format('0,0')

const mon = (sym, num, def = '-') => num ? `${sym}${numeral(num).format('0,0.00')}` : def

const SourcedPartsPopover = props => {

  const {
    sourcedParts,
    children,
    ...rest
  } = props

  if (!sourcedParts.length) return children

  const content = (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Date</th>
            <th className={styles.num}>Qty</th>
            <th className={styles.num}>Unit Cost</th>
            <th className={styles.num}>Carr. Costs</th>
            <th>Lead Time</th>
            <th>RoHS</th>
            <th>Date Code</th>
          </tr>
        </thead>
        <tbody>
          {sourcedParts.map(sourcedPart => (
            <tr key={sourcedPart.id}>
              <td>{moment(sourcedPart.date).format('Do MMM YYYY')}</td>
              <td className={styles.num}>{int(sourcedPart.quantity)}</td>
              <td className={styles.num}>{mon('£', sourcedPart.total_cost_gbp / sourcedPart.quantity, 'No Bid')}</td>
              <td className={styles.num}>{mon('£', sourcedPart.landing_costs, '-')}</td>
              <td>{sourcedPart.lead_time}</td>
              <td>
                {sourcedPart.rohs === null && 'N/A'}
                {sourcedPart.rohs === 1 && 'Yes'}
                {sourcedPart.rohs === 0 && 'No'}
              </td>
              <td>{sourcedPart.date_code}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (

    <Popover
      title='Sourced'
      content={content}
      {...rest}
    >

      {children}

    </Popover>

  )

}

SourcedPartsPopover.propTypes = {
  sourcedParts: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default SourcedPartsPopover
