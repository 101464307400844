import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from "react-router-dom"

import Login from './Login'
import Home from './Home'

import Dashboard from './Dashboard'

import Users from './Users'

import Customers from './Customers'
import Customer from './Customer'

import Suppliers from './Suppliers'
import Supplier from './Supplier'

import Manufacturers from './Manufacturers'
import Manufacturer from './Manufacturer'

import Parts from './Parts'
import Part from './Part'

import CustomerEnquiries from './CustomerEnquiries'
import CustomerEnquiry from './CustomerEnquiry'

import SupplierEnquiries from './SupplierEnquiries'
import SupplierEnquiry from './SupplierEnquiry'

const Screens = ({ user }) => (

  <Switch>

    <Route path="/portal" exact component={Home} />

    <Route path="/portal/login" exact component={Login} />

    <Route path="/portal/dashboard" exact component={Dashboard} />

    {!!user && !!user.is_admin && <Route path="/portal/users" exact component={Users} />}

    <Route path="/portal/customers" exact component={Customers} />

    <Route path="/portal/customers/:id" exact component={Customer} />

    <Route path="/portal/suppliers" exact component={Suppliers} />

    <Route path="/portal/suppliers/:id" exact component={Supplier} />

    <Route path="/portal/manufacturers" exact component={Manufacturers} />

    <Route path="/portal/manufacturers/:id" exact component={Manufacturer} />

    <Route path="/portal/parts" exact component={Parts} />

    <Route path="/portal/parts/:id" exact component={Part} />

    <Route path="/portal/customer_enquiries" exact component={CustomerEnquiries} />

    <Route path="/portal/customer_enquiries/:id" exact component={CustomerEnquiry} />

    <Route path="/portal/supplier_enquiries" exact component={SupplierEnquiries} />

    <Route path="/portal/supplier_enquiries/:id" exact component={SupplierEnquiry} />

  </Switch>

)

Screens.propTypes = {
  user: PropTypes.object,
}

Screens.defaultProps = {
  user: null,
}

const mapStateToProps = (state) => ({
  user: state.app.user,
})

export default connect(mapStateToProps, {})(Screens)
