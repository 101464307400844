import React from 'react'
import PropTypes from 'prop-types'
import { Input, Table, Button } from 'antd'
import { LinkButton } from '../../../../../packages/sota'
import styles from './Component.css'

import PartModal from '../../../Part'

export default class Finder extends React.PureComponent {

  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    parts: PropTypes.array.isRequired,
    partModal: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClickCreatePart: PropTypes.func.isRequired,
    onClickSelectPart: PropTypes.func.isRequired,
    onClosePartModal: PropTypes.func.isRequired,
  }

  render () {

    const {
      searchTerm,
      fetching,
      parts,
      partModal,
      onSearch,
      onClickCreatePart,
      onClickSelectPart,
      onClosePartModal,
    } = this.props

    return (

      <React.Fragment>
        
        <div className={styles.header}>
          
          <div className={styles.left}>
            
            <Input.Search
              value={searchTerm}
              loading={fetching}
              autoFocus
              onSearch={value => onSearch(value)}
              onChange={e => onSearch(e.target.value)}
              placeholder="Search"
              style={{ width: 250 }}
            />
            
          </div>
          
          <div className={styles.right}>
            
            <Button
              icon="plus"
              onClick={onClickCreatePart}
            >
              New Part
            </Button>
            
          </div>
          
        </div>
        
        <Table
          dataSource={parts}
          loading={fetching}
          size="small"
          rowKey="id"
        >
          
          <Table.Column
            key="manufacturer_no"
            title="Manufacturer PN"
            width={200}
            render={(text, record) => (
              <div>
                <div>
                  <strong>{record.manufacturer_no}</strong>
                </div>
                <div>
                  <span style={{ color: '#999' }}>{record.manufacturer ? record.manufacturer.name : ''}</span>
                </div>
              </div>
            )}
          />

          <Table.Column
            key="internal_no"
            title="Internal PN"
            width={200}
            render={(text, record) => record.internal_no}
          />

          <Table.Column
            key="action"
            title=" "
            width={60}
            render={(text, record) => (
              <LinkButton
                type="primary"
                onClick={() => onClickSelectPart(record)}
              >
                Select Part
              </LinkButton>
            )}
          />
          
        </Table>
        
        {partModal.show && (
          <PartModal
            onClose={onClosePartModal}
          />
        )}
        
      </React.Fragment>

    )
  }
}