import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openCustomerContactModal = createAction('SCREENS/CUSTOMER/CUSTOMER_CONTACTS/OPEN_CUSTOMER_CONTACT_MODAL')
const closeCustomerContactModal = createAction('SCREENS/CUSTOMER/CUSTOMER_CONTACTS/CLOSE_CUSTOMER_CONTACT_MODAL')

const defaultState = {
  show: false,
  customerContactId: null,
}

const reducer = handleActions(
  {
    [openCustomerContactModal]: (state, action) => Object.assign({}, state, {
      show: true,
      customerContactId: get(action, 'payload.customerContactId', null),
    }),
    [closeCustomerContactModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCustomerContactModal, closeCustomerContactModal, reducer }
