import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio } from 'antd'
import { Module } from '../../../../../../../packages/sota'
import styles from './Header.css'

const Header = (props) => {

  const {
    activeTab,
    searchTerm,
    onChangeTab,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <Radio.Group onChange={onChangeTab} value={activeTab}>
      <Radio.Button value="All" className={styles.tab}>All</Radio.Button>
      <Radio.Button value="Draft" className={styles.tab}>Draft</Radio.Button>
      <Radio.Button value="Sent" className={styles.tab}>Sent</Radio.Button>
      <Radio.Button value="Responded" className={styles.tab}>Responded</Radio.Button>
    </Radio.Group>

  )

  const renderRight = () => (

    <React.Fragment>

      <div style={{ width: 16 }} />

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder="Search"
        style={{ width: 200 }}
      />
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header
