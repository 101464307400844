import { connect } from 'react-redux'
import SalesOrder from './SalesOrder'

import onClickEdit from './Handlers/onClickEdit'

const mapStateToProps = (state) => ({
  customerEnquiry: state.screens.CustomerEnquiry.customerEnquiry,
})

const actionCreators = {
  onClickEdit,
}

export default connect(mapStateToProps, actionCreators)(SalesOrder)
