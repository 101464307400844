import { connect } from 'react-redux'
import Header from './Header'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onChangeFilter from './Handlers/onChangeFilter'

const mapStateToProps = (state) => ({
  searchTerm: state.screens.Users.Table.searchTerm,
  filters: state.screens.Users.Table.filters,
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onChangeFilter,
}

export default connect(mapStateToProps, actionCreators)(Header)
