import { connect } from 'react-redux'
import Content from './Content'

import onChange from './Handlers/onChange'
import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  data: state.screens.Parts.Table.data,
  isFetching: state.screens.Parts.Table.isFetching,
})

const actionCreators = {
  onChange,
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(Content)
