import { createSelector } from 'reselect'

import selectUserConfig from './selectUserConfig'
import selectDepartmentConfig from './selectDepartmentConfig'
import selectServiceConfig from './selectServiceConfig'
import selectCustomerConfig from './selectCustomerConfig'

const selectSource = state => state.screens.Dashboard.SalesOrdersInPeriod.controls.source

export default createSelector(
  selectSource,
  selectUserConfig,
  selectDepartmentConfig,
  selectServiceConfig,
  selectCustomerConfig,
  (source, userConfig, departmentConfig, serviceConfig, customerConfig) => {
    if (source === 'user') return userConfig
    if (source === 'department') return departmentConfig
    if (source === 'service') return serviceConfig
    if (source === 'customer') return customerConfig
    return {}
  },
)
