import { openPartModal } from '../../../State/partModal'

export default (values) => (dispatch) => {

  dispatch(openPartModal({
    index: values.key,
    manufacturerNo: values.manufacturer_no,
    manufacturer: values.manufacturer,
    internalNo: values.internal_no,
    description: values.description,
  }))

}
