import { createAction, handleActions } from 'redux-actions'

const setSupplierEnquiries = createAction('SCREENS/CUSTOMER_ENQUIRY/SUPPLIER_ENQUIRIES/SET_SUPPLIER_ENQUIRIES')
const resetSupplierEnquiries = createAction('SCREENS/CUSTOMER_ENQUIRY/SUPPLIER_ENQUIRIES/RESET_SUPPLIER_ENQUIRIES')

const defaultState = []

const reducer = handleActions(
  {
    [setSupplierEnquiries]: (state, action) => action.payload,
    [resetSupplierEnquiries]: () => [],
  },
  defaultState,
)

export { setSupplierEnquiries, resetSupplierEnquiries, reducer }
