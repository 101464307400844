import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'react-final-form'
import { Icon, Dropdown, Menu } from 'antd'
import isInteger from '../../Utils/isInteger'
import isFloat from '../../Utils/isFloat'
import styles from './styles.css'

const SupplierEnquiryItem = (props) => {

  const {
    item,
    itemIndex,
    isClosed,
    onToggleClosed,
    onRemove,
    onChangeField,
    onClickManufacturerNo,
  } = props
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.closer} onClick={() => onToggleClosed(item)}>
        
        <Icon
          type={isClosed ? "caret-right" : "caret-down"}
        />
        
      </div>

      <div className={classNames(styles.col, styles.col1)}>

        <div className={classNames(styles.textOutlet, styles.manufacturerNo)} onClick={() => onClickManufacturerNo(item)}>
          {(item.part && item.part.manufacturer_no) ? item.part.manufacturer_no : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col2)}>

        <div className={classNames(styles.textOutlet)}>
          {(item.part && item.part.manufacturer) ? item.part.manufacturer.name : '-'}
        </div>

      </div>

      <div className={classNames(styles.col, styles.col3)}>

        <div className={classNames(styles.textOutlet)}>
          {(item.part && item.part.internal_no) ? item.part.internal_no : '-'}
        </div>

      </div>
      
      <div className={classNames(styles.col, styles.col4)}>
        
        <Field name={`supplier_enquiry_items[${itemIndex}].quantity`}>

          {({ input, meta }) => (
            
            <input
              type="text"
              className={classNames(styles.textInput, styles.rightAlign, {[styles.invalid]: (meta.invalid && meta.touched)})}
              placeholder="0"
              autoComplete="off"
              {...input}
              onChange={(event) => {
                if (isInteger(event.target.value)) {
                  input.onChange(event)
                  onChangeField()
                }
              }}
            />
            
          )}
        
        </Field>
      
      </div>
      
      <div className={classNames(styles.col, styles.col5)}>
        
        <Field name={`supplier_enquiry_items[${itemIndex}].target_price`}>

          {({ input }) => (
            
            <input
              type="text"
              className={classNames(styles.textInput, styles.rightAlign)}
              placeholder="-"
              autoComplete="off"
              {...input}
              onChange={(event) => {
                if (isFloat(event.target.value)) {
                  input.onChange(event)
                  onChangeField()
                }
              }}
            />
            
          )}
        
        </Field>
      
      </div>
      
      <Dropdown
        trigger={["click"]}
        overlay={(
          <Menu>
            
            <Menu.Item onClick={() => onRemove(itemIndex)}>
              <Icon type="minus-circle" />
              Remove Line
            </Menu.Item>
            
          </Menu>
        )}
      >
      
        <div className={styles.moreButton} tabIndex={0}>
          
          <Icon type="more" />
          
        </div>
      
      </Dropdown>
      
    </div>

  )

}

SupplierEnquiryItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemIndex: PropTypes.number.isRequired,
  isClosed: PropTypes.bool.isRequired,
  onToggleClosed: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onClickManufacturerNo: PropTypes.func.isRequired,
}

export default React.memo(SupplierEnquiryItem)
