import React from 'react'
import styles from './styles.css'

const Divider = () => (

  <div className={styles.divider} />

)

export default Divider
