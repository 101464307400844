import { connect } from 'react-redux'
import SupplierEnquiries from './SupplierEnquiries'

import componentDidMount from './Handlers/componentDidMount'
import onCloseSupplierEnquiryModal from './Handlers/onCloseSupplierEnquiryModal'

const mapStateToProps = (state) => ({
  customerEnquiryId: state.screens.CustomerEnquiry.customerEnquiry.id,
  supplierEnquiryModal: state.screens.CustomerEnquiry.SupplierEnquiries.supplierEnquiryModal,
})

const actionCreators = {
  componentDidMount,
  onCloseSupplierEnquiryModal,
}

export default connect(mapStateToProps, actionCreators)(SupplierEnquiries)
