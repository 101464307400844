import { createAction, handleActions } from 'redux-actions'

const setActiveTab = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/SET_ACTIVE_TAB')
const resetActiveTab = createAction('MODALS/CUSTOMER_ENQUIRY_IMPORT/RESET_ACTIVE_TAB')

const defaultState = 'rows'

const reducer = handleActions(
  {
    [setActiveTab]: (state, { payload }) => payload,
    [resetActiveTab]: () => defaultState,
  },
  defaultState,
)

export { setActiveTab, resetActiveTab, reducer }
