import { omit, get } from 'lodash'
import modalProps from '../../../modalProps'
import { createSupplier, updateSupplier } from '../../../../../Api/suppliers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id } = modalProps()

    const data = {
      ...omit(values, ['currency']),
      telephone: values.telephone || null,
      email: values.email || null,
      currency_id: get(values, 'currency.id'),
      sage_ref: values.sage_ref || null,
    }
    
    let response

    if (id) {

      response = await updateSupplier({ id, data })

    } else {

      response = await createSupplier({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)

    throw error

  }

}
