import React from 'react'
import { Modal, Icon } from 'antd'
import generateSupplierEnquiries from '../../../Services/generateSupplierEnquiries'

export default () => (dispatch) => {

  Modal.confirm({
    title: 'Generate Supplier Enquiries',
    content: 'Supplier enquiries will be auto-filled based on the data provided. Therefore, you should ensure that this data complete. Are you sure you want to continue?',
    icon: <Icon type='arrow-right' style={{ color: '#1890ff' }} />,
    okText: 'Yes',
    onOk: () => dispatch(generateSupplierEnquiries()),
  })
  
}
