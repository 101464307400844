import { closeCustomerEnquiryImportModal } from '../State/customerEnquiryImportModal'
import initializeRequest from '../Services/initialize'
import fetchCustomerEnquiry from '../../../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeCustomerEnquiryImportModal())

  if (reload) {
    dispatch(initializeRequest())
    dispatch(fetchCustomerEnquiry())
  }

}
