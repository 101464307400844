import modalProps from '../../../modalProps'
import { pick } from 'lodash'
import { findCustomerEnquiry } from '../../../../../Api/customerEnquiries'
import { setFormInitialValues } from '../State/formState'
import { handleException } from '../../../../../../packages/sota'
import moment from 'moment'

export default () => async (dispatch, getState) => {

  try {

    const { id } = modalProps()

    if (id) {

      const params = {
        include: 'customer.currency,customer_contact',
      }

      const response = await findCustomerEnquiry({ id, params })

      const customerEnquiry = response.data.data
      
      const initialValues = {
        ...pick(customerEnquiry, [
          'id',
          'manager_id',
          'customer_ref',
          'exchange_rate',
          'value',
          'payment_terms',
          'status',
          'notes',
        ]),
        customer: {
          ...pick(customerEnquiry.customer, [
            'id',
            'name',
            'currency',
          ]),
        },
        customer_contact: {
          ...pick(customerEnquiry.customer_contact, [
            'id',
            'name',
          ]),
        },
        received_on: moment(customerEnquiry.received_on),
        respond_by: customerEnquiry.respond_by ? moment(customerEnquiry.respond_by) : undefined,
      }

      dispatch(setFormInitialValues(initialValues))

    } else {
      
      const { user } = getState().app

      const initialValues = {
        customer: {},
        customer_contact: {},
        manager_id: user.id,
        received_on: moment(),
        respond_by: moment().add(1, 'weeks'),
        exchange_rate: 1,
        status: 'Validating',
      }

      dispatch(setFormInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)

  }

}