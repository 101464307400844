import React from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import Parts from './Components/Parts'
import ManufacturerModal from '../../Modals/Manufacturer'
import ManufacturerMergeModal from '../../Modals/ManufacturerMerge'

export default class Manufacturer extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    manufacturerModal: PropTypes.object.isRequired,
    manufacturerMergeModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseManufacturerModal: PropTypes.func.isRequired,
    onCloseManufacturerMergeModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      manufacturerModal,
      manufacturerMergeModal,
      onCloseManufacturerModal,
      onCloseManufacturerMergeModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 200 }}
        contentStyle={{ backgroundColor: 'white', paddingBottom: 60 }}
      >

        {(initialized && activeTab === 0) && <Parts />}

        {manufacturerModal.show && (
          <ManufacturerModal
            id={id}
            onClose={onCloseManufacturerModal}
          />
        )}

        {manufacturerMergeModal.show && (
          <ManufacturerMergeModal
            {...manufacturerMergeModal}
            onClose={onCloseManufacturerMergeModal}
          />
        )}
        
      </Screen>

    )

  }
  
}
