import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Select, Switch, Icon, Divider } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import modalProps from '../../modalProps'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      initialValues,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const { user } = modalProps()

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >

        {({ handleSubmit }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ values: true, submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name='first_name'>
              {({ input, meta }) => (
                <Form.Item
                  label='First Name'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='last_name'>
              {({ input, meta }) => (
                <Form.Item
                  label='Last Name'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='email'>
              {({ input, meta }) => (
                <Form.Item
                  label='Email'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='department_key'>
              {({ input, meta }) => (
                <Form.Item
                  label='Department'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    value={input.value || undefined}
                    onChange={value => input.onChange(value || null)}
                  >
                    {[
                      { label: 'Aerospace & Defence', value: 'A&D' },
                      { label: 'Commercial & Kitting', value: 'C&K' },
                      { label: 'Other', value: 'Other' },
                    ].map(({ label, value }) => (
                      <Select.Option key={value} value={value}>{label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='is_admin'>
              {({ input }) => (
                <Form.Item
                  label='Admin'
                >
                  <Switch
                    {...input}
                    checked={!!input.value}
                    checkedChildren={<Icon type='check' />}
                    style={{ marginTop: 4 }}
                  />
                </Form.Item>
              )}
            </Field>

            {!user && (

              <React.Fragment>

                <Divider />

                <Field name='password'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Password'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input.Password
                        {...input}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='password_confirm'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Confirm'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input.Password
                        {...input}
                      />
                    </Form.Item>
                  )}
                </Field>

              </React.Fragment>

            )}

          </Form>

        )}

      </FormProvider>

    )

  }

}
