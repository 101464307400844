import { createAction, handleActions } from 'redux-actions'

const setCustomerContacts = createAction('SCREENS/CUSTOMER_CONTACTS/SET_CUSTOMER_CONTACTS')
const resetCustomerContacts = createAction('SCREENS/CUSTOMER_CONTACTS/RESET_CUSTOMER_CONTACTS')

const defaultState = []

const reducer = handleActions(
  {
    [setCustomerContacts]: (state, action) => action.payload,
    [resetCustomerContacts]: () => defaultState,
  },
  defaultState,
)

export { setCustomerContacts, resetCustomerContacts, reducer }
