export default (state) => {

  const { controls } = state.screens.Dashboard
    
  return {
    date_range: controls.dateRange.map(value => value.format('YYYY-MM-DD')).join(','),
    user_ids: controls.userIds.length ? controls.userIds.join(',') : undefined,
    department_keys: controls.departmentKeys.length ? controls.departmentKeys.join(',') : undefined,
    service_ids: controls.serviceIds.length ? controls.serviceIds.join(',') : undefined,
    customer_ids: controls.customerIds.length ? controls.customerIds.join(',') : undefined,
  }

}
