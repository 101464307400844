import { Modal } from 'antd'
import { pick } from 'lodash'
import { openSourcedPartModal } from '../../../State/sourcedPartModal'

export default (item) => (dispatch, getState) => {
  
  const { id: supplierEnquiryItemId, part } = item
  
  const { supplierEnquiry } = getState().screens.SupplierEnquiry
  
  const supplier = pick(supplierEnquiry.supplier, [
    'id',
    'name',
    'currency',
  ])

  const service = pick(supplierEnquiry.service, [
    'id',
    'name',
  ])

  const exchangeRate = Number(supplierEnquiry.exchange_rate)

  if (supplier.currency.code !== 'GBP' && exchangeRate === 1) {
    Modal.warning({
      title: 'Invalid Exchange Rate',
      content: `The supplier enquiry's exchange rate needs to be updated. This can be done via the edit button.`,
    })
  } else {
    dispatch(openSourcedPartModal({
      supplierEnquiryItemId,
      part,
      supplier,
      service,
      exchangeRate,
    }))
  }
  
}
