import { createAction, handleActions } from 'redux-actions'

const setSorting = createAction('MODALS/FIND_PART/SET_SORTING')
const resetSorting = createAction('MODALS/FIND_PART/RESET_SORTING')

const defaultState = {
  key: 'customer_quote_items',
  direction: 'desc',
}

const reducer = handleActions(
  {
    [setSorting]: (state, action) => ({ ...state, ...action.payload }),
    [resetSorting]: () => defaultState,
  },
  defaultState,
)

export { setSorting, resetSorting, reducer }
