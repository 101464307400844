import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'

import Screen from '../../Components/Screen'
import Table from "./Components/Table"

import CustomerEnquiryModal from '../../Modals/CustomerEnquiry'

export default class CustomerEnquiriesScreen extends React.PureComponent {

  static propTypes = {
    customerEnquiryModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseCustomerEnquiryModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => (

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New Enquiry
    </Button>

  )

  render () {
    
    const {
      customerEnquiryModal,
      onCloseCustomerEnquiryModal,
    } = this.props

    return (

      <Screen
        title="Enquiries"
        headerRight={this.renderHeaderRight()}
      >

        <Table />

        {customerEnquiryModal.show && (
          <CustomerEnquiryModal
            onClose={onCloseCustomerEnquiryModal}
          />
        )}

      </Screen>

    )
  }
}
