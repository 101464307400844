import React from 'react'
import PropTypes from 'prop-types'
import { Table, Typography, Popconfirm } from 'antd'
import { LinkButton } from '../../../../../packages/sota'

const Rows = props => {

  const {
    tableRows,
    onChangeValue,
    onClickDelete,
  } = props
  
  return (
    
    <Table
      rowKey='guid'
      dataSource={tableRows}
      size='small'
    >

      <Table.Column
        key='manufacturer_part_no'
        title='Manufacturer No.'
        render={(text, record) => (
          <Typography.Text
            editable={{ onChange: value => value !== record.manufacturer_part_no && onChangeValue(record.guid, 'manufacturer_part_no', value) }}
          >
            {record.manufacturer_part_no || ''}
          </Typography.Text>
        )}
      />

      <Table.Column
        key='customer_part_no'
        title='Customer No.'
        render={(text, record) => (
          <Typography.Text
            editable={{ onChange: value => value !== record.customer_part_no && onChangeValue(record.guid, 'customer_part_no', value) }}
          >
            {record.customer_part_no || ''}
          </Typography.Text>
        )}
      />

      <Table.Column
        key='manufacturer.name'
        title='Manufacturer'
        render={(text, record) => (
          <Typography.Text
            editable={{ onChange: value => value !== record['manufacturer.name'] && onChangeValue(record.guid, 'manufacturer.name', value) }}
          >
            {record['manufacturer.name'] || ''}
          </Typography.Text>
        )}
      />

      <Table.Column
        key='quantity'
        title='Quantity'
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => (
          <Typography.Text
            editable={{ onChange: value => value !== record.quantity && onChangeValue(record.guid, 'quantity', value) }}
          >
            {record.quantity ? record.quantity.toString() : ''}
          </Typography.Text>
        )}
      />

      <Table.Column
        key='service.name'
        title='Service'
        render={(text, record) => (
          <Typography.Text
            editable={{ onChange: value => value !== record['service.name'] && onChangeValue(record.guid, 'service.name', value) }}
          >
            {record['service.name'] || ''}
          </Typography.Text>
        )}
      />

      <Table.Column
        key='part.description'
        title='Part Description'
        render={(text, record) => (
          <Typography.Text
            ellipsis
            editable={{ onChange: value => value !== record['part.description'] && onChangeValue(record.guid, 'part.description', value) }}
          >
            {record['part.description'] || ''}
          </Typography.Text>
        )}
      />

      <Table.Column
        key='delete'
        title=''
        render={(text, record) => (
          <Popconfirm
            title='Are you sure you want to delete this row?'
            okText='Yes'
            cancelText='No'
            onConfirm={() => onClickDelete(record.guid)}
          >
            <LinkButton
              type='primary'
              icon='delete'
            >
              Delete Row
            </LinkButton>
          </Popconfirm>
        )}
      />

    </Table>

  )

}

Rows.propTypes = {
  tableRows: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
}

export default Rows
