import modalProps from '../modalProps'
import fetchManufacturers from '../../../Core/Services/fetchManufacturers'
import fetch from '../Services/fetch'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchManufacturers())

  dispatch(fetch())

}
