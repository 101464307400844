import { createAction, handleActions } from 'redux-actions'

const setLoading = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/SET_LOADING")
const resetLoading = createAction("SCREENS/SUPPLIER_ENQUIRY/REQUIREMENTS/RESET_LOADING")

const defaultState = false

const reducer = handleActions(
  {
    [setLoading]: (state, action) => action.payload,
    [resetLoading]: () => defaultState,
  },
  defaultState,
)

export { setLoading, resetLoading, reducer }
