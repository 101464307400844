export default () => (dispatch, getState) => {

  const { token } = getState().app

  const { searchTerm, filters, sorting } = getState().screens.Users.Table

  const params = {}

  if (searchTerm) params.search = searchTerm

  if (filters.departmentKey) params['filter[department_key]'] = filters.departmentKey
  
  if (filters.status) params['filter[status]'] = filters.status

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'

  let url = `/api/users/export?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.open(url)

}
