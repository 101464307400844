import { closePartModal } from '../State/partModal'
import fetch from '../Services/fetch'

export default (part) => (dispatch) => {
  
  dispatch(closePartModal())
  
  if (part) {
    
    dispatch(fetch())
    
  }
  
}
