import { createAction, handleActions } from 'redux-actions'

const openPartMergeModal = createAction('SCREENS/PART/OPEN_PART_MERGE_MODAL')
const closePartMergeModal = createAction('SCREENS/PART/CLOSE_PART_MERGE_MODAL')

const defaultState = {
  show: false,
  part: null,
}

const reducer = handleActions(
  {
    [openPartMergeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      part: action.payload.part,
    }),
    [closePartMergeModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openPartMergeModal, closePartMergeModal, reducer }
