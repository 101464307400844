import { combineReducers } from 'redux'

import { reducer as searchTerm } from './State/searchTerm'
import { reducer as sorting } from './State/sorting'
import { reducer as customerEnquiries } from './State/customerEnquiries'

export default combineReducers({
  searchTerm,
  sorting,
  customerEnquiries,
})
