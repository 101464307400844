import modalProps from '../../../modalProps'
import { openPartModal } from '../State/partModal'

export default () => (dispatch) => {

  const {
    manufacturer_part_no: manufacturerPartNo,
    manufacturer,
  } = modalProps().customerEnquiryItem

  dispatch(openPartModal({
    manufacturerNo: manufacturerPartNo,
    manufacturer,
    internalNo: manufacturerPartNo && manufacturerPartNo.replace(/\W/g, ''),
  }))

}
