import { combineReducers } from 'redux'

import { reducer as searchTerm } from './State/searchTerm'
import { reducer as sorting } from './State/sorting'
import { reducer as parts } from './State/parts'

export default combineReducers({
  searchTerm,
  sorting,
  parts,
})
