export default (values) => () => {

  const errors = {}

  if (!values.internal_no) errors.internal_no = 'Internal PN is required'

  if (!values.manufacturer.id) errors.manufacturer = 'Manufacturer is required'

  if (!values.manufacturer_no) errors.manufacturer_no = 'Manufacturer PN is required'

  if (!values.description) errors.description = 'Description is required'

  return errors

}

