import { closeSourcedPartModal } from '../State/sourcedPartModal'
import initialize from '../Services/initialize'

export default (sourcedPart) => async (dispatch) => {

  dispatch(closeSourcedPartModal())
  
  if (sourcedPart) {
    dispatch(initialize())
  }
  
}
