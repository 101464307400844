import { updateSupplierEnquiry } from '../../../../../Api/supplierEnquiries'
import { handleException } from '../../../../../../packages/sota'
import initialize from '../../../Services/initialize'

export default (supplierEnquiry, event) => async (dispatch) => {

  try {

    await updateSupplierEnquiry({
      id: supplierEnquiry.id,
      data: {
        status: event.target.value,
      },
    })

    dispatch(initialize())

  } catch (error) {

    handleException(error)

  }

}
