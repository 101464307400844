import { connect } from "react-redux"
import { includes } from 'lodash'
import SupplierEnquiryItem from "./SupplierEnquiryItem"

import onToggleClosed from './Handlers/onToggleClosed'
import onRemove from './Handlers/onRemove'
import onChangeField from './Handlers/onChangeField'
import onClickManufacturerNo from './Handlers/onClickManufacturerNo'

const mapStateToProps = (state, props) => ({
  isClosed: includes(state.screens.SupplierEnquiry.Requirements.closedItems, props.item.guid),
})

const actionCreators = {
  onToggleClosed,
  onRemove,
  onChangeField,
  onClickManufacturerNo,
}

export default connect(mapStateToProps, actionCreators)(SupplierEnquiryItem)
