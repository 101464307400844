import React from 'react'
import { Modal, Icon } from 'antd'
import modalProps from '../modalProps'
import deleteUser from '../Services/delete'

export default () => (dispatch) => {

  const { user } = modalProps()

  Modal.confirm({
    title: `Delete "${user.name}"`,
    content: 'Are you sure you want to delete this user?',
    icon: <Icon type='delete' style={{ color: '#ff4d4f' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteUser()),
  })

}
