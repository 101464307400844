import { getParts } from '../../../../../Api/parts'
import { getSourcedParts } from '../../../../../Api/sourcedParts'
import { setSuitableParts } from '../State/suitableParts'
import { setSourcedParts } from '../State/sourcedParts'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id } = getState().screens.CustomerEnquiry.customerEnquiry

    const getPartsResponse = await getParts({
      params: {
        'filter[customer_enquiry_id]': id,
        include: 'manufacturer',
      },
    })

    const getSourcedPartsResponse = await getSourcedParts({
      params: {
        'filter[customer_enquiry_id]': id,
        include: 'part.manufacturer,supplier.currency,service,customer_quote_items',
      },
    })

    dispatch(setSuitableParts(getPartsResponse.data.data))

    dispatch(setSourcedParts(getSourcedPartsResponse.data.data))

  } catch (error) {

    handleException(error)

  }

}
