import { createAction, handleActions } from 'redux-actions'

const setActiveTab = createAction("SCREENS/SUPPLIER_ENQUIRY/SET_ACTIVE_TAB")
const resetActiveTab = createAction("SCREENS/SUPPLIER_ENQUIRY/RESET_ACTIVE_TAB")

const defaultState = 0

const reducer = handleActions(
  {
    [setActiveTab]: (state, action) => action.payload,
    [resetActiveTab]: () => defaultState,
  },
  defaultState,
)

export { setActiveTab, resetActiveTab, reducer }
