import { createSelector } from 'reselect'
import { get, reject, every } from 'lodash'

const selectSearch = state => state.modals.CustomerEnquiryImport.search
const selectData = state => state.modals.CustomerEnquiryImport.data

export default createSelector(
  selectSearch,
  selectData,
  (search, data) => {

    let customerEnquiryItems = get(data, 'payload.customer_enquiry_items', [])

    if (search) {
      customerEnquiryItems = reject(customerEnquiryItems, row => every(Object.keys(row), field => {
        if (field === 'guid') return true
        return !(String(row[field]).toLowerCase().includes(search.toLowerCase()))
      }))
    }

    return customerEnquiryItems

  },
)
