import { connect } from 'react-redux'
import MoveSourcedPart from './MoveSourcedPart'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onChangeField from './Handlers/onChangeField'
import onClickMove from './Handlers/onClickMove'

const mapStateToProps = (state) => ({
  customerEnquiries: state.core.customerEnquiries,
  fields: state.modals.MoveSourcedPart.fields,
  submitting: state.modals.MoveSourcedPart.submitting,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onChangeField,
  onClickMove,
}

export default connect(mapStateToProps, actionCreators)(MoveSourcedPart)
