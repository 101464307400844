import { connect } from 'react-redux'
import Component from './Component'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onClickMove from './Handlers/onClickMove'
import onCloseMoveSourcedPartModal from './Handlers/onCloseMoveSourcedPartModal'

const mapStateToProps = (state) => ({
  isInitialized: !!state.modals.SourcedPart.Form.formState.initialValues,
  isSubmitting: state.modals.SourcedPart.Form.formState.submitting,
  moveSourcedPartModal: state.modals.SourcedPart.moveSourcedPartModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onSubmit,
  onClickMove,
  onCloseMoveSourcedPartModal,
}

export default connect(mapStateToProps, actionCreators)(Component)