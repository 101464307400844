import { message } from 'antd'
import modalProps from '../../../modalProps'
import { mergePart } from '../../../../../Api/parts'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { part } = modalProps()

  const data = {
    part_id: values.part.id,
  }

  try {

    const response = await mergePart({ id: part.id, data })
    
    modalProps().onClose(response.data.data)
    
    message.success(`Merged "${part.manufacturer_no}" into "${values.part.manufacturer_no}" successfully`)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
