import { debounce } from 'lodash'
import shouldAddRow from '../../../Utils/shouldAddRow'
import addRow from '../../../Services/addRow'
import triggerSave from '../../../Services/triggerSave'
import guessFields from '../../../Services/guessFields'

const guessFieldsWithDebounce = debounce((dispatch, index, field, value) => dispatch(guessFields(index, field, value)), 1000)

export default (index, field, value) => (dispatch, getState) => {

  const { values } = getState().screens.CustomerEnquiry.Request.formState

  if (shouldAddRow(values)) {
    dispatch(addRow())
  }

  dispatch(triggerSave())

  guessFieldsWithDebounce(dispatch, index, field, value)

}
