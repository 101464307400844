import { createAction, handleActions } from 'redux-actions'

const setSuitableParts = createAction('SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/SET_SUITABLE_PARTS')
const resetSuitableParts = createAction('SCREENS/CUSTOMER_ENQUIRY/SOURCED_PARTS/RESET_SUITABLE_PARTS')

const defaultState = []

const reducer = handleActions(
  {
    [setSuitableParts]: (state, action) => action.payload,
    [resetSuitableParts]: () => [],
  },
  defaultState,
)

export { setSuitableParts, resetSuitableParts, reducer }
