import { createAction, handleActions } from 'redux-actions'

const loadServices = createAction('CORE/SERVICES/LOAD_SERVICES')

const defaultState = []

const reducer = handleActions(
  {
    [loadServices]: (state, action) => action.payload,
  },
  defaultState,
)

export { loadServices, reducer }
