import { combineReducers } from 'redux'

import { reducer as users } from './State/users'
import { reducer as services } from './State/services'
import { reducer as customers } from './State/customers'
import { reducer as customerPaymentTerms } from './State/customerPaymentTerms'
import { reducer as manufacturers } from './State/manufacturers'
import { reducer as customerEnquiries } from './State/customerEnquiries'
import { reducer as customerEnquiryStatuses } from './State/customerEnquiryStatuses'
import { reducer as uploads } from './State/uploads'

export default combineReducers({
  users,
  services,
  customers,
  customerPaymentTerms,
  manufacturers,
  customerEnquiries,
  customerEnquiryStatuses,
  uploads,
})
