import screenProps from '../../../screenProps'
import { getSupplierContacts } from '../../../../../Api/supplierContacts'
import { setSupplierContacts } from '../State/supplierContacts'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id } = screenProps().match.params

    const { searchTerm, sorting } = getState().screens.Supplier.SupplierContacts

    const params = {
      search: searchTerm,
      'filter[supplier_id]': id,
    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'

    const response = await getSupplierContacts({ id, params })

    const supplierContacts = response.data.data

    dispatch(setSupplierContacts(supplierContacts))

  } catch (error) {

    handleException(error)

  }

}
