import { createAction, handleActions } from 'redux-actions'

const setManufacturer = createAction('SCREENS/MANUFACTURER/SET_MANUFACTURER')
const resetManufacturer = createAction('SCREENS/MANUFACTURER/RESET_MANUFACTURER')

const defaultState = null

const reducer = handleActions(
  {
    [setManufacturer]: (state, action) => action.payload,
    [resetManufacturer]: () => defaultState,
  },
  defaultState,
)

export { setManufacturer, resetManufacturer, reducer }
