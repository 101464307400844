import { combineReducers } from 'redux'

import { reducer as salesOrderItems } from './State/salesOrderItems'
import { reducer as salesOrderModal } from './State/salesOrderModal'
import { reducer as salesOrderItemModal } from './State/salesOrderItemModal'

export default combineReducers({
  salesOrderItems,
  salesOrderModal,
  salesOrderItemModal,
})
