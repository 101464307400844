import { resetData } from '../../../State/data'
import { setFilter } from '../../../State/filters'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (key, value) => (dispatch) => {
  
  if (key === 'status') dispatch(resetData())

  dispatch(setFilter({ key, value }))

  dispatch(firstPage())

  dispatch(fetch())

}
