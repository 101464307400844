import modalProps from '../../../modalProps'
import { pick } from 'lodash'
import chance from 'chance'
import { findSourcedPart } from '../../../../../Api/sourcedParts'
import { getCustomerEnquiryItems } from '../../../../../Api/customerEnquiryItems'
import { setFormInitialValues } from '../State/formState'
import { setCustomerEnquiryItemOptions } from '../State/customerEnquiryItemOptions'
import getUnitPrice from '../../../Utils/getUnitPrice'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id } = modalProps()

    const params = {
      include: [
        'customer_enquiry.customer.currency',
        'part',
        'supplier.currency',
        'service',
      ].join(','),
    }

    const findSourcedPartResponse = await findSourcedPart({ id, params })

    const sourcedPart = findSourcedPartResponse.data.data

    const getCustomerEnquiryItemsResponse = await getCustomerEnquiryItems({
      params: {
        'filter[customer_enquiry_id]': sourcedPart.customer_enquiry_id,
        'filter[part_id]': sourcedPart.part_id,
        include: 'service',
      },
    })

    const customerEnquiryItems = getCustomerEnquiryItemsResponse.data.data

    const initialValues = {
      part: {
        ...pick(sourcedPart.part, [
          'id',
          'internal_no',
        ]),
      },
      service: {
        ...pick(sourcedPart.service, [
          'id',
          'name',
        ]),
      },
      customer_enquiry_item_id: customerEnquiryItems.length === 1 ? customerEnquiryItems[0].id : null,
      sourced_part_id: sourcedPart.id,
      quantity: sourcedPart.quantity,
      lead_time: sourcedPart.lead_time,
      rohs: sourcedPart.rohs,
      date_code: sourcedPart.date_code,
      sourced_part: sourcedPart,
      customer_enquiry: sourcedPart.customer_enquiry,
      guid: chance().guid(),
    }

    dispatch(setFormInitialValues({
      ...initialValues,
      unit_price: getUnitPrice(initialValues, initialValues.quantity, 25),
    }))

    dispatch(setCustomerEnquiryItemOptions(customerEnquiryItems))

  } catch (error) {

    handleException(error)

  }

}
