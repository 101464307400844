import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Screen as SotaScreen } from '../../packages/sota'
import AppBar from './AppBar'

const Screen = (props) => {

  const {
    uploads,
    ...restProps
  } = props

  return (

    <SotaScreen
      AppBar={AppBar}
      maxScreenWidth={1400}
      uploads={uploads}
      {...restProps}
    />

  )
}

Screen.propTypes = {
 uploads: PropTypes.object,
}

Screen.defaultProps = {
 uploads: undefined,
}

const mapStateToProps = (state) => ({
  uploads: state.core.uploads,
})

export default connect(mapStateToProps)(Screen)