import screenProps from '../screenProps'
import initialize from '../Services/initialize'

export default (props) => async (dispatch) => {

  screenProps(props)

  await dispatch(initialize())

}
