import { get } from 'lodash'
import qs from 'qs'

const selectActiveTab = (state) => {
  
  const tab = get(qs.parse(state.router.location.search, { ignoreQueryPrefix: true }), 'tab')
  
  return tab ? Number(tab) : 0
  
}

export default selectActiveTab
