import { connect } from 'react-redux'
import Manufacturers from './Manufacturers'

import componentDidMount from './Handlers/componentDidMount'
import onClickNew from './Handlers/onClickNew'
import onCloseManufacturerModal from './Handlers/onCloseManufacturerModal'

const mapStateToProps = (state) => ({
  manufacturerModal: state.screens.Manufacturers.manufacturerModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseManufacturerModal,
}

export default connect(mapStateToProps, actionCreators)(Manufacturers)
