import { closeMovedSourcedPartModal } from '../State/moveSourcedPartModal'
import modalProps from '../modalProps'

export default (reload = false) => (dispatch) => {
  
  dispatch(closeMovedSourcedPartModal())
  
  if (reload) {
    modalProps().onClose(true)
  }
  
}
