import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openOrderQuoteItemModal = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/OPEN_ORDER_QUOTE_ITEM_MODAL')
const closeOrderQuoteItemModal = createAction('SCREENS/CUSTOMER_ENQUIRY/CUSTOMER_QUOTE/CLOSE_ORDER_QUOTE_ITEM_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openOrderQuoteItemModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id', null),
    }),
    [closeOrderQuoteItemModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openOrderQuoteItemModal, closeOrderQuoteItemModal, reducer }
