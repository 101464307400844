import { createAction, handleActions } from 'redux-actions'

const openUseSupplierModal = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/OPEN_USE_SUPPLIER_MODAL')
const closeUseSupplierModal = createAction('SCREENS/CUSTOMER_ENQUIRY/REQUEST/CLOSE_USE_SUPPLIER_MODAL')

const defaultState = {
  show: false,
  index: null,
}

const reducer = handleActions(
  {
    [openUseSupplierModal]: (state, { payload }) => ({ ...state, show: true, ...payload }),
    [closeUseSupplierModal]: () => defaultState,
  },
  defaultState,
)

export { openUseSupplierModal, closeUseSupplierModal, reducer }
