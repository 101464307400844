import { combineReducers } from 'redux'

import { reducer as supplierEnquiry } from './State/supplierEnquiry'
import { reducer as initialized } from './State/initialized'
import { reducer as activeTab } from './State/activeTab'
import { reducer as supplierEnquiryModal } from './State/supplierEnquiryModal'
import { reducer as duplicateSupplierEnquiryModal } from './State/duplicateSupplierEnquiryModal'

import Requirements from './Components/Requirements/reducer'

export default combineReducers({
  supplierEnquiry,
  initialized,
  activeTab,
  supplierEnquiryModal,
  duplicateSupplierEnquiryModal,
  Requirements,
})
