import modalProps from '../modalProps'
import fetchServices from '../../../Core/Services/fetchServices'
import fetch from '../Components/Finder/Services/fetch'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchServices())

  dispatch(fetch())

}
