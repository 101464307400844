import { connect } from 'react-redux'
import Table from './Table'

import onClickRow from './Handlers/onClickRow'
import onChangeStatus from './Handlers/onChangeStatus'

const mapStateToProps = (state) => ({
  supplierEnquiries: state.screens.CustomerEnquiry.SupplierEnquiries.supplierEnquiries,
})

const actionCreators = {
  onClickRow,
  onChangeStatus,
}

export default connect(mapStateToProps, actionCreators)(Table)
