import { push } from 'connected-react-router'
import { closeDuplicateSupplierEnquiryModal } from '../State/duplicateSupplierEnquiryModal'

export default (supplierEnquiry) => (dispatch) => {
  
  dispatch(closeDuplicateSupplierEnquiryModal())

  if (supplierEnquiry) {

    dispatch(push(`/portal/supplier_enquiries/${supplierEnquiry.id}`))

  }
  
}
