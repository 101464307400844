import { openSalesOrderModal } from '../../../State/salesOrderModal'
import { openOrderQuoteItemModal } from '../../../State/orderQuoteItemModal'

export default (customerEnquiry, customerQuoteItem) => (dispatch) => {

  if (!customerEnquiry.sales_order_number) {
    dispatch(openSalesOrderModal({
      customerEnquiry,
      customerQuoteItemId: customerQuoteItem.id,
    }))
  } else {
    dispatch(openOrderQuoteItemModal({
      id: customerQuoteItem.id,
    }))
  }
  
}
