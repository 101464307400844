import { connect } from 'react-redux'
import ServiceSelect from './ServiceSelect'

const mapStateToProps = (state) => ({
  services: state.core.services,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(ServiceSelect)
