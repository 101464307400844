import { combineReducers } from 'redux'

import { reducer as suitableParts } from './State/suitableParts'
import { reducer as sourcedParts } from './State/sourcedParts'
import { reducer as sourcedPartModal } from './State/sourcedPartModal'
import { reducer as closedParts } from './State/closedParts'
import { reducer as quoteSourcedPartModal } from './State/quoteSourcedPartModal'

export default combineReducers({
  suitableParts,
  sourcedParts,
  sourcedPartModal,
  closedParts,
  quoteSourcedPartModal,
})
