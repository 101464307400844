import { createAction, handleActions } from 'redux-actions'
import modalProps from '../modalProps'

const openMovedSourcedPartModal = createAction('MODALS/SOURCED_PART/OPEN_MOVE_SOURCED_PART_MODAL')
const closeMovedSourcedPartModal = createAction('MODALS/SOURCED_PART/CLOSE_MOVE_SOURCED_PART_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openMovedSourcedPartModal]: (state) => ({
      ...state,
      show: true,
      id: modalProps().id,
    }),
    [closeMovedSourcedPartModal]: () => defaultState,
  },
  defaultState,
)

export { openMovedSourcedPartModal, closeMovedSourcedPartModal, reducer }
