import screenProps from '../screenProps'
import reset from '../Services/reset'
import resetParts from '../Components/Parts/Services/reset'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(reset())

  dispatch(resetParts())

}
