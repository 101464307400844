import { createAction, handleActions } from 'redux-actions'

const setToken = createAction('APP/SET_TOKEN')
const unsetToken = createAction('APP/UNSET_TOKEN')

const defaultState = null

const reducer = handleActions(
  {
    [setToken]: (state, action) => action.payload,
    [unsetToken]: () => null,
  },
  defaultState,
)

export { setToken, unsetToken, reducer }