import { createAction, handleActions } from 'redux-actions'

const loadCustomerEnquiries = createAction('CORE/CUSTOMER_ENQUIRIES/LOAD_CUSTOMER_ENQUIRIES')

const defaultState = []

const reducer = handleActions(
  {
    [loadCustomerEnquiries]: (state, action) => action.payload,
  },
  defaultState,
)

export { loadCustomerEnquiries, reducer }
