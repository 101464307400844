import modalProps from '../../../modalProps'
import { findManufacturer } from '../../../../../Api/manufacturers'
import { setFormInitialValues } from '../State/formState'
import fetchMatches from './fetchMatches'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch) => {

  try {

    const { id, name } = modalProps()

    if (id) {

      const response = await findManufacturer({ id })

      const manufacturer = response.data.data

      const initialValues = {
        ...manufacturer,
      }

      dispatch(setFormInitialValues(initialValues))

    } else {

      const initialValues = {
        name,
      }

      dispatch(setFormInitialValues(initialValues))

      dispatch(fetchMatches(name))

    }

  } catch (error) {

    handleException(error)

  }

}
