import { connect } from 'react-redux'
import { get } from 'lodash'
import CustomerEnquiryImport from './CustomerEnquiryImport'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onChangeTab from './Handlers/onChangeTab'
import onChangeSearch from './Handlers/onChangeSearch'
import onClickCreateAll from './Handlers/onClickCreateAll'
import onClickDelete from './Handlers/onClickDelete'
import onClickRun from './Handlers/onClickRun'
import onCloseManufacturerModal from './Handlers/onCloseManufacturerModal'
import onClosePartModal from './Handlers/onClosePartModal'

const mapStateToProps = (state) => ({
  activeTab: state.modals.CustomerEnquiryImport.activeTab,
  search: state.modals.CustomerEnquiryImport.search,
  manufacturers: get(state.modals.CustomerEnquiryImport.data, 'new.manufacturers', []),
  parts: get(state.modals.CustomerEnquiryImport.data, 'new.parts', []),
  creatingAll: state.modals.CustomerEnquiryImport.creatingAll,
  running: state.modals.CustomerEnquiryImport.running,
  manufacturerModal: state.modals.CustomerEnquiryImport.manufacturerModal,
  partModal: state.modals.CustomerEnquiryImport.partModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onChangeTab,
  onChangeSearch,
  onClickCreateAll,
  onClickDelete,
  onClickRun,
  onCloseManufacturerModal,
  onClosePartModal,
}

export default connect(mapStateToProps, actionCreators)(CustomerEnquiryImport)
