import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteManufacturer } from '../../../Api/manufacturers'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { manufacturer } = getState().screens.Manufacturer

    await deleteManufacturer({ id: manufacturer.id })

    message.success(`Deleted Manufacturer`)

    dispatch(push(`/portal/manufacturers`))

  } catch (error) {

    handleException(error)

  }

}
