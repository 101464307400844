import updateMediaCollection from '../../../Services/updateMediaCollection'
import { incrementFileChanges } from '../../../State/fileChanges'

export default (key, id) => async (dispatch) => {

  switch (key) {
    case 'move-to-order-bucket':
      await dispatch(updateMediaCollection(id, 'order'))
      break
    case 'move-to-enquiry-bucket':
      await dispatch(updateMediaCollection(id, 'enquiry'))
      break
    default:
      break
  }

  dispatch(incrementFileChanges())

}
