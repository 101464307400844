import React from 'react'
import { Empty as AntEmpty } from 'antd'
import styles from './styles.css'

const Empty = () => (

  <div className={styles.wrapper}>
    
    <AntEmpty />
    
  </div>

)

export default Empty
