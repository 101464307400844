import { omit } from 'lodash'
import modalProps from '../../../modalProps'
import { createCustomerQuoteItem, updateCustomerQuoteItem } from '../../../../../Api/customerQuoteItems'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  try {

    const { id, customerEnquiryItemId } = modalProps()
    
    const data = {
      ...omit(values, ['sourced_part', 'part', 'service']),
      customer_enquiry_item_id: customerEnquiryItemId,
      part_id: values.part.id,
      service_id: values.service.id,
      lead_time: values.lead_time || null,
      rohs: values.rohs !== undefined ? values.rohs : null,
      date_code: values.date_code || null,
      notes: values.notes || null,
      guid: values.guid,
    }

    let response

    if (id) {

      response = await updateCustomerQuoteItem({ id, data })

    } else {

      response = await createCustomerQuoteItem({ data })

    }

    modalProps().onClose(response.data.data)

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
