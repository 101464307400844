import findParts from './findParts'
// import findSuppliers from './findSuppliers'
import updateRow from './updateRow'

export default (index, field, value) => async (dispatch, getState) => {

  const { values } = getState().screens.CustomerEnquiry.Request.formState

  const customerEnquiryItem = values.customer_enquiry_items[index]

  const data = {}

  if (field === 'manufacturer_part_no') {

    if (value) {

      const parts = await dispatch(findParts(value, null, customerEnquiryItem.manufacturer.id))

      if (parts.length !== 1) {
        data.part = null
      }

      if (parts.length === 1) {
        const part = parts[0]
        data.manufacturer = part.manufacturer
        data.part = part
      }

    } else {

      data.part = null

    }

    dispatch(updateRow(index, data))

  }

  if (field === 'customer_part_no') {

    if (value && !customerEnquiryItem.manufacturer_part_no) {

      const parts = await dispatch(findParts(null, value, customerEnquiryItem.manufacturer.id))

      if (parts.length === 1) {
        const part = parts[0]
        data.manufacturer_part_no = part.manufacturer_no
        data.manufacturer = part.manufacturer
        data.part = part
      }

      dispatch(updateRow(index, data))

    }

  }

  if (field === 'manufacturer') {

    if (value && (customerEnquiryItem.manufacturer_part_no || customerEnquiryItem.customer_part_no)) {

      const parts = await dispatch(findParts(
        customerEnquiryItem.manufacturer_part_no,
        !customerEnquiryItem.manufacturer_part_no && customerEnquiryItem.customer_part_no,
        value,
      ))

      if (parts.length !== 1) {
        data.part = null
      }

      if (parts.length === 1) {
        const part = parts[0]
        data.manufacturer_part_no = part.manufacturer_no
        data.part = part
      }

    } else {

      data.part = null
      
    }

    dispatch(updateRow(index, data))

  }

  /*

  if (field === 'service_id') {

    if (value && customerEnquiryItem.part && !customerEnquiryItem.suppliers.length) {

      const suppliers = await dispatch(findSuppliers(customerEnquiryItem.part.id, value))
      
      data.suppliers = suppliers

      dispatch(updateRow(index, data))

    }

  }

  */

}
