import { connect } from "react-redux"
import Requirements from "./Requirements"

import selectRows from './Selectors/selectRows'

import componentDidMount from './Handlers/componentDidMount'
import onFormChange from './Handlers/onFormChange'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'
import onClickAddRequirement from './Handlers/onClickAddRequirement'
import onCloseSelectPartModal from './Handlers/onCloseSelectPartModal'
import onCloseSourcedPartModal from './Handlers/onCloseSourcedPartModal'
import onCloseQuoteSourcedPartModal from './Handlers/onCloseQuoteSourcedPartModal'

const mapStateToProps = (state) => ({
  rows: selectRows(state),
  initialValues: state.screens.SupplierEnquiry.Requirements.formState.initialValues,
  customerEnquiryId: state.screens.SupplierEnquiry.supplierEnquiry.customer_enquiry_id,
  selectPartModal: state.screens.SupplierEnquiry.Requirements.selectPartModal,
  sourcedPartModal: state.screens.SupplierEnquiry.Requirements.sourcedPartModal,
  quoteSourcedPartModal: state.screens.SupplierEnquiry.Requirements.quoteSourcedPartModal,
})

const actionCreators = {
  componentDidMount,
  onFormChange,
  onSubmit,
  validate,
  onClickAddRequirement,
  onCloseSelectPartModal,
  onCloseSourcedPartModal,
  onCloseQuoteSourcedPartModal,
}

export default connect(mapStateToProps, actionCreators)(Requirements)
