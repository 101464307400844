import { openSourcedPartModal } from '../../../State/sourcedPartModal'

export default (sourcedPart) => (dispatch, getState) => {

  const { supplierEnquiry } = getState().screens.SupplierEnquiry

  dispatch(openSourcedPartModal({
    id: sourcedPart.id,
    exchangeRate: Number(supplierEnquiry.exchange_rate),
  }))
  
}
