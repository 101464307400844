import { message } from 'antd'
import modalProps from '../modalProps'
import { createImportParts } from '../../../Api/imports'
import { runCustomerEnquiryImport } from '../../../Api/customerEnquiries'
import fetch from './fetch'
import { startCreatingAll, finishCreatingAll } from '../State/creatingAll'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id, onClose } = modalProps()

  dispatch(startCreatingAll())

  try {

    await createImportParts({ id })

    message.success('Parts created')

    const { data: { success } } = await runCustomerEnquiryImport({ id })

    if (success) {
      message.success('Import successful')
      onClose(true)
    } else {
      dispatch(fetch())
    }

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishCreatingAll())

  }

}
