import screenProps from '../screenProps'
import reset from '../Services/reset'
import resetRequirements from '../Components/Requirements/Services/reset'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(reset())
  
  dispatch(resetRequirements())

}
