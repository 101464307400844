import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntdTable } from 'antd'
import moment from 'moment'
import styles from './Table.css'

import SubTable from './Components/SubTable'

const Table = (props) => {

  const {
    supplierEnquiries,
    onClickRow,
    onChangeStatus,
  } = props

  return (

    <AntdTable
      dataSource={supplierEnquiries}
      rowKey="id"
      pagination={false}
      size="middle"
      className={styles.table}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
      expandedRowRender={record => <SubTable tableRows={record.supplier_enquiry_items} />}
      expandedRowClassName={() => styles.subTable}
    >

      <AntdTable.Column
        key="reference"
        title="Ref."
        render={(text, record) => (
          <strong>
            {record.reference}
          </strong>
        )}
      />

      <AntdTable.Column
        key="supplier.name"
        title="Supplier"
        render={(text, record) => `${record.supplier.name} (${record.supplier.currency.symbol})`}
      />

      <AntdTable.Column
        key="created_at"
        title="Started"
        render={(text, record) => moment(record.created_at).calendar(null, {
          sameDay: '[Today], h:mm a',
          nextDay: '[Tomorrow], h:mm a',
          lastDay: '[Yesterday], h:mm a',
          nextWeek: 'D MMM YYYY [at] h:mm a',
          lastWeek: 'D MMM YYYY [at] h:mm a',
          sameElse: 'D MMM YYYY [at] h:mm a',
        })}
      />

      <AntdTable.Column
        key="service.name"
        title="Service"
        render={(text, record) => record.service.name}
      />

      <AntdTable.Column
        key='status'
        title="Status"
        render={(text, record) => (
          <select value={record.status} onClick={(e) => e.stopPropagation()} onChange={(event) => onChangeStatus(record, event)}>
            <option value='Draft'>Draft</option>
            <option value='Sent'>Sent</option>
            <option value='Responded'>Responded</option>
          </select>
        )}
      />

    </AntdTable>

  )

}

Table.propTypes = {
  supplierEnquiries: PropTypes.array.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}

export default Table
