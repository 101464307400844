import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Finder from './Components/Finder'

export default class UseSupplier extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    return (

      <Modal
        visible
        title='Use Supplier'
        width={900}
        footer={false}
        maskClosable={false}
        onCancel={() => this.props.onClose()}
      >

        <Finder />

      </Modal>

    )

  }

}
