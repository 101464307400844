import { connect } from 'react-redux'
import SalesOrderItemFooter from './SalesOrderItemFooter'

import selectTotals from './Selectors/selectTotals'

import onClickAdd from './Handlers/onClickAdd'

const mapStateToProps = (state) => ({
  totals: selectTotals(state),
})

const actionCreators = {
  onClickAdd,
}

export default connect(mapStateToProps, actionCreators)(SalesOrderItemFooter)
