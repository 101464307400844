import screenProps from '../screenProps'
import reset from '../Services/reset'
import resetCustomerContacts from '../Components/CustomerContacts/Services/reset'
import resetCustomerEnquiries from '../Components/CustomerEnquiries/Services/reset'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(reset())

  dispatch(resetCustomerContacts())
  dispatch(resetCustomerEnquiries())

}
