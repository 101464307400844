import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import MoveSourcePartModal from '../MoveSourcedPart'

import Form from './Components/Form'

export default class SourcedPartModal extends React.PureComponent {

  static propTypes = {
    isInitialized: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    moveSourcedPartModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClickMove: PropTypes.func.isRequired,
    onCloseMoveSourcedPartModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      isInitialized,
      isSubmitting,
      moveSourcedPartModal,
      onSubmit,
      onClickMove,
      onCloseMoveSourcedPartModal,
      onClose,
    } = this.props

    const footer = [
      <Button key="move" type="link" disabled={!isInitialized || isSubmitting} onClick={() => onClickMove()} style={{ float: 'left' }}>
        Move
      </Button>,
      <Button key='cancel' disabled={!isInitialized || isSubmitting} onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' disabled={!isInitialized} loading={isSubmitting} onClick={() => onSubmit()}>
        Save
      </Button>,
    ]

    return (
        
      <Modal
        visible
        title="Sourced Part"
        width={600}
        maskClosable={false}
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

        {moveSourcedPartModal.show && (
          <MoveSourcePartModal
            {...moveSourcedPartModal}
            onClose={onCloseMoveSourcedPartModal}
          />
        )}

      </Modal>
      
    )

  }

}
