import { createAction, handleActions } from 'redux-actions'

const setSearchTerm = createAction('SCREENS/CUSTOMERS/TABLE/SET_SEARCH_TERM')
const resetSearchTerm = createAction('SCREENS/CUSTOMERS/TABLE/RESET_SEARCH_TERM')

const defaultState = ''

const reducer = handleActions(
  {
    [setSearchTerm]: (state, action) => action.payload,
    [resetSearchTerm]: () => defaultState,
  },
  defaultState,
)

export { setSearchTerm, resetSearchTerm, reducer }
