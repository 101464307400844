import modalProps from '../modalProps'
import { deleteCustomerEnquirySalesOrder } from '../../../Api/customerEnquiries'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  try {

    const { customerEnquiry } = modalProps()

    await deleteCustomerEnquirySalesOrder({ id: customerEnquiry.id })

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
