import { connect } from 'react-redux'
import Component from './Component'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'
import onClickCreateManufacturer from './Handlers/onClickCreateManufacturer'
import onCloseManufacturerModal from './Handlers/onCloseManufacturerModal'

const mapStateToProps = (state) => ({
  initialValues: state.modals.Part.Form.formState.initialValues,
  manufacturerModal: state.modals.Part.Form.manufacturerModal,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
  onClickCreateManufacturer,
  onCloseManufacturerModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
