import { connect } from 'react-redux'
import Header from './Header'

import onChangeManufacturerId from './Handlers/onChangeManufacturerId'
import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'

const mapStateToProps = (state) => ({
  manufacturers: state.core.manufacturers,
  selectedManufacturerId: state.screens.Parts.Table.selectedManufacturerId,
  searchTerm: state.screens.Parts.Table.searchTerm,
})

const actionCreators = {
  onChangeManufacturerId,
  onChangeSearch,
  onSearch,
}

export default connect(mapStateToProps, actionCreators)(Header)
