import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectCustomerEnquiryItems = state => get(state.screens.CustomerEnquiry.customerEnquiry, 'customer_enquiry_items', [])
const selectPart = (state, props) => props.part

const selectCustomerPartNumbers = createSelector(
  selectCustomerEnquiryItems,
  selectPart,
  (customerEnquiryItems, part) => {

    const customerPartNumbers = []

    customerEnquiryItems.forEach(customerEnquiryItem => {
      if (customerEnquiryItem.part_id === part.id) {
        customerPartNumbers.push(customerEnquiryItem.customer_part_no)
      }
    })

    return customerPartNumbers

  },
)

export default selectCustomerPartNumbers
