import React from 'react'
import { getServices } from '../../Api/services'

import Select from './Select'

export default class Service extends React.PureComponent {
  
  fetchOptions = async () => {

    const response = await getServices({
      params: {
        sort: 'id',
      },
    })
    
    return response.data.data

  }

  render () {

    return (

      <Select
        keyProp="id"
        labelProp="name"
        placeholder="Select Service"
        fetchOptions={this.fetchOptions}
        {...this.props}
      />

    )
  }
}