import React from 'react'
import PropTypes from 'prop-types'

import Header from './Components/Header'
import Table from './Components/Table'

export default class SupplierEnquiries extends React.PureComponent {
  
  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    return (

      <React.Fragment>

        <Header />

        <Table />

      </React.Fragment>

    )

  }
  
}
