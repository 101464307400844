import { message } from 'antd'
import modalProps from '../../../modalProps'
import { mergeManufacturer } from '../../../../../Api/manufacturers'
import fetchManufacturers from '../../../../../Core/Services/fetchManufacturers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { manufacturer } = modalProps()

  const data = {
    manufacturer_id: values.manufacturer.id,
  }

  try {

    const response = await mergeManufacturer({ id: manufacturer.id, data })

    dispatch(fetchManufacturers({ force: true }))
    
    modalProps().onClose(response.data.data)
    
    message.success(`Merged "${manufacturer.name}" into "${values.manufacturer.name}" successfully`)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
