import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

const ResetPassword = props => {

  const {
    submitting,
    componentDidMount,
    componentWillUnmount,
    onSubmit,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  const footer = []

  footer.push(
    <Button key='cancel' onClick={() => onClose()}>
      Cancel
    </Button>,
    <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
      Reset
    </Button>,
  )

  return (

    <Modal
      visible
      title='Reset Password'
      width={500}
      onCancel={() => onClose()}
      maskClosable={false}
      footer={footer}
    >

      <Form />

    </Modal>

  )

}

ResetPassword.propTypes = {
  submitting: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ResetPassword
