import React from 'react'
import PropTypes from 'prop-types'
import { Screen } from '../../../packages/sota'
import AppBar from '../../Components/AppBar'

export default class Home extends React.PureComponent {

  static propTypes = {
    redirect: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.props.redirect('/portal/dashboard')
  }

  render = () => (
    
    <Screen AppBar={AppBar} />

  )
}