import screenProps from '../screenProps'
import initialize from '../Services/initialize'
import initializeRequirements from '../Components/Requirements/Services/initialize'

export default (props, prevProps) => async (dispatch) => {

  screenProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    await dispatch(initialize())

    await dispatch(initializeRequirements())

  }

}
