import { connect } from 'react-redux'
import SubTable from './SubTable'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(SubTable)
