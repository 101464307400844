import { closeSupplierEnquiryModal } from '../State/supplierEnquiryModal'
import fetch from '../Services/fetch'

export default (supplierEnquiry) => (dispatch) => {
  
  dispatch(closeSupplierEnquiryModal())
  
  if (supplierEnquiry) {
    
    dispatch(fetch())
    
  }
  
}
