import { updateMedia } from '../../../Api/media'
import { handleException } from '../../../../packages/sota'

export default (id, collection) => async () => {

  try {

    const data = {
      collection_name: collection,
    }

    await updateMedia({ id, data })

  } catch (error) {

    handleException(error)

    throw error

  }

}
