import React from "react"
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import Request from './Components/Request'
import SupplierEnquiries from './Components/SupplierEnquiries'
import SourcedParts from './Components/SourcedParts'
import CustomerQuote from './Components/CustomerQuote'
import SalesOrder from './Components/SalesOrder'
import CustomerEnquiryModal from '../../Modals/CustomerEnquiry'

export default class CustomerEnquiry extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    customerEnquiryModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseCustomerEnquiryModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      customerEnquiryModal,
      onCloseCustomerEnquiryModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 200 }}
        contentStyle={{ backgroundColor: 'white', paddingBottom: 60, minHeight: 500 }}
      >
        
        {(initialized && activeTab === 0) && <Request />}
        
        {(initialized && activeTab === 1) && <SupplierEnquiries />}
        
        {(initialized && activeTab === 2) && <SourcedParts />}
        
        {(initialized && activeTab === 3) && <CustomerQuote />}
        
        {(initialized && activeTab === 4) && <SalesOrder />}

        {customerEnquiryModal.show && (
          <CustomerEnquiryModal
            id={id}
            onClose={onCloseCustomerEnquiryModal}
          />
        )}
        
      </Screen>

    )
  }
}
