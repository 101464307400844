import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { LinkButton } from '../../../../../../../packages/sota'
import styles from './styles.css'

const SourcedPartFooter = (props) => {

  const {
    totals,
    onClickAdd,
  } = props
  
  const { totalPriceGbp, margin } = totals
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.actions}>
        
        <LinkButton icon='plus' type='primary' onClick={() => onClickAdd()}>
          Add Order Item
        </LinkButton>
        
      </div>

      <div className={styles.totals}>

        <div>
          {`Total: `}
          <span style={{ fontSize: 16, fontWeight: 500 }}>
            {`£${numeral(totalPriceGbp).format('0,0.00')}`}
          </span>
        </div>

        <div style={{ width: 15 }} />

        <div>
          {`Margin: `}
          <span style={{ fontSize: 16, fontWeight: 500, color: margin > 0 ? 'green' : 'red' }}>
            {`${margin > 0 ? '+' : '-'}£${numeral(Math.abs(margin)).format('0,0')}`}
            {` `}
            {margin >= 0 && `(${numeral((margin / totalPriceGbp) * 100).format('0,0')}%)`}
          </span>
        </div>

      </div>
      
    </div>

  )

}

SourcedPartFooter.propTypes = {
  totals: PropTypes.object.isRequired,
  onClickAdd: PropTypes.func.isRequired,
}

export default React.memo(SourcedPartFooter)
