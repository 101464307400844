import { combineReducers } from "redux"

import { reducer as formState } from "./State/formState"
import { reducer as partModal } from "./State/partModal"
import { reducer as supplierModal } from "./State/supplierModal"

export default combineReducers({
  formState,
  partModal,
  supplierModal,
})
