import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { get } from 'lodash'
import styles from "./styles.css"

const Content = (props) => {

  const {
    data,
    isFetching,
    onChange,
    onClickRow,
  } = props

  return (

    <Table
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      className={styles.table}
      onChange={onChange}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
    >

      <Table.Column
        key="name"
        title="Name"
        sorter
        render={(text, record) => <strong>{record.name}</strong>}
      />

      <Table.Column
        key="manager.name"
        title="Manager"
        sorter
        render={(text, record) => get(record.manager, 'name')}
      />

      <Table.Column
        key="currency.code"
        title="Currency"
        width={150}
        sorter
        render={(text, record) => record.currency.code}
      />

      <Table.Column
        key="payment_terms"
        title="Terms"
        width={150}
        sorter
        render={(text, record) => record.payment_terms}
      />

      <Table.Column
        key="sage_ref"
        title="Sage Ref"
        width={150}
        sorter
        render={(text, record) => record.sage_ref}
      />

    </Table>

  )
}

Content.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Content
