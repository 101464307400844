import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './AppBarButton.css'

export default class AppBarButton extends React.PureComponent {

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    style: PropTypes.object,
    buttonClassName: PropTypes.string,
    buttonStyle: PropTypes.object,
    buttonOpenedClassName: PropTypes.string,
    buttonOpenedStyle: PropTypes.object,
    highlight: PropTypes.bool,
    highlightClassName: PropTypes.string,
    highlightStyle: PropTypes.object,
    dropdown: PropTypes.func,
    dropdownPlacement: PropTypes.oneOf(['left','right']),
    dropdownClassName: PropTypes.string,
    dropdownStyle: PropTypes.object,
  }

  static defaultProps = {
    className: undefined,
    style: undefined,
    href: undefined,
    buttonClassName: undefined,
    buttonStyle: undefined,
    buttonOpenedClassName: undefined,
    buttonOpenedStyle: undefined,
    highlight: false,
    highlightClassName: undefined,
    highlightStyle: undefined,
    dropdown: undefined,
    dropdownPlacement: 'left',
    dropdownClassName: undefined,
    dropdownStyle: undefined,
  }

  state = {
    opened: false, 
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.onClickAnywhere)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.onClickAnywhere)
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  close = () => {
    this.setState({ opened: false })
  }

  toggle = () => {
    this.setState({ opened: !this.state.opened })
  }

  onClickAnywhere = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.close()
    }
  }

  onClickWrapper = (event) => {
    if (event.target.getAttribute('data-closes') === 'true') {
      this.close()
    }
  }

  onClickButton = () => {
    if (this.props.dropdown) {
      this.toggle()
    }
  }

  render () {

    const {
      children,
      className,
      style,
      href,
      buttonClassName,
      buttonStyle,
      buttonOpenedClassName,
      buttonOpenedStyle,
      highlight,
      highlightClassName,
      highlightStyle,
      dropdown,
      dropdownPlacement,
      dropdownClassName,
      dropdownStyle,
    } = this.props

    const {
      opened,
    } = this.state

    const dropdownProps = {
      close: this.close,
    }

    const Component = () => (

      <span
        ref={this.setWrapperRef}
        className={classNames(styles.wrapper, className)}
        style={style}
        onClick={this.onClickWrapper}
      >

        <div
          className={classNames(
            styles.button,
            { [styles.opened]: opened },
            buttonClassName,
            buttonOpenedClassName && { [buttonOpenedClassName]: opened }
          )}
          style={{
            ...buttonStyle,
            ...(opened ? buttonOpenedStyle : {})
          }}
          onClick={this.onClickButton}
        >

          {children}

          {highlight && (
            <div
              className={classNames(
                styles.highlight,
                highlightClassName
              )}
              style={highlightStyle}
            />
          )}

        </div>

        {(dropdown && opened) && (

          <div
            className={classNames(
              styles.dropdown,
              styles[dropdownPlacement],
              dropdownClassName
            )}
            style={dropdownStyle}
          >
            {dropdown(dropdownProps)}
          </div>

        )}

      </span>

    )

    if (href) {
      return (
        <Link to={href}>
          <Component />
        </Link>
      )
    }
  
    return <Component />
    
  }
}