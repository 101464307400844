/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Popover, Empty, Modal, Icon, message } from 'antd'
import { LinkButton } from '../../../packages/sota'
import { getMedia, uploadMedia, renameMedia, deleteMedia } from '../../Api/media'
import styles from './styles.css'

import Table from './Components/Table'

export default class MediaPopover extends React.PureComponent {

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    modelType: PropTypes.string.isRequired,
    modelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    collection: PropTypes.string,
    fileChanges: PropTypes.number,
    moreMenu: PropTypes.func,
    upload: PropTypes.func.isRequired,
  }

  static defaultProps = {
    collection: 'default',
    fileChanges: null,
    moreMenu: null,
  }
  
  state = {
    mediaItems: [],
    isFetching: false,
    empty: true,
  }

  componentDidMount = () => {
    this._isMounted = true
    this.fetch()
  }
  
  componentDidUpdate = (prevProps) => {
    if (prevProps.fileChanges !== this.props.fileChanges) {
      this.fetch()
    }
  }
  
  componentWillUnmount = () => {
    this._isMounted = false
  }

  fetch = async () => {

    this.setState({ isFetching: true })

    const { data: { data: mediaItems } } = await getMedia({
      params: {
        'filter[model_type]': this.props.modelType,
        'filter[model_id]': this.props.modelId,
        'filter[collection_name]': this.props.collection,
        'append': 'thumb_square_url',
        sort: '-created_at',
      },
    })

    this.setState({
      mediaItems,
      isFetching: false,
      empty: mediaItems.length === 0,
    })

  }

  onDrop = (files) => {

    const data = {
      modelType: this.props.modelType,
      modelId: this.props.modelId,
      collection: this.props.collection,
    }

    const request = uploadMedia

    const onSuccess = () => () => {
      if (this._isMounted) {
        this.fetch()
      }
    }

    const onFailure = (error, file) => () => message.error(`Failed to upload ${file.name}`)

    const uploads = files.map(file => ({
      file,
      data,
      request,
      onSuccess,
      onFailure,
    }))

    this.props.upload(uploads)
  }

  onDownload = async (media) => {

    const response = await getMedia({
      id: media.id,
      params: {
        append: 'url',
      },
    })

    window.open(response.data.data.url)

  }

  onDelete = (media) => {

    const onOk = async () => {

      await deleteMedia({
        id: media.id,
      })

      await this.fetch()

    }

    Modal.confirm({
      title: 'Delete File?',
      content: 'This action cannot be undone.',
      icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
      onOk,
      okType: 'danger',
      okText: 'Delete',
      zIndex: 10000,
    })

  }

  onRename = async (media, name) => {

    await renameMedia({
      id: media.id,
      data: { name },
    })

    await this.fetch()

  }
  
  onVisibleChange = (visible) => {
    if (visible) this.fetch()
  }
  
  renderContent = () => {
    
    const {
      moreMenu,
    } = this.props
    
    const {
      mediaItems,
      isFetching,
      empty,
    } = this.state
    
    const {
      onDrop,
      onDownload,
      onRename,
      onDelete,
    } = this
    
    return (
      
      <Dropzone onDrop={onDrop}>

        {({ getRootProps, getInputProps, open, isDragActive }) => (
      
          <div {...getRootProps()} onClick={() => null} className={styles.wrapper}>
            
            <input {...getInputProps()} />
          
            <div className={styles.header}>

              <div className={styles.title}>Files</div>

              <LinkButton onClick={() => open()} type="primary">
                Upload Files
              </LinkButton>

            </div>

            {(empty) && (
              <div className={styles.empty}>
                <Empty />
              </div>
            )}

            {(!empty) && (
              <Table
                mediaItems={mediaItems}
                isFetching={isFetching}
                moreMenu={moreMenu}
                onDownload={onDownload}
                onRename={onRename}
                onDelete={onDelete}
              />
            )}
            
            {isDragActive && (
              <div className={styles.dropzone}>
                <Icon type="cloud-upload" className={styles.icon} />
                <div className={styles.text}>Upload File</div>
              </div>
            )}
          
          </div>

        )}

      </Dropzone>
      
    )
  }
  
  render () {
    
    const {
      onVisibleChange,
    } = this
    
    const {
      children,
    } = this.props
    
    const {
      mediaItems,
    } = this.state
    
    const count = mediaItems.length
    
    return (
      
      <Popover
        content={this.renderContent()}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName={styles.popover}
        onVisibleChange={onVisibleChange}
      >
        
        {typeof children === 'object' && children}
        
        {typeof children === 'function' && children({ count })}
        
      </Popover>

    )
  }
}