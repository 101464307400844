import screenProps from '../screenProps'
import initialize from '../Services/initialize'
import initializeParts from '../Components/Parts/Services/initialize'

export default (props, prevProps) => async (dispatch, getState) => {

  screenProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    const { activeTab } = getState().screens.Manufacturer

    await dispatch(initialize())

    if (activeTab === 0) await dispatch(initializeParts())

  }

}
