import React from 'react'
import { getCurrencies } from '../../Api/currencies'

import Select from './Select'

export default class Currency extends React.PureComponent {

  fetchOptions = async () => {

    const response = await getCurrencies({
      params: {
        sort: 'id',
      },
    })
    
    return response.data.data

  }

  render () {

    return (

      <Select
        keyProp="id"
        labelProp="code"
        placeholder="Select Currency"
        fetchOptions={this.fetchOptions}
        {...this.props}
      />

    )
  }
}