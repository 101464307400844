import { resetInitialized } from '../State/initialized'
import { resetManufacturer } from '../State/manufacturer'
import { resetActiveTab } from '../State/activeTab'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetManufacturer())
  dispatch(resetActiveTab())
  
}
