import { getCustomerEnquiryItems } from '../../../../../Api/customerEnquiryItems'
import { setCustomerEnquiryItems } from '../State/customerEnquiryItems'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  try {

    const { id } = getState().screens.CustomerEnquiry.customerEnquiry

    const response = await getCustomerEnquiryItems({
      params: {
        'filter[customer_enquiry_id]': id,
        include: [
          'customer_quote_items.sourced_part.supplier.currency',
          'customer_quote_items.sourced_part.service',
          'customer_quote_items.part.manufacturer',
          'customer_quote_items.service',
          'customer_quote_items.sales_order_items',
        ].join(','),
      },
    })

    dispatch(setCustomerEnquiryItems(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
